// outsource dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
// UI
import Typography from '@material-ui/core/Typography';
// local dependencies
// UI
import Layout from 'components/layout/Common';
interface IProps {
  pageTitle: string;
  language: string;
}

export default function Contacts(props: IProps) {
  const { pageTitle, language } = props;
  const { t } = useTranslation();
  let { status } = useParams<{ status: string }>();
  return (
    <Layout pageTitle={pageTitle} language={language}>
      <Typography variant='h5'>{t('public_page.email_confirmation.title')}</Typography>
      <br />
      {status === 'confirmed' && <Typography>{t('public_page.email_confirmation.confirmed')}</Typography>}
      {status === 'not-found' && <Typography>{t('public_page.email_confirmation.not_found')}</Typography>}
    </Layout>
  );
}
