import { processingTasks } from 'private-layout/processing-tasks/reducer';
import { userProfile } from 'private-layout/user-profile/reducer';
import { addFile } from 'private-layout/add-file/reducer';
import { prices } from 'private-layout/prices/reducer';
import {edFileList} from "private-layout/encrypt-decrypt/file-list/reducer";
import {edPayment} from "private-layout/encrypt-decrypt/ed-payment/reducer";
import {edFileView} from "private-layout/encrypt-decrypt/file-view/reducer";
import {edUploadFile} from "private-layout/encrypt-decrypt/upload/reducer";
import {ticket} from "private-layout/ticket/reducer";

export default { processingTasks, userProfile, addFile, prices, edFileList, edPayment, edFileView, edUploadFile, ticket };
