// outsource dependencies
import { fork, takeLatest, call, put, select } from 'redux-saga/effects';
// local dependencies
import { TYPE, updateExpectAnswerAction, selector as changePasswordSelector, updateNewPasswordSuccessAction } from 'public-layout/change-password/reducer';
import API from 'services/request.service';

function* changePasswordSaga() {
  const { newPassword, token } = yield select(changePasswordSelector);
  yield put(updateExpectAnswerAction(true));
  try {
    yield call<any>(API, {
      url: '/client/set-new-password',
      method: 'POST',
      data: {
        newPassword,
        token,
      },
    });
    yield put(updateNewPasswordSuccessAction(true));
    yield put(updateExpectAnswerAction(false));
  } catch (e) {
    console.error('changePasswordSaga error: ', e.response, e);
    yield put(updateExpectAnswerAction(false));
  }
}

function* activityTasks() {
  yield takeLatest(TYPE.CHANGE_PASSWORD, changePasswordSaga);
}

export function* sagas() {
  yield fork(activityTasks);
}

export default sagas;
