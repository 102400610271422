// outsource dependencies
import { fork } from 'redux-saga/effects';
// local dependencies
import { sagas as loginSagas } from 'public-layout/log-in/saga';
import { sagas as signUpSagas } from 'public-layout/sign-up/saga';
import { sagas as changePasswordSagas } from 'public-layout/change-password/saga';

export function* sagas() {
  yield fork(loginSagas);
  yield fork(signUpSagas);
  yield fork(changePasswordSagas);
}

export default sagas;
