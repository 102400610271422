// outsource dependencies
import React from 'react';
// UI
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
// local dependencies
// UI
import Layout from 'components/layout/Common';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  title: {
    fontWeight: 600,
    fontSize: 20,
  },
  center: {
    textAlign: 'center',
  },
  text: {
    textIndent: 20,
  },
  italic: {
    fontStyle: 'italic'
  }
});

interface IProps {
  pageTitle: string;
  language: string;
}

export default function UserAgreement(props: IProps) {
  const { pageTitle, language } = props;
  const { i18n } = useTranslation();
  const classes = useStyles();
  return (
    <Layout pageTitle={pageTitle} language={language}>
      {i18n.language === 'ua' && (
        <>
          <Typography className={classNames(classes.title, classes.center)}>Умови використання</Typography>
          <Typography className={classes.title}>Набирає чинності з 01 січня 2020 року</Typography>
          <Typography className={classes.title}>Преамбула</Typography>
          <Typography className={classes.text}>
            Дані Умови використання motorcar tuning складають Угоду користувача (далі «Угода») між Вами як Користувачем і Сервісом тюнінгу автомобілів (далі - motorcar tuning). Починаючи використання сервісу motorcar tuning, Ви підтверджуєте свою дієздатність для укладання цієї Угоди та прийняття умов Угоди у повному обсязі.
          </Typography>
          <Typography className={classes.text}>
            Для цілей цієї Угоди та регулювання правових відносин між motorcar tuning та Користувачем наведені нижче терміни будуть мати таке значення:
          </Typography>
          <Typography className={classes.text}>
            <strong>Користувач</strong> – фізична/юридична особа як користувач мережі Інтернет, яка має намір скористатися послугами motorcar tuning;
            tuning;
          </Typography>
          <Typography className={classes.text}>
            <strong>Послуги</strong> – будь-які доступні сервіси motorcar tuning, у тому числі, але не виключно, завантаження, аналіз, обробка файлів, а також будь-які інші сервіси, які пропонуються Користувачам на сайті https://motorcar-tuning.shop/;
          </Typography>
          <Typography className={classes.text}>
            <strong>Обробка файлу</strong> – конкретна послуга з обсягу Послуг з налаштування автомобіля, яка надається Користувачеві на Сайті автоматично або вручну;
          </Typography>
          <Typography className={classes.text}>
            <strong>Сайт</strong> - https://motorcar-tuning.shop/;
          </Typography>
          <Typography className={classes.text}>
            <strong>Конфіденційна інформація</strong> - будь-яка інформація, у тому числі, але не виключно, факти та дані, що використовуються у Угоді, укладеній між motorcar tuning та Користувачем; інформація, факти та дані з питань тюнінгу автомобілів та Користувача, яка стала відомою сторонам під час або у зв'язку із укладенням Угоди або дотриманням окремих її умов; інформація про платіжні операції та використання платіжних інструментів, а також будь-яка інша інформація, що стосується дії будь-якої зі сторін; інформація, що класифікується її власником як конфіденційна або її конфіденційний характер випливає з її суті або обставин, про які відомо іншій стороні;
          </Typography>
          <Typography className={classes.text}>
            <strong>Права інтелектуальної власності </strong> - будь-які права, які існують зараз чи в майбутньому відповідно до патентного законодавства, законодавства про захист даних, про товарні знаки, про недобросовісну конкуренцію, незалежно від того, чи зареєстровані вони, чи може бути зареєстровані, і чи існують у будь-якій конкретній країні (-ах), а також будь-які інші права інтелектуальної власності будь-якого роду, включаючи, крім іншого, права на доменне ім'я motorcar tuning, елементи дизайну Сайту, файли ECU, текст, графіка, ілюстрації, відео, програмне забезпечення, бази даних та інші об'єкти, а також будь-який контент, розміщений на Сайті.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>1. Загальні положення</Typography>
          <Typography className={classes.text}>
            <strong>1.1.</strong> У Угоді викладаються основні права та обов'язки, що випливають із договірних відносин, встановлених між motorcar tuning та Користувачем, які є обов'язковими для обох Сторін з дати, коли Користувач висловлює свій намір скористатися послугами motorcar tuning шляхом реєстрації Облікового запису та/або придбанням будь-якої з доступних послуг .
          </Typography>
          <Typography className={classes.text}>
            <strong>1.2.</strong>
            Використовуючи послуги, що надаються, Користувач приймає всі умови, описані в цій Угоді, і погоджується з усіма положеннями Політики конфіденційності. Усі тексти опубліковані на сайті.
          </Typography>
          <Typography className={classes.text}>
            <strong>1.3.</strong>
            Умови надання послуг залежать від типу обробки файлу. Обробка Файла з позначкою «Автоматичний режим» обробляється незалежно від часу. Обробка Файла з позначкою «Ручний режим» обробляється в середньому не більше протягом однієї години, якщо файл завантажений після 18:00 він обробляється протягом наступного робочого дня, якщо інше не погоджено Сторонами заздалегідь.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>2. Обліковий запис Користувача</Typography>
          <Typography className={classes.text}>
            <strong>2.1.</strong>
            Для доступу до певних Послуг Користувач повинен зареєструватися на Сайті для створення особистого облікового запису, вказавши свою адресу електронної пошти як логін та створивши унікальний пароль для доступу до облікового запису, при цьому Користувач зобов'язується використовувати обліковий запис самостійно та не передавати доступ до облікового запису записи третіх осіб.
          </Typography>
          <Typography className={classes.text}>
            <strong>2.2.</strong>
            Для реєстрації Користувач зобов'язується надати дійсну та повну інформацію, яку запитують у реєстраційній формі, та оновлювати її у разі зміни такої. Якщо інформація, надана Користувачем та використана в Угоді, виявляється недостовірною, неповною, неточною та незрозумілою motorcar tuning може на свій розсуд заблокувати або видалити обліковий запис Користувача або відмовитися від надання Послуг Користувачеві.
          </Typography>
          <Typography className={classes.text}>
            <strong>2.3.</strong>
            Інформація виявляється недостовірною, неповною, неточною, незрозумілою у разі, якщо Користувач не може/відмовляється підтвердити її, надавши документи. на запит motorcar tuning.
          </Typography>
          <Typography className={classes.text}>
            <strong>2.4.</strong>
            Користувач має право видалити обліковий запис у будь-який час – у цьому випадку Користувач повинен надіслати запит до служби підтримки contacts@motorcar-tuning.shop. У певних випадках дія облікового запису користувача може бути припинена щодо конкретної Послуги (якщо ця опція доступна в обліковому записі Користувача).
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>3. Зобов'язання та відповідальність Користувача</Typography>
          <Typography className={classes.text}>
            Користувач підтверджує дотримання всіх наведених нижче положень, зокрема, Користувач зобов'язується:
          </Typography>
          <Typography className={classes.text}>
            <strong>3.1.</strong>
            Нести відповідальність за безпеку облікового запису, за конфіденційність даних, що надаються, а також за будь-які дії (і їх наслідки) при налаштуванні облікового запису користувача, включаючи добровільну передачу користувачем інформації, необхідної для доступу до облікового запису користувача, третім особам на будь-яких умовах (у в тому числі за контрактами чи угодами); будь-які дії по тюнінгу автомобіля або з тюнінгом автомобіля, вчинені через обліковий запис Користувача, вважаються виконаними Користувачем, за винятком випадків, коли Користувач повідомляє motorcar tuning про несанкціонований доступ до Сервісів через обліковий запис Користувача та/або будь-яке інше порушення (передбачуване порушення) засобів доступу до його облікового запису;
          </Typography>
          <Typography className={classes.text}>
            <strong>3.2.</strong>
            Нести відповідальність за відповідність будь-якого контенту, розміщеного Користувачем, застосовним юридичним вимогам, включаючи відповідальність перед третіми особами у разі розміщення Користувачем будь-якого контенту, який порушує права та законні інтереси третіх осіб, включаючи особисті немайнові права авторів, будь-які інші права інтелектуальної власності на motorcar tuning, його конфіденційну інформацію;
          </Typography>
          <Typography className={classes.text}>
            <strong>3.3.</strong>
            Нести відповідальність перед третіми сторонами за будь-які дії, пов'язані з використанням послуг motorcar tuning, включаючи будь-які дії, що призводять до порушення прав та законних інтересів третіх осіб, а також недотримання локального/міжнародного законодавства при використанні motorcar tuning.
          </Typography>
          <Typography className={classes.text}>
            Користувач підтверджує, що motorcar tuning може обробляти Контент користувача на свій розсуд і може відтворювати Контент користувача відповідно до технічних вимог конкретної Послуги.
          </Typography>
          <Typography className={classes.text}>Користувач зобов'язується:</Typography>
          <Typography className={classes.text}>
            <strong>3.4.</strong>
            Не порушувати права третіх осіб, у тому числі неповнолітніх, та/або завдавати шкоди будь-яким способом;
          </Typography>
          <Typography className={classes.text}>
            <strong>3.5.</strong>
            Не видавати себе за іншу особу чи представника організації та/або спільноти, не маючи на те відповідних повноважень;
          </Typography>
          <Typography className={classes.text}>
            <strong>3.6.</strong>
            Не сприяти будь-яким діям, спрямованим на порушення будь-яких обмежень та заборон, що накладаються цією Угодою, або інше порушення правових стандартів, включаючи локальне та міжнародне законодавство.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>4. Заходи безпеки</Typography>
          <Typography className={classes.text}>
            <strong>4.1.</strong>
            motorcar tuning підтверджує, що будь-яка персональна інформація Користувача, яка міститься в обліковому записі Користувача, зберігатиметься та оброблятиметься motorcar tuning відповідно до Політики конфіденційності.
          </Typography>
          <Typography className={classes.text}>
            <strong>4.2.</strong>
            motorcar tuning зберігає за собою право накладати обмеження на використання послуг для всіх Користувачів або певних категорій користувачів (залежно від розташування користувача, мови тощо) у тому числі, але не виключно, на доступність/недоступність певних Послуг, період зберігання завдань або файлів та будь-якого іншого контенту, кількість завдань або файлів на одного зареєстрованого Користувача, максимальний розмір файлу або дискового простору, максимальна кількість сервісів, використаних за певний період, максимальний період зберігання контенту, спеціальні параметри завантажуваного контенту. motorcar tuning може заборонити автоматичні запити до своїх серверів, а також припинити прийом будь-якої автоматично створеної інформації. motorcar tuning зберігає за собою право надсилати інформаційні повідомлення своїм користувачам.
          </Typography>
          <Typography className={classes.text}>
            <strong>4.3.</strong>
            motorcar tuning має право заблокувати або видалити обліковий запис Користувача, а також заборонити доступ через будь-який обліковий запис до певних Послуг та видалити будь-який контент без вказівки причин, у тому числі, але не виключно, у разі порушення Користувачем умов цієї Угоди та умов будь-яких документів, перерахованих у п. 1.3. цієї Угоди, а також у разі відмови від використання Послуги.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>5. Умови повернення коштів</Typography>
          <Typography className={classes.text}>
            <strong>5.1.</strong>
            Користувач має право заявляти претензії на якість обробки файлів протягом 30 календарних днів з моменту оплати замовлення. Цей період розраховується для кожної обробки файлу окремо і не може бути включений в іншу обробку файлу.
          </Typography>
          <Typography className={classes.text}>
            <strong>5.2.</strong>
            Для розгляду претензії щодо якості Послуги Користувач повинен надіслати електронне повідомлення на адресу contacts@motorcar-tuning.shop з адреси електронної пошти, яка використовувалася в обліковому записі користувача, в іншому випадку претензії не розглядається. Повідомлення має містити докладну інформацію про проблеми із Завданням обробки, включаючи опис проблеми, фотографії, скріншоти та іншу інформацію, що підтверджує наявність проблеми, а також будь-які додаткові дані, які запитує Користувач при використанні послуг motorcar tuning.
          </Typography>
          <Typography className={classes.text}>
            <strong>5.3.</strong>
            Користувач зобов'язується очікувати, поки motorcar tuning не вирішить проблему, або повідомить Користувача, що проблема не може бути вирішена.
          </Typography>
          <Typography className={classes.text}>
            <strong>5.4.</strong>
            Претензія про якість наданої Послуги розглядається протягом 14 робочих днів після підтвердження її отримання motorcar tuning. Користувач буде повідомлено про результати електронною поштою.
          </Typography>
          <Typography className={classes.text}>
            <strong>5.5.</strong>
            Обґрунтованість претензії визначається motorcar tuning на власний розсуд.
          </Typography>
          <Typography className={classes.text}>
            <strong>5.6.</strong>
            Повернення коштів здійснюється виключно за дотримання умов 5.1 - 5.4. цієї Угоди
          </Typography>

          <Typography className={classNames(classes.title, classes.center)}>6. Інтелектуальна власність</Typography>
          <Typography className={classes.text}>
            <strong>6.1.</strong>
            Користувач зобов'язується не: відтворювати, дублювати або копіювати, продавати, перепродавати або використовувати в будь-яких комерційних цілях будь-які дані motorcar tuning (включаючи контент, доступний Користувачеві через послуги) або персональний доступ до motorcar tuning, за винятком випадків, коли це дозволено motorcar tuning або це прямо зазначене у Угоді користувача.
          </Typography>
          <Typography className={classes.text}>
            <strong>6.2.</strong>
            Будь-які об'єкти, що надаються motorcar tuning, включаючи елементи дизайну, файли ECU, текст, графіку, ілюстрації, відео, програмне забезпечення, бази даних та інші об'єкти (далі - Зміст послуг), а також будь-який контент, розміщений на Сайті, є винятковим власністю motorcar tuning, Користувачів та інших правовласників.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>7. Відмова від гарантій & Limitations of Liability</Typography>
          <Typography className={classes.text}>
            <strong>7.1.</strong>
            Користувач користується послугами motorcar tuning на свій страх та ризик. При цьому motorcar tuning не несе відповідальності в тому числі, але не виключно за відповідність Послуг очікуваної мети Користувача;
          </Typography>
          <Typography className={classes.text}>
            <strong>7.2.</strong>
            motorcar tuning не гарантує наступне: послуги відповідають / відповідатимуть вимогам Користувача; послуги будуть надаватися без перебоїв, швидко, стійко та без помилок; результати, отримані з використанням Послуг є точними та надійними та можуть використовуватись для будь-яких цілей та в будь-якій якості (наприклад, для ідентифікації та/або перевірка будь-яких фактів); якість будь-якого продукту, послуги, інформації тощо, отриманої через Послуги, відповідатиме вимогам/очікуванням Користувача.
          </Typography>
          <Typography className={classes.text}>
            <strong>7.3.</strong>
            Будь-яка інформація та/або матеріали (включаючи файли ECU, завантажуване програмне забезпечення, повідомлення, будь-які інструкції та посібники тощо), до яких Користувач отримує доступ через motorcar tuning, використовується Користувачем на свій страх та ризик, та Користувач несе відповідальність за будь-які потенційні наслідки використання такої інформації та/або матеріалів, включаючи будь-які збитки персональним пристроям Користувача або третім особам, будь-яке пошкодження транспортного засобу Користувача або третіх осіб або будь-які інші збитки;
          </Typography>
          <Typography className={classes.text}>
            <strong>7.4.</strong>
            motorcar tuning не несе відповідальності за будь-які збитки, які можуть виникнути внаслідок використання Користувачем Послуг motorcar tuning або окремих частин/функцій Послуг;
          </Typography>
          <Typography className={classes.text}>
            <strong>7.5.</strong>
            У разі виникнення претензій до якості послуг з тюнінгу автомобілів Користувачеві необхідно зв'язатися зі службою підтримки електронною поштою contacts@motorcar-tuning.shop.
          </Typography>
          <Typography className={classes.text}>
            <strong>7.6.</strong>
            Усі ризики щодо програмування блоку управління несе майстер, який працює з ЕБУ або автомобілем загалом. При запиті ми обчислюємо контрольну суму прошивки файлу (КС) у разі наявності такої можливості.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>8. Вирішення спорів</Typography>
          <Typography className={classes.text}>
            <strong>8.1.</strong>
            Будь-яка суперечка між Користувачем та motorcar tuning, його співробітниками, посадовими особами, директорами, керівниками, правонаступниками, що виникає, або пов'язані з цією Угодою та її тлумаченням/порушенням, а також правовідносини, що випливають з Угоди, включаючи суперечки щодо дійсності, обсягу цієї Угоди вирішуються шляхом переговорів між Сторонами.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>9. Інші положення</Typography>
          <Typography className={classes.text}>
            <strong>9.1.</strong>
            Використовуючи motorcar tuning Користувач підтверджує прийняття цієї Угоди в повному обсязі, без будь-яких обмежень та винятків. Якщо Користувач не погоджується з будь-якими положеннями цієї Угоди, Користувач не має права використовувати motorcar tuning, Сайт та будь-які доступні Послуги.
          </Typography>
          <Typography className={classes.text}>
            <strong>9.2.</strong>
            Ніщо в цій Угоді не повинно тлумачитися як агентські, партнерські, спільні, трудові або будь-які інші відносини, які прямо не вказані в цій Угоді.
          </Typography>
          <Typography className={classes.text}>
            <strong>9.3.</strong>
            Визнання уповноваженим органом будь-якого положення Угоди недійсним не тягне за собою недійсності чи нездійсненності інших положень цієї Угоди.
          </Typography>
          <Typography className={classes.text}>
            <strong>9.4.</strong>
            Нездатність motorcar tuning діяти у разі порушення Користувачем положень цієї Угоди не позбавляє motorcar tuning права вживати заходів щодо захисту своїх інтересів у майбутньому і не повинна тлумачитися як відмова motorcar tuning від своїх прав у разі будь-яких майбутніх подібних чи ідентичних порушень.
          </Typography>
          <Typography className={classes.text}>
            <strong>9.5.</strong>
            У випадку, якщо motorcar tuning або Користувач не може виконати будь-яке зобов'язання за цією Угодою з будь-якої причини, що знаходиться поза розумним контролем сторони, яка посилається на це положення, виконання таких зобов'язань буде відкладено на період затримки або неможливості їх виконання.
          </Typography>
        </>
      )}
      {i18n.language === 'en' && (
        <>
          <Typography className={classNames(classes.title, classes.center)}>motorcar tuning service Terms of Use</Typography>
          <Typography className={classes.title}>Effective: 01 January, 2020</Typography>
          <Typography className={classes.title}>Preamble</Typography>
          <Typography className={classes.text}>
            These motorcar tuning service Terms of Use form the Service Agreement (hereinafter referred to as the “Agreement”) between you as the User and
            motorcar tuningservice (hereinafter referred to as the “motorcar tuning”). By using the motorcar tuning you represent to us that you are legally
            competent to enter into this Agreement.
          </Typography>
          <Typography className={classes.text}>
            For the purposes of this Agreement and the legal relationship between motorcar tuning and the Users the following terms will have the meanings given
            to them below:
          </Typography>
          <Typography className={classes.text}>
            <strong>User</strong> – an individual / a legal entity as Internet user who intends to use the services provided by the motorcar tuning;
          </Typography>
          <Typography className={classes.text}>
            <strong>Services</strong> – all available functions and opportunities of the motorcar tuning including uploading, analysis, modification of files,
            searching engines which are offered to the Users at the website https://motorcar-tuning.shop/;
          </Typography>
          <Typography className={classes.text}>
            <strong>Processing Task</strong> – specific service from the scope of Services provided by motorcar tuning to the Client automatically or manually;
          </Typography>
          <Typography className={classes.text}>
            <strong>Business days</strong> – the period of time from 9:00 a.m. to 7 p.m., Monday through Friday (except public or other holidays);
          </Typography>
          <Typography className={classes.text}>
            <strong>Website</strong> - https://motorcar-tuning.shop/;
          </Typography>
          <Typography className={classes.text}>
            <strong>Confidential information</strong> - any information, facts and data that are used in the Agreement made between motorcar tuning and the
            User, or information, facts and data on matters relating to motorcar tuning and the User, which came to the knowledge of the parties during or in
            connection with the conclusion of the Agreement or compliance with the individual contractual terms and conditions, or information on payment
            transactions and on the use of funds through payment tools, and any other information relating to the activities of any of the parties having a
            certain value and capacity to cause benefit or harm to motorcar tuning /User, or information that is classified by its provider as confidential or
            its confidential nature results from its essence or circumstances of which the other party is aware;
          </Typography>
          <Typography className={classes.text}>
            <strong>Intellectual property rights</strong> - any and all rights existing now or in the future under patent law, copyright law, data and database
            protection law, trade secret law, trademark law, competition law, whether or not registered or capable of registration, and whether subsisting in
            any specific country(-ies) or any other part of the world, and any and all other proprietary rights of any kind, including without limitation rights
            to domain name of the motorcar tuning, design elements, ECU files, text, graphics, illustrations, video, software, databases and other objects as
            well as any content posted at the Website.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>1. General Provisions</Typography>
          <Typography className={classes.text}>
            <strong>1.1.</strong>
            The Agreement set out the basic rights and obligations resulting from a contractual relationship established between motorcar tuning and User; and
            are binding for all the parties involved in this relationship from the date when the User expresses its intention to enter into a contractual
            relationship with motorcar tuning by using of any services/certain functions or going through the registration procedure.
          </Typography>
          <Typography className={classes.text}>
            <strong>1.2.</strong>
            Using the provided services the User accepts all the conditions described in this Agreement and agrees with all the statements of the Privacy
            Policy. All the texts are published at the Website.
          </Typography>
          <Typography className={classes.text}>
            <strong>1.3.</strong>
            The terms of providing Services are depend on type of Processing Task. Processing Task with a label “automatic mode” is being processed regardless
            of the time of day. Processing Task with a label “manual mode” is being processed during business day wherein the files uploaded after 6 p.m. is
            being processed during the next business day unless otherwise agreed by parties in advance.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>2. The User Account</Typography>
          <Typography className={classes.text}>
            <strong>2.1.</strong>
            To obtain certain Services the User has to sign up at the Website for creation a personal account by providing its email address as login and
            creating unique password to access the account wherein the User agrees to use account solely and not transfer access to the account to any third
            parties.
          </Typography>
          <Typography className={classes.text}>
            <strong>2.2.</strong>
            For signing up the User has to provide valid and complete information requested in the registration form and shall regularly update such
            information. In case the information provided by the Client and used in the Agreement proves to be untrue, incomplete, inaccurate, and
            incomprehensible* motorcar tuning may at its discretion block or delete the User’s account or refuse providing Services to the User.
          </Typography>
          <Typography className={classes.text}>
            <strong>2.3.</strong>
            The information proves to be untrue, incomplete, inaccurate, incomprehensible in case the User could not / refuses / fails to verify it by providing
            the documents requested by motorcar tuning
          </Typography>
          <Typography className={classes.text}>
            <strong>2.4.</strong>
            The User has the right to delete the account at any time – in this case User has to send a request to the support service{' '}
            contacts@motorcar-tuning.shop. In certain cases the User account may be terminated regarding specific Service*.
          </Typography>
          <Typography className={classes.text}>
            <strong>2.5.</strong> In case this option is available in the User account.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>3. The User Obligations and Responsibility</Typography>
          <Typography className={classes.text}>The User confirms to comply all the below mentioned statements, specifically the User has to:</Typography>
          <Typography className={classes.text}>
            <strong>3.1.</strong> be responsible for security of the account for confidentiality of such means as for any actions (and their consequences) in
            and with motorcar tuning through the User’s account including voluntary transfer by the User of information required to access the User account to
            third parties on any conditions (including by contracts or agreements); any actions in or with motorcar tuning performed through the User’s account
            shall be considered done by the User, except the cases when the User notifies motorcar tuning of unauthorized access to Services through the User’s
            account and/or any other breach (alleged breach) of confidentiality of the chosen means of access to his/her account;
          </Typography>
          <Typography className={classes.text}>
            <strong>3.2.</strong> be responsible for compliance of any content posted by the User with applicable legal requirements including responsibility to
            third parties in case posting by the User of any content or its subject matter infringes on rights and legitimate interests of third parties
            including personal non-property rights of authors, any other intellectual property rights of motorcar tuning and its confidential information;
          </Typography>
          <Typography className={classes.text}>
            <strong>3.3.</strong> be responsible to third parties for any actions related to the use of motorcar tuningservice including any actions resulting
            in violation of rights and legitimate interests of third parties as well as compliance with laws upon using the motorcar tuning.
          </Typography>
          <Typography className={classes.text}>
            The User confirms that the motorcar tuningService can process the User content at its discretion and can reproduction of User content for compliance
            with technical requirements of a particular Service.
          </Typography>
          <Typography className={classes.text}>The User is obliged not to:</Typography>
          <Typography className={classes.text}>
            <strong>3.4.</strong> violate third party rights including underage people and/or cause harm in any way;
          </Typography>
          <Typography className={classes.text}>
            <strong>3.5.</strong> impersonate any other person or representative of an organization and/or community without being authorized to do so;
          </Typography>
          <Typography className={classes.text}>
            <strong>3.6.</strong> assist any actions to violate any restrictions and prohibitions imposed by this Agreement or otherwise violate legal standards
            including international law.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>4. Security Measures</Typography>
          <Typography className={classes.text}>
            <strong>4.1.</strong> motorcar tuning confirms that any personal information of the User contained in the User account shall be stored and processed
            by motorcar tuningService according to Privacy Policy.
          </Typography>
          <Typography className={classes.text}>
            <strong>4.2.</strong> motorcar tuning may impose restrictions on the use of services for all Users or certain categories of Users (depending on the
            User’s location, language of the service, etc.) including: availability/unavailability of certain service functions, period of storing tasks or
            files and any other content, the number of tasks or files by one registered user, maximum size of an file or disc space, maximum number of service
            uses in a certain period, maximum period of content storage, special parameters of downloadable content, etc. motorcar tuning may prohibit automatic
            requests to its servers as well as terminate acceptance of any automatically generated information. motorcar tuning may send information messages to
            its users.
          </Typography>
          <Typography className={classes.text}>
            <strong>4.3.</strong> motorcar tuning may block or delete the User’s account as well as prohibit access through any account to certain motorcar
            tuning services and delete any content without giving reasons including in case the User violates the terms of this Agreement and terms of any
            documents listed in Clause 1.3 hereof and in case of failure to use a service.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>5. Refund policy</Typography>
          <Typography className={classes.text}>
            <strong>5.1.</strong> The User has the right to claim on quality of processing of files within 30 calendar days from the date of payment of the
            order. This period is counted for each Processing Task independently and cannot be transferred to other Processing Task.
          </Typography>
          <Typography className={classes.text}>
            <strong>5.2.</strong> The User has to send the email message to contacts@motorcar-tuning.shop from email address that was used in the User account
            otherwise the claims are not accepted. The claim should contain detailed information about problems with the Processing Task, including a
            description of the problem, photos, screenshots and other information that confirms the existence of the problem and any additional data requested
            by motorcar tuning from the User.
          </Typography>
          <Typography className={classes.text}>
            <strong>5.3.</strong> In any case Service has priority right and the User has to wait until the motorcar tuning fix the problem or notify the User
            that the problem cannot be fixed.
          </Typography>
          <Typography className={classes.text}>
            <strong>5.4.</strong> The claim is considered by motorcar tuning within 14 business days for its validity*. The User will be notified regarding the
            results via e-mail.
          </Typography>
          <Typography className={classes.text}>
            <strong>5.5.</strong> Validness of the claim is determined by motorcar tuning on its discretion.
          </Typography>
          <Typography className={classes.text}>
            <strong>5.6.</strong> Refunds can be made exclusively in case of compliance with the provisions 5. - 5.4. of this Agreement
          </Typography>

          <Typography className={classNames(classes.title, classes.center)}>6. Intellectual Property</Typography>
          <Typography className={classes.text}>
            <strong>6.1.</strong>
            The User may not reproduce, duplicate or copy, sell, resell or use for any commercial purposes any parts of motorcar tuning (including content
            available to the User through services) or access to motorcar tuning, except when authorized by motorcar tuning or it is directly stated in the user
            agreement for any service.
          </Typography>
          <Typography className={classes.text}>
            <strong>6.2.</strong>
            Any objects available through motorcar tuning including design elements, ECU files, text, graphics, illustrations, video, software, databases and
            other objects (hereinafter – service content) as well as any content posted at motorcar tuning are exclusive property of motorcar tuning, Users and
            other right holders.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>7. Disclaimer of Warranties &amp; Limitations of Liability</Typography>
          <Typography className={classes.text}>
            <strong>7.1.</strong>
            The User uses motorcar tuning services at his own risk. Services are provided as is. motorcar tuningService accepts not responsibility including for
            compliance of services with the User's goals;
          </Typography>
          <Typography className={classes.text}>
            <strong>7.2.</strong>
            motorcar tuning does not guarantee that services comply/will comply with User's requirements; that services will be provided without interruptions,
            promptly, sustainably and without errors; that results received with the use of services are accurate and reliable and may be used for any purposes
            or in any capacity (for instance, for identification and/or verification of any facts); that the quality of any product, service, information, etc.
            received through the services will meet the User's expectations;
          </Typography>
          <Typography className={classes.text}>
            <strong>7.3.</strong>
            Any information and/or materials (including ECU files, downloadable software, messages, any instructions and guidelines, etc.) which the User
            accesses through motorcar tuning may be used by the User at his own risk and the User shall be responsible for any potential consequences of the use
            of such information and/or materials including any damage to the User’s computer or third parties, any damage to the User’s vehicle or third parties
            or any other damage;
          </Typography>
          <Typography className={classes.text}>
            <strong>7.4.</strong> motorcar tuning shall not be liable for any losses resulting from the User using motorcar tuning services or separate parts/
            functions of services;
          </Typography>
          <Typography className={classes.text}>
            <strong>7.5.</strong>
            If the User has claims to the quality of the services of motorcar tuning, the User should contact with support service by email 
            contacts@motorcar-tuning.shop.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>8. Resolutions of Disputes</Typography>
          <Typography className={classes.text}>
            <strong>8.1.</strong> Any dispute between the User and motorcar tuning and its agents, employees, officers, directors, principals, successors,
            assigns, subsidiaries or arising from or relating to this Agreement and their interpretation or the breach, termination or validity thereof, the
            relationships which result from the Agreement, including disputes about the validity, scope or enforceability of this Agreement will be settled
            by negotiation between the Parties.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>9. Miscellaneous</Typography>
          <Typography className={classes.text}>
            <strong>9.1.</strong>
            The User is considered to have accepted this Agreement in full without any limitations or exceptions. If the User does not agree to any provisions
            of this Agreement, the User cannot use motorcar tuning.
          </Typography>
          <Typography className={classes.text}>
            <strong>9.2.</strong> Nothing in this Agreement shall be interpreted as agency, partnership, mutual activities, employment or any other relations
            not directly stated in this Agreement.
          </Typography>
          <Typography className={classes.text}>
            <strong>9.3.</strong>
            In case any provision of the Agreement is or becomes unenforceable or invalid, the remaining provisions will continue with the same effect as if
            such unenforceable or invalid provision had not been inserted herein.
          </Typography>
          <Typography className={classes.text}>
            <strong>9.3.</strong>
            Failure by motorcar tuning to act in case of violation by the User or any other users of the provisions of this Agreement shall not deprive motorcar
            tuning of the right to take action to protect its interests in the future and shall not be interpreted as waiver by motorcar tuning of its rights in
            case of any future similar or identical violations.
          </Typography>
          <Typography className={classes.text}>
            <strong>9.4.</strong>
            In case motorcar tuning or the User are prevented from performing or unable to perform any obligation under this Agreement due to any cause beyond
            the reasonable control of the party invoking this provision, the affected party’s performance will be extended for the period of delay or inability
            to perform due to such occurrence.
          </Typography>
        </>
      )}
    </Layout>
  );
}
