// outsource dependencies
import { fork, takeLatest, call,  put, all, select } from 'redux-saga/effects';
import get from 'lodash/get';
// local dependencies
import {
  TYPE,
  selector as pricesSelector,
  updateExpectAnswerAction,
  //
  updateVehiclesAction,
  updateVehicleBrandsAction,
  // updateVehicleModelsAction,
  // updateVehicleEnginesAction,
  updateVehicleEcusAction,
  updateVehicleActionsAction,
  updateVehiclePriceAction,
  updateShowInfoAction,
  updateAllowDtcAction,
  updateAutoDtcAction,
  //
  updateVehicleBrandsLoadedAction,
  updateVehicleModelsLoadedAction,
  updateVehicleEnginesLoadedAction,
  updateVehicleEcusLoadedAction,
  updateVehicleActionsLoadedAction,
  //
  updateExpectBrandsAnswerAction,
  // updateExpectModelsAnswerAction,
  // updateExpectEnginesAnswerAction,
  updateExpectEcusAnswerAction,
  updateExpectActionsAnswerAction,
  updateExpectVehiclePriceAnswerAction,
  // for type check in effects only
  loadVehicleBrandsAction,
  // loadVehicleModelsAction,
  // loadVehicleEnginesAction,
  // loadVehicleEcusAction,
  // loadVehicleActionsAction,
  // interfaces
  IVehicleAction,
  updateSelectedVehicleKeyAction,
} from 'private-layout/prices/reducer';
import API from 'services/request.service';

function* loadVehiclesSaga() {
  yield put(updateExpectAnswerAction(true));
  try {
    const { data } = yield call<any>(API, {
      url: '/vehicle',
      method: 'GET',
    });
    const vehicles = get(data, 'response', null);
    // hide selects on reselect
    yield all([
      put(updateVehiclesAction(vehicles)),
      put(updateVehicleBrandsLoadedAction(false)),
      put(updateVehicleModelsLoadedAction(false)),
      put(updateVehicleEnginesLoadedAction(false)),
      put(updateVehicleEcusLoadedAction(false)),
      put(updateVehicleActionsLoadedAction(false)),
      put(updateAutoDtcAction(false)),
    ]);
    yield put(updateExpectAnswerAction(false));
  } catch (e) {
    console.error('loadVehiclesSaga error: ', e.response, e);
    yield put(updateExpectAnswerAction(false));
  }
}

function* loadVehiclesBrandsSaga({ payload }: ReturnType<typeof loadVehicleBrandsAction>) {
  yield put(updateExpectBrandsAnswerAction(true));
  yield put(updateSelectedVehicleKeyAction(payload));
  try {
    const { data } = yield call<any>(API, {
      url: '/vehicle/brands',
      method: 'POST',
      data: {
        vehicle: payload,
      },
    });
    const vehicleBrands = get(data, 'response', null);
    // hide selects on reselect
    yield all([
      put(updateVehicleBrandsAction(vehicleBrands)),
      put(updateVehicleBrandsLoadedAction(true)),
      put(updateVehicleModelsLoadedAction(false)),
      put(updateVehicleEnginesLoadedAction(false)),
      put(updateVehicleEcusLoadedAction(false)),
      put(updateVehicleActionsLoadedAction(false)),
      put(updateAutoDtcAction(false)),
    ]);
    yield put(updateExpectBrandsAnswerAction(false));
  } catch ({ message }) {
    console.error('loadVehiclesBrandsSaga error: ', message);
    yield put(updateExpectBrandsAnswerAction(false));
  }
}

// function* loadVehiclesModelsSaga({ payload }: ReturnType<typeof loadVehicleModelsAction>) {
//   yield put(updateExpectModelsAnswerAction(true));
//   try {
//     const { data } = yield call<any>(API, {
//       url: '/vehicle/models',
//       method: 'POST',
//       data: {
//         brand: payload,
//       },
//     });
//     console.log('loadVehiclesModelsSaga data', data);
//     const vehicleModels = get(data, 'response', null);
//     // hide selects on reselect
//     yield all([
//       put(updateVehicleModelsAction(vehicleModels)),
//       put(updateVehicleModelsLoadedAction(true)),
//       put(updateVehicleEnginesLoadedAction(false)),
//       put(updateVehicleEcusLoadedAction(false)),
//       put(updateVehicleActionsLoadedAction(false)),
//     ]);
//     yield put(updateExpectModelsAnswerAction(false));
//   } catch ({ message }) {
//     console.error('loadVehiclesModelsSaga error: ', message);
//     yield put(updateExpectModelsAnswerAction(false));
//   }
// }
// function* loadVehiclesEnginesSaga({ payload }: ReturnType<typeof loadVehicleEnginesAction>) {
//   yield put(updateExpectEnginesAnswerAction(true));
//   try {
//     const { data } = yield call<any>(API, {
//       url: '/vehicle/engine',
//       method: 'POST',
//       data: {
//         model: payload,
//       },
//     });
//     console.log('loadVehiclesEnginesSaga data', data);
//     const vehicleEngines = get(data, 'response', null);
//     // hide selects on reselect
//     yield all([
//       put(updateVehicleEnginesAction(vehicleEngines)),
//       put(updateVehicleEnginesLoadedAction(true)),
//       put(updateVehicleEcusLoadedAction(false)),
//       put(updateVehicleActionsLoadedAction(false)),
//     ]);
//     yield put(updateExpectEnginesAnswerAction(false));
//   } catch ({ message }) {
//     console.error('loadVehiclesEnginesSaga error: ', message);
//     yield put(updateExpectEnginesAnswerAction(false));
//   }
// }

function* loadVehiclesEcusSaga() {
  const { selectedBrandKey, selectedVehicleKey } = yield select(pricesSelector);
  yield put(updateExpectEcusAnswerAction(true));
  try {
    // const { data } = yield call<any>(API, {
    //   url: '/vehicle/ecus',
    //   method: 'POST',
    //   data: {
    //     engine: payload,
    //   },
    // });
    const { data } = yield call<any>(API, {
      url: '/vehicle/ecus',
      method: 'POST',
      data: {
        brand: selectedBrandKey,
        vehicle: selectedVehicleKey,
        search: '',
      },
    });
    const vehicleEcus = get(data, 'response', null);
    // hide selects on reselect
    yield all([
      put(updateVehicleEcusAction(vehicleEcus)),
      put(updateVehicleEcusLoadedAction(true)),
      put(updateVehicleActionsLoadedAction(false)),
      put(updateAutoDtcAction(false)),
    ]);
    yield put(updateExpectEcusAnswerAction(false));
  } catch ({ message }) {
    console.error('loadVehiclesEcusSaga error: ', message);
    yield put(updateExpectEcusAnswerAction(false));
  }
}

function* loadVehiclesActionsSaga() {
  const { selectedBrandKey, selectedEcusKey } = yield select(pricesSelector);
  yield put(updateExpectActionsAnswerAction(true));
  try {
    const { data } = yield call<any>(API, {
      url: '/vehicle/actions',
      method: 'POST',
      data: {
        key: `${selectedEcusKey}-${selectedBrandKey}`,
      },
    });
    let vehicleActions = get(data, 'response.actions', null);
    vehicleActions = Array.isArray(vehicleActions)
      ? vehicleActions.map((vehicleAction: IVehicleAction) => ({ ...vehicleAction, selected: false }))
      : vehicleActions;
    const allowDtc = get(data, 'response.allowDtc', null);
    const autoDtc = get(data, 'response.autoDtc', false);
    yield all([
      put(updateVehicleActionsAction(vehicleActions)),
      put(updateVehicleActionsLoadedAction(true)),
      put(updateAllowDtcAction(allowDtc)),
      put(updateAutoDtcAction(autoDtc)),
    ]);
    yield put(updateExpectActionsAnswerAction(false));
  } catch ({ message }) {
    console.error('loadVehiclesEcusSaga error: ', message);
    yield put(updateExpectActionsAnswerAction(false));
  }
}

function* loadVehiclePriceSaga() {
  const { vehicleActions, dtcKey, selectedEcusKey, selectedBrandKey } = yield select(pricesSelector);
  const actions = Array.isArray(vehicleActions)
    ? vehicleActions
        .filter((vehicleAction: IVehicleAction) => vehicleAction.selected)
        .map((vehicleAction: IVehicleAction) => vehicleAction.key)
        .join()
    : '';
  yield put(updateExpectVehiclePriceAnswerAction(true));
  try {
    const { data } = yield call<any>(API, {
      url: '/vehicle/price',
      method: 'POST',
      data: {
        ecu_brand: `${selectedEcusKey}-${selectedBrandKey}`,
        actions: actions,
        dtc: dtcKey,
      },
    });
    const vehiclePrice = get(data, 'response', null);
    yield all([put(updateVehiclePriceAction(vehiclePrice)), put(updateExpectVehiclePriceAnswerAction(false)), put(updateShowInfoAction(true))]);
  } catch ({ message }) {
    yield put(updateExpectVehiclePriceAnswerAction(false));
  }
}

function* activityTasks() {
  yield takeLatest(TYPE.LOAD_VEHICLES, loadVehiclesSaga);
  yield takeLatest(TYPE.LOAD_VEHICLE_BRANDS, loadVehiclesBrandsSaga);
  // yield takeLatest(TYPE.LOAD_VEHICLE_MODELS, loadVehiclesModelsSaga);
  // yield takeLatest(TYPE.LOAD_VEHICLE_ENGINES, loadVehiclesEnginesSaga);
  yield takeLatest(TYPE.LOAD_VEHICLE_ECUS, loadVehiclesEcusSaga);
  yield takeLatest(TYPE.LOAD_VEHICLE_ACTIONS, loadVehiclesActionsSaga);
  yield takeLatest(TYPE.LOAD_VEHICLE_PRICE, loadVehiclePriceSaga);
}

export function* sagas() {
  yield fork(activityTasks);
}

export default sagas;
