// outsource dependencies
import size from 'lodash/size';
import defaults from 'lodash/defaults';
// local dependencies
import { emailOnly, noSpacePresent, usernamePattern, mobilePhonePattern } from 'constants/regexp';

interface IOptions {
  maxLength?: number;
  noSpace?: boolean;
  minLength?: number;
}

const defaultEmailOptions: IOptions = {
  maxLength: 200,
  noSpace: true,
  minLength: 6,
};

const defaultPasswordOptions: IOptions = {
  maxLength: 50,
  noSpace: false,
  minLength: 8,
};

const defaultNameOptions: IOptions = {
  maxLength: 50,
  noSpace: true,
  minLength: 3,
};

const defaultMobilePhoneOptions: IOptions = {
  maxLength: 16,
  noSpace: true,
  minLength: 9,
};

const defaultCompanyNameOptions: IOptions = {
  maxLength: 50,
  noSpace: false,
  minLength: 0,
};

const defaultUsernameOptions: IOptions = {
  maxLength: 16,
  noSpace: false,
  minLength: 6,
};

const getEmailInvalidReason = (string: string, options: IOptions | null) => {
  options = defaults(options, defaultEmailOptions);
  if (options.minLength && size(string) < options.minLength) {
    return 'errors.min_length.email';
  } else if (options.maxLength && size(string) > options.maxLength) {
    return 'errors.max_length.email';
  } else if (options.noSpace && noSpacePresent.test(string)) {
    return 'errors.space.email';
  } else if (!emailOnly.test(string)) {
    return !emailOnly.test(string) && 'errors.format.email';
  }
  return null;
};

const getPasswordInvalidReason = (string: string, options: IOptions | null) => {
  options = defaults(options, defaultPasswordOptions);
  if (options.minLength && size(string) < options.minLength) {
    return 'errors.min_length.password';
  } else if (options.maxLength && size(string) > options.maxLength) {
    return 'errors.max_length.password';
  } else if (options.noSpace && noSpacePresent.test(string)) {
    return 'errors.space.password';
  }
  return null;
};

const getNameInvalidReason = (string: string, options: IOptions | null, fieldKey: string) => {
  options = defaults(options, defaultNameOptions);
  if (options.minLength && size(string) < options.minLength) {
    return `errors.min_length.${fieldKey}`;
  } else if (options.maxLength && size(string) > options.maxLength) {
    return `errors.max_length.${fieldKey}`;
  } else if (options.noSpace && noSpacePresent.test(string)) {
    return `errors.space.${fieldKey}`;
  }
  return null;
};

const getMobilePhoneInvalidReason = (string: string, options: IOptions | null) => {
  options = defaults(options, defaultMobilePhoneOptions);
  if (options.minLength && size(string) < options.minLength) {
    return 'errors.min_length.mobile_phone';
  } else if (options.maxLength && size(string) > options.maxLength) {
    return 'errors.max_length.mobile_phone';
  } else if (options.noSpace && noSpacePresent.test(string)) {
    return 'errors.space.mobile_phone';
  } else if (!mobilePhonePattern.test(string)) {
    return !mobilePhonePattern.test(string) && 'errors.format.mobile_phone';
  }
  return null;
};

const getCompanyInvalidReason = (string: string, options: IOptions | null) => {
  options = defaults(options, defaultCompanyNameOptions);
  if (options.minLength && size(string) < options.minLength) {
    return 'errors.min_length.company';
  } else if (options.maxLength && size(string) > options.maxLength) {
    return 'errors.max_length.company';
  } else if (options.noSpace && noSpacePresent.test(string)) {
    return 'errors.space.company';
  }
  return null;
};

const getUsernameInvalidReason = (string: string, options: IOptions | null) => {
  options = defaults(options, defaultUsernameOptions);
  if (options.minLength && size(string) < options.minLength) {
    return 'errors.min_length.username';
  } else if (options.maxLength && size(string) > options.maxLength) {
    return 'errors.max_length.username';
  } else if (options.noSpace && noSpacePresent.test(string)) {
    return 'errors.space.username';
  } else if (!usernamePattern.test(string)) {
    return !usernamePattern.test(string) && 'errors.format.username';
  }
  return null;
};

export default {
  getPasswordInvalidReason,
  getEmailInvalidReason,
  getNameInvalidReason,
  getMobilePhoneInvalidReason,
  getCompanyInvalidReason,
  getUsernameInvalidReason,
};
