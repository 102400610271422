import React, {useEffect, useState} from 'react';
import Layout from 'components/layout/Common';
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import Paper from "@material-ui/core/Paper";
import {makeStyles,} from "@material-ui/core/styles";
import {Table, TableBody, TableContainer} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {connect} from "react-redux";

import {
    loadInitialDataAction,
    selector as edFileListSelector,
} from 'private-layout/encrypt-decrypt/file-list/reducer'
import {Link} from "react-router-dom";
import {getLocalizedPath} from "../../../utils/routing.utils";
import {PRIVATE} from "../../../constants/routes";
import EdPayment from "../ed-payment";
import Preloader from "../../../components/Preloader";
import currentConfig from "../../../utils/config.utils";

const useStyles = makeStyles(() => ({
    tableContainer: {
        marginTop: 16,
    },
    table: {
        minWidth: 650,
    },
    actionLink: {
        cursor: 'pointer',
    },
    fileName: {
        maxWidth: "280px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    payLabel: {
        cursor: "pointer",
        color: "blue",
        textDecoration: "underline"
    }
}));


interface IProps {
    pageTitle: string;
    language: string;
    fileList?: any[];
    loadInitialData?: () => void;
    expectAnswer?: boolean;
}

function FileList(props: IProps) {
    const {pageTitle, language, fileList, loadInitialData, expectAnswer} = props;
    const {t} = useTranslation();
    const classes = useStyles();

    const [payFileKey, setPayFileKey] = useState<string | null>(null);

    useEffect(() => {
        loadInitialData && loadInitialData();
    }, [loadInitialData])

    const handleOnClickPay = (fileKey: string) => {
        setPayFileKey(fileKey);
    }
    const handleOnClosePay = () => {
        setPayFileKey(null);
    }

    return (
        <Layout pageTitle={pageTitle} language={language}>
            <Typography variant='h5'>
                <span>{t('files_list.title')}</span>
            </Typography>

            {!expectAnswer ?
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'>{t('files_list.key')}</TableCell>
                                <TableCell align='center'>{t('files_list.status')}</TableCell>
                                <TableCell align='center'>{t('files_list.file_name')}</TableCell>
                                <TableCell align='center'>{t('files_list.size') + " " + t('files_list.bytes')}</TableCell>
                                <TableCell align='center'>{t('files_list.add_date')}</TableCell>
                                <TableCell align='center'>{t('files_list.additional_files')}</TableCell>
                                <TableCell align='center'>{t('files_list.action')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fileList ? fileList.map((file) =>
                                <TableRow key={file.key}>
                                    <TableCell align='center'>
                                        {file.status !== "1" ?
                                        <Link
                                            to={getLocalizedPath(PRIVATE.ENCRYPT_DECRYPT_FILES_VIEW_FILE_RAW) + file.key}>
                                            <span>{file.key}</span>
                                        </Link> : <span>{file.key}</span> }
                                    </TableCell>
                                    <TableCell align='center'>{file.status === "1" ? t("upload_file.new") : t("upload_file.payed") }</TableCell>
                                    <TableCell className={classes.fileName} align='center'>
                                        {file.status !== "1" ?
                                        <Link
                                            to={getLocalizedPath(PRIVATE.ENCRYPT_DECRYPT_FILES_VIEW_FILE_RAW) + file.key}>
                                            <span>{file.name}</span>
                                        </Link>
                                            : <span>{file.name}</span> }
                                    </TableCell>
                                    <TableCell align='center'>{file.size}</TableCell>
                                    <TableCell align='center'>
                                        {new Date((file.date + ' UTC').replace(/-/g, "/")).toLocaleString()}
                                    </TableCell>
                                    <TableCell align='center'>{file.count}</TableCell>
                                    <TableCell align='center'>
                                        {file.status === "1" ?
                                            <label onClick={() => handleOnClickPay(file.key)}
                                                   className={classes.payLabel}>{t("upload_file.pay")}</label> :
                                            <a href={currentConfig().downloadUrl + 'download/ed/processed/' + file.key}>{t("upload_file.download")}</a>}
                                    </TableCell>
                                </TableRow>
                            ) : null}
                        </TableBody>
                    </Table>
                </TableContainer>
                : <Preloader pageCentered={true}/>}

            {payFileKey !== null ?
                <EdPayment fileKey={payFileKey} open={true} handleClose={handleOnClosePay}/> : null
            }
        </Layout>
    )
}

export default connect(
    (state) => edFileListSelector(state),
    (dispatch) => ({
        loadInitialData: () => dispatch(loadInitialDataAction()),
    })
)(FileList);