// outsource dependencies
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLocalizedPath } from 'utils/routing.utils';
// UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
// local dependencies
import { IFileData as IProcessingTasksFileData, IPrice as IProcessingTasksPrice, IRequestFileResponse } from 'private-layout/processing-tasks/reducer';
// constants
import { APP_COLOR } from 'constants/style';
import { COMMON as COMMON_ROUTE, PRIVATE, PUBLIC as PUBLIC_ROUTE } from 'constants/routes';

const useStyles = makeStyles({
  paperFullWidth: {
    width: 700,
  },
  preloader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: 300,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 8px 8px 24px',
    backgroundColor: APP_COLOR.GREY,
    color: APP_COLOR.WHITE,
  },
  close: {
    padding: 6,
    color: APP_COLOR.WHITE,
    cursor: 'pointer',
  },
  titleContent: {
    lineHeight: 2,
  },
  content: {
    display: 'flex',
    padding: 12,
    flexDirection: 'column',
  },
  infoContainer: {
    lineHeight: '25px',
    paddingLeft: 8,
  },
  infoTextLine: {
    whiteSpace: 'nowrap',
  },
  infoContent: {
    display: 'inline-block',
    width: 150,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 16,
    alignItems: 'center',
  },
  paymentIconsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  links: {
    fontSize: 12,
  },
  continueAction: {
    backgroundColor: APP_COLOR.LIGHT_GREY,
    color: APP_COLOR.WHITE,
    '&:hover': {
      color: APP_COLOR.BLACK,
    },
    transition: '0.6s',
  },
});

interface IProps {
  open: boolean;
  handleClose: () => void;
  handlePayment: () => void;
  //
  fileData?: IProcessingTasksFileData | null;
  price?: IProcessingTasksPrice | null;
  actions?: string[] | null;
  expectOrderAnswer?: boolean;
  expectRequestFileAnswer?: boolean;
  requestFileResponse?: IRequestFileResponse | null;
  selectedFileKey?: string | null;
}

function PaymentApproveDialog(props: IProps) {
  const { open, handleClose, handlePayment, fileData, price, actions, expectOrderAnswer, expectRequestFileAnswer, selectedFileKey, requestFileResponse } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={handleClose} classes={{ paperFullWidth: classes.paperFullWidth }} fullWidth maxWidth='md'>
      <DialogTitle disableTypography classes={{ root: classes.title }}>
        <Typography variant='h6' className={classes.titleContent}>
          {t('dialog.title.payment_approve')}
        </Typography>
        <CloseIcon onClick={handleClose} className={classes.close} />
      </DialogTitle>
      {(expectOrderAnswer || expectRequestFileAnswer) && (
        <div className={classes.preloader}>
          <CircularProgress />
        </div>
      )}
      {!expectOrderAnswer && !expectRequestFileAnswer && requestFileResponse === null && (
        <DialogContent>
          <div className={classes.content}>
            <Typography className={classes.titleContent}>
              <strong>{t('dialog.content.payment_approve.file')}</strong>
            </Typography>
            <div className={classes.infoContainer}>
              <span className={classes.infoTextLine}>
                <span className={classes.infoContent}>{t('dialog.content.payment_approve.key')}</span> {selectedFileKey}
              </span>
              <br />
              <span className={classes.infoTextLine}>
                <span className={classes.infoContent}>{t('dialog.content.payment_approve.name')}</span> {fileData?.name}
              </span>
              <br />
              <span className={classes.infoTextLine}>
                <span className={classes.infoContent}>{t('dialog.content.payment_approve.date')}</span> {fileData?.date}
              </span>
              <br />
              <span className={classes.infoTextLine}>
                <span className={classes.infoContent}>{t('dialog.content.payment_approve.ecu')}</span> {fileData?.ecu}
              </span>
            </div>
            {Array.isArray(actions) && (
              <>
                <Typography className={classes.titleContent}>
                  <strong>{t('dialog.content.payment_approve.actions')}</strong>
                </Typography>
                <div className={classes.infoContainer}>
                  {actions.map((action: string, index) => (
                    <div className={classes.infoTextLine} key={index}>
                      {action}
                    </div>
                  ))}
                </div>
              </>
            )}

            {Boolean(fileData?.dtc) && (
              <>
                <Typography className={classes.titleContent}>
                  <strong>{t('dialog.content.payment_approve.dtc')}</strong>
                </Typography>
                <div className={classes.infoContainer}>
                  <span className={classes.infoTextLine}>{fileData?.dtc}</span>
                  <br />
                </div>
              </>
            )}
            <Typography className={classes.titleContent}>
              <strong>{t('dialog.content.payment_approve.price')}</strong>
            </Typography>
            <div className={classes.infoContainer}>
              <span className={classes.infoTextLine}>
                <span className={classes.infoContent}>{t('dialog.content.payment_approve.base')}</span> {price?.basePrice} грн. (UAH)
              </span>
              <br />
              {Boolean(price?.discount) && (
                <>
                  <span className={classes.infoTextLine}>
                    <span className={classes.infoContent}>{t('dialog.content.payment_approve.discount')}</span> {price?.discount} %
                  </span>
                  <br />
                </>
              )}
              {Boolean(price?.bonus) && (
                <>
                  <span className={classes.infoTextLine}>
                    <span className={classes.infoContent}>{t('dialog.content.payment_approve.bonus')}</span> {price?.bonus} грн. (UAH)
                  </span>
                  <br />
                </>
              )}
              <span className={classes.infoTextLine}>
                <span className={classes.infoContent}>{t('dialog.content.payment_approve.to_pay')}</span> {price?.toPay} грн. (UAH)
              </span>
            </div>
            <div className={classes.actions}>
              <div className={classes.paymentIconsContainer}>
                <img src='/assets/vbm_blugrad01.png' height='30px' alt='Visa' />
                <img src='/assets/mc_vrt_pos.svg' height='50px' alt='MasterCard' />
              </div>
              <div>
                <Button onClick={handlePayment} className={classes.continueAction}>
                  {t('button_title.pay')}
                </Button>
              </div>
            </div>
            <Typography className={classes.links}>
              {t('dialog.content.payment_approve.payment_condition')}{' '}
              <Link to={getLocalizedPath(COMMON_ROUTE.USER_AGREEMENT)}>
                <span>{t('dialog.content.payment_approve.link.user_agreement')}</span>
              </Link>
              ,{' '}
              <Link to={getLocalizedPath(COMMON_ROUTE.PRIVACY_POLICY)}>
                <span>{t('dialog.content.payment_approve.link.privacy_policy')}</span>
              </Link>
              ,{' '}
              <Link to={getLocalizedPath(PUBLIC_ROUTE.PAYMENT_AND_DELIVERY)}>
                <span>{t('dialog.content.payment_approve.link.payment_and_delivery')}</span>
              </Link>
            </Typography>
          </div>
        </DialogContent>
      )}
      {requestFileResponse !== null && (
        <DialogContent>
        <div className={classes.content}>
          <Typography className={classes.titleContent}>
              {requestFileResponse?.status === 'requested' && t('dialog.content.payment_approve.file_process_requested')}
              {requestFileResponse?.status === 'processed_automatically' && t('dialog.content.payment_approve.file_processed_automatically')}
          </Typography>
          <Typography className={classes.links}>
            <Link to={getLocalizedPath(PRIVATE.PROCESSING_TASKS)}>
                <span>{t('dialog.content.payment_approve.link.file_list')}</span>
            </Link>
          </Typography>
        </div>
        </DialogContent>
      )}
    </Dialog>
  );
}

export default PaymentApproveDialog;
