// outsource dependencies
import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
// local dependencies
import {
  selector as app,
  // action
  userInitializeAction,
  updateAppDataAction,
  // interface
  IUser,
} from 'reducers';
import { history } from 'store';
// public pages
import HomePage from 'public-layout/home';
import ContactsPage from 'public-layout/contacts';
import DiscountAndSales from 'public-layout/discount-and-news';
import SupportedVehiclesPage from 'public-layout/supported-vehicles';
import EmailConfirmation from 'public-layout/email-confirmation';
import ChangePassword from 'public-layout/change-password';
import PricesPage from 'private-layout/prices';
import PaymentAndDeliveryPage from 'public-layout/payment-and-delivery';
// private pages
import BillingPage from 'private-layout/billing';
import ProcessingTasksPage from 'private-layout/processing-tasks';
import SettingsPage from 'private-layout/settings';
import UserProfilePage from 'private-layout/user-profile';
import AddFilePage from 'private-layout/add-file';
// common pages
import PrivacyPolicyPage from 'common-layout/privacy-policy';
import UserAgreementPage from 'common-layout/user-agreement';
// UI
import Preloader from 'components/Preloader';
// constants
import { PUBLIC as PUBLIC_ROUTE, PRIVATE as PRIVATE_ROUTE, COMMON as COMMON_ROUTE, ROUTE_TITLE } from 'constants/routes';
import LanguageList, { DEFAULT_LANGUAGE } from 'constants/language';
// utils
import { localizedPath } from 'utils/routing.utils';
// service
import CookieStorage from 'services/storage.service';
import UploadFile from "../private-layout/encrypt-decrypt/upload";
import FileList from "../private-layout/encrypt-decrypt/file-list";
import FileView from "../private-layout/encrypt-decrypt/file-view";
import Ticket from "../private-layout/ticket";
interface IProps {
  initialized?: boolean;
  user?: IUser | null;
  globalError?: boolean;
  locale?: string;
  //
  userInitialize?: () => void;
  updateAppLanguage?: (language: string | null) => void;
}

const Routes = (props: IProps) => {
  const {
    initialized,
    user,
    locale,
    //
    userInitialize,
    updateAppLanguage,
  } = props;
  const { i18n } = useTranslation();
  const isUserLoggedIn = Boolean(user);

  useEffect(() => {
    userInitialize && userInitialize();
  }, [userInitialize]);

  // global language watcher
  useEffect(() => {
    if (!locale) {
      CookieStorage.setItem('locale', DEFAULT_LANGUAGE);
      updateAppLanguage && updateAppLanguage(DEFAULT_LANGUAGE);
      i18n.changeLanguage(DEFAULT_LANGUAGE);
    } else {
      CookieStorage.setItem('locale', locale);
      i18n.changeLanguage(locale);
    }
  }, [locale, updateAppLanguage, i18n]);

  return (
    <ConnectedRouter history={history}>
      <>
        {initialized && isUserLoggedIn && (
          <Switch>
            {/* private */}
            {LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, PRIVATE_ROUTE.BILLING)}
                component={() => <BillingPage language={lang.language} pageTitle={ROUTE_TITLE.BILLING} />}
              />
            ))}
            {LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, PRIVATE_ROUTE.PROCESSING_TASKS)}
                component={() => <ProcessingTasksPage language={lang.language} pageTitle={ROUTE_TITLE.PROCESSING_TASKS} />}
              />
            ))}
            {LanguageList.map((lang, index) => (
                <Route
                    key={index}
                    exact
                    path={localizedPath(lang.language, PRIVATE_ROUTE.TICKET)}
                    component={() => <Ticket language={lang.language} pageTitle={ROUTE_TITLE.PROCESSING_TASKS} />}
                />
            ))}
            {LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, PRIVATE_ROUTE.SETTINGS)}
                component={() => <SettingsPage language={lang.language} pageTitle={ROUTE_TITLE.SETTINGS} />}
              />
            ))}
            {LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, PRIVATE_ROUTE.USER_PROFILE)}
                component={() => <UserProfilePage language={lang.language} pageTitle={ROUTE_TITLE.USER_PROFILE} />}
              />
            ))}
            {LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, PRIVATE_ROUTE.ADD_FILE)}
                component={() => <AddFilePage language={lang.language} pageTitle={ROUTE_TITLE.ADD_FILE} />}
              />
            ))}
            {LanguageList.map((lang, index) => (
                <Route
                    key={index}
                    exact
                    path={localizedPath(lang.language, PRIVATE_ROUTE.ENCRYPT_DECRYPT_FILES_UPLOAD)}
                    component={() => <UploadFile language={lang.language} pageTitle={ROUTE_TITLE.UPLOAD_FILE} />}
                />
            ))}
            {LanguageList.map((lang, index) => (
                <Route
                    key={index}
                    exact
                    path={localizedPath(lang.language, PRIVATE_ROUTE.ENCRYPT_DECRYPT_FILES_LIST)}
                    component={() => <FileList language={lang.language} pageTitle={ROUTE_TITLE.FILE_LIST} />}
                />
            ))}
            {LanguageList.map((lang, index) => (
                <Route
                    key={index}
                    exact
                    path={localizedPath(lang.language, PRIVATE_ROUTE.ENCRYPT_DECRYPT_FILES_VIEW_FILE)}
                    component={() => <FileView language={lang.language} pageTitle={ROUTE_TITLE.VIEW_FILE} />}
                />
            ))}
            {/* common */}
            {LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, COMMON_ROUTE.PRIVACY_POLICY)}
                component={() => <PrivacyPolicyPage language={lang.language} pageTitle={ROUTE_TITLE.PRIVACY_POLICY} />}
              />
            ))}
            {LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, COMMON_ROUTE.USER_AGREEMENT)}
                component={() => <UserAgreementPage language={lang.language} pageTitle={ROUTE_TITLE.USER_AGREEMENT} />}
              />
            ))}
            {/* public */}
            {LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, PUBLIC_ROUTE.HOME)}
                component={() => <HomePage language={lang.language} pageTitle={ROUTE_TITLE.HOME} />}
              />
            ))}
            {LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, PUBLIC_ROUTE.CONTACTS)}
                component={() => <ContactsPage language={lang.language} pageTitle={ROUTE_TITLE.CONTACTS} />}
              />
            ))}
            {false && LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, PUBLIC_ROUTE.DISCOUNT_AND_NEWS)}
                component={() => <DiscountAndSales language={lang.language} pageTitle={ROUTE_TITLE.DISCOUNT_AND_NEWS} />}
              />
            ))}
            {LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, PUBLIC_ROUTE.SUPPORTED_VEHICLES)}
                component={() => <SupportedVehiclesPage language={lang.language} pageTitle={ROUTE_TITLE.SUPPORTED_VEHICLES} />}
              />
            ))}
            {LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, PUBLIC_ROUTE.EMAIL_CONFIRMATION)}
                component={() => <EmailConfirmation language={lang.language} pageTitle={ROUTE_TITLE.EMAIL_CONFIRMATION} />}
              />
            ))}
            {LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, PUBLIC_ROUTE.CHANGE_PASSWORD)}
                component={() => <ChangePassword language={lang.language} pageTitle={ROUTE_TITLE.CHANGE_PASSWORD} />}
              />
            ))}
            {LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, PUBLIC_ROUTE.PAYMENT_AND_DELIVERY)}
                component={() => <PaymentAndDeliveryPage language={lang.language} pageTitle={ROUTE_TITLE.PAYMENT_AND_DELIVERY} />}
              />
            ))}
            {LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, PUBLIC_ROUTE.PRICES)}
                component={() => <PricesPage language={lang.language} pageTitle={ROUTE_TITLE.PRICES} />}
              />
            ))}
            {/*  keep route as default without language prefix */}
            <Route exact path={PUBLIC_ROUTE.HOME} component={() => <HomePage language={locale || DEFAULT_LANGUAGE} pageTitle={ROUTE_TITLE.HOME} />} />
            <Route path='*'>
              <Redirect to={PUBLIC_ROUTE.HOME} />
            </Route>
          </Switch>
        )}
        {initialized && !isUserLoggedIn && (
          <Switch>
            {/* common */}
            {LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, COMMON_ROUTE.PRIVACY_POLICY)}
                component={() => <PrivacyPolicyPage language={lang.language} pageTitle={ROUTE_TITLE.PRIVACY_POLICY} />}
              />
            ))}
            {LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, COMMON_ROUTE.USER_AGREEMENT)}
                component={() => <UserAgreementPage language={lang.language} pageTitle={ROUTE_TITLE.USER_AGREEMENT} />}
              />
            ))}
            {/* public */}
            {LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, PUBLIC_ROUTE.HOME)}
                component={() => <HomePage language={lang.language} pageTitle={ROUTE_TITLE.HOME} />}
              />
            ))}
            {LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, PUBLIC_ROUTE.CONTACTS)}
                component={() => <ContactsPage language={lang.language} pageTitle={ROUTE_TITLE.CONTACTS} />}
              />
            ))}
            {false && LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, PUBLIC_ROUTE.DISCOUNT_AND_NEWS)}
                component={() => <DiscountAndSales language={lang.language} pageTitle={ROUTE_TITLE.DISCOUNT_AND_NEWS} />}
              />
            ))}
            {LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, PUBLIC_ROUTE.SUPPORTED_VEHICLES)}
                component={() => <SupportedVehiclesPage language={lang.language} pageTitle={ROUTE_TITLE.SUPPORTED_VEHICLES} />}
              />
            ))}
            {LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, PUBLIC_ROUTE.EMAIL_CONFIRMATION)}
                component={() => <EmailConfirmation language={lang.language} pageTitle={ROUTE_TITLE.EMAIL_CONFIRMATION} />}
              />
            ))}
            {LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, PUBLIC_ROUTE.CHANGE_PASSWORD)}
                component={() => <ChangePassword language={lang.language} pageTitle={ROUTE_TITLE.CHANGE_PASSWORD} />}
              />
            ))}
            {LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, PUBLIC_ROUTE.PAYMENT_AND_DELIVERY)}
                component={() => <PaymentAndDeliveryPage language={lang.language} pageTitle={ROUTE_TITLE.PAYMENT_AND_DELIVERY} />}
              />
            ))}
            {LanguageList.map((lang, index) => (
              <Route
                key={index}
                exact
                path={localizedPath(lang.language, PUBLIC_ROUTE.PRICES)}
                component={() => <PricesPage language={lang.language} pageTitle={ROUTE_TITLE.PRICES} />}
              />
            ))}
            {/*  keep route as default without language prefix */}
            <Route exact path={PUBLIC_ROUTE.HOME} component={() => <HomePage language={locale || DEFAULT_LANGUAGE} pageTitle={ROUTE_TITLE.HOME} />} />
            {/* keep HOME_DUPLICATE last to save correct routing */}
            <Route exact path={PUBLIC_ROUTE.HOME_DUPLICATE} component={() => <HomePage language={locale || DEFAULT_LANGUAGE} pageTitle={ROUTE_TITLE.HOME} />} />
            <Route path='*'>
              <Redirect to={PUBLIC_ROUTE.HOME} />
            </Route>
          </Switch>
        )}
        {!initialized && <Preloader centered />}
      </>
    </ConnectedRouter>
  );
};

export default connect(
  (state) => app(state),
  (dispatch) => ({
    userInitialize: () => dispatch(userInitializeAction()),
    updateAppLanguage: (language: string | null) => dispatch(updateAppDataAction({ locale: language })),
  })
)(Routes);
