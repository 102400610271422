// outsource dependencies
import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
// UI
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
// local dependencies
import {
  selector as pricesSelector,
  // triggers
  loadVehiclesAction,
  loadVehicleBrandsAction,
  loadVehicleModelsAction,
  loadVehicleEnginesAction,
  loadVehicleEcusAction,
  loadVehicleActionsAction,
  loadVehiclePriceAction,
  // interfaces
  IVehicle,
  IVehicleBrand,
  IVehicleModel,
  IVehicleEngine,
  IVehicleEcus,
  IVehicleAction,
  IVehiclePrice,
  // state update
  updateSelectedEcusKeyAction,
  updateSelectedBrandKeyAction,
  updateDtcAction,
  updateSelectActionStateAction,
  updateShowInfoAction,
  updateVehicleActionsLoadedAction,
  updateVehiclePriceAction,
} from 'private-layout/prices/reducer';
// UI
import Layout from 'components/layout/Common';
import Preloader from 'components/Preloader';
import CustomSelect from 'components/select/Select';
import CustomCheckbox from 'components/checkbox/Checkbox';
import CustomTextField from 'components/input/TextField';
// constants
import { CONSTRAINT } from 'constants/style';
import { IUser } from 'reducers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  optionsListContainer: {
    marginLeft: '20px',
  },
  leftBlockContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.spacing(2),
  },
  rightBlockContainer: {
    display: 'flex',
    width: '40%',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    paddingTop: 8,
  },
  alert: {
    marginTop: '40px',
  },
  preloader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    padding: '16px 0',
  },
  actionBtn: {
    minWidth: `calc(${CONSTRAINT.BUTTON_WIDTH}px + 16px)`,
  },
  cardInfo: {
    marginTop: 20,
  },
  cardInfoTextLine: {
    whiteSpace: 'nowrap',
  },
  cardInfoStrong: {
    display: 'inline-block',
    width: 150,
  },
  cogwheel: {
    cursor: 'pointer',
  },
}));

interface IProps {
  pageTitle: string;
  language: string;
  user?: IUser | null;
  //
  vehicles?: IVehicle[] | null;
  vehicleBrands?: IVehicleBrand[] | null;
  vehicleModels?: IVehicleModel[] | null;
  vehicleEngines?: IVehicleEngine[] | null;
  vehicleEcus?: IVehicleEcus[] | null;
  vehicleActions?: IVehicleAction[] | null;

  vehiclePrice?: IVehiclePrice | null;

  allowDtc?: boolean;
  autoDtc?: boolean;
  dtcKey?: string;
  showInfo?: boolean;
  selectedBrandKey?: string | null;
  selectedEcusKey?: string | null;

  vehicleBrandsLoaded?: boolean;
  vehicleModelsLoaded?: boolean;
  vehicleEnginesLoaded?: boolean;
  vehicleEcusLoaded?: boolean;
  vehicleActionsLoaded?: boolean;

  expectAnswer?: boolean;
  expectBrandsAnswer?: boolean;
  expectModelsAnswer?: boolean;
  expectEnginesAnswer?: boolean;
  expectEcusAnswer?: boolean;
  expectActionsAnswer?: boolean;
  expectVehiclePriceAnswer?: boolean;
  // triggers
  loadVehicles?: () => void;
  loadVehicleBrands?: (vehicle: string) => void;
  loadVehicleModels?: (brand: string) => void;
  loadVehicleEngines?: (model: string) => void;
  loadVehicleEcus?: (engine: string | null) => void;
  loadVehicleActions?: (key: string | null) => void;
  loadVehiclePrice?: () => void;
  // update
  updateSelectedEcusKey?: (selectedEcusKey: string | null) => void;
  updateSelectedBrandKey?: (selectedBrandKey: string | null) => void;
  updateSelectActionState?: (actionKey: string) => void;
  updateDtc?: (dtcKey: string) => void;
  updateShowInfo?: (showInfo: boolean) => void;
  updateVehicleActionsLoaded?: (vehicleActionsLoaded: boolean) => void;
  updateVehiclePrice?: (vehiclePrice: IVehiclePrice | null) => void;
}

function Prices(props: IProps) {
  const {
    pageTitle,
    language,
    user,
    //
    vehicles,
    vehicleBrands,
    // vehicleModels,
    // vehicleEngines,
    vehicleEcus,
    vehicleActions,
    vehiclePrice,
    allowDtc,
    autoDtc,
    dtcKey,
    showInfo,
    selectedEcusKey,
    selectedBrandKey,
    //
    vehicleBrandsLoaded,
    // vehicleModelsLoaded,
    // vehicleEnginesLoaded,
    vehicleEcusLoaded,
    vehicleActionsLoaded,
    //
    expectAnswer,
    expectBrandsAnswer,
    // expectModelsAnswer,
    // expectEnginesAnswer,
    expectEcusAnswer,
    expectActionsAnswer,
    expectVehiclePriceAnswer,
    //
    loadVehicles,
    loadVehicleBrands,
    // loadVehicleModels,
    // loadVehicleEngines,
    loadVehicleEcus,
    loadVehicleActions,
    loadVehiclePrice,
    //
    updateSelectActionState,
    updateDtc,
    updateSelectedEcusKey,
    updateSelectedBrandKey,
    updateShowInfo,
    updateVehicleActionsLoaded,
    updateVehiclePrice,
  } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const isUserLoggedIn = Boolean(user);

  const [errorDtc, setErrorDtc] = useState<boolean>(false);

  useEffect(() => {
    loadVehicles && loadVehicles();
    return () => {
      updateDtc && updateDtc('');
      updateShowInfo && updateShowInfo(false);
    };
  }, [loadVehicles, updateDtc, updateShowInfo]);
  const handleVehicle = (e: React.ChangeEvent<HTMLSelectElement>) => {
    loadVehicleBrands && loadVehicleBrands(e.target.value);
  };
  const handleBrand = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateSelectedBrandKey && updateSelectedBrandKey(e.target.value);
    // loadVehicleModels(e.target.value);
    loadVehicleEcus && loadVehicleEcus(e.target.value);
  };
  // const handleModel = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   loadVehicleEngines(e.target.value);
  // };
  // const handleEngine = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   loadVehicleEcus(e.target.value);
  // };
  const handleEcusLabel = (ecusOption: IVehicleEcus) => ecusOption.name;
  const handleEcus = (e: any, value: IVehicleEcus | null) => {
    const flattenValue = value ? value.key : null;
    updateSelectedEcusKey && updateSelectedEcusKey(flattenValue);
    updateVehiclePrice && updateVehiclePrice(null);
    if (flattenValue) {
      loadVehicleActions && loadVehicleActions(flattenValue);
    } else {
      // hide all actions data on ecus delete
      updateVehicleActionsLoaded && updateVehicleActionsLoaded(false);
    }
  };
  const handleAction = (actionKey: string) => () => {
    updateSelectActionState && updateSelectActionState(actionKey);
    handleCalculatePrice();
  };

  const validateDtc = (dtc: string) => {
    if (dtc.length === 0) {
      return true;
    }

    const reg = new RegExp("^[0-9a-fA-F]{4}");

    const dtcArr = dtc.split(",");
    for (let i = 0; i < dtcArr.length; i++) {
      const valid = reg.test(dtcArr[i]);
      if (!valid) {
        return false;
      }
    }

    return true;
  }

  const handleDtc = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateDtc && updateDtc(e.target.value);
    const valid = validateDtc(e.target.value);
    if (!valid) {
      setErrorDtc(true);
    } else {
      setErrorDtc(false);
    }
    const isRecalculatePrice = (!dtcKey?.trim().length && Boolean(e.target.value.trim().length)) || (dtcKey?.trim().length && !e.target.value.trim().length);
    // recalculate only when dtc was empty or become empty
    if (isRecalculatePrice) handleCalculatePrice();
  };
  const handleCalculatePrice = () => {
    if (selectedEcusKey && selectedBrandKey) loadVehiclePrice && loadVehiclePrice();
  };
  return (
    <Layout pageTitle={pageTitle} language={language}>
      <Typography variant='h5'>{t('prices.title')}</Typography>
      {!isUserLoggedIn && (
        <>
        {i18n.language === 'ua' && (
          <p>
            Оскільки фінальна ціна на наші послуги залежить від вашого партнерського статусу, будь ласка, увійдіть або зареєструйтесь на сайті.
          </p>
        )}
        {i18n.language === 'en' && (
          <p>
              Our prices are depend to your partner status, please login or register on the site to see your individual prices. 
          </p>
        )}
        </>
      )}
      {isUserLoggedIn && (
        <>
        {i18n.language === 'ua' && (
          <>
          <p>
            На этой странице вы можете узнать базовую цену на услуги по модификации файлов прошивок
          </p>
          <p>Для получения цены выберите тип транспортного средства, бренд и блок, который используется на данном ТС. Затем выберите требуемые действия.</p>
          <p>
            Например:
            <br />
            Транспортное средство: Car
            <br />
            Бренд: VAG
            <br />
            ECU: BOSCH EDC17C46
            <br />
            <small>(Для упрощения поиска по блоку можно ввести только часть текста, например: "17c46")</small>
            <br />
          </p>
          </>
        )}
        {i18n.language === 'en' && (
          <>
            <p>
              On this page you can find out the base price for services for modifying firmware files.
            </p>
            <p>To get a price, select the type of vehicle, brand and ECU that is used on this vehicle. Then select the required actions.</p>
            <p>
              For example:
              <br />
              Vehicle: Car
              <br />
              Brand: VAG
              <br />
              ECU: BOSCH EDC17C46
              <br />
              <small>(To simplify the search in a block, you can enter only part of the text, for example: "17c46")</small>
              <br />
            </p>
          </>
        )}
        {expectAnswer && <Preloader pageCentered />}
        {!expectAnswer && (
          <div className={classes.root}>
            <div className={classes.leftBlockContainer}>
              <div className={classes.optionsListContainer}>
                <CustomSelect label={t('select_label.vehicle')} options={vehicles} onChange={handleVehicle} />
                <div className={classes.optionsContainer}>
                  {expectBrandsAnswer && <Preloader />}
                  {!expectBrandsAnswer && vehicleBrandsLoaded && <CustomSelect label={t('select_label.brand')} options={vehicleBrands} onChange={handleBrand} />}
                </div>
                {/* <div className={classes.optionsContainer}>
                  {expectModelsAnswer && <Preloader />}
                  {!expectModelsAnswer && vehicleModelsLoaded && <CustomSelect label={t('select_label.model')} options={vehicleModels} onChange={handleModel} />}
                </div> */}
                {/* <div className={classes.optionsContainer}>
                  {expectEnginesAnswer && <Preloader />}
                  {!expectEnginesAnswer && vehicleEnginesLoaded && (
                    <CustomSelect label={t('select_label.engine')} options={vehicleEngines} onChange={handleEngine} />
                  )}
                </div> */}
                <div className={classes.optionsContainer}>
                  {expectEcusAnswer && <Preloader />}
                  {!expectEcusAnswer && vehicleEcusLoaded && vehicleEcus && (
                    <Autocomplete
                      options={vehicleEcus}
                      onChange={handleEcus}
                      getOptionLabel={handleEcusLabel}
                      renderInput={(params) => <CustomTextField {...params} label={t('select_label.ecus')} />}
                    />
                  )}
                </div>
              </div>
              {!expectActionsAnswer && vehicleActionsLoaded && (
                <Typography className={classes.alert} variant='h6'>
                  {t('prices.available_actions')}
                </Typography>
              )}
              <div className={classes.optionsListContainer}>
                <div className={classes.optionsContainer}>
                  {expectActionsAnswer && <Preloader />}

                  {!expectActionsAnswer &&
                    vehicleActionsLoaded &&
                    Array.isArray(vehicleActions) &&
                    vehicleActions.map((vehicleAction: IVehicleAction) => (
                      <div key={vehicleAction.key}>
                        <CustomCheckbox label={vehicleAction.name} onChange={handleAction(vehicleAction.key)} disabled={!vehicleAction.available} />
                        {Boolean(vehicleAction.auto) && (
                          <Tooltip title={String(t('prices.cogwheel_tooltip'))} arrow placement='right'>
                            <span role='img' aria-label='cogwheel' className={classes.cogwheel}>
                              ⚙️
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    ))}
                  {!expectActionsAnswer && vehicleActionsLoaded && ((Array.isArray(vehicleActions) && !vehicleActions.length) || !vehicleActions) && (
                    <Typography>{t('prices.no_actions')}</Typography>
                  )}
                </div>
              </div>
              {!expectActionsAnswer && vehicleActionsLoaded && (autoDtc || !autoDtc) && (
                <Typography className={classes.alert} variant='h6'>
                  {`${t('prices.remove_dtc')} `}
                  {Boolean(autoDtc) && (
                    <Tooltip title={String(t('add_file.remove_dtc_tooltip'))} arrow placement='right'>
                      <span role='img' aria-label='cogwheel' className={classes.cogwheel}>
                        ⚙️
                      </span>
                    </Tooltip>
                  )}
                </Typography>
              )}
              <div className={classes.optionsListContainer}>
                {!expectActionsAnswer && vehicleActionsLoaded && allowDtc && (
                  <CustomTextField helperText={t("add_file.errorDtc")} error={errorDtc} onChange={handleDtc} label={t('input.label.dtc')} placeholder={t('input.placeholder.dtc')} value={dtcKey || ''} />
                )}
                {!expectActionsAnswer && vehicleActionsLoaded && !allowDtc && <Typography style={{ marginTop: 20 }}>{t('prices.dtc_not_allowed')}</Typography>}
              </div>
            </div>
            {showInfo && (
              <div className={classes.rightBlockContainer}>
                {expectVehiclePriceAnswer && (
                  <div className={classes.preloader}>
                    <Preloader />
                  </div>
                )}
                {!expectVehiclePriceAnswer && Boolean(vehiclePrice) && (
                  <div style={{ lineHeight: '25px' }}>
                    <span className={classes.cardInfoTextLine}>
                      <strong className={classes.cardInfoStrong}>{t('prices.base_price')}</strong> {String(vehiclePrice?.basePrice || 0)} грн. (UAH)
                    </span>
                    <br />
                    {Boolean(vehiclePrice?.discount) && (
                      <>
                        <span className={classes.cardInfoTextLine}>
                          <strong className={classes.cardInfoStrong}>{t('prices.discount')}</strong> {String(vehiclePrice?.discount || 0)}%
                        </span>
                        <br />
                      </>
                    )}
                    {Boolean(vehiclePrice?.bonus) && (
                      <>
                        <span className={classes.cardInfoTextLine}>
                          <strong className={classes.cardInfoStrong}>{t('prices.bonus')}</strong> {String(vehiclePrice?.bonus || 0)} грн. (UAH)
                        </span>
                        <br />
                      </>
                    )}
                    <span className={classes.cardInfoTextLine}>
                      <strong className={classes.cardInfoStrong}>{t('prices.to_pay')}</strong> {String(vehiclePrice?.toPay || 0)} грн. (UAH)
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        </>
      )}
    </Layout>
  );
}

export default connect(
  (state) => pricesSelector(state),
  (dispatch) => ({
    loadVehicles: () => dispatch(loadVehiclesAction()),
    loadVehicleBrands: (vehicle: string) => dispatch(loadVehicleBrandsAction(vehicle)),
    loadVehicleModels: (brand: string) => dispatch(loadVehicleModelsAction(brand)),
    loadVehicleEngines: (model: string) => dispatch(loadVehicleEnginesAction(model)),
    loadVehicleEcus: (engine: string) => dispatch(loadVehicleEcusAction(engine)),
    loadVehicleActions: (key: string) => dispatch(loadVehicleActionsAction(key)),
    loadVehiclePrice: () => dispatch(loadVehiclePriceAction()),
    //
    updateSelectedEcusKey: (selectedEcusKey: string) => dispatch(updateSelectedEcusKeyAction(selectedEcusKey)),
    updateSelectedBrandKey: (selectedBrandKey: string) => dispatch(updateSelectedBrandKeyAction(selectedBrandKey)),
    updateSelectActionState: (actionKey: string) => dispatch(updateSelectActionStateAction(actionKey)),
    updateDtc: (dtcKey: string) => dispatch(updateDtcAction(dtcKey)),
    updateShowInfo: (showInfo: boolean) => dispatch(updateShowInfoAction(showInfo)),
    updateVehicleActionsLoaded: (vehicleActionsLoaded: boolean) => dispatch(updateVehicleActionsLoadedAction(vehicleActionsLoaded)),
    updateVehiclePrice: (vehiclePrice: IVehiclePrice | null) => dispatch(updateVehiclePriceAction(vehiclePrice)),
  })
)(Prices);
