
export const API_VALIDATION = {
  username_not_found: 'username_not_found',
  password_not_valid: 'password_not_valid',
  conflict: 'conflict',
  not_found: 'not_found'
};

export enum ExpectedErrorStatus {
  unauthorized = 401,
  forbidden = 403,
  conflict = 409,
};
