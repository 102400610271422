// outsource dependencies
import { fork, takeLatest, call, select,  put } from 'redux-saga/effects';
import get from 'lodash/get';
// local dependencies
import { TYPE, selector as signUpSelector, updateExpectAnswerAction, updateErrorSignUpAction, updateEmailConfirmationAction } from 'public-layout/sign-up/reducer';
import API from 'services/request.service';
// utils
import { getFlattenValidationErrors } from 'utils/response.utils';
// constants
import { API_VALIDATION } from 'constants/error';

function* signUpSaga() {
  const { email, password, username, company, first_name, last_name, mobile_phone } = yield select(signUpSelector);
  let data: any = { email, password, username };
  if (company) data = { ...data, company };
  if (first_name) data = { ...data, first_name };
  if (last_name) data = { ...data, last_name };
  if (mobile_phone) data = { ...data, mobile_phone };
  yield put(updateExpectAnswerAction(true));
  try {
    yield call<any>(API, {
      url: '/client/signup',
      method: 'POST',
      data: data,
    });
    yield put(updateExpectAnswerAction(false));
    yield put(updateEmailConfirmationAction(true));
  } catch (e) {
    console.error('signUpSaga error: ', e.response, e, getFlattenValidationErrors(e.response?.data.response));
    const errorKey = get(e, 'response.data.errorKey', null);
    let duplicateEmail = {};
    if (errorKey === API_VALIDATION.conflict) {
      duplicateEmail = { email: 'errors.validation_error.conflict.email' };
    }
    const errors = getFlattenValidationErrors(e.response?.data.response);
    yield put(updateErrorSignUpAction({ ...errors, ...duplicateEmail }));
    yield put(updateExpectAnswerAction(false));
  }
}

/**
 * connect page sagas
 *
 * @private
 */
function* activityTasks() {
  yield takeLatest(TYPE.SIGN_UP_SUBMIT, signUpSaga);
}

export function* sagas() {
  yield fork(activityTasks);
}

export default sagas;
