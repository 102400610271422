// outsource dependencies
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// UI
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
// local dependencies
// constants
import { COMMON as COMMON_ROUTE } from 'constants/routes';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    padding: '0 6px 0 0'
  },
  label: {
    fontSize: 12
  },
});

interface CheckboxProps {
  checked: boolean;
  onChange: () => void;
  style?: any;
}

export default function TermsConditionsCheckbox(props: CheckboxProps) {
  const { checked, onChange, style } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div style={style} className={classes.container}>
      <Checkbox checked={checked} onChange={onChange} color='default' classes={{ root: classes.checkbox }} />
      <Typography className={classes.label}>
        {t('component.checkbox.agree')}{' '}
        <Link to={COMMON_ROUTE.USER_AGREEMENT}>
          <span>{t('component.checkbox.user_agreement')}</span>
        </Link>
        {' '}{t('component.checkbox.and')}{' '}
        <Link to={COMMON_ROUTE.PRIVACY_POLICY}>
          <span>{t('component.checkbox.privacy_policy')}</span>
        </Link>
      </Typography>
    </div>
  );
}

TermsConditionsCheckbox.defaultProps = {
  style: {},
};
