// locale dependencies
// service
import CookieStorage from 'services/storage.service';
// constants
import { DEFAULT_LANGUAGE } from 'constants/language';

// get correct localized path due to language cookie
export function getLocalizedPath(path: string) {
  const currentLocale = Boolean(CookieStorage.getItem('locale')) ? CookieStorage.getItem('locale') : DEFAULT_LANGUAGE;
  
  return `/${currentLocale}${path}`;
}

export const localizedPath = (language: string, path: string) => {
  return `/${language}${path}`;
};
