
// outsource dependencies
import 'regenerator-runtime/runtime';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory as createHistory } from 'history';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { connectRouter, routerMiddleware as createRouterMiddleware, push } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as form } from 'redux-form';
// local dependencies
import reducers from 'reducers';
import sagas from 'sagas';

// export history outside of components to be able dispatch navigation actions from anywhere!
export const history = createHistory();

// Build the middleware for intercepting and dispatching navigation actions
const routerMiddleware = createRouterMiddleware(history);

const rootReducer = (history: any) => combineReducers({
  router: connectRouter(history),
  ...reducers,
  form,
});

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer(history), composeWithDevTools(applyMiddleware(sagaMiddleware, routerMiddleware)));
sagaMiddleware.run(sagas);

// provide functionality to change history outside of component
export function historyPush(path: string) {
  store.dispatch(push(path));
}

export default store;
