export default {
  translation: {
    errors: {
      validation_error: {
        username_not_found: 'username not found',
        password_not_valid: 'password not valid',
        email_not_found: 'email not found',
        bad_request: 'Some error occurred. Please try again or contact us',
        passwords_not_equal: 'Passwords not equal',
        conflict: {
          email: 'client email already exist',
        },
        minLength: {
          openapi: {
            validation: {
              email: 'should NOT be shorter than 3 characters',
              password: 'should NOT be shorter than 8 characters',
              first_name: 'should NOT be shorter than 3 characters',
              last_name: 'should NOT be shorter than 3 characters',
              mobile_phone: 'should NOT be shorter than 9 characters',
              company: 'should NOT be shorter than 3 characters',
              username: 'should NOT be shorter than 9 characters',
            },
          },
        },
        format: {
          openapi: {
            validation: {
              email: 'should match format "email"',
              password: 'should match format "password"',
              first_name: 'should match format "first name"',
              last_name: 'should match format "last name"',
              mobile_phone: 'should match format "mobile phone"',
              company: 'should match format "company"',
              username: 'should match format "username"',
            },
          },
        },
        pattern: {
          openapi: {
            validation: {
              email: 'should match pattern "email"',
              password: 'should match pattern "password"',
              first_name: 'should match pattern "first name"',
              last_name: 'should match pattern "last name"',
              mobile_phone: 'should match pattern "mobile phone"',
              company: 'should match pattern "company"',
              username: 'should match pattern "username"',
            },
          },
        },
      },
      format: {
        email: 'Invalid email format',
        mobile_phone: 'Invalid mobile phone format',
        username: 'Invalid username format',
      },
      min_length: {
        email: 'should NOT be shorter than 3 characters',
        password: 'should NOT be shorter than 8 characters',
        first_name: 'should NOT be shorter than 3 characters',
        last_name: 'should NOT be shorter than 3 characters',
        mobile_phone: 'should NOT be shorter than 9 characters',
        company: 'should NOT be shorter than 3 characters',
        username: 'should NOT be shorter than 9 characters',
      },
      max_length: {
        email: 'should NOT be shorter than 200 characters',
        password: 'should NOT be shorter than 50 characters',
        first_name: 'should NOT be shorter than 50 characters',
        last_name: 'should NOT be shorter than 50 characters',
        mobile_phone: 'should NOT be shorter than 16 characters',
        company: 'should NOT be shorter than 50 characters',
        username: 'should NOT be shorter than 16 characters',
      },
      space: {
        email: 'Space not allowed',
        password: 'Space not allowed',
        first_name: 'Space not allowed',
        last_name: 'Space not allowed',
        mobile_phone: 'Space not allowed',
        company: 'Space not allowed',
        username: 'Space not allowed',
      },
    },
    email: {
      confirmation: 'Please confirm your email',
    },
    language: {
      en: 'EN',
      ua: 'UA',
    },
    route: {
      discount_and_news: 'News',
      supported_vehicles: 'Supported Vehicles',
      contacts: 'Contacts',
      prices: 'Prices',
      process_file_old: 'Process file old version',
      processing_tasks: 'Processing tasks',
      privacy_policy: 'Privacy policy',
      user_agreement: 'User agreement',
      user_profile: 'User profile',
      billing: 'Billing',
      settings: 'Settings',
      payment_and_delivery: 'Payment, delivery, refund',
      add_file: 'Add file',
      home: 'About us',
    },
    button_title: {
      log_in: 'Log in',
      sign_up: 'Sign up',
      log_out: 'Log out',
      choose_file: 'Choose file',
      submit: 'Submit',
      calculate_price: 'Calculate',
      upload: 'Upload',
      pay: 'Pay',
      continue: 'Continue',
      change_password: 'Change password',
      upload_file: "Upload file",
      check_files: "Check files",
      forgot_password: 'Forgot password?',
      get_new_password: 'Get new password',
      close: 'Close',
      process_file: 'Process file',
      no_ecu_in_list: 'No ECU in the list',
    },
    mobile: {
      limited_version: 'Full version available on PC',
    },
    billing: {
      title: 'Billing',
    },
    settings: {
      title: 'Settings',
    },
    user_profile: {
      title: 'User profile',
      title_files: "Files",
      user_information: 'User Information',
      first_name: 'First Name',
      last_name: 'Last Name',
      mobile_phone: 'Mobile phone',
      company: 'Company',
      username: 'Username',
      email: 'Email',
      email_confirmed: 'Email confirmed',
      email_not_confirmed: 'Email not confirmed',
      user_plan: 'User Plan',
      bonus: 'Бонусы',
      telegram_id: 'Telegram ID',
      user_plan_tooltip: 'Your discount plan',
      bonus_tooltip: 'Your current bonus',
      link_telegram_tooltip: 'To link your telegram account to your profile click the link and open a telegram app',
      ed_main_price: "Price for first file",
      ed_second_price: "Price for 4+ file",
      subscription: "Subscription",
      active_subscription: "Active",
      ed_subscription_start: "Start",
      ed_subscription_end: "End",
      ed_subscribe_limit: "Limit",
      uploaded_files: "Uploaded files"

    },
    ticket: {
      ticket: "Ticket",
      messages: "Messages",
      date: "Date",
      name: "Name",
      attachment: "Attachment",
      file: "File",
      newMessage: "New Message",
      selectedFile: "Selected File",
      send: "Send",
      client: "Client:",
      admin: "Admin:",
      task_closed_text: 'Task was closed. You can\'t send message here'
    },
    user_plan: {
      basic: 'Basic',
      discount: 'Discount'
    },
    prices: {
      title: 'Price',
      base_price: 'Base price',
      discount: 'Discount',
      to_pay: 'To pay',
      bonus: 'Bonus',
      no_actions: 'No available actions',
      dtc_not_allowed: 'DTC not allowed',
      available_actions: 'Available actions',
      remove_dtc: 'Remove DTC',
      cogwheel_tooltip: 'cogwheel_tooltip',
      remove_dtc_tooltip: 'remove_dtc_tooltip',
    },
    signup: {
      confirm_email: 'Thank you for your registration! Please check your mailbox and confirm email.',
    },
    payment_and_delivery: {
      title: 'Payment and delivery',
    },
    files_list: {
      title: "File list",
      key: "Key",
      status: "Status",
      file_name: "File Name",
      add_date: "Add Date",
      additional_files: "Additional files",
      action: "Action",
      size: "Size",
      bytes: "(Bytes)",
    },
    view_file: {
      title: "View File",
      key: "Key",
      name: "Name",
      file_name: "File name",
      size: "Size",
      date: "Date",
      status: "Status",
      action: "Action",
      additional_files: "Additional files",
      add_file: "Add file",
      download: "Download",
      pay: "Pay",
      bytes: "Bytes",
      bytes2: "(Bytes)",
    },
    upload_file: {
      title: 'Upload file',
      encrypt: "Encrypt (bin -> mmf)",
      decrypt: "Decrypt (mmf -> bin)",
      choose_file: "Choose file",
      file_name: "File name",
      size: "Size",
      key: "Key",
      upload: "Upload",
      price: "Price",
      to_pay: "To pay",
      pay: "Pay",
      download: "Download",
      payed: "Payed",
      new: "New",
      to_file: "To file",
      payed_by_subscription: "Paid by subscription",
    },
    add_file: {
      title: 'Add New ECU file',
      upload_file: 'Upload File',
      before_upload_warning_title: 'You must use only original ECU file!',
      before_upload_warning_text:
        'The service works only with original ECU files or files with chip-tunning. Service does not work with files in archives (zip, rar and etc). The files should be unpacked.',
      file_name: 'File name',
      file_size: 'File size',
      file_key: 'ID',
      ecu: 'ECU',
      soft: 'Soft',
      choose_ecu: 'Choose ECU',
      no_ecu: 'ECU not found',
      no_actions: 'No available actions',
      dtc_not_allowed: 'DTC not allowed',
      available_actions: 'Available actions',
      remove_dtc: 'Remove DTC',
      base_price: 'Base price',
      discount: 'Discount',
      to_pay: 'To pay',
      bonus: 'Bonus',
      price: 'Price',
      pay_title: 'Payment process',
      no_ecus_text1: 'We have identified your ECU automatically, but could not find it automatically in our database, please select it from the list below.',
      no_ecus_text2: 'We were unable to detect your ECU automatically, please select it from the list below.',
      no_ecus_text3: 'Select the desired ECU from the list below',
      pay_info:
        'To process the selected actions, the involvement of our specialist is required. We can do it after payment. Attention! After successful processing, the file will be available for download. In the event that we are unable to complete the required actions, we will refund your funds.',
      pay_info2:
        'We have processed all the required actions automatically and are ready to give you the processed file immediately after payment. The file will be available for download automatically as soon as we receive confirmation of payment.',
      pay_info3:
        'An error occurred while processing the file. Please contact us via the feedback form on the page to the right. Be sure to include the File Key into message',
      processed_dtcs: 'Processed DTCs',
      processed_actions: 'Processed actions',
      manual_actions: 'Manual actions',
      cogwheel_tooltip: 'cogwheel_tooltip',
      remove_dtc_tooltip: 'remove_dtc_tooltip',
      return_ecu_suggestions: 'Return to ECU suggestions',
      errorDtc: "DTC codes must be in the format XXXX separated by commas where X is character A-F, a-f, 1-9"
    },
    processing_tasks: {
      title: 'Processing Tasks',
      tasks: {
        title: {
          key: 'Key',
          status: 'Status',
          request_date: 'Date',
          ecu: 'ECU',
          name: 'File name',
          comment: 'Comment',
          note: 'Note',
          download: 'Download',
          history: "History",
          fileName: "File Name",
          addDate: "Add Date",
          action: "Action",
          tickets: "Tickets"
        },
      },
      action: {
        pay: 'Pay',
        download: 'Download',
      },
      status: {
        new: 'New',
        payed: 'Payed',
        processed: 'Processed',
        allow_download: 'Ready',
        can_not_open: 'Can`t open',
        unsupported_format: 'Unsupported format',
        wrong_ecu_or_engine: 'Wrong ecu/engine',
        actions_not_supported: 'Actions not supported',
        closed: 'Closed',
      },
    },
    public_page: {
      contacts: {
        title: 'Contacts and working time',
        content:
          '+38 (095) 07 33 495<a href="https://t.me/motorcar_tuning_shop" about="_blank"><img src="/assets/Telegram_logo.svg" height="30px" width="30px" style="margin-left:10px;margin-top:3px;position:absolute" alt="Telegram" /></a><br/>+38 (097) 07 33 495<br/><br/>contacts@motorcar-tuning.shop<br/><br/><a target="_blank" href="https://motorcar-tuning.com.ua">https://motorcar-tuning.com.ua</a><br/><br/>',
        text1: '',
        text2: 'Open hours:',
        text3:
          '<pre style="font-family: Roboto, Helvetica, Arial, sans-serif;">Mon-Fri    9.00 - 18.00 average file processing time 30 min<br/>Mon-Fri  18.00 - 23.00 average file processing time 1.5 hours<br/>Sat-Sun        10.00 - 23.00 average file processing time 2 hours<br/><br/>TimeZone: EET</pre>',
        text4: '',
        text5: '',
      },
      home: {
        about_us: 'About Us',
        text1:
          'We present a file service of proven solutions for switching off environmental systems and other modifications made to the firmware of electronic control units:',
        text2: 'passenger cars',
        text3: 'trucks',
        text4: 'agri',
        text5: 'buses',
        text6: 'motorcycles, ATVs, scooters, snowmobiles',
        text7: `On our portal you can quickly and conveniently buy a high-quality firmware file for an electronic control unit (ECU) with the modifications you need.
        All solutions are tested, correct and safe.
        The files are processed in real time by our calibrators using special licensed software or automatically with additional manual control.`,
        text8: 'Benefits:',
        text9: 'Competitive price',
        text10: 'Flexible and transparent pricing',
        text11: 'Accumulative discounts for regular customers',
        text12: 'Priority for file processing time to owners of slave devices',
        text13: 'Fast file processing time',
        text14: 'Correct result guarantee',
        text15: '100% money back guarantee in case of non-working solution',
        text16: 'Pay only for the current file without the need to replenish your personal account',
        text17: 'Technical support in the chat during the whole working time',
        text18: 'Possibility to get a turnkey service from us or our representatives',
        text19: 'Prices',
        text20: 'You can find out the specific price after registration. There is a system of discounts for regular customers:',
        text21: 'Доступны следующие уровни:',
        text22: 'базовый',
        text23: 'мастер 	  -5%		при достижении оборота 500$',
        text24: 'партнер 	 - 10% 		при достижении оборота 1500$',
        text25: 'VIP		 - 15%		при достижении оборота 2500$',
        text26: 'Скидки несгораемые и действуют все время существования аккаунта.',
        options: 'Available options and modifications',
        option1: 'Disable AdBlue/SCR',
        option2: 'Burbles activation',
        option3: 'Cold Start Noise Reduction',
        option4: 'Disable DPF',
        option5: 'Remove DTC',
        option6: 'Disable EGR',
        option7: 'Disable EVAP',
        option8: 'Disable Exhaust Flap',
        option9: 'Disable Flaps / Swirl',
        option10: 'Disable GPF/OPF',
        option11: 'HOT Start Fix',
        option12: 'Disable IMMO',
        option13: 'Kickdown Off',
        option14: 'DisableLambda/O2',
        option15: 'Disable MAF',
        option16: 'Popcorn Activation',
        option17: 'Readiness Calibration',
        option18: 'Disable SAP',
        option19: 'Remove Speed Limiter',
        option20: 'Sport Displays Calibration',
        option21: 'Disable StartStop',
        option22: 'Disable Torque Monitoring',
        option23: 'Disable TVA',
      },
      discount_and_news: {
        text1: `In the "Prices" section you can see the basic cost of our services. <br/>
        These prices are not final. <br/>
        We always offer our regular customers the most optimal conditions. <br/>
        Contact us, tell us about yourself and we will consider an individual discount for you. <br/>`,
        text2: `motorcar tuning official partner of magicmotorsport company <br/>
        You can buy Flex programmers and any magic products from us.  <br/>
        Contact us for a personalized offer  <br/>`
      },
      email_confirmation: {
        title: 'Email Confirmation',
        confirmed: 'Thank you for email confirmation. Now you can receive info emails from us. Please login in.',
        not_found: 'Your confirmation link not valid. Probably you have confirmed your email early. Please try to login.',
      },
      change_password: {
        title: 'Change password',
      },
    },
    component: {
      select_placeholder: 'Please choose value',
      checkbox: {
        agree: 'I agree with',
        and: 'and',
        privacy_policy: 'Privacy policy',
        user_agreement: 'User agreement',
      },
    },
    select_label: {
      vehicle: '* Vehicle',
      brand: '* Brand',
      model: '* Model',
      engine: '* Engine',
      ecus: '* Ecus',
      action: '* Action',
    },
    input: {
      label: {
        email: 'Email: ',
        emailOrUsername: 'Email/Username: ',
        password: 'Password: ',
        new_password: 'New password: ',
        new_password_duplicate: 'Repeat password: ',
        username: 'Username: ',
        company: 'Company: ',
        mobile_phone: 'Mobile phone: ',
        first_name: 'First name: ',
        last_name: 'Last name: ',
        dtc: 'DTC: ',
        comment: 'Comment: ',
      },
      placeholder: {
        email: 'Enter your email',
        password: 'Enter your password',
        new_password: 'Enter new password: ',
        new_password_duplicate: 'Enter new password again: ',
        username: 'Enter your username',
        company: 'Enter your company',
        mobile_phone: 'Enter your mobile phone',
        first_name: 'Enter your first name',
        last_name: 'Enter your last name',
        dtc: 'Enter your DTC',
        comment: 'Enter your comment',
      },
    },
    dialog: {
      title: {
        login: 'Login',
        sign_up: 'Signup',
        server_error: 'Error',
        change_password: 'Change password',
        forgot_password: 'Forgot password',
        request_new_password_success: 'Success',
        change_password_success: 'Password changed',
        payment_approve: 'Payment approve',
      },
      error_map: {
        file_not_valid: "File not valid",
        client_has_no_slave: "Client has no slave",
        file_not_uploaded: "File not uploaded",
      },
      content: {
        server_error: 'Some error occurred. Please try again or contact us',
        request_new_password_success: 'We sent you a message on email for password change',
        change_password_success: 'Password was successfully changed',
        payment_approve: {
          file: 'File',
          key: 'Key',
          name: 'Name',
          date: 'Date',
          ecu: 'ECU',
          actions: 'Actions: ',
          action: 'Action ',
          dtc: 'DTC: ',
          price: 'Price',
          bonus: 'Bonus',
          base: 'Base',
          discount: 'Discount',
          to_pay: 'To pay',
          payment_condition: 'By making a payment you agree with',
          file_process_requested: 'We are accept your request. Please be patient, wi will process it aprox in 30 min',
          file_processed_automatically: 'Your file are ready for download',
          link: {
            privacy_policy: 'Privacy policy',
            user_agreement: 'User agreement',
            payment_and_delivery: 'Payment and delivery',
            file_list: 'Back to files'
          },
        },
      },
    },
  },
};
