// outsource dependencies
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import {APP_COLOR, CONSTRAINT} from 'constants/style';
import Paper from "@material-ui/core/Paper";
import NewReleasesOutlinedIcon from "@material-ui/icons/NewReleasesOutlined";
import UploadPayment from "../../ed-payment";
import Preloader from "../../../../components/Preloader";
import currentConfig from "../../../../utils/config.utils";


const useStyles = makeStyles((theme: Theme) => ({
    paperFullWidth: {
        width: 700,
    },
    preloader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        minHeight: 300,
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 8px 8px 24px',
        backgroundColor: APP_COLOR.GREY,
        color: APP_COLOR.WHITE,
    },
    close: {
        padding: 6,
        color: APP_COLOR.WHITE,
        cursor: 'pointer',
    },
    titleContent: {
        lineHeight: 2,
    },
    content: {
        display: 'flex',
        padding: 12,
        flexDirection: 'column',
    },
    infoContainer: {
        lineHeight: '25px',
        paddingLeft: 8,
    },
    infoTextLine: {
        whiteSpace: 'nowrap',
    },
    infoContent: {
        display: 'inline-block',
        width: 150,
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 16,
        alignItems: 'center',
    },
    paymentIconsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    links: {
        fontSize: 12,
    },
    continueAction: {
        backgroundColor: APP_COLOR.LIGHT_GREY,
        color: APP_COLOR.WHITE,
        '&:hover': {
            color: APP_COLOR.BLACK,
        },
        transition: '0.6s',
    },
    uploadWarningContainer: {
        width: "400px",
        padding: 15,
        fontSize: 'small',
        color: '#f44336',
        margin: '20px 0 10px 16px',
    },
    newReleaseIcon: {
        width: 50,
        height: 50,
        marginTop: "50px",
    },
    decryptRadioContainer: {
        marginLeft: '50px',
    },
    actionBtn: {
        marginTop: "20px",
        marginBottom: "20px",
    },
    cardInfoTextLine: {
        whiteSpace: 'nowrap',
    },
    cardInfoStrong: {
        display: 'inline-block',
        width: 150,
    },
    fileInfoContainer: {
        marginLeft: 20,
        lineHeight: '25px',
        overflow: 'hidden',
        display: "grid",
    },
    priceContainer: {
        marginTop: "5px",
        marginLeft: 20,
        lineHeight: '25px',
        overflow: 'hidden',
        display: "grid",
    },
    warningRootContainer: {
        display: "flex",
    },
    uploaderBtn: {
        display: "block",
        backgroundColor: (props: any) => (!props.uploadEcuSuccess ? theme.palette.primary.light : 'rgba(0, 0, 0, 0.26)'),
        padding: 8,
        cursor: 'pointer',
        width: 'max-content',
        minWidth: CONSTRAINT.BUTTON_WIDTH,
        textAlign: 'center',
        color: APP_COLOR.WHITE,
        borderRadius: 4,
        marginRight: 8,
        '&:hover': {
            backgroundColor: (props: any) => (!props.uploadEcuSuccess ? theme.palette.primary.dark : 'rgba(0, 0, 0, 0.26)'),
        },
        marginBottom: "20px",
        marginTop: "20px",
    },
    hideUploader: {
        visibility: 'hidden',
        position: 'absolute',
    },
    hiddenBtn: {
        marginTop: "20px"
    },
    uploadPreloader: {
        paddingLeft: "20px",
        display: "inline-block"
    },
    encryptLabel: {
        display: "block",
        marginTop: "20px",
        fontWeight: "bold",
    },
    payBtn: {
        width: "150px",
        marginTop: "20px",
        marginBottom: "20px",
    },
    downloadLink: {
        display: "inline-block",
        marginTop: "20px",
        marginBottom: "20px",
    },
}));

interface IProps {
    open: boolean;
    parentFileKey?: string;
    uploadedExpectAnswer?: boolean;

    handleOnClose: () => void;

    edFileInfo?: any;
    loadEdFile?: (formData: any) => void;
}

function AdditionalFileModal(props: IProps) {
    const {open, handleOnClose, loadEdFile, parentFileKey, edFileInfo, uploadedExpectAnswer} = props;
    const classes = useStyles({uploadEcuSuccess: false});
    const {t} = useTranslation();

    const [file, setFile] = useState<null | File>(null);
    const [openPayment, setOpenPayment] = useState<boolean>(false);

    const handleOpenPayment = () => {
        setOpenPayment(true);
    }

    const handleClosePayment = () => {
        setOpenPayment(false);
    }

    const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const cFile = e.target.files ? e.target.files[0] : null;
        setFile(cFile);
    };

    const handleFileUploadSubmit = () => {
        uploadEncodeDecodeFile && uploadEncodeDecodeFile(file);
    };

    const handleOnCloseForm = () => {
        handleOnClose && handleOnClose();

        setFile(null);
    }


    const uploadEncodeDecodeFile = (carFile: File | null) => {
        if (!carFile || !parentFileKey) {
            return;
        }

        loadEdFile && loadEdFile({
            carFile: carFile,
            parentFileKey: parentFileKey,
        });
    }


    return (
        <Dialog open={open} classes={{paperFullWidth: classes.paperFullWidth}} fullWidth
                maxWidth='md'>
            <DialogTitle disableTypography classes={{root: classes.title}}>
                <Typography variant='h5'>
                    <span>{t('upload_file.title')}</span>
                </Typography>
                <CloseIcon onClick={handleOnCloseForm} className={classes.close}/>
            </DialogTitle>
            <DialogContent>
                <div className={classes.warningRootContainer}>
                    <NewReleasesOutlinedIcon color={"error"} className={classes.newReleaseIcon}/>
                    <Paper elevation={3} className={classes.uploadWarningContainer}>
                        {t('add_file.before_upload_warning_text_ed')}
                    </Paper>
                </div>

                <label className={classes.encryptLabel}>{t('upload_file.encrypt')}</label>

                {!edFileInfo && !uploadedExpectAnswer ?
                    <label className={classes.uploaderBtn}>
                        {t('button_title.choose_file')}
                        <input type='file' className={classes.hideUploader} onChange={handleFile}/>
                    </label> : <div className={classes.hiddenBtn}/>}

                {file ?
                    <div>
                        <div className={classes.fileInfoContainer}>
                <span className={classes.cardInfoTextLine}>
                    <strong className={classes.cardInfoStrong}>{t('upload_file.file_name')}</strong> {file.name}
                </span>
                            <span className={classes.cardInfoTextLine}>
                    <strong className={classes.cardInfoStrong}>{t('upload_file.size')}</strong> {file.size + " bytes"}
                </span>
                            {edFileInfo ?
                                <span className={classes.cardInfoTextLine}>
                                <strong
                                    className={classes.cardInfoStrong}>{t('upload_file.key')}</strong> {edFileInfo.key}
                            </span> : null}
                        </div>


                        <div>
                            {!edFileInfo ?
                                <Button className={classes.actionBtn} variant='contained' color='primary'
                                        onClick={handleFileUploadSubmit}>
                                    {t('upload_file.upload')}
                                </Button>
                                : <div className={classes.hiddenBtn}/>}

                            {uploadedExpectAnswer ?
                                <div className={classes.uploadPreloader}>
                                    <Preloader size={25}/>
                                </div>
                                : null}

                        </div>


                        {edFileInfo ?
                            <div>
                                {edFileInfo.status === "1" ?
                                <div>
                                    <Typography variant='h5'>
                                        <span>{t('upload_file.price')}</span>
                                    </Typography>

                                    <div className={classes.priceContainer}>
                                        <span className={classes.cardInfoTextLine}>
                                            <strong
                                                className={classes.cardInfoStrong}>{t('upload_file.to_pay')}</strong> {edFileInfo.orderInfo.to_pay + " UAH"}
                                            </span>
                                    </div>
                                </div> : null}

                                {edFileInfo.status === "1" ?
                                <Button className={classes.payBtn} variant='contained' color='primary'
                                        onClick={handleOpenPayment}>
                                    {t('upload_file.pay')}
                                </Button> : <a className={classes.downloadLink} href={currentConfig().downloadUrl + 'download/ed/processed/' + edFileInfo.key }>{t("upload_file.download")}</a> }
                            </div> : null}
                    </div> : null}
            </DialogContent>
            {edFileInfo && openPayment ?
                <UploadPayment handleClose={handleClosePayment} open={openPayment}
                               fileKey={edFileInfo.key}/>
                : null}
        </Dialog>
    );
}

export default AdditionalFileModal;
