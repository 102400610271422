// polyfill from MDN https://developer.mozilla.org/en-US/docs/Web/API/Storage/LocalStorage
const cookieStorage = {
  getItem: (sKey: string) =>
    !cookieStorage.hasProperty(sKey)
      ? void 0
      : unescape(document.cookie.replace(new RegExp(`(?:^|.*;\\s*)${escape(sKey).replace(/[-.+*]/g, '\\$&')}\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*`), '$1')),

  setItem: (sKey: string, sValue: any) => (!sKey ? null : (document.cookie = `${escape(sKey)}=${escape(sValue)}; expires=Tue, 19 Jan 2038 03:14:07 GMT; path=/`)) && void 0,

  removeItem: (sKey: string) => (!cookieStorage.hasProperty(sKey) ? void 0 : (document.cookie = `${escape(sKey)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`)) && void 0,

  hasProperty: (sKey: string) => (!sKey ? false : new RegExp(`(?:^|;\\s*)${escape(sKey).replace(/[-.+*]/g, '\\$&')}\\s*\\=`).test(document.cookie)),
};

export default cookieStorage;

