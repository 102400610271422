// types
export const TYPE = ((prefix) => ({
  PREFIX: new RegExp(prefix, 'i'),
  // complex actions
  CLEAR: `${prefix}CLEAR`,
  DATA: `${prefix}DATA`,
  TASK_DATA: `${prefix}TASK_DATA`,
  // triggers
  LOAD_FILES: `${prefix}LOAD_FILES`,
  BILLING_ORDER: `${prefix}BILLING_ORDER`,
  REQUEST_PAYMENT: `${prefix}REQUEST_PAYMENT`,
  LOAD_FILE_HISTORY: `${prefix}LOAD_FILE_HISTORY`,
}))('@processing-tasks/');

// selectors
export const selector = (state: any) => state.processingTasks;

export function typedAction(type: string, payload?: any) {
  return { type, payload };
}

interface IUserState {
  expectAnswer: boolean;
  expectOrderAnswer: boolean;
  expectRequestFileAnswer: boolean;
  requestFileResponse: IRequestFileResponse | null;
  tasks: IFile[] | null;
  selectedFileKey: string | null;
  fileData: IFileData | null;
  price: IPrice | null;
  actions: string[] | null;
  signature: string | null;
  data: string| null;
  filesHistory: any;
}

export interface IFile {
  key: string | null;
  status: string;
  request_date: string;
  ecu: string | number | null;
  name: string | number | null;
  comment: string | null;
  note?: string | null;
  history_count: number | null;
  ticket_count: number | null;
}

export interface IFileData {
  date: string;
  dtc: string;
  ecu: string;
  name: string;
}

export interface IPrice {
  basePrice: number | string;
  discount: number | string;
  toPay: number | string;
  bonus: number | string;
}

export interface IRequestFileResponse {
  status: string;
  fileKey: string;
  fileStatus: string;
}

export const updateExpectAnswerAction = (expectAnswer: boolean) => typedAction(TYPE.DATA, { expectAnswer });
export const updateExpectOrderAnswerAction = (expectOrderAnswer: boolean) => typedAction(TYPE.DATA, { expectOrderAnswer });
export const updateExpectRequestFileAnswerAction = (expectRequestFileAnswer: boolean) => typedAction(TYPE.DATA, { expectRequestFileAnswer });
// update
export const updateTasksAction = (tasks: IFile[] | null) => typedAction(TYPE.DATA, { tasks });
export const updateFileHistory = (filesHistory: any) => typedAction(TYPE.DATA, filesHistory);
export const updateTaskAction = (task: IRequestFileResponse | null) => typedAction(TYPE.TASK_DATA, { task });
export const updateSelectedFileKeyAction = (selectedFileKey: string | null) => typedAction(TYPE.DATA, { selectedFileKey });
export const updateFileDataAction = (fileData: IFileData | null) => typedAction(TYPE.DATA, { fileData });
export const updatePriceAction = (price: IPrice | null) => typedAction(TYPE.DATA, { price });
export const updateActionsAction = (actions: string[] | null) => typedAction(TYPE.DATA, { actions });
export const updateSignatureAction = (signature: string | null) => typedAction(TYPE.DATA, { signature });
export const updateDataAction = (data: string | null) => typedAction(TYPE.DATA, { data });
export const updateRequestFileResponseAction = (requestFileResponse: IRequestFileResponse | null) => typedAction(TYPE.DATA, { requestFileResponse });
// triggers
export const loadTasksAction = () => typedAction(TYPE.LOAD_FILES);
export const requestBillingOrderAction = () => typedAction(TYPE.BILLING_ORDER);
export const requestPaymentAction = () => typedAction(TYPE.REQUEST_PAYMENT);
export const clearAction = () => typedAction(TYPE.CLEAR);
export const loadFileHistoryAction = (key: string | null) => typedAction(TYPE.LOAD_FILE_HISTORY, key);

type UserAction = ReturnType< 
| typeof updateExpectAnswerAction 
| typeof loadTasksAction 
| typeof updateExpectOrderAnswerAction 
| typeof updateExpectRequestFileAnswerAction 
| typeof updateFileDataAction 
| typeof updatePriceAction 
| typeof updateActionsAction 
| typeof updateSignatureAction
| typeof updateDataAction
| typeof updateRequestFileResponseAction
| typeof clearAction
>;

const initialState: IUserState = {
  expectAnswer: false,
  expectOrderAnswer: false,
  expectRequestFileAnswer: false,
  requestFileResponse: null,
  tasks: null,
  selectedFileKey: null,
  fileData: null,
  price: null,
  actions: null,
  signature: null,
  data: null,
  filesHistory: null,
};

export function processingTasks(state = initialState, action: UserAction): IUserState {
  switch (action.type) {
    default:
      return state;
    case TYPE.DATA:
      return { ...state, ...action.payload };
    case TYPE.TASK_DATA:
      const updated = state.tasks?.map(item => {
        if (item.key === action.payload.task.fileKey)
          item.status = action.payload.task.fileStatus
        return item
      })
      return { ...state, tasks: updated || null };
    case TYPE.CLEAR:
      return initialState;
  }
}
