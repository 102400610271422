// types
export const TYPE = ((prefix) => ({
  PREFIX: new RegExp(prefix, 'i'),
  // complex actions
  CLEAR: `${prefix}CLEAR`,
  DATA: `${prefix}DATA`,
  // loading actions
  LOAD_VEHICLES: `${prefix}LOAD_VEHICLE`,
  LOAD_VEHICLE_BRANDS: `${prefix}LOAD_VEHICLE_BRANDS`,
  LOAD_VEHICLE_MODELS: `${prefix}LOAD_VEHICLE_MODELS`,
  LOAD_VEHICLE_ENGINES: `${prefix}LOAD_VEHICLE_ENGINES`,
  LOAD_VEHICLE_ECUS: `${prefix}LOAD_VEHICLE_ECUS`,
  LOAD_VEHICLE_ACTIONS: `${prefix}LOAD_VEHICLE_ACTIONS`,
  LOAD_VEHICLE_PRICE: `${prefix}LOAD_VEHICLE_PRICE`,
  // state
  SELECT_ACTION_STATE: `${prefix}SELECT_ACTION_STATE`,
}))('@prices/');

// selectors
export const selector = (state: any) => ({ ...state.prices, user: state.app.user });

export interface IVehicle {
  key: string;
  name: string;
}
export interface IVehicleBrand {
  key: string;
  name: string;
}
export interface IVehicleModel {
  key: string;
  name: string;
}
export interface IVehicleEngine {
  key: string;
  name: string;
}
export interface IVehicleEcus {
  key: string;
  name: string;
}
export interface IVehicleAction {
  key: string;
  name: string;
  auto: boolean;
  available: boolean;
  selected: boolean;
}

export interface IVehiclePrice {
  basePrice: string;
  discount: number | string;
  toPay: string;
  bonus: string;
}

interface IUserState {
  expectAnswer: boolean;

  vehicles: IVehicle[] | null;
  vehicleBrands: IVehicleBrand[] | null;
  vehicleModels: IVehicleModel[] | null;
  vehicleEngines: IVehicleEngine[] | null;
  vehicleEcus: IVehicleEcus[] | null;
  vehicleActions: IVehicleAction[] | null;

  vehiclePrice: IVehiclePrice | null;

  selectedEcusKey: string | null;
  selectedBrandKey: string | null;
  dtcKey: string;
  allowDtc: boolean;
  autoDtc: boolean;
  showInfo: boolean;

  vehicleBrandsLoaded: boolean;
  vehicleModelsLoaded: boolean;
  vehicleEnginesLoaded: boolean;
  vehicleEcusLoaded: boolean;
  vehicleActionsLoaded: boolean;

  expectBrandsAnswer: boolean;
  expectModelsAnswer: boolean;
  expectEnginesAnswer: boolean;
  expectEcusAnswer: boolean;
  expectActionsAnswer: boolean;
  expectVehiclePriceAnswer: boolean;
}

export function typedAction(type: string, payload?: any) {
  return { type, payload };
}

export const updateExpectAnswerAction = (expectAnswer: boolean) => typedAction(TYPE.DATA, { expectAnswer });
// trig loading
export const loadVehiclesAction = () => typedAction(TYPE.LOAD_VEHICLES);
export const loadVehicleBrandsAction = (vehicle: string) => typedAction(TYPE.LOAD_VEHICLE_BRANDS, vehicle);
export const loadVehicleModelsAction = (brand: string) => typedAction(TYPE.LOAD_VEHICLE_MODELS, brand);
export const loadVehicleEnginesAction = (model: string) => typedAction(TYPE.LOAD_VEHICLE_ENGINES, model);
export const loadVehicleEcusAction = (engine: string) => typedAction(TYPE.LOAD_VEHICLE_ECUS, engine);
export const loadVehicleActionsAction = (key: string) => typedAction(TYPE.LOAD_VEHICLE_ACTIONS, key);
export const loadVehiclePriceAction = () => typedAction(TYPE.LOAD_VEHICLE_PRICE);
// update state
export const updateSelectedVehicleKeyAction = (selectedVehicleKey: string | null) => typedAction(TYPE.DATA, { selectedVehicleKey });
export const updateVehiclesAction = (vehicles: IVehicle[] | null) => typedAction(TYPE.DATA, { vehicles });
export const updateVehicleBrandsAction = (vehicleBrands: IVehicleBrand[] | null) => typedAction(TYPE.DATA, { vehicleBrands });
export const updateVehicleModelsAction = (vehicleModels: IVehicleModel[] | null) => typedAction(TYPE.DATA, { vehicleModels });
export const updateVehicleEnginesAction = (vehicleEngines: IVehicleEngine[] | null) => typedAction(TYPE.DATA, { vehicleEngines });
export const updateVehicleEcusAction = (vehicleEcus: IVehicleEcus[] | null) => typedAction(TYPE.DATA, { vehicleEcus });
export const updateVehicleActionsAction = (vehicleActions: IVehicleAction[] | null) => typedAction(TYPE.DATA, { vehicleActions });

export const updateVehiclePriceAction = (vehiclePrice: IVehiclePrice | null) => typedAction(TYPE.DATA, { vehiclePrice });

export const updateSelectedEcusKeyAction = (selectedEcusKey: string | null) => typedAction(TYPE.DATA, { selectedEcusKey });
export const updateSelectedBrandKeyAction = (selectedBrandKey: string | null) => typedAction(TYPE.DATA, { selectedBrandKey });
export const updateAllowDtcAction = (allowDtc: boolean) => typedAction(TYPE.DATA, { allowDtc });
export const updateAutoDtcAction = (autoDtc: boolean) => typedAction(TYPE.DATA, { autoDtc });
export const updateDtcAction = (dtcKey: string) => typedAction(TYPE.DATA, { dtcKey });
export const updateShowInfoAction = (showInfo: boolean) => typedAction(TYPE.DATA, { showInfo });

export const updateSelectActionStateAction = (actionKey: string) => typedAction(TYPE.SELECT_ACTION_STATE, { actionKey });
// handle "select" render due to loading step
export const updateVehicleBrandsLoadedAction = (vehicleBrandsLoaded: boolean) => typedAction(TYPE.DATA, { vehicleBrandsLoaded });
export const updateVehicleModelsLoadedAction = (vehicleModelsLoaded: boolean) => typedAction(TYPE.DATA, { vehicleModelsLoaded });
export const updateVehicleEnginesLoadedAction = (vehicleEnginesLoaded: boolean) => typedAction(TYPE.DATA, { vehicleEnginesLoaded });
export const updateVehicleEcusLoadedAction = (vehicleEcusLoaded: boolean) => typedAction(TYPE.DATA, { vehicleEcusLoaded });
export const updateVehicleActionsLoadedAction = (vehicleActionsLoaded: boolean) => typedAction(TYPE.DATA, { vehicleActionsLoaded });
// to trig preloader render
export const updateExpectBrandsAnswerAction = (expectBrandsAnswer: boolean) => typedAction(TYPE.DATA, { expectBrandsAnswer });
export const updateExpectModelsAnswerAction = (expectModelsAnswer: boolean) => typedAction(TYPE.DATA, { expectModelsAnswer });
export const updateExpectEnginesAnswerAction = (expectEnginesAnswer: boolean) => typedAction(TYPE.DATA, { expectEnginesAnswer });
export const updateExpectEcusAnswerAction = (expectEcusAnswer: boolean) => typedAction(TYPE.DATA, { expectEcusAnswer });
export const updateExpectActionsAnswerAction = (expectActionsAnswer: boolean) => typedAction(TYPE.DATA, { expectActionsAnswer });
export const updateExpectVehiclePriceAnswerAction = (expectVehiclePriceAnswer: boolean) => typedAction(TYPE.DATA, { expectVehiclePriceAnswer });


type UserAction = ReturnType<
  | typeof updateExpectAnswerAction
  //
  | typeof updateVehiclesAction
  | typeof updateVehicleBrandsAction
  | typeof updateVehicleModelsAction
  | typeof updateVehicleEnginesAction
  | typeof updateVehicleEcusAction
  | typeof updateVehicleActionsAction
  | typeof updateSelectActionStateAction
  | typeof updateSelectedBrandKeyAction
  | typeof updateSelectedEcusKeyAction
  | typeof updateAllowDtcAction
  | typeof updateShowInfoAction
  //
  | typeof updateVehicleBrandsLoadedAction
  | typeof updateVehicleModelsLoadedAction
  | typeof updateVehicleEnginesLoadedAction
  | typeof updateVehicleEcusLoadedAction
  | typeof updateVehicleActionsLoadedAction
  //
  | typeof updateExpectBrandsAnswerAction
  | typeof updateExpectModelsAnswerAction
  | typeof updateExpectEnginesAnswerAction
  | typeof updateExpectEcusAnswerAction
  | typeof updateExpectActionsAnswerAction
>;

const initialState: IUserState = {
  expectAnswer: false,

  vehicles: null,
  vehicleBrands: null,
  vehicleModels: null,
  vehicleEngines: null,
  vehicleEcus: null,
  vehicleActions: null,

  vehiclePrice: null,

  selectedEcusKey: null,
  selectedBrandKey: null,
  dtcKey: '',
  allowDtc: false,
  autoDtc: false,
  showInfo: false,

  vehicleBrandsLoaded: false,
  vehicleModelsLoaded: false,
  vehicleEnginesLoaded: false,
  vehicleEcusLoaded: false,
  vehicleActionsLoaded: false,

  expectBrandsAnswer: false,
  expectModelsAnswer: false,
  expectEnginesAnswer: false,
  expectEcusAnswer: false,
  expectActionsAnswer: false,
  expectVehiclePriceAnswer: false,
};

export function prices(state = initialState, action: UserAction): IUserState {
  switch (action.type) {
    default:
      return state;
    case TYPE.DATA:
      return { ...state, ...action.payload };
    case TYPE.SELECT_ACTION_STATE:
      return _handleSelectStateAction(state, action.payload);
  }
}

const _handleSelectStateAction = (state: IUserState, payload: { actionKey: string }) => {
  const { vehicleActions } = state;
  if (!vehicleActions) return state;
  const updatedVehicleActions = vehicleActions.map((vehicleAction: IVehicleAction) => {
    if (vehicleAction.key === payload.actionKey) {
      return { ...vehicleAction, selected: !vehicleAction.selected };
    }
    return vehicleAction;
  });
  return { ...state, vehicleActions: updatedVehicleActions };
};
