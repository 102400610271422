
export enum ProcessTaskStatus {
  new = 1,
  payed = 2,
  processed = 3,
  allow_download = 4,
  can_not_open = 10,
  unsupported_format = 11,
  wrong_ecu_or_engine = 12,
  actions_not_supported = 13,
  closed = 99
}
