export default {
  translation: {
    errors: {
      validation_error: {
        username_not_found: 'користувач не знайдений',
        password_not_valid: 'неправильний пароль',
        email_not_found: 'поштова адреса не зареєстрована',
        bad_request: 'Виникла помилка. Будь ласка, спробуйте ще раз або зв\'яжіться з нами',
        conflict: {
          email: 'email вже існує',
        },
        minLength: {
          openapi: {
            validation: {
              email: 'має бути не менше ніж 3 символи',
              password: 'має бути не менше ніж 8 символів',
              first_name: 'має бути не менше ніж 3 символи',
              last_name: 'має бути не менше ніж 3 символи',
              mobile_phone: 'має бути не менше ніж 9 символів',
              company: 'має бути не менше ніж 3 символи',
              username: 'має бути не менше ніж 9 символів',
            },
          },
        },
        format: {
          openapi: {
            validation: {
              email: 'неправильний формат "email"',
              password: 'неправильний формат "password"',
              first_name: 'неправильний формат "first name"',
              last_name: 'неправильний формат "last name"',
              mobile_phone: 'неправильний формат "mobile phone"',
              company: 'неправильний формат "company"',
              username: 'неправильний формат "username"',
            },
          },
        },
        pattern: {
          openapi: {
            validation: {
              email: 'має відповідати "email"',
              password: 'має відповідати "password"',
              first_name: 'має відповідати "first name"',
              last_name: 'має відповідати "last name"',
              mobile_phone: 'має відповідати "mobile phone"',
              company: 'має відповідати "company"',
              username: 'має відповідати "username"',
            },
          },
        },
      },
      format: {
        email: 'неправильний формат "email"',
        mobile_phone: 'неправильний формат "mobile phone"',
        username: 'неправильний формат "username"',
      },
      min_length: {
        email: 'має бути не менше ніж 3 символи',
        password: 'має бути не менше ніж 8 символів',
        first_name: 'має бути не менше ніж 3 символи',
        last_name: 'має бути не менше ніж 3 символи',
        mobile_phone: 'має бути не менше ніж 9 символів',
        company: 'має бути не менше ніж 3 символи',
        username: 'має бути не менше ніж 9 символів',
      },
      max_length: {
        email: 'має бути не більше ніж 50 символів',
        password: 'має бути не більше ніж 50 символів',
        first_name: 'має бути не більше ніж 50 символів',
        last_name: 'має бути не більше ніж 50 символів',
        mobile_phone: 'має бути не більше ніж 16 символів',
        company: 'має бути не більше ніж 50 символів',
        username: 'має бути не більше ніж 16 символів',
      },
      space: {
        email: 'Пробіли неприпустимі',
        password: 'Пробіли неприпустимі',
        first_name: 'Пробіли неприпустимі',
        last_name: 'Пробіли неприпустимі',
        mobile_phone: 'Пробіли неприпустимі',
        company: 'Пробіли неприпустимі',
        username: 'Пробіли неприпустимі',
      },
    },
    email: {
      confirmation: 'Підтвердьте ваш email',
    },
    language: {
      en: 'EN',
      // ru: 'RU',
      ua: 'UA',
    },
    route: {
      discount_and_news: 'Новини',
      supported_vehicles: 'Підтримувані авто',
      contacts: 'Контакти',
      prices: 'Ціни',
      process_file_old: 'Стара версія обробки файлу',
      processing_tasks: 'Ваші файли',
      privacy_policy: 'Умови використання',
      user_agreement: 'Угода користувача',
      user_profile: 'Профайл користувача',
      billing: 'Платежі',
      settings: 'Налаштування',
      payment_and_delivery: 'Оплата, доставка, повернення',
      add_file: 'Додати файл',
      home: 'Про нас',
    },
    button_title: {
      log_in: 'Вхід',
      sign_up: 'Реєстрація',
      log_out: 'Вихід',
      choose_file: 'Вибрати файл',
      submit: 'Відправити',
      calculate_price: 'Розрахувати ціну',
      upload: 'Завантажити',
      pay: 'Оплатити',
      continue: 'Продовжити',
      change_password: 'Змінити пароль',
      upload_file: "Завантажити файл",
      check_files: "Перевірити файли",
      get_new_password: 'Отримати новий пароль',
      close: 'Закрити',
      forgot_password: 'Забули пароль?',
      no_ecu_in_list: 'ECU не знайдено',
      process_file: 'Обробити файл',
    },
    mobile: {
      limited_version: 'Повна версія доступна на ПК',
    },
    billing: {
      title: 'Платежі',
    },
    settings: {
      title: 'Налаштування',
    },
    user_profile: {
      title: 'Профайл користувача',
      title_files: "Файли",
      user_information: 'Інформація про користувача',
      first_name: 'Ім\'я',
      last_name: 'Прізвище',
      mobile_phone: 'Мобільний телефон',
      company: 'Компанія',
      username: 'Ім\'я користувача',
      email: 'Email',
      email_confirmed: 'Email підтверджено',
      email_not_confirmed: 'Email не підтверджено',
      user_plan: 'Тарифний план',
      bonus: 'Бонус',
      telegram_id: 'Telegram ID',
      user_plan_tooltip: 'Ваш тарифний план',
      bonus_tooltip: 'Ваш бонус',
      link_telegram_tooltip: 'Щоб підключити Telegram до системи, натисніть посилання ліворуч і відкрийте програму Telegram. Запустіть робота.',
      ed_main_price: "Цена 1го файла",
      ed_second_price: "Цена 4+ файлів",
      subscription: "Подписка",
      active_subscription: "Активна",
      ed_subscription_start: "початок",
      ed_subscription_end: "Кінець",
      ed_subscribe_limit: "Ліміт",
      uploaded_files: "Завантажено файлів"
    },
    ticket: {
      ticket: 'Тікет',
      messages: 'Повідомлення',
      date: 'Дата',
      name: 'Ім\'я Файла',
      attachment: 'Вкладення',
      file: 'Файл',
      newMessage: 'Нове повідомлення',
      selectedFile: 'Вибраний файл',
      send: 'Відправити',
      client: 'Клієнт:',
      admin: 'Адміністратор:',
      text: 'Текст',
      dtc: 'DTC',
      task_closed_text: 'Завдання закрите, ви не можете писати до неї тікети'
    },
    user_plan: {
      basic: 'Базовий',
      discount: 'Знижка',
    },
    prices: {
      title: 'Калькулятор ціни',
      base_price: 'Базова ціна',
      discount: 'Знижка',
      to_pay: 'До оплати',
      bonus: 'Бонус',
      no_actions: 'Немає доступних дій',
      dtc_not_allowed: 'DTC не підтримується',
      available_actions: 'Доступні дії',
      remove_dtc: 'Видалити DTC',
      cogwheel_tooltip: 'Ця дія буде оброблена автоматично',
      remove_dtc_tooltip: 'Видалити DTC',
    },
    signup: {
      confirm_email: 'Дякую за реєстрацію! Будь ласка перевірте вашу пошту та підтвердіть свій email.',
    },
    payment_and_delivery: {
      title: 'Оплата, доставка, повернення',
    },
    files_list: {
      title: "Список файлов",
      key: "Ключ",
      status: "Статус",
      file_name: "ім'я файлу",
      add_date: "дата додавання",
      additional_files: "Додаткові файли",
      action: "Дія",
      size: "Розмір",
      bytes: "(Байт)",
    },
    view_file: {
      title: "Смотреть файл",
      key: "Ключ",
      name: "Ім'я",
      file_name: "Ім'я файла",
      size: "",
      date: "Дата",
      status: "Статус",
      action: "Действие",
      additional_files: "Дополнительные файлы",
      add_file: "Добавить файл",
      download: "Загрузить",
      pay: "Оплатить",
      bytes: "Байт",
      bytes2: "(Байт)",
    },
    upload_file: {
      title: 'завантажити файл',
      encrypt: "Зашифрувати (bin -> mmf)",
      decrypt: "Розшифрувати (mmf -> bin)",
      choose_file: "виберіть файл",
      file_name: "Ім'я файла",
      size: "Розмір",
      key: "Ключ",
      upload: "Завантажити",
      price: "Ціна",
      to_pay: "До оплати",
      pay: "Оплатити",
      download: "Завантажити",
      payed: "Оплачено",
      new: "Новий",
      to_file: "До файлу",
      payed_by_subscription: "Сплачено за передплатою",
    },
    add_file: {
      title: 'Додати новий файл ECU',
      upload_file: 'завантаження файлу',
      before_upload_warning_title: 'Ви повинні використовувати лише оригінальні ECU файли!',
      before_upload_warning_text:
        'Будь ласка, переконайтеся, що файл НЕ запакований архіватором і є файлом ECU. У випадку, якщо у Вас немає оригінального файлу, зв\'яжіться з нами і ми Вам допоможемо',
      before_upload_warning_text_ed:
        `Ви повинні використовувати оригінальні ECU файли або файли з тюнінгом потужності!
        Будь ласка, переконайтеся, що файл НЕ запакований архіватором і є файлом ECU.
        У випадку, якщо у Вас немає оригінального файлу, зв'яжіться з нами і ми Вам допоможемо.`,
      file_name: 'Ім\'я файла',
      file_size: 'Розмір файла',
      file_key: 'Ідентифікатор',
      ecu: 'ECU',
      soft: 'Прошивка',
      choose_ecu: 'Виберіть ECU',
      no_ecu: 'ECU не знайдено',
      no_actions: 'Немає доступних дій',
      dtc_not_allowed: 'DTC не підтримується',
      available_actions: 'Доступні дії',
      remove_dtc: 'Видалити DTC',
      base_price: 'Базова ціна',
      discount: 'Знижка',
      to_pay: 'До оплати',
      bonus: 'Бонус',
      price: 'Вартість',
      pay_title: 'Процес оплати',
      no_ecus_text1: 'Ми визначили ваш блок ECU автоматично, але не змогли знайти його у нас в базі автоматично, будь ласка, оберіть його зі списку нижче.',
      no_ecus_text2: 'Ми не змогли визначити ваш блок ECU автоматично, будь ласка, оберіть його зі списку нижче.',
      no_ecus_text3: 'Виберіть потрібний ECU зі списку нижче',
      pay_info:
        'Для обробки вибраних дій потрібне залучення нашого фахівця. Ми зможемо це зробити після оплати. Увага! Після успішної обробки файл буде доступний для скачування. Якщо ми не зможемо виконати потрібні дії, ми повернемо вам ваші кошти.',
      pay_info2:
        'Ми обробили всі необхідні дії в автоматичному режимі та готові віддати вам оброблений файл одразу після оплати. Файл буде доступний до скачування автоматично, як тільки ми отримаємо підтвердження оплати.',
      pay_info3:
        'Під час обробки файлу виникла помилка. Будь ласка, зв\'яжіться з нами через форму зворотнього зв\'язку, що знаходиться на сторінці праворуч. Обов\'язково вкажіть Ідентифікатор файлу в системі.',
      processed_dtcs: 'Оброблені DTC',
      processed_actions: 'Автоматично оброблені дії',
      manual_actions: 'Дії, що вимагають обробки вручну',
      cogwheel_tooltip: 'Ця дія буде оброблена автоматично',
      remove_dtc_tooltip: 'Ця дія буде оброблена автоматично',
      return_ecu_suggestions: 'Повернути ECU варіанти',
      errorDtc: "Будь ласка, введіть 4х значний OBD-код (PXXXX) відомих Вам помилок без літери P, без пробілів, через кому",
      ticketDTCHint: "Будь ласка, введіть 4х значний OBD код (PXXXX) відомих Вам помилок без літери P, без пробілів, через кому. Якщо Ви знаєте код виробника або код OBD починається з символу U, введіть текст у повідомлення"
    },
    processing_tasks: {
      title: 'Файли для обробки',
      tasks: {
        title: {
          key: 'Ключ',
          status: 'Статус',
          request_date: 'Дата',
          comment: 'Коментар',
          note: 'Позначки',
          ecu: 'ECU',
          name: 'Ім\'я файла',
          download: 'завантажити',
          history: 'Історія',
          fileName: "Ім'я файла",
          addDate: "Дата додавання",
          action: "Дія",
          tickets: "Тикети"
        },
      },
      action: {
        pay: 'Сплатити',
        download: 'завантажити',
      },
      status: {
        new: 'новий',
        payed: 'Сплачено',
        processed: 'В обробці',
        allow_download: 'Готовий',
        can_not_open: 'Не відкривається',
        unsupported_format: 'Непідтримуваний формат',
        wrong_ecu_or_engine: 'Невірний ecu/engine',
        actions_not_supported: 'Дії не підтримуються',
        closed: 'Закритий'
      },
    },
    public_page: {
      contacts: {
        title: 'Контакти та час роботи',
        content: '+38 (095) 07 33 495 <a href="https://t.me/motorcar_tuning_shop" about="_blank"><img src="/assets/Telegram_logo.svg" height="30px" width="30px" style="margin-left:10px;margin-top:3px;position:absolute" alt="Telegram" /></a><br/>+38 (097) 07 33 495<br/><br/>contacts@motorcar-tuning.shop<br/><br/><a target="_blank" href="https://motorcar-tuning.com.ua">https://motorcar-tuning.com.ua</a><br/><br/>',
        text1: '',
        text2: 'Час роботи файл сервісу:',
        text3:
          '<pre style="font-family: Roboto, Helvetica, Arial, sans-serif;">ПН-ПТ с   9.00 до 18.00 (среднее время обработки файла - 30 мин) <br/>ПН-ПТ с 18.00 до 23.00 (среднее время обработки файла - 1.5 ч)<br/>СБ       c 10.00 до 23.00 (среднее время обработки файла до 2 ч)<br/>ВС       выходной<br/><br/>часовой пояс (киевское время) — UTC+3</pre>',
        text4: '',
        text5: '',
      },
      home: {
        about_us: 'Про нас',
        text1:
          'Представляємо файл сервіс перевірених рішень щодо відключення систем екології та інших модифікацій, що вносяться у прошивку електронних блоків управління:',
        text2: 'легкових автомобілів',
        text3: 'вантажних автомобілів',
        text4: 'спецтехніки',
        text5: 'автобусів',
        text6: 'мотоциклів, квадроциклів, скутерів, снігоходів',
        text7: `На нашому порталі Ви зможете швидко та зручно купити якісний файл прошивки електронного блоку управління (ЕБУ) з потрібними Вам відключеннями або модифікаціями.
        Усі рішення протестовані, коректні та безпечні.
        Файли обробляються в реальному часі нашими калібрувальниками з використанням спеціального ліцензійного програмного забезпечення з додатковим ручним контролем.`,
        text8: 'Переваги:',
        text9: 'Конкурентна ціна',
        text10: 'Гнучке та прозоре ціноутворення',
        text11: 'Накопичувальні знижки, що не згоряють, постійним клієнтам',
        text12: 'Пріоритет за часом обробки файлу власникам slave пристроїв',
        text13: 'Швидкий час обробки файлу',
        text14: 'Гарантія коректного результату',
        text15: 'Гарантія 100% повернення коштів у разі неробочого рішення',
        text16: 'Оплата лише поточного файлу без необхідності поповнювати рахунок особистого облікового запису',
        text17: 'Техпідтримка в чаті протягом усього робочого часу',
        text18: 'Можливість отримати послугу під ключ у нас чи наших представників',
        text19: 'Цены',
        text20: 'Узнать конкретную цену можно после регистрации. Для постоянных клиентов действует система скидок:',
        text21: 'Доступны следующие уровни:',
        text22: 'базовый',
        text23: 'мастер 	  -5%		при достижении оборота 500$',
        text24: 'партнер 	 - 10% 		при достижении оборота 1500$',
        text25: 'VIP		 - 15%		при достижении оборота 2500$',
        text26: 'Скидки несгораемые и действуют все время существования аккаунта.',
        options: 'Доступні опції та модифікації',
        option1: 'Вимкнення системи AdBlue/SCR',
        option2: 'Активация хлопков выпускной системы Burbles',
        option3: 'Уменьшение шума холодного двигателя (Cold Start Noise Reduction)',
        option4: 'Відключення сажового фільтру DPF',
        option5: 'Видалення кодів несправностей DTC',
        option6: 'Вимкнення системи EGR',
        option7: 'Вимкнення системи вентиляції паливного бака EVAP',
        option8: 'Вимкнення вихлопної заслінки Exhaust Flap',
        option9: 'Вимкнення впускних вихрових заслінок Flaps / Swirl',
        option10: 'Вимкнення системи GPF/OPF',
        option11: 'Оптимизация запуска горячего двигателя (HOT Start Fix)',
        option12: 'Отключение IMMO',
        option13: 'Деактивация функции Kickdown',
        option14: 'Вимкнення моніторингу каталізатора (Lambda/O2)',
        option15: 'Вимкнення датчика витрати повітря MAF',
        option16: 'Активація прострілів випускної системи (Popcorn Activation)',
        option17: 'Установка кода готовности (Readiness Calibration)',
        option18: 'Вимкнення системи SAP',
        option19: 'Зняття ліміту максимальної швидкості (Speed Limiter)',
        option20: 'Калібрування спортивних дисплеїв (Sport Displays Calibration)',
        option21: 'Вимкнення системи StartStop',
        option22: 'Вимкнення моніторингу крутного моменту (Torque Monitoring Disable)',
        option23: 'Вимкнення дроселю TVA',
      },
      discount_and_news: {
        text1: `У розділі "Ціни" ви можете бачити базову вартість наших послуг.<br/>
        Ці ціни не є остаточними.<br/>
        Ми завжди пропонуємо своїм постійним клієнтам найоптимальніші умови. <br/>
        Зв'яжіться з нами, розкажіть про себе та ми розглянемо для Вас індивідуальну знижку.<br/>`,
        text2: `motorcar tuning офіційний партнер компанії magicmotorsport <br/>
        У нас можна придбати програматори Flex та будь-яку продукцію magic. <br/>
        Зв'яжіться з нами для отримання персональної пропозиції <br/>`
      },
      email_confirmation: {
        title: 'Підтвердження Email',
        confirmed: 'Дякуємо за підтвердження вашого електронної пошти. Тепер ви можете отримати повідомлення від нас! Приємної роботи!',
        not_found:
          'Ваше посилання не працює. Можливо, ви вже підтвердили свій email. Якщо ви цього не робили – зверніться на підтримку. У жодному разі це не впливає на роботу сервісу.',
      },
    },
    component: {
      select_placeholder: 'Please choose value',
      checkbox: {
        agree: 'Я згоден з',
        and: 'і',
        privacy_policy: 'Умовами використання',
        user_agreement: 'Угодою користувача',
      },
    },
    select_label: {
      vehicle: '* Транспортний засіб',
      brand: '* Бренд',
      model: '* Модель',
      engine: '* Двигун',
      ecus: '* Ecus',
      action: '* Дії',
    },
    input: {
      label: {
        email: 'Email: ',
        emailOrUsername: 'Email/Username: ',
        password: 'Пароль: ',
        new_password: 'Новий пароль: ',
        new_password_duplicate: 'Повторіть пароль: ',
        username: 'Ім\'я користувача: ',
        company: 'Компанія: ',
        mobile_phone: 'Мобільний: (Міжнародний формат починаючи з +)',
        first_name: 'Ім\'я: ',
        last_name: 'Прізвище: ',
        dtc: 'DTC: ',
        comment: 'Коментар: ',
      },
      placeholder: {
        email: 'Введіть Ваш email',
        password: 'Введіть Ваш пароль',
        new_password: 'Введіть новий пароль',
        new_password_duplicate: 'Повторіть новий пароль',
        username: 'Введіть Ваше ім\'я користувача',
        company: 'Введіть назву Вашої компанії',
        mobile_phone: 'Введіть Ваш мобільний телефон',
        first_name: 'Введіть ваше Ім\'я',
        last_name: 'Введіть ваше Прізвище',
        dtc: 'Введіть DTC',
        comment: 'Введіть коментар',
      },
    },
    dialog: {
      title: {
        login: 'Вхід',
        sign_up: 'Реєстрація',
        server_error: 'Помилка сервера',
        change_password: 'Зміна пароля',
        forgot_password: 'Забули пароль',
        request_new_password_success: 'Запит на зміну пароля',
        change_password_success: 'Зміна пароля',
        payment_approve: 'Підтвердження оплати',
      },
      error_map: {
        file_not_valid: "Невалідний файл",
        client_has_no_slave: "Клієнт не містить менеджера",
        file_not_uploaded: "Файл не завантажений",
      },
      content: {
        server_error: 'Виникла помилка. Будь ласка, спробуйте ще раз або зв\'яжіться з нами',
        request_new_password_success: 'Ми надіслали вам листа на email для зміни пароля',
        change_password_success: 'Пароль успішно змінено',
        payment_approve: {
          file: 'Файл: ',
          key: 'Ключ',
          name: 'Ім\'я',
          date: 'Дата',
          ecu: 'ECU',
          actions: 'Дії: ',
          action: 'Дія: ',
          dtc: 'DTC: ',
          price: 'До оплати: ',
          base: 'Базова',
          bonus: 'Бонус',
          discount: 'Знижка',
          to_pay: 'До оплати',
          payment_condition: 'Здійснивши оплату ви погоджуєтесь з ',
          file_process_requested: 'Ми отримали ваш запит. Ми вже працюємо над файлом. Результат буде приблизно за 30 хвилин',
          file_processed_automatically: 'Ваш файл оброблений автоматично та готовий до завантаження',
          link: {
            privacy_policy: 'Умовами використання',
            user_agreement: 'Угодою користувача',
            payment_and_delivery: 'Умовами оплати та доставки',
            file_list: 'Перейти до файлів'
          },
        },
      },
    },
  },
};
