
export const PUBLIC = {
  CONTACTS: '/contacts',
  HOME: '/',
  HOME_DUPLICATE: '/:locale/:home', // to keep not relevant path from cleaning (need for redirection when routes changes from public to private)
  DISCOUNT_AND_NEWS: '/discount-and-news',
  SUPPORTED_VEHICLES: '/supported-vehicles',
  EMAIL_CONFIRMATION: '/email-confirmation/:status',
  CHANGE_PASSWORD: '/change-password/:token',
  PAYMENT_AND_DELIVERY: '/payment-and-delivery',
  PRICES: '/prices',
};

export const PRIVATE = {
  BILLING: '/billing',
  PROCESSING_TASKS: '/processing-tasks',
  SETTINGS: '/settings',
  USER_PROFILE: '/user-profile',
  ADD_FILE: '/add-file',
  ENCRYPT_DECRYPT_FILES_UPLOAD: '/encrypt-decrypt-files/upload',
  ENCRYPT_DECRYPT_FILES_LIST: '/encrypt-decrypt-files/file-list',
  ENCRYPT_DECRYPT_FILES_VIEW_FILE: '/encrypt-decrypt-files/view-file/:key',
  ENCRYPT_DECRYPT_FILES_VIEW_FILE_RAW: '/encrypt-decrypt-files/view-file/',
  TICKET: "/ticket/:key",
};

export const COMMON = {
  PRIVACY_POLICY: '/privacy-policy',
  USER_AGREEMENT: '/user-agreement',
};

export const ROUTE_TITLE = ((prefix: string) => ({
  CONTACTS: `${prefix} - contacts`,
  HOME: `${prefix}`,
  DISCOUNT_AND_NEWS: `${prefix} - discount and news`,
  SUPPORTED_VEHICLES: `${prefix} - supported vehicles`,
  EMAIL_CONFIRMATION: `${prefix} - email confirmation`,
  CHANGE_PASSWORD: `${prefix} - change password`,
  PRICES: `${prefix} - prices`,
  PAYMENT_AND_DELIVERY: `${prefix} - payment and delivery`,
  //
  BILLING: `${prefix} - billing`,
  PROCESSING_TASKS: `${prefix} - processing tasks`,
  TICKET: `${prefix} - ticket`,
  SETTINGS: `${prefix} - settings`,
  USER_PROFILE: `${prefix} - user profile`,
  ADD_FILE: `${prefix} - add file`,
  //
  PRIVACY_POLICY: `${prefix} - privacy policy`,
  USER_AGREEMENT: `${prefix} - user agreement`,
  UPLOAD_FILE: `${prefix} - upload file`,
  FILE_LIST: `${prefix} - file list`,
  VIEW_FILE: `${prefix} - view file`,
}))('motorcar tuning');
