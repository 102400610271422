// outsource dependencies
import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {getLocalizedPath} from 'utils/routing.utils';
// UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
// constants
import {APP_COLOR} from 'constants/style';
import {COMMON as COMMON_ROUTE, PUBLIC as PUBLIC_ROUTE} from 'constants/routes';
import {connect} from "react-redux";
import {
    loadInitialDataAction,
    selector as edPaymentSelector,
} from 'private-layout/encrypt-decrypt/ed-payment/reducer'
import Preloader from "../../../components/Preloader";

const useStyles = makeStyles({
    paperFullWidth: {
        width: 700,
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 8px 8px 24px',
        backgroundColor: APP_COLOR.GREY,
        color: APP_COLOR.WHITE,
    },
    close: {
        padding: 6,
        color: APP_COLOR.WHITE,
        cursor: 'pointer',
    },
    titleContent: {
        lineHeight: 2,
    },
    content: {
        display: 'flex',
        padding: 12,
        flexDirection: 'column',
    },
    infoContainer: {
        lineHeight: '25px',
        paddingLeft: 8,
    },
    infoTextLine: {
        whiteSpace: 'nowrap',
    },
    infoContent: {
        display: 'inline-block',
        width: 150,
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 16,
        alignItems: 'center',
    },
    paymentIconsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    links: {
        fontSize: 12,
    },
    cardInfoTextLineFileName: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: '600px'
    },
    continueAction: {
        backgroundColor: APP_COLOR.LIGHT_GREY,
        color: APP_COLOR.WHITE,
        '&:hover': {
            color: APP_COLOR.BLACK,
        },
        transition: '0.6s',
    },
    preloader: {
        height: "359px"
    }
});

interface IProps {
    open: boolean;
    handleClose: () => void;
    fileKey: string;

    //redux state
    orderData?: any;
    signature?: string | null;
    data?: string | null;
    expectAnswer?: boolean;

    //redux actions
    loadInitialData?: (key: string) => void;
}

function EdPayment(props: IProps) {
    const {open, handleClose, fileKey, orderData, signature, data, loadInitialData, expectAnswer} = props;
    const classes = useStyles();
    const {t} = useTranslation();

    useEffect(() => {
        loadInitialData && loadInitialData(fileKey);
    }, [fileKey, loadInitialData])

    const handleOnClickPay = () => {
        if (!data || !signature) {
            return; //error this
        }

        const liqPayForm = document.createElement('form');
        liqPayForm.setAttribute('method', 'post');
        liqPayForm.setAttribute('action', 'https://www.liqpay.ua/api/3/checkout');
        //liqPayForm.setAttribute('target', '_blank');

        const d = document.createElement('input'); //input element, text
        d.setAttribute('type', 'text');
        d.setAttribute('name', 'data');
        d.setAttribute('value', data);

        const s = document.createElement('input'); //input element, text
        s.setAttribute('type', 'text');
        s.setAttribute('name', 'signature');
        s.setAttribute('value', signature);

        liqPayForm.appendChild(d);
        liqPayForm.appendChild(s);
        document.getElementsByTagName('body')[0].appendChild(liqPayForm);
        liqPayForm.submit();
        liqPayForm.remove();
    }

    return (
        <Dialog open={open} classes={{paperFullWidth: classes.paperFullWidth}} fullWidth
                maxWidth='md'>
            <DialogTitle disableTypography classes={{root: classes.title}}>
                <Typography variant='h6' className={classes.titleContent}>
                    {t('dialog.title.payment_approve')}
                </Typography>
                <CloseIcon onClick={handleClose} className={classes.close}/>
            </DialogTitle>
            {orderData && !expectAnswer ?
                <DialogContent>
                    <div className={classes.content}>
                        <Typography className={classes.titleContent}>
                            <strong>{t('dialog.content.payment_approve.file')}</strong>
                        </Typography>
                        <div className={classes.infoContainer}>
                            <div className={classes.infoTextLine}>
                                <span
                                    className={classes.infoContent}>{t('dialog.content.payment_approve.key')}</span> {orderData.fileData.key}
                            </div>
                            <div className={classes.cardInfoTextLineFileName}>
                                <span
                                    className={classes.infoContent}>{t('dialog.content.payment_approve.name')}</span> {orderData.fileData.name}
                            </div>
                            <div className={classes.infoTextLine}>
                                <span
                                    className={classes.infoContent}>{t('dialog.content.payment_approve.date')}</span> {orderData.fileData.date}
                            </div>
                        </div>
                        <Typography className={classes.titleContent}>
                            <strong>{t('dialog.content.payment_approve.action')}</strong>
                        </Typography>

                        <div className={classes.infoContainer}>
                            <div className={classes.infoTextLine}>
                                <span className={classes.infoContent}>{orderData.fileData.action}</span>
                            </div>
                        </div>

                        <Typography className={classes.titleContent}>
                            <strong>{t('dialog.content.payment_approve.price')}</strong>
                        </Typography>

                        <div className={classes.infoContainer}>
                        <span className={classes.infoTextLine}>
                            <span
                                className={classes.infoContent}>{t('dialog.content.payment_approve.to_pay')}</span>
                            {orderData.price.toPay} грн. (UAH)
                            </span>
                        </div>
                        <div className={classes.actions}>
                            <div className={classes.paymentIconsContainer}>
                                <img src='/assets/vbm_blugrad01.png' height='30px' alt='Visa'/>
                                <img src='/assets/mc_vrt_pos.svg' height='50px' alt='MasterCard'/>
                            </div>
                            <div>
                                <Button onClick={handleOnClickPay} className={classes.continueAction}>
                                    {t('button_title.pay')}
                                </Button>
                            </div>
                        </div>
                        <Typography className={classes.links}>
                            {t('dialog.content.payment_approve.payment_condition')}{' '}
                            <Link to={getLocalizedPath(COMMON_ROUTE.USER_AGREEMENT)}>
                                <span>{t('dialog.content.payment_approve.link.user_agreement')}</span>
                            </Link>
                            ,{' '}
                            <Link to={getLocalizedPath(COMMON_ROUTE.PRIVACY_POLICY)}>
                                <span>{t('dialog.content.payment_approve.link.privacy_policy')}</span>
                            </Link>
                            ,{' '}
                            <Link to={getLocalizedPath(PUBLIC_ROUTE.PAYMENT_AND_DELIVERY)}>
                                <span>{t('dialog.content.payment_approve.link.payment_and_delivery')}</span>
                            </Link>
                        </Typography>
                    </div>
                </DialogContent>
                : <div className={classes.preloader}> <Preloader pageCentered={true}/> </div>}
        </Dialog>
    );
}

export default connect(
    (state) => edPaymentSelector(state),
    (dispatch) => ({
        loadInitialData: (key: string) => dispatch(loadInitialDataAction(key)),
    })
)(EdPayment);
