// outsource dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';
// UI
import Typography from '@material-ui/core/Typography';
// local dependencies
// UI
import Layout from 'components/layout/Common';
interface IProps {
  pageTitle: string;
  language: string;
}

export default function PaymentAndDelivery(props: IProps) {
  const { pageTitle, language } = props;
  const { i18n } = useTranslation();

  return (
    <Layout pageTitle={pageTitle} language={language}>
      {i18n.language === 'ua' && (
        <>
          <Typography variant='h5'>Оплата</Typography>
          <p>Оплата приймається кредитними картками Visa та MasterCard в автоматичному режимі.</p>
          <p>
            Здійснити оплату можна на сторінці додавання файлу після заповнення всієї необхідної інформації, а також у розділі "Мої файли" для файлів, які мають статус "Новий".
          </p>
          <p>Обробка файлів можлива лише після оплати.</p>
          <p>Також можлива оплата замовлення шляхом переказу коштів на банківський рахунок.</p>

          <Typography variant='h5'>Доставка</Typography>
          <p>Отримати замовлення можна шляхом завантаження обробленого файлу з Вашого особистого кабінету у розділі "Ваші файли".</p>
          <p>Для файлів зі статусом "готовий" доступне посилання для скачування. Завантажити файл можна скільки завгодно разів.</p>

          <Typography variant='h5'>Повернення коштів</Typography>
          <p>У разі незадовільного результату гарантуємо повернення 100% сплачених Вами коштів згідно із законами України.</p>
          <p>
            Протягом 30 днів у вас є право зв'язатися з нами та повідомити про можливу проблему. Ми гарантуємо виправлення замовленого Вами файлу у разі таких проблем, якщо з'ясується, що ці проблеми викликані нашим файлом. Для проведення віддаленої діагностики та виявлення факту проблеми від клієнта можливо знадобляться фото та відеоматеріали.
          </p>
          <p>
            У разі неможливості вирішити спірне питання, Ви можете подати запит на повернення всієї суми. Запит буде розглянуто протягом 14 днів із моменту його отримання.{' '}
          </p>
          <p>
            У разі надання не повної, або свідомо неправдивої інформації, а також у разі надання матеріалів у спотвореній формі ми маємо право відмовити Вам у відшкодуванні.
          </p>
          <p>
            Ми дуже зацікавлені в тому, щоб кожен клієнт був задоволений результатом і завжди робимо все можливе для того, щоб кожен клієнт став постійним.
          </p>
        </>
      )}
      {i18n.language === 'en' && (
        <>
          <Typography variant='h5'>Payment</Typography>
          <p>We accept Visa and MasterCard payment cards</p>
          <p>
            You can pay on the file adding page after filling in all the necessary information, as well as in the "My files" section for files that have the
            "New" status.
          </p>
          <p>You can download the file immediately after payment (if all selected actions are processed automatically)</p>
          <p>It is also possible to pay for the order by transferring funds to a bank card. Please contact to us to choose a convenient way</p>

          <Typography variant='h5'>Delivery</Typography>
          <p>You can receive an order by downloading the processed file from your personal account in the "Your files" section.</p>
          <p>A download link is available for files with the "ready" status. You can download a file as many times as you like.</p>
          <p>At this moment, we do not deliver files in any other way.</p>

          <Typography variant='h5'>Refunds</Typography>
          <p>In case of an unsatisfactory result, we guarantee a refund of 100% of the funds you paid within 30 days from the date of file processing</p>
          <p>
            Within 30 days, you have the right to contact us and report a possible problem. We guarantee the correction of the file you ordered in case of such
            problems, if it turns out that the reported problems are caused by our file. To carry out remote diagnostics and identify the fact of the problem,
            the client may need photo and video materials.
          </p>
          <p>
            If it is impossible to resolve the disputed issue, you can submit a request for a refund of the entire amount. The request will be considered within
            14 days from the date of its receipt.{' '}
          </p>
          <p>
            In case of providing incomplete or deliberately false information, as well as in case of providing materials in a distorted form, we have the right
            to refuse you compensation.
          </p>
          <br />
          <p>
            We are extremely interested in ensuring that each of our clients is satisfied with the result and always do our best to ensure that each client
            becomes a permanent one.
          </p>
        </>
      )}
    </Layout>
  );
}
