import React, {useEffect, useState} from 'react';
import Layout from 'components/layout/Common';
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import {Table, TableBody, TableContainer} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import AdditionalFileModal from "./addition-file-modal";
import {useParams} from "react-router";
import {connect} from "react-redux";
import {
    loadInitialDataAction,
    selector as edFileViewSelector,
    loadEdFileAction, removeEdFileInfoAction
} from 'private-layout/encrypt-decrypt/file-view/reducer'
import EdPayment from "../ed-payment";
import Preloader from "../../../components/Preloader";
import currentConfig from "../../../utils/config.utils";

const useStyles = makeStyles(() => ({
    tableContainer: {
        marginTop: 16,
    },
    table: {
        minWidth: 650,
    },
    actionLink: {
        cursor: 'pointer',
    },
    cardInfoTextLine: {
        whiteSpace: 'nowrap',
    },
    cardInfoTextLineFileName: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: '800px'
    },
    cardInfoStrong: {
        display: 'inline-block',
        width: 150,
    },
    infoContainer: {
        display: "grid",
        marginTop: "10px",
        marginLeft: "40px",
        marginBottom: "20px",
        gridRowGap: "10px",
    },
    addFileBtn: {
        marginLeft: "30px",
        marginBottom: "5px",
        width: "200px",
    },
    typography: {
        display: "inline",
    },
    fileName: {
        maxWidth: "400px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    payLabelMain: {
        cursor: "pointer",
        color: "blue",
        textDecoration: "underline",
        paddingLeft: "4px",
    },
    payLabel: {
        cursor: "pointer",
        color: "blue",
        textDecoration: "underline",
    },
    downloadUrl: {
        display: "inline-block",
        paddingLeft: "4px",
    }

}));


interface IProps {
    pageTitle: string;
    language: string;

    //redux state
    fileInfo?: any
    expectAnswer?: boolean;
    edFileInfo?: any;
    uploadedExpectAnswer?: boolean;

    //redux actions
    loadInitialData?: (key:string) => void;
    loadEdFile?: (formData: any) => void;
    removeEdFileInfo?: () => void;
}

function FileView(props: IProps) {
    const {key} = useParams();

    const {pageTitle, language, loadInitialData, fileInfo, expectAnswer, edFileInfo,
        loadEdFile, uploadedExpectAnswer, removeEdFileInfo} = props;

    const {t} = useTranslation();
    const classes = useStyles();

    const [payFileKey, setPayFileKey] = useState<string | null>(null);
    const [addFileModalOpen, setAddFileModalOpen] = useState<boolean>(false);

    const handleOpenModal = () => {
        if (fileInfo.file.status === "1") {
            return;
        }
        setAddFileModalOpen(true);
    }

    const handleOnCloseModal = () => {
        setAddFileModalOpen(false);
        removeEdFileInfo && removeEdFileInfo();
        loadInitialData && loadInitialData(key);
    }

    useEffect(() => {
        loadInitialData && loadInitialData(key);
    }, [key, loadInitialData])

    const handleOnClickPay = (fileKey: string) => {
        setPayFileKey(fileKey);
    }
    const handleOnClosePay = () => {
        setPayFileKey(null);
    }


    return (
        <Layout pageTitle={pageTitle} language={language}>
            <Typography variant='h5'>
                <span>{t('view_file.title')}</span>
            </Typography>

            {fileInfo && !expectAnswer ?
                <div>
                    <div className={classes.infoContainer}>
            <span className={classes.cardInfoTextLine}>
                    <strong className={classes.cardInfoStrong}>{t('view_file.key')}</strong> {fileInfo.file.key}
            </span>
                        <span className={classes.cardInfoTextLineFileName}>
                    <strong className={classes.cardInfoStrong}>{t('view_file.name')}</strong> {fileInfo.file.name}
            </span>
                        <span className={classes.cardInfoTextLine}>
                    <strong className={classes.cardInfoStrong}>{t('view_file.size')}</strong>
                            {fileInfo.file.size ? (" " + fileInfo.file.size  + " " + t('view_file.bytes')) : ""}
            </span>
                        <span className={classes.cardInfoTextLine}>
                    <strong className={classes.cardInfoStrong}>{t('view_file.date')}</strong> {new Date((fileInfo.file.request_date + ' UTC').replace(/-/g, "/")).toLocaleString()}
            </span>
                        <span className={classes.cardInfoTextLine}>
                    <strong className={classes.cardInfoStrong}>{t('view_file.status')}</strong> {fileInfo.file.status === "1" ? t("upload_file.new") : t("upload_file.payed")}
            </span>
                        <span className={classes.cardInfoTextLine}>
                    <strong className={classes.cardInfoStrong}>{t('view_file.action')}</strong>
                            {fileInfo.file.status === "1" ?
                                <label onClick={() => handleOnClickPay(fileInfo.file.key)} className={classes.payLabelMain}>{t("upload_file.pay")}</label>:
                                <a className={classes.downloadUrl} href={currentConfig().downloadUrl + 'download/ed/processed/' + fileInfo.file.key }>{t("upload_file.download")}</a> }
            </span>
                    </div>

                    <Typography className={classes.typography} variant='h5'>
                        <span>{t('view_file.additional_files')}</span>
                    </Typography>
                    {fileInfo.file.status !== "1" ?
                    <Button className={classes.addFileBtn} variant='contained' color='primary'
                            onClick={handleOpenModal}>
                        {t('view_file.add_file')}
                    </Button> : null }


                    <TableContainer component={Paper} className={classes.tableContainer}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>{t('view_file.key')}</TableCell>
                                    <TableCell align='center'>{t('view_file.status')}</TableCell>
                                    <TableCell align='center'>{t('view_file.file_name')}</TableCell>
                                    <TableCell align='center'>{t('view_file.size') + " " + t('view_file.bytes2')}</TableCell>
                                    <TableCell align='center'>{t('view_file.date')}</TableCell>
                                    <TableCell align='center'>{t('view_file.action')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fileInfo.additionalFiles.map((additionalFile:any) =>
                                    <TableRow key={additionalFile.key}>
                                        <TableCell align='center'>{additionalFile.key}</TableCell>
                                        <TableCell align='center'>{additionalFile.status === "1" ? t("upload_file.new") : t("upload_file.payed")}</TableCell>
                                        <TableCell className={classes.fileName} align='center'>{additionalFile.name}</TableCell>
                                        <TableCell align='center'>{additionalFile.size}</TableCell>
                                        <TableCell align='center'>{new Date((additionalFile.request_date + ' UTC').replace(/-/g, "/")).toLocaleString()}</TableCell>
                                        <TableCell align='center'>
                                            {additionalFile.status === "1" ?
                                                <label onClick={() => handleOnClickPay(additionalFile.key)} className={classes.payLabel}>{t("upload_file.pay")}</label>:
                                                <a href={currentConfig().downloadUrl + 'download/ed/processed/' + additionalFile.key }>{t("upload_file.download")}</a> }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div> : <Preloader pageCentered={true}/>}

            {payFileKey !== null ?
                <EdPayment fileKey={payFileKey} open={true} handleClose={handleOnClosePay}/> : null
            }

            {fileInfo !== null ?
            <AdditionalFileModal uploadedExpectAnswer={uploadedExpectAnswer} parentFileKey={fileInfo.file.key}
                                 edFileInfo={edFileInfo} loadEdFile={loadEdFile}
                                 open={addFileModalOpen} handleOnClose={handleOnCloseModal}/>
            : null}
        </Layout>
    )
}

export default connect(
    (state) => edFileViewSelector(state),
    (dispatch) => ({
        loadInitialData : (key: string) => dispatch(loadInitialDataAction(key)),
        loadEdFile : (fileForm: any) => dispatch(loadEdFileAction(fileForm)),
        removeEdFileInfo: () => dispatch(removeEdFileInfoAction())
    })
)(FileView);