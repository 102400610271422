// outsource dependencies
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
// UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import HelpIcon from '@material-ui/icons/Help';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import LinkIcon from '@material-ui/icons/Link';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
// local dependencies
import {
  selector as userProfileSelector,
  IUser,
  loadUserProfileAction,
  clearStateAction,
  updateNewPasswordSuccessAction,
} from 'private-layout/user-profile/reducer';
// UI
import Layout from 'components/layout/Common';
import Preloader from 'components/Preloader';
import BaseDialog from 'components/dialog/Base';
import ChangePasswordDialog from 'private-layout/user-profile/components/ChangePassword';
import {historyPush} from "../../store";

const useStyles = makeStyles({
  tableClass: {
    border: 0,
    width: 500,
    marginTop: 20
  },
  changePassword: {
    marginTop: 16
  },
  userProfileContainer: {
    display: "flex"
  },
  uploadFile: {
    marginTop: 16,
  },
  checkFiles: {
    marginTop: 16,
    marginLeft: 20,
  },
  edContainer: {
    display: "grid",
    marginTop: "20px",
    gridTemplateColumns: "180px auto",
    rowGap: "20px"
  },
  edLabel: {
    fontWeight: "bold",
  }
});
interface IProps {
  pageTitle: string;
  language: string;
  //
  user?: IUser | null;
  loadUserProfile?: () => void;
  expectAnswer?: boolean;
  clearState?: () => void;
  newPasswordSuccess?: boolean;
  updateNewPasswordSuccess?: (newPasswordSuccess: boolean) => void;
}

function UserProfile(props: IProps) {
  const {
    pageTitle,
    language,
    //
    user,
    loadUserProfile,
    expectAnswer,
    clearState,
    newPasswordSuccess,
    updateNewPasswordSuccess,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [changePasswordOpen, setChangePasswordOpen] = useState<boolean>(false);

  useEffect(() => {
    loadUserProfile && loadUserProfile();
    return () => {
      clearState && clearState();
    };
  }, [loadUserProfile, clearState]);
  useEffect(() => {
    if (newPasswordSuccess) {
      handleChangePasswordClose();
    }
  }, [newPasswordSuccess]);

  // let email_confirmation_title = t(user?.email_confirmed ? 'user_profile.email_confirmed' : 'user_profile.email_not_confirmed')
  // console.log(email_confirmation_title);
  let email_confirmed: boolean = user?.email_confirmed === true;

  const handleChangePasswordOpen = () => {
    setChangePasswordOpen(true);
  };
  const handleChangePasswordClose = () => {
    setChangePasswordOpen(false);
  };
  const handlePasswordSuccessClose = () => {
    updateNewPasswordSuccess && updateNewPasswordSuccess(false);
  };

  const handleUploadFile = () => {
      historyPush("/" + language + "/encrypt-decrypt-files/upload");
  }

  const handleFileList = () => {
    historyPush("/" + language + "/encrypt-decrypt-files/file-list");
  }

  return (
    <Layout pageTitle={pageTitle} language={language}>
      <Typography variant='h5'>{t('user_profile.title')}</Typography>
      {expectAnswer && <Preloader pageCentered />}
      {!expectAnswer && (
        <>
          <div className={classes.userProfileContainer}>
            <div>
          <Table size='small' className={classes.tableClass}>
            <TableBody>
              <TableRow>
                <TableCell component='th' scope='row' style={{ fontWeight: 'bold' }}>
                  {t('user_profile.username')}
                </TableCell>
                <TableCell align='right'>{user?.username}</TableCell>
                <TableCell align='center' style={{ border: 0 }}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell component='th' scope='row' style={{ fontWeight: 'bold' }}>
                  {t('user_profile.first_name')}
                </TableCell>
                <TableCell align='right'>{user?.first_name}</TableCell>
                <TableCell align='center' style={{ border: 0 }}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell component='th' scope='row' style={{ fontWeight: 'bold' }}>
                  {t('user_profile.last_name')}
                </TableCell>
                <TableCell align='right'>{user?.last_name}</TableCell>
                <TableCell align='center' style={{ border: 0 }}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell component='th' scope='row' style={{ fontWeight: 'bold' }}>
                  {t('user_profile.email')}
                </TableCell>
                <TableCell align='right'>{user?.email}</TableCell>
                <TableCell align='center' style={{ border: 0 }}>
                  {email_confirmed && (
                    <Tooltip title={t('user_profile.email_confirmed') || ' '} arrow placement='right'>
                      <CheckCircleIcon style={{ width: 16, height: 16, color: '#4caf50' }} />
                    </Tooltip>
                  )}
                  {!email_confirmed && (
                    <Tooltip title={t('user_profile.email_not_confirmed') || ' '} arrow placement='right'>
                      <CancelIcon style={{ width: 16, height: 16, color: '#f44336' }} />
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component='th' scope='row' style={{ fontWeight: 'bold' }}>
                  {t('user_profile.company')}
                </TableCell>
                <TableCell align='right'>{user?.company}</TableCell>
                <TableCell align='center' style={{ border: 0 }}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell component='th' scope='row' style={{ fontWeight: 'bold' }}>
                  {t('user_profile.mobile_phone')}
                </TableCell>
                <TableCell align='right'>{user?.mobile_phone}</TableCell>
                <TableCell align='center' style={{ border: 0 }}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell component='th' scope='row' style={{ fontWeight: 'bold' }}>
                  {t('user_profile.user_plan')}
                </TableCell>
                <TableCell align='right'>{user?.plan === 1 ? t('user_plan.basic') : (t('user_plan.discount') + ' ' + (user?.discount || user?.plan || 1) + '%')}</TableCell>
                <TableCell align='center' style={{ border: 0 }}>
                  <Tooltip title={t('user_profile.user_plan_tooltip') || ' '} arrow placement='right'>
                    <HelpIcon style={{ width: 16, height: 16, color: '#1769aa' }} />
                  </Tooltip>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component='th' scope='row' style={{ fontWeight: 'bold' }}>
                  {t('user_profile.bonus')}
                </TableCell>
                <TableCell align='right'>{user?.bonus}</TableCell>
                <TableCell align='center' style={{ border: 0 }}>
                  <Tooltip title={t('user_profile.bonus_tooltip') || ' '} arrow placement='right'>
                    <HelpIcon style={{ width: 16, height: 16, color: '#1769aa' }} />
                  </Tooltip>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component='th' scope='row' style={{ fontWeight: 'bold' }}>
                  {t('user_profile.telegram_id')}
                </TableCell>
                <TableCell align='right'>
                  {!user?.telegram_id && (
                    <Link href={'https://t.me/MotorCarTuningBot?start=' + user?.key} target='_blank'>
                      <LinkIcon style={{ height: 16, color: '#1769aa' }} />
                    </Link>
                  )}
                  {user?.telegram_id}
                </TableCell>
                <TableCell align='center' style={{ border: 0 }}>
                  <Tooltip title={t('user_profile.link_telegram_tooltip') || ' '} arrow placement='right'>
                    <HelpIcon style={{ width: 16, height: 16, color: '#1769aa' }} />
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Button className={classes.changePassword} variant='contained' color='primary' onClick={handleChangePasswordOpen}>
            {t('button_title.change_password')}
          </Button>

          {changePasswordOpen && <ChangePasswordDialog open={changePasswordOpen} handleClose={handleChangePasswordClose} />}
          {newPasswordSuccess && (
            <BaseDialog
              open={newPasswordSuccess}
              handleClose={handlePasswordSuccessClose}
              title={t('dialog.title.change_password_success')}
              textContent={t('dialog.content.change_password_success')}
            />
          )}
        </div>
        <div>
          {user?.allow_ed ?
            <div>
                <Typography variant='h5'>{t('user_profile.title_files')}</Typography>
                <Button className={classes.uploadFile} variant='contained' color='primary' onClick={handleUploadFile}>
                  {t('button_title.upload_file')}
                </Button>
                <Button className={classes.checkFiles} variant='contained' color='primary' onClick={handleFileList}>
                {t('button_title.check_files')}
                </Button>
                <div className={classes.edContainer}>
                    {user?.active_subscription ? <>
                      <div className={classes.edLabel}>{t('user_profile.subscription')}</div>
                      <div>{user?.active_subscription && t('user_profile.active_subscription')}</div>
                      <div className={classes.edLabel}>{t('user_profile.ed_subscription_start')}</div>
                      <div>{user?.ed_subscribe_start}</div>
                      <div className={classes.edLabel}>{t('user_profile.ed_subscription_end')}</div>
                      <div>{user?.ed_subscribe_end}</div>
                      <div className={classes.edLabel}>{t('user_profile.ed_subscribe_limit')}</div>
                      <div>{user?.ed_subscribe_limit}</div>
                      <div className={classes.edLabel}>{t('user_profile.uploaded_files')}</div>
                      <div>{user?.uploaded_files}</div>
                    </> :
                    <>
                      <div className={classes.edLabel}>{t('user_profile.ed_main_price')}</div>
                      <div>{user?.ed_main_price}</div>
                      <div className={classes.edLabel}>{t('user_profile.ed_second_price')}</div>
                      <div>{user?.ed_second_price}</div>
                    </>}
                </div>
            </div> : <div/>}
          </div>
          </div>
        </>
      )}

    </Layout>
  );
}

export default connect(
  (state) => userProfileSelector(state),
  (dispatch) => ({
    loadUserProfile: () => dispatch(loadUserProfileAction()),
    clearState: () => dispatch(clearStateAction()),
    updateNewPasswordSuccess: (newPasswordSuccess: boolean) => dispatch(updateNewPasswordSuccessAction(newPasswordSuccess)),
  })
)(UserProfile);
