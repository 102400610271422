// outsource dependencies
import React from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
// local dependencies
// constants
import { CONSTRAINT, APP_COLOR } from 'constants/style';
import { COMMON as ROUTE } from 'constants/routes';
// utils
import { getLocalizedPath } from 'utils/routing.utils';
interface IStyle {
  isMobile: boolean
}

const useStyles = makeStyles({
  container: {
    backgroundColor: '#969595',
    minHeight: CONSTRAINT.FOOTER,
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    maxWidth: CONSTRAINT.MAX_CONTENT_SIZE,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  link: {
    color: APP_COLOR.WHITE,
    fontSize: 14,
    textDecoration: 'none',
    '&:hover': {
      color: APP_COLOR.WHITE,
    },
    marginRight: 12,
  },
  copyright: {
    position: 'absolute',
    left: 0,
    fontSize: 14,
    color: APP_COLOR.WHITE,
  },
  commonLinks: {
    display: 'flex',
  },
  mcvisa: {
  },
  sn: {

  },
  mc: {
    marginRight: '10px',
  },
  visa: {
    marginBlock: '12px',
    marginRight: '10px',
  },
  mms: {
    marginBlock: '7px'
  },
  // social
  instagram: {
    marginLeft: (props: IStyle) => (props.isMobile ? CONSTRAINT.MOBILE_INDENT : 20),
  },
  facebook: {
    marginLeft: (props: IStyle) => (props.isMobile ? CONSTRAINT.MOBILE_INDENT : 20),
  },
  drive: {
    marginLeft: (props: IStyle) => (props.isMobile ? CONSTRAINT.MOBILE_INDENT : 20),
  },
  mms_link: {
    marginLeft: (props: IStyle) => (props.isMobile ? CONSTRAINT.MOBILE_INDENT : 20),
  },
  // mobile
  contentMobile: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: CONSTRAINT.MOBILE_INDENT,
  },
  socialsMobile: {
    display: 'flex',
    alignItems: 'center',
  },
  paymentMobile: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  targetLinksContainerMobile: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    paddingTop: CONSTRAINT.MOBILE_INDENT,
  },
});

const Footer: React.FC = () => {
  const classes = useStyles({ isMobile });
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      {!isMobile && (
        <div className={classes.content}>
          <div className={classes.sn}>

          </div>
          <div>
            <Link className={classes.link} to={getLocalizedPath(ROUTE.PRIVACY_POLICY)}>
              <span>{t('route.privacy_policy')}</span>
            </Link>
            <Link className={classes.link} to={getLocalizedPath(ROUTE.USER_AGREEMENT)}>
              <span>{t('route.user_agreement')}</span>
            </Link>
          </div>
          <div className={classes.mcvisa}>
            <img src='/assets/vbm_blugrad01.png' height='20px' alt='Visa' className={classes.visa} />
            <img src='/assets/mc_vrt_pos.svg' height='40px' className={classes.mc} alt='MasterCard' />
            <img src='/assets/Logo_MMS.png' className={classes.mms} height='30px' alt='MMS'/>
          </div>
        </div>
      )}
      {isMobile && (
        <div className={classes.contentMobile}>
          <div>
            <Link className={classes.link} to={getLocalizedPath(ROUTE.PRIVACY_POLICY)}>
              <span>{t('route.privacy_policy')}</span>
            </Link>
            <Link className={classes.link} to={getLocalizedPath(ROUTE.USER_AGREEMENT)}>
              <span>{t('route.user_agreement')}</span>
            </Link>
          </div>
          <div className={classes.targetLinksContainerMobile}>
            <div className={classes.socialsMobile}>
            </div>
            <div className={classes.paymentMobile}>
              <img src='/assets/mc_vrt_pos.svg' height='40px' alt='MasterCard' />
              <img src='/assets/vbm_blugrad01.png' height='20px' alt='Visa' />
              <img src='/assets/Logo_MMS.png' height='30px' alt='MMS' />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Footer;
