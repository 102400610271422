// outsource dependencies
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
// UI
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// local dependencies
import { selector as app, IUser, userLogOutAction, updateAppDataAction } from 'reducers';
// UI
import Language from 'components/button/Language';
// constants
import { CONSTRAINT, APP_COLOR } from 'constants/style';
import { PUBLIC as PUBLIC_ROUTE, PRIVATE as PRIVATE_ROUTE } from 'constants/routes';
// assets
import warning from 'assets/warning.png';
// utils
import { getLocalizedPath } from 'utils/routing.utils';
interface IStyle {
  isMobile: boolean;
}
const useStyles = makeStyles({
  container: {
    minHeight: CONSTRAINT.HEADER,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#3E3E3E',
    padding: (props: IStyle) => (props.isMobile ? `0 ${CONSTRAINT.MOBILE_INDENT}px` : 0),
  },
  content: {
    maxWidth: CONSTRAINT.MAX_CONTENT_SIZE,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    color: APP_COLOR.WHITE,
    fontSize: 16,
    '&:hover': {
      color: APP_COLOR.WHITE,
    },
    marginRight: 8,
    textDecoration: 'none',
  },
  logo: {
    fontSize: 22,
    textDecoration: 'none',
  },
  navigation: {
    display: 'flex',
    alignItems: 'center',
  },
  actionBtn: {
    color: APP_COLOR.WHITE,
  },
  rightIndent8: {
    marginRight: 8,
  },
  separator: {
    fontSize: 26,
    marginRight: 8,
  },
  menuOpen: {
    color: APP_COLOR.WHITE,
  },
  // mobile
  contentMobile: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `0 ${CONSTRAINT.MOBILE_INDENT}px`,
  },
  mobileConstraintLine: {
    backgroundImage: `url(${warning})`,
    width: `calc(100% + ${CONSTRAINT.MOBILE_INDENT}px * 2)`,
    height: 60,
    backgroundSize: 'auto 60px',
    backgroundRepeat: 'no-repeat',
    color: APP_COLOR.BLACK,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  warning: {
    padding: CONSTRAINT.MOBILE_INDENT,
    fontSize: 18,
    fontWeight: 'bold',
  },
});
interface IProps {
  locale?: string;
  user?: IUser | null;
  //
  handleLoginOpen: () => void;
  handleSignUpOpen: () => void;
  handleAppNavigate: () => void;
  userLogOut?: () => void;
  updateAppLanguage?: (language: string | null) => void;
}

const Header: React.FC<any> = (props: IProps) => {
  const {
    user,
    //
    userLogOut,
    handleAppNavigate,
    updateAppLanguage,
    //
    handleSignUpOpen,
    handleLoginOpen,
  } = props;
  const classes = useStyles({ isMobile });
  const history = useHistory();
  const { t } = useTranslation();
  const isUserLoggedIn = Boolean(user);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleUserProfile = () => {
    handleAppNavigate();
    history.push(getLocalizedPath(PRIVATE_ROUTE.USER_PROFILE));
  };
  /*
  const handleSettings = () => {
    history.push(PRIVATE_ROUTE.SETTINGS);
  };
  const handleBilling = () => {
    history.push(PRIVATE_ROUTE.BILLING);
  };
*/
  const handleUserLogOut = () => {
    userLogOut && userLogOut();
  };
  return (
    <AppBar className={classes.container} position='sticky'>
      {!isMobile && (
        <div className={classes.content}>
          <div className={classes.logo}>
            <Link className={classes.link} to={getLocalizedPath(PUBLIC_ROUTE.HOME)} onClick={handleAppNavigate}>
              <img src='/assets/LOGO-02.svg' alt='Home' height='50px' style={{ marginTop: '5px' }} />
            </Link>
          </div>
          <div className={classes.navigation}>
            {false && <Link className={classes.link} to={getLocalizedPath(PUBLIC_ROUTE.DISCOUNT_AND_NEWS)} onClick={handleAppNavigate}>
              <span>{t('route.discount_and_news')}</span>
            </Link>}
            {false && <span className={classes.separator}>/</span>}
            <Link className={classes.link} to={getLocalizedPath(PUBLIC_ROUTE.CONTACTS)} onClick={handleAppNavigate}>
              <span>{t('route.contacts')}</span>
            </Link>
            <span className={classes.separator}>/</span>
            <Link className={classes.link} to={getLocalizedPath(PUBLIC_ROUTE.PAYMENT_AND_DELIVERY)} onClick={handleAppNavigate}>
              <span>{t('route.payment_and_delivery')}</span>
            </Link>
            <span className={classes.separator}>/</span>
                <Link className={classes.link} to={getLocalizedPath(PUBLIC_ROUTE.PRICES)} onClick={handleAppNavigate}>
                  <span>{t('route.prices')}</span>
                </Link>
            {isUserLoggedIn && (
              <>
                
                <span className={classes.separator}>/</span>
                <Link className={classes.link} to={getLocalizedPath(PRIVATE_ROUTE.PROCESSING_TASKS)} onClick={handleAppNavigate}>
                  <span>{t('route.processing_tasks')}</span>
                </Link>
                <span className={classes.separator}>/</span>
                <Link className={classes.link} to={getLocalizedPath(PRIVATE_ROUTE.ADD_FILE)} onClick={handleAppNavigate}>
                  <span>{t('route.add_file')}</span>
                </Link>
              </>
            )}
            <Language updateAppLanguage={updateAppLanguage} />
            {isUserLoggedIn && (
              <>
                <Button onClick={handleMenuOpen} className={classes.menuOpen}>
                  <MenuIcon />
                </Button>
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
                  <MenuItem onClick={handleUserProfile}>{t('route.user_profile')}</MenuItem>
                  {/*                 <MenuItem onClick={handleBilling}>{t('route.billing')}</MenuItem>
          <MenuItem onClick={handleSettings}>{t('route.settings')}</MenuItem> */}
                  <MenuItem onClick={handleUserLogOut}>{t('button_title.log_out')}</MenuItem>
                </Menu>
              </>
            )}
            {!isUserLoggedIn && (
              <>
                <Button className={classNames(classes.actionBtn, classes.rightIndent8)} onClick={handleLoginOpen}>
                  {t('button_title.log_in')} <ExitToAppIcon />
                </Button>
                <Button className={classes.actionBtn} onClick={handleSignUpOpen}>
                  {t('button_title.sign_up')}
                </Button>
              </>
            )}
          </div>
        </div>
      )}
      {isMobile && (
        <>
          <div className={classes.contentMobile}>
            <div className={classes.logo}>
              <Link className={classes.link} to={getLocalizedPath(PUBLIC_ROUTE.HOME)} onClick={handleAppNavigate}>
                <img src='/assets/LOGO-02.svg' alt='Home' height='50px' style={{ marginTop: '5px' }} />
              </Link>
            </div>
            <div className={classes.navigation}>
              <Link className={classes.link} to={getLocalizedPath(PUBLIC_ROUTE.HOME)} onClick={handleAppNavigate}>
                <span>{t('route.home')}</span>
              </Link>
              <span className={classes.separator}>/</span>
              <Link className={classes.link} to={getLocalizedPath(PUBLIC_ROUTE.CONTACTS)} onClick={handleAppNavigate}>
                <span>{t('route.contacts')}</span>
              </Link>
            </div>
          </div>
          <div className={classes.mobileConstraintLine}>
            <span className={classes.warning}>{t('mobile.limited_version')}</span>
          </div>
        </>
      )}
    </AppBar>
  );
};

export default connect(
  (state) => ({ user: app(state).user, locale: app(state).locale }),
  (dispatch) => ({
    userLogOut: () => dispatch(userLogOutAction()),
    updateAppLanguage: (language: string | null) => dispatch(updateAppDataAction({ locale: language })),
  })
)(Header);
