// outsource dependencies
import React from 'react';
// UI
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

interface IProps {
  onChange?: (e: any) => void;
  checked?: boolean;
  label?: string;
  disabled?: boolean;
}

function CustomCheckbox(props: IProps) {
  const { onChange, checked, label, disabled } = props;
  return <FormControlLabel control={<Checkbox checked={checked} onChange={onChange} color='primary' />} label={label} disabled={Boolean(disabled)} />;
}

export default CustomCheckbox;
