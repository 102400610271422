// outsource dependencies
import { fork } from 'redux-saga/effects';
// local dependencies
import { sagas as processingTasksSagas } from 'private-layout/processing-tasks/saga';
import { sagas as userProfileSagas } from 'private-layout/user-profile/saga';
import { sagas as addFileSagas } from 'private-layout/add-file/saga';
import { sagas as pricesSagas } from 'private-layout/prices/saga';
import { sagas as edFileListSaga } from 'private-layout/encrypt-decrypt/file-list/saga';
import { sagas as edPaymentSaga} from 'private-layout/encrypt-decrypt/ed-payment/saga';
import {sagas as edFileView} from 'private-layout/encrypt-decrypt/file-view/saga';
import {sagas as edUploadFile} from 'private-layout/encrypt-decrypt/upload/saga';
import {sagas as ticket} from 'private-layout/ticket/saga';

export function* sagas() {
  yield fork(processingTasksSagas);
  yield fork(userProfileSagas);
  yield fork(addFileSagas);
  yield fork(pricesSagas);
  yield fork(edFileListSaga);
  yield fork(edPaymentSaga);
  yield fork(edFileView);
  yield fork(edUploadFile);
  yield fork(ticket);
}

export default sagas;
