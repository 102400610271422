import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import {
    loadEdFileAction, setEdFileInfoAction,
    TYPE,
    updateExpectAnswerAction
} from 'private-layout/encrypt-decrypt/upload/reducer';
import {API_FORM_DATA} from "services/request.service";
import get from "lodash/get";


function* loadEdFileSaga({payload}: ReturnType<typeof loadEdFileAction>) {
    yield put(updateExpectAnswerAction(true));

    try {

        const formData = new FormData();
        formData.append('clientFile', payload.carFile);
        formData.append('fileAction', payload.encodeDecodeRadioGroup);
        formData.append('fileEncodingType', payload.encodeDecodeRadioGroup === "encode" ? payload.encodeRadioGroup : null);

        const {data} = yield call<any>(API_FORM_DATA, {
            url: '/client/encode-decode/file/upload/v1',
            method: 'POST',
            data: formData,
        });
        const fileInfo = get(data, 'response', null);


        //test data. remove this in dev/prod env
        /** 
        const fileInfo = {
            key: "e8a8c7",
            orderInfo: {
                to_pay: 332.00
            }
        };
        */


        yield all([
            put(setEdFileInfoAction({fileInfo: fileInfo})),
            put(updateExpectAnswerAction(false))
        ]);
    } catch ({message}) {
        console.error('loadInitialData error: ', message);
        yield put(updateExpectAnswerAction(false));
    }

}

function* activityTasks() {
    yield takeLatest(TYPE.LOAD_ED_FILE, loadEdFileSaga);
}

export function* sagas() {
    yield fork(activityTasks);
}

export default sagas;