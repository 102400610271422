// outsource dependencies
import { fork, takeLatest, call, select, put } from 'redux-saga/effects';
import get from 'lodash/get';
// local dependencies
import {
  TYPE,
  selector as loginSelector,
  updateExpectAnswerAction,
  updateErrorLogInAction,
  updateExpectRequestPasswordAnswerAction,
  updateRequestNewPasswordSuccessAction,
} from 'public-layout/log-in/reducer';
import API from 'services/request.service';
import { historyPush } from 'store';
// constants
import { API_VALIDATION } from 'constants/error';
import { PRIVATE as PRIVATE_ROUTE } from 'constants/routes';
// utils
import { getFlattenValidationErrors } from 'utils/response.utils';
import { getLocalizedPath } from 'utils/routing.utils';
import CookieStorage from "../../services/storage.service";
import {DEFAULT_LANGUAGE} from "../../constants/language";

function* loginSaga() {
  const { email, password } = yield select(loginSelector);
  yield put(updateExpectAnswerAction(true));
  try {
    yield call<any>(API, {
      url: '/client/login',
      method: 'POST',
      data: {
        email,
        password,
      },
    });
    yield put(updateExpectAnswerAction(false));
    historyPush(getLocalizedPath(PRIVATE_ROUTE.ADD_FILE));
    window.location.reload();
  } catch (e) {
    console.error('loginSaga error: ', e.response, e, getFlattenValidationErrors(e.response?.data.response));
    const errorKey = get(e, 'response.data.errorKey', null);
    const errors = getFlattenValidationErrors(e.response?.data.response);
    let userNotFound = {}
    if (errorKey === API_VALIDATION.username_not_found) {
      userNotFound = { email: 'errors.validation_error.username_not_found' };
    }
    if (errorKey === API_VALIDATION.password_not_valid) {
      userNotFound = { ...userNotFound, password: 'errors.validation_error.password_not_valid' };
    }
    yield put(updateErrorLogInAction({ ...errors, ...userNotFound }));
    yield put(updateExpectAnswerAction(false));
  }
}

function* requestNewPasswordSaga() {
  const { email} = yield select(loginSelector);
  const locale = Boolean(CookieStorage.getItem('locale')) ? CookieStorage.getItem('locale') : DEFAULT_LANGUAGE;
  yield put(updateExpectRequestPasswordAnswerAction(true));
  try {
    yield call<any>(API, {
      url: '/client/request-change-password',
      method: 'POST',
      data: {
        email,
        locale,
      },
    });
    yield put(updateRequestNewPasswordSuccessAction(true));
    yield put(updateExpectRequestPasswordAnswerAction(false));
  } catch (e) {
    console.error('requestNewPasswordSaga error: ', e.response, e, getFlattenValidationErrors(e.response?.data.response));
    const errorKey = get(e, 'response.data.errorKey', null);
    const errors = getFlattenValidationErrors(e.response?.data.response);
    let userNotFound = {};
    if (errorKey === API_VALIDATION.not_found) {
      userNotFound = { email: 'errors.validation_error.email_not_found' };
    } else {
      userNotFound = { email: 'errors.validation_error.bad_request' };
    }
    yield put(updateErrorLogInAction({ ...errors, ...userNotFound }));
    yield put(updateExpectRequestPasswordAnswerAction(false));
  }
}

/**
 * connect page sagas
 *
 * @private
 */
function* activityTasks() {
  yield takeLatest(TYPE.LOG_IN_SUBMIT, loginSaga);
  yield takeLatest(TYPE.REQUEST_PASSWORD, requestNewPasswordSaga);
}

export function* sagas() {
  yield fork(activityTasks);
}

export default sagas;
