
export const LANGUAGE = {
  en: 'en',
  ua: 'ua',
};

export const DEFAULT_LANGUAGE = LANGUAGE.en;

export default [
  {
    language: LANGUAGE.en,
    textKey: 'language.en',
  },
  {
    language: LANGUAGE.ua,
    textKey: 'language.ua',
  },
];
