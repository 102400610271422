// outsource dependencies
import React from 'react';
// import { useTranslation } from 'react-i18next';
// local dependencies
// UI
import Layout from 'components/layout/Common';
interface IProps {
  pageTitle: string;
  language: string;
}

export default function SupportedVehicles(props: IProps) {
  const { pageTitle, language } = props;
  // const { i18n } = useTranslation();
  return (
    <Layout pageTitle={pageTitle} language={language}>
      Supported Vehicles
    </Layout>
  );
}
