// outsource dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';
// UI
import Typography from '@material-ui/core/Typography';
// local dependencies
// UI
import Layout from 'components/layout/Common';
interface IProps {
  pageTitle: string;
  language: string;
}

export default function Billing(props: IProps) {
  const { pageTitle, language } = props;
  const { t } = useTranslation();
  return (
    <Layout pageTitle={pageTitle} language={language}>
      <Typography variant='h5'>{t('billing.title')}</Typography>
    </Layout>
  );
}
