// types
export const TYPE = ((prefix) => ({
  PREFIX: new RegExp(prefix, 'i'),
  // complex actions
  CLEAR: `${prefix}CLEAR`,
  DATA: `${prefix}DATA`,
  ERROR: `${prefix}ERROR`,
  RESET_ERROR: `${prefix}RESET_ERROR`,
  SIGN_UP_SUBMIT: `${prefix}SIGN_UP_SUBMIT`,
}))('@sign-up/');

// selectors
export const selector = (state: any) => state.signUp;

export const SIGN_UP_FIELD_KEY = {
  username: 'username',
  company: 'company',
  password: 'password',
  email: 'email',
  first_name: 'first_name',
  last_name: 'last_name',
  mobile_phone: 'mobile_phone',
};

export interface ISignUpError {
  username?: string | null;
  company?: string | null;
  password?: string | null;
  email?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  mobile_phone?: string | null;
}

interface IUserState {
  username: string;
  company: string;
  password: string;
  email: string;
  expectAnswer: boolean;
  isTermsAgree: boolean;
  errors: ISignUpError | null;
  first_name: string | null;
  last_name: string | null;
  mobile_phone: string | null;
  confirmEmail: boolean;
}

export function typedAction(type: string, payload?: any) {
  return { type, payload };
}

// sign up flow
export const updateUsernameAction = (username: string) => typedAction(TYPE.DATA, { username });
export const updateCompanyAction = (company: string) => typedAction(TYPE.DATA, { company });
export const updatePasswordAction = (password: string) => typedAction(TYPE.DATA, { password });
export const updateEmailAction = (email: string) => typedAction(TYPE.DATA, { email });
export const updateFirstNameAction = (first_name: string) => typedAction(TYPE.DATA, { first_name });
export const updateLastNameAction = (last_name: string) => typedAction(TYPE.DATA, { last_name });
export const updateMobilePhoneAction = (mobile_phone: string) => typedAction(TYPE.DATA, { mobile_phone });
export const updateEmailConfirmationAction = (confirmEmail: boolean) => typedAction(TYPE.DATA, { confirmEmail });
export const updateTermsAgreeAction = (isTermsAgree: boolean) => typedAction(TYPE.DATA, { isTermsAgree });

export const resetErrorSignUpAction = (fieldKey: string) => typedAction(TYPE.RESET_ERROR, { fieldKey });
export const updateErrorSignUpAction = (errors: ISignUpError) => typedAction(TYPE.ERROR, errors);

export const updateExpectAnswerAction = (expectAnswer: boolean) => typedAction(TYPE.DATA, { expectAnswer });

export const signUpSubmitAction = () => typedAction(TYPE.SIGN_UP_SUBMIT);

type UserAction = ReturnType<
  | typeof updateUsernameAction
  | typeof updateFirstNameAction
  | typeof updateLastNameAction
  | typeof updateMobilePhoneAction
  | typeof updateCompanyAction
  | typeof updatePasswordAction
  | typeof updateEmailAction
  | typeof signUpSubmitAction
  | typeof updateExpectAnswerAction
  | typeof resetErrorSignUpAction
  | typeof updateErrorSignUpAction
  | typeof updateTermsAgreeAction
>;

const initialState: IUserState = {
  username: '',
  password: '',
  company: '',
  email: '',
  first_name: '',
  last_name: '',
  mobile_phone: '',
  isTermsAgree: false,
  expectAnswer: false,
  errors: null,
  confirmEmail: false,
};

export function signUp(state = initialState, action: UserAction): IUserState {
  switch (action.type) {
    default:
      return state;
    case TYPE.DATA:
      return { ...state, ...action.payload };
    case TYPE.ERROR:
      return _setError(state, action.payload);
    case TYPE.RESET_ERROR:
      return _resetError(state, action.payload);
  }
}

const _resetError = (state: IUserState, error: { fieldKey: string }) => {
  const newErrors = state.errors ? { ...state.errors, [error.fieldKey]: null } : { [error.fieldKey]: null };
  return { ...state, errors: newErrors };
};

const _setError = (state: IUserState, errors: ISignUpError) => {
  const newErrors = state.errors ? { ...state.errors, ...errors } : errors;
  return { ...state, errors: newErrors };
};
