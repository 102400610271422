
// types
export const TYPE = ((prefix) => ({
  PREFIX: new RegExp(prefix, 'i'),
  // complex actions
  CLEAR: `${prefix}CLEAR`,
  DATA: `${prefix}DATA`,
  // loading actions
  LOAD_VEHICLES: `${prefix}LOAD_VEHICLE`,
  LOAD_VEHICLE_BRANDS: `${prefix}LOAD_VEHICLE_BRANDS`,
  LOAD_VEHICLE_MODELS: `${prefix}LOAD_VEHICLE_MODELS`,
  LOAD_VEHICLE_ENGINES: `${prefix}LOAD_VEHICLE_ENGINES`,
  LOAD_VEHICLE_ECUS: `${prefix}LOAD_VEHICLE_ECUS`,
  LOAD_VEHICLE_ACTIONS: `${prefix}LOAD_VEHICLE_ACTIONS`,
  LOAD_VEHICLE_ACTIONS_BY_BRAND_AND_ECU: `${prefix}LOAD_VEHICLE_ACTIONS_BY_BRAND_AND_ECU`,
  LOAD_VEHICLE_PRICE: `${prefix}LOAD_VEHICLE_PRICE`,
  // state
  SELECT_ACTION_STATE: `${prefix}SELECT_ACTION_STATE`,
  FILE_INFO: `${prefix}FILE_INFO`,
  NO_ECUS: `${prefix}NO_ECUS`,
  // trigger
  SELECT_ECUS: `${prefix}SELECT_ECUS`,
  UPLOAD_FILE: `${prefix}UPLOAD_FILE`,
  BILLING_ORDER: `${prefix}BILLING_ORDER`,
  REQUEST_PAYMENT: `${prefix}REQUEST_PAYMENT`,
  PROCESS_FILE: `${prefix}PROCESS_FILE`,
  RETURN_ECU_SUGGESTIONS: `${prefix}RETURN_ECU_SUGGESTIONS`,
  FILE_ECODING_TYPE: `${prefix}FILE_ECODING_TYPE`,
}))('@add-file/');

// selectors
export const selector = (state: any) => ({ ...state.addFile, user:state.app.user });
export interface IVehicle {
  key: string;
  name: string;
}
export interface IVehicleBrand {
  key: string;
  name: string;
}
export interface IVehicleEcus {
  key: string;
  name: string;
}
export interface ISuggectECUs {
  key: string;
  name: string;
  selected?: boolean;
}
export interface IVehicleAction {
  key: string;
  name: string;
  auto: boolean;
  available: boolean;
  selected: boolean;
}
export interface IVehiclePrice {
  basePrice: string;
  discount: number | string;
  toPay: string;
  bonus: string;
}
export interface IFileInfo {
  key?: string | null;
  fileName?: string | null;
  size?: string | number | null;
  ecu?: string | null;
  soft?: string | null;
}
export interface IFileData {
  date: string;
  dtc: string;
  ecu: string;
  name: string;
}
export interface IPrice {
  basePrice: number | string;
  discount: number | string;
  toPay: number | string;
  bonus: number | string;
}

export interface IRequestFileResponse {
  status: string;
  fileKey: string;
  fileStatus: string;
}

interface IUserState {
  expectAnswer: boolean;

  suggectECUs: ISuggectECUs[] | null;
  //
  vehicles: IVehicle[] | null;
  vehicleBrands: IVehicleBrand[] | null;
  vehicleEcus: IVehicleEcus[] | null;
  vehicleActions: IVehicleAction[] | null;
  processedDTCs: string[] | null;
  processedActions: string[] | null;
  manualActions: string[] | null;

  vehiclePrice: IVehiclePrice | null;

  selectedEcuAndBrandKey: string | null;
  selectedEcusKey: string | null;
  selectedBrandKey: string | null;
  dtcKey: string;
  allowDtc: boolean;
  autoDtc: boolean;
  fileKey: string | null;
  showInfo: boolean;
  noEcusInList: boolean;

  fileData: IFileData | null;
  fileInfo: IFileInfo;
  price: IPrice | null;
  actions: string[] | null;
  signature: string | null;
  data: string | null;

  vehicleBrandsLoaded: boolean;
  vehicleEcusLoaded: boolean;
  vehicleActionsLoaded: boolean;
  fileProcessed: boolean;
  requestFileResponse: IRequestFileResponse | null;

  expectBrandsAnswer: boolean;
  expectEcusAnswer: boolean;
  expectActionsAnswer: boolean;
  expectUploadFileAnswer: boolean;
  expectVehiclePriceAnswer: boolean;
  expectBillingOrderAnswer: boolean;
  expectProcessFileAnswer: boolean;

  uploadEcuSuccess: boolean;
  ecuProcessingFailed: boolean;
  paymentAllowed: boolean;
  fileEncodingType: string;
}

export function typedAction(type: string, payload?: any) {
  return { type, payload };
}

export const updateExpectAnswerAction = (expectAnswer: boolean) => typedAction(TYPE.DATA, { expectAnswer });
// trig loading
export const loadVehiclesAction = () => typedAction(TYPE.LOAD_VEHICLES);
export const loadVehicleBrandsAction = (vehicle: string) => typedAction(TYPE.LOAD_VEHICLE_BRANDS, vehicle);
export const loadVehicleEcusAction = (engine: string) => typedAction(TYPE.LOAD_VEHICLE_ECUS, engine);
export const loadVehicleActionsAction = (key: string) => typedAction(TYPE.LOAD_VEHICLE_ACTIONS, key);
export const loadVehicleActionsByBrandAndEcuAction = () => typedAction(TYPE.LOAD_VEHICLE_ACTIONS_BY_BRAND_AND_ECU);
export const loadVehiclePriceAction = () => typedAction(TYPE.LOAD_VEHICLE_PRICE);
// update state
export const updateFileInfoAction = (fileInfo: IFileInfo) => typedAction(TYPE.FILE_INFO, { fileInfo });
export const selectSuggectECUsAction = (suggectECUKey: string) => typedAction(TYPE.SELECT_ECUS, { suggectECUKey });

export const updateSuggectECUsAction = (suggectECUs: ISuggectECUs[] | null) => typedAction(TYPE.DATA, { suggectECUs });
export const updateSelectedEcusAndBrandKeyAction = (selectedEcuAndBrandKey: string | null) => typedAction(TYPE.DATA, { selectedEcuAndBrandKey });
export const updateSelectedVehicleKeyAction = (selectedVehicleKey: string | null) => typedAction(TYPE.DATA, { selectedVehicleKey });
//
export const updateVehiclesAction = (vehicles: IVehicle[] | null) => typedAction(TYPE.DATA, { vehicles });
export const updateVehicleBrandsAction = (vehicleBrands: IVehicleBrand[] | null) => typedAction(TYPE.DATA, { vehicleBrands });
export const updateVehicleEcusAction = (vehicleEcus: IVehicleEcus[] | null) => typedAction(TYPE.DATA, { vehicleEcus });
export const updateVehicleActionsAction = (vehicleActions: IVehicleAction[] | null) => typedAction(TYPE.DATA, { vehicleActions });
export const updateProcessedDTCsAction = (processedDTCs: string[] | null) => typedAction(TYPE.DATA, { processedDTCs });
export const updateProcessedActionsAction = (processedActions: string[] | null) => typedAction(TYPE.DATA, { processedActions });
export const updateManualActionsAction = (manualActions: string[] | null) => typedAction(TYPE.DATA, { manualActions });

export const updateVehiclePriceAction = (vehiclePrice: IVehiclePrice | null) => typedAction(TYPE.DATA, { vehiclePrice });
export const updateFileKeyAction = (fileKey: string | null) => typedAction(TYPE.DATA, { fileKey });

export const updateSelectedEcusKeyAction = (selectedEcusKey: string | null) => typedAction(TYPE.DATA, { selectedEcusKey });
export const updateSelectedBrandKeyAction = (selectedBrandKey: string | null) => typedAction(TYPE.DATA, { selectedBrandKey });
export const updateAllowDtcAction = (allowDtc: boolean) => typedAction(TYPE.DATA, { allowDtc });
export const updateAutoDtcAction = (autoDtc: boolean) => typedAction(TYPE.DATA, { autoDtc });
export const updateDtcAction = (dtcKey: string) => typedAction(TYPE.DATA, { dtcKey });
export const updateShowInfoAction = (showInfo: boolean) => typedAction(TYPE.DATA, { showInfo });
export const updateNoEcusInListAction = (noEcusInList: boolean) => typedAction(TYPE.NO_ECUS, { noEcusInList });
export const updateFileEncodingTypeAction = (value: string) => typedAction(TYPE.FILE_ECODING_TYPE, { value });

export const updateSelectActionStateAction = (actionKey: string) => typedAction(TYPE.SELECT_ACTION_STATE, { actionKey });
export const updateUploadEcuSuccessAction = (uploadEcuSuccess: boolean) => typedAction(TYPE.DATA, { uploadEcuSuccess });
export const updatePaymentAllowedAction = (paymentAllowed: boolean) => typedAction(TYPE.DATA, { paymentAllowed });
export const updateEcuProcessingFailedAction = (ecuProcessingFailed: boolean) => typedAction(TYPE.DATA, { ecuProcessingFailed });

export const updateFileDataAction = (fileData: IFileData | null) => typedAction(TYPE.DATA, { fileData });
export const updatePriceAction = (price: IPrice | null) => typedAction(TYPE.DATA, { price });
export const updateActionsAction = (actions: string[] | null) => typedAction(TYPE.DATA, { actions });
export const updateSignatureAction = (signature: string | null) => typedAction(TYPE.DATA, { signature });
export const updateDataAction = (data: string | null) => typedAction(TYPE.DATA, { data });
// handle "select" render due to loading step
export const updateVehicleBrandsLoadedAction = (vehicleBrandsLoaded: boolean) => typedAction(TYPE.DATA, { vehicleBrandsLoaded });
export const updateVehicleEcusLoadedAction = (vehicleEcusLoaded: boolean) => typedAction(TYPE.DATA, { vehicleEcusLoaded });
export const updateVehicleActionsLoadedAction = (vehicleActionsLoaded: boolean) => typedAction(TYPE.DATA, { vehicleActionsLoaded });
export const updateFileProcessedAction = (fileProcessed: boolean) => typedAction(TYPE.DATA, { fileProcessed });
// to trig preloader render
export const updateExpectBrandsAnswerAction = (expectBrandsAnswer: boolean) => typedAction(TYPE.DATA, { expectBrandsAnswer });
export const updateExpectEcusAnswerAction = (expectEcusAnswer: boolean) => typedAction(TYPE.DATA, { expectEcusAnswer });
export const updateExpectActionsAnswerAction = (expectActionsAnswer: boolean) => typedAction(TYPE.DATA, { expectActionsAnswer });
export const updateExpectUploadFileAnswerAction = (expectUploadFileAnswer: boolean) => typedAction(TYPE.DATA, { expectUploadFileAnswer });
export const updateExpectVehiclePriceAnswerAction = (expectVehiclePriceAnswer: boolean) => typedAction(TYPE.DATA, { expectVehiclePriceAnswer });
export const updateExpectBillingOrderAnswerAction = (expectBillingOrderAnswer: boolean) => typedAction(TYPE.DATA, { expectBillingOrderAnswer });
export const updateExpectProcessFileAnswerAction = (expectProcessFileAnswer: boolean) => typedAction(TYPE.DATA, { expectProcessFileAnswer });
export const updateRequestFileResponseAction = (requestFileResponse: IRequestFileResponse | null) => typedAction(TYPE.DATA, { requestFileResponse });
export const updateExpectRequestFileAnswerAction = (expectRequestFileAnswer: boolean) => typedAction(TYPE.DATA, { expectRequestFileAnswer });
//export const updateTaskAction = (task: IRequestFileResponse | null) => typedAction(TYPE.TASK_DATA, { task });

// trig saga request
export const uploadFileAction = (data: {carFile: File | null, comment: string}) => typedAction(TYPE.UPLOAD_FILE, data);
export const requestBillingOrderAction = () => typedAction(TYPE.BILLING_ORDER);
export const processFileAction = () => typedAction(TYPE.PROCESS_FILE);
export const requestPaymentAction = () => typedAction(TYPE.REQUEST_PAYMENT);
export const clearAction = () => typedAction(TYPE.CLEAR);
export const returnEcuSuggestionsAction = () => typedAction(TYPE.RETURN_ECU_SUGGESTIONS);

type UserAction = ReturnType<
  | typeof updateExpectAnswerAction
  //
  | typeof updateVehiclesAction
  | typeof updateVehicleBrandsAction
  | typeof updateVehicleEcusAction
  | typeof updateVehicleActionsAction
  | typeof updateAllowDtcAction
  | typeof updateDtcAction
  | typeof updateSelectActionStateAction
  | typeof updateSelectedBrandKeyAction
  | typeof updateSelectedEcusKeyAction
  | typeof updatePaymentAllowedAction
  | typeof updateVehiclePriceAction
  | typeof updateShowInfoAction
  | typeof updateRequestFileResponseAction
  //
  | typeof updateVehicleBrandsLoadedAction
  | typeof updateVehicleEcusLoadedAction
  | typeof updateVehicleActionsLoadedAction
  //
  | typeof updateExpectBrandsAnswerAction
  | typeof updateExpectEcusAnswerAction
  | typeof updateExpectActionsAnswerAction
  | typeof updateExpectUploadFileAnswerAction
  | typeof updateExpectBillingOrderAnswerAction
  //
  | typeof uploadFileAction
  | typeof requestBillingOrderAction
>;

const initialState: IUserState = {
  expectAnswer: false,

  suggectECUs: null,
  //
  vehicles: null,
  vehicleBrands: null,
  vehicleEcus: null,
  vehicleActions: null,
  processedDTCs: null,
  processedActions: null,
  manualActions: null,

  vehiclePrice: null,

  selectedEcuAndBrandKey: null,
  selectedEcusKey: null,
  selectedBrandKey: null,
  dtcKey: '',
  allowDtc: false,
  autoDtc: false,
  fileKey: null,
  showInfo: false,
  noEcusInList: false,
  requestFileResponse: null,

  fileData: null,
  fileInfo: {
    key: null,
    fileName: null,
    ecu: null,
    soft: null,
  },
  price: null,
  actions: null,
  signature: null,
  data: null,

  vehicleBrandsLoaded: false,
  vehicleEcusLoaded: false,
  vehicleActionsLoaded: false,
  fileProcessed: false,

  expectBrandsAnswer: false,
  expectEcusAnswer: false,
  expectActionsAnswer: false,
  expectUploadFileAnswer: false,
  expectVehiclePriceAnswer: false,
  expectBillingOrderAnswer: false,
  expectProcessFileAnswer: false,

  uploadEcuSuccess: false,
  ecuProcessingFailed: false,
  paymentAllowed: false,
  fileEncodingType: ''
};

export function addFile(state = initialState, action: UserAction): IUserState {
  switch (action.type) {
    default:
      return state;
    case TYPE.DATA:
      return { ...state, ...action.payload };
    case TYPE.FILE_INFO:
      return { ...state, fileInfo: { ...state.fileInfo, ...action.payload.fileInfo } };
    case TYPE.FILE_ECODING_TYPE:
      return { ...state, fileEncodingType: action.payload.value };
    case TYPE.SELECT_ACTION_STATE:
      return _handleSelectStateAction(state, action.payload);
    case TYPE.NO_ECUS:
      return _handleNoEcusAction(state, action.payload);
    case TYPE.CLEAR:
      return initialState;
  }
}

const _handleNoEcusAction = (state: IUserState, payload: { noEcusInList: boolean }) => {
  const { noEcusInList } = payload;
  let update = {};
  if (noEcusInList) {
    update = {
      vehicleBrandsLoaded: false,
      vehicleEcusLoaded: false,
      vehicleActionsLoaded: false,
      fileProcessed: false,
      showInfo: false,
      vehicleBrands: null,
      vehicleEcus: null,
      vehicleActions: null,
      processedDTCs: null,
      processedActions: null,
      manualActions: null,
    };
  }
  return { ...state, ...update, noEcusInList };
};

const _handleSelectStateAction = (state: IUserState, payload: { actionKey: string }) => {
  const { vehicleActions } = state;
  if (!vehicleActions) return state;
  const updatedVehicleActions = vehicleActions.map((vehicleAction: IVehicleAction) => {
    if (vehicleAction.key === payload.actionKey) {
      return { ...vehicleAction, selected: !vehicleAction.selected };
    }
    return vehicleAction;
  });
  return { ...state, vehicleActions: updatedVehicleActions };
};
