// outsource dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
// UI
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// local dependencies
// UI
import Layout from 'components/layout/Common';
// constants
import { CONSTRAINT } from 'constants/style';
interface IStyle {
  isMobile: boolean;
}
const useStyles = makeStyles({
  container: {
    padding: (props: IStyle) => (props.isMobile ? `0 ${CONSTRAINT.MOBILE_INDENT}px` : 0),
  },
  howItWorks: {
    float: 'right',
    width: (props: IStyle) => (props.isMobile ? `calc(100% + ${CONSTRAINT.MOBILE_INDENT}px * 4)` : 402),
    paddingLeft: 20,
    marginRight: (props: IStyle) => (props.isMobile ? `calc(-${CONSTRAINT.MOBILE_INDENT}px * 2)` : 0),
    marginBottom: (props: IStyle) => (props.isMobile ? `calc(${CONSTRAINT.MOBILE_INDENT}px * 2)` : 0),
  },
});
interface IProps {
  pageTitle: string;
  language: string;
}

export default function Home(props: IProps) {
  const { pageTitle, language } = props;
  const classes = useStyles({ isMobile });
  const { t, i18n } = useTranslation();

  return (
    <Layout pageTitle={pageTitle} language={language}>
      <div className={classes.container}>
        <Typography variant='h5'>{t('public_page.home.about_us')}</Typography>
        <br />
        {i18n.language === 'ru' && <img src='/assets/how_it_work_ru.png' className={classes.howItWorks} alt='how it work' />}
        {i18n.language === 'ua' && <img src='/assets/how_it_work_ua.png' className={classes.howItWorks} alt='how it work' />}
        {i18n.language === 'en' && <img src='/assets/task_mc_eng.png' className={classes.howItWorks} alt='how it work' />}
        <Typography>{t('public_page.home.text1')}</Typography>
        <ul>
          <li>
            <Typography>{t('public_page.home.text2')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.text3')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.text4')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.text5')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.text6')}</Typography>
          </li>
        </ul>

        <Typography>{t('public_page.home.text7')}</Typography>
        <br />

        <Typography variant='h5'>{t('public_page.home.text8')}</Typography>

        <ul>
          <li>
            <Typography>{t('public_page.home.text9')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.text10')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.text11')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.text12')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.text13')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.text14')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.text15')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.text16')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.text17')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.text18')}</Typography>
          </li>
        </ul>

        <Typography variant='h5'>{t('public_page.home.options')}</Typography>

        <ul>
          <li>
            <Typography>{t('public_page.home.option1')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.option4')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.option5')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.option6')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.option7')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.option8')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.option9')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.option10')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.option14')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.option15')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.option16')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.option18')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.option19')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.option20')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.option21')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.option22')}</Typography>
          </li>
          <li>
            <Typography>{t('public_page.home.option23')}</Typography>
          </li>
        </ul>
      </div>
    </Layout>
  );
}
