// types
export const TYPE = ((prefix) => ({
  PREFIX: new RegExp(prefix, 'i'),
  // complex actions
  CLEAR: `${prefix}CLEAR`,
  DATA: `${prefix}DATA`,
  ERROR: `${prefix}ERROR`,
  RESET_ERROR: `${prefix}RESET_ERROR`,
  // trig actions
  CHANGE_PASSWORD: `${prefix}CHANGE_PASSWORD`,
}))('@change-password/');

// selectors
export const selector = (state: any) => state.changePassword;

export interface IChangePasswordError {
  newPassword?: string | null;
  newPasswordDuplicate?: string | null;
}

interface IUserState {
  expectAnswer: boolean;
  newPassword: string;
  newPasswordDuplicate: string;
  token: string | null;
  errors: IChangePasswordError | null;
  newPasswordSuccess: boolean;
}

export function typedAction(type: string, payload?: any) {
  return { type, payload };
}

export const updateExpectAnswerAction = (expectAnswer: boolean) => typedAction(TYPE.DATA, { expectAnswer });
// state update
export const updateTokenAction = (token: string) => typedAction(TYPE.DATA, { token });
export const updateNewPasswordAction = (newPassword: string) => typedAction(TYPE.DATA, { newPassword });
export const updateNewPasswordDuplicateAction = (newPasswordDuplicate: string) => typedAction(TYPE.DATA, { newPasswordDuplicate });
export const updateNewPasswordSuccessAction = (newPasswordSuccess: boolean) => typedAction(TYPE.DATA, { newPasswordSuccess });

export const resetErrorAction = (fieldKey: string) => typedAction(TYPE.RESET_ERROR, { fieldKey });
export const updateErrorAction = (errors: IChangePasswordError) => typedAction(TYPE.ERROR, errors);
// trig loading
export const submitChangePasswordAction = () => typedAction(TYPE.CHANGE_PASSWORD);
export const clearStateAction = () => typedAction(TYPE.CLEAR);

type UserAction = ReturnType<
  | typeof updateExpectAnswerAction
  | typeof updateNewPasswordAction
  | typeof updateNewPasswordDuplicateAction
  | typeof submitChangePasswordAction
  | typeof updateTokenAction
  | typeof resetErrorAction
  | typeof updateErrorAction
  | typeof updateNewPasswordSuccessAction
  | typeof clearStateAction
>;

const initialState: IUserState = {
  expectAnswer: false,
  newPassword: '',
  newPasswordDuplicate: '',
  token: null,
  errors: null,
  newPasswordSuccess: false,
};

export function changePassword(state = initialState, action: UserAction): IUserState {
  switch (action.type) {
    default:
      return state;
    case TYPE.DATA:
      return { ...state, ...action.payload };
    case TYPE.ERROR:
      return _setError(state, action.payload);
    case TYPE.RESET_ERROR:
      return _resetError(state, action.payload);
    case TYPE.CLEAR:
      return initialState;
  }
}

const _resetError = (state: IUserState, error: { fieldKey: string }) => {
  const newErrors = state.errors ? { ...state.errors, [error.fieldKey]: null } : { [error.fieldKey]: null };
  return { ...state, errors: newErrors };
};

const _setError = (state: IUserState, errors: IChangePasswordError) => {
  const newErrors = state.errors ? { ...state.errors, ...errors } : errors;
  return { ...state, errors: newErrors };
};
