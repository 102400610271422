// outsource dependencies
import React from 'react';
// UI
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  mainPart: {
    color: '#AAA',
  },
}));

const MAIN_PART_LENGTH = 10;

interface IProps {
  fileSoft: string;
}

export default function FileSoft(props: IProps) {
  const { fileSoft } = props;
  const classes = useStyles();
  return (
    <>
      <span>{`${fileSoft.substring(0, MAIN_PART_LENGTH)}`}</span>
      <span className={classes.mainPart}>{fileSoft.substring(MAIN_PART_LENGTH)}</span>
    </>
  );
}
