// outsource dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';
// UI
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// local dependencies
import { ISuggectECUs } from 'private-layout/add-file/reducer';
const useStyles = makeStyles(() => ({
  alert: {
    marginTop: 8,
  },
  listItem: {
    paddingBottom: 0,
    paddingTop: 0
  }
}));

interface IProps {
  selectSuggectECUs?: (suggectECUKey: string) => void;
  suggectECUs?: ISuggectECUs[] | null;
}

export default function ECUsList(props: IProps) {
  const { selectSuggectECUs, suggectECUs } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const handleSelectSuggectECUs = (suggectECUKey: string) => () => {
    selectSuggectECUs && selectSuggectECUs(suggectECUKey);
  };

  return (
    <>
      {!Array.isArray(suggectECUs) && (
        <Typography className={classes.alert} variant='h6'>
          {t('add_file.no_ecu')}
        </Typography>
      )}
      {Array.isArray(suggectECUs) && (
        <>
          {Boolean(suggectECUs.length) && (
            <Typography className={classes.alert} variant='h6'>
              {t('add_file.choose_ecu')}
            </Typography>
          )}
          {!suggectECUs.length && (
            <Typography className={classes.alert} variant='h6'>
              {t('add_file.no_ecu')}
            </Typography>
          )}
          <List>
            {suggectECUs.map((suggectECUItem: ISuggectECUs) => {
              return (
                <ListItem key={suggectECUItem.key} button onClick={handleSelectSuggectECUs(suggectECUItem.key)} classes={{ root: classes.listItem }}>
                  <ListItemIcon>
                    <Checkbox color='primary' checked={suggectECUItem.selected} />
                  </ListItemIcon>
                  <ListItemText primary={suggectECUItem.name} />
                </ListItem>
              );
            })}
          </List>
        </>
      )}
    </>
  );
}
