interface IValidationError {
  path: string;
  message: string;
  errorCode: string;
}

export const getFlattenValidationErrors = (rawErrors: IValidationError[]) => {
  // multiple possible structure pf error response
  const errors = Array.isArray(rawErrors) ? rawErrors : [];
  const newErrors: any = {};
  // field prefix related to validation error meta info
  const errorPrefix = '.body.';
  errors.forEach((rawError: IValidationError) => {
    const field = rawError.path.replace(errorPrefix, '').trim();
    // value due to translation path
    const errorKey = `errors.validation_error.${rawError.errorCode}.${field}`;
    newErrors[field] = errorKey;
  });
  return newErrors;
};
