export const CONSTRAINT = {
  MAX_CONTENT_SIZE: 1024,
  HEADER: 60,
  FOOTER: 50,
  BUTTON_WIDTH: 150,
  MOBILE_INDENT: 10
};

export const APP_COLOR = {
  BLACK: '#000',
  WHITE: '#fff',
  RED: '#CC0000',
  ERROR: '#CC0000',
  GREY: '#3E3E3E',
  MEDIUM_GREY: '#1E1E1E',
  LIGHT_GREY: '#7e7e7e',
  DARK_GREY: '#262626',
  WARNING: '#FDF751',
};
