import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import {
    TYPE,
    loadFileDataAction,
    loadMessagesAction,
    sendMessageAction,
    updateExpectAnswerFileDataAction,
    updateExpectAnswerMessagesAction,
    updateExpectAnswerSendMessageAction,
    updateFileDataAction, updateMessagesAction
} from "./reducer";
import API, {API_FORM_DATA} from "../../services/request.service";
import get from "lodash/get";

function* loadFileDataSaga({payload}: ReturnType<typeof loadFileDataAction>) {
    yield put(updateExpectAnswerFileDataAction(true));
    try {
        const {data} = yield call<any>(API, {
            url: '/ticket/loadFileData',
            method: 'POST',
            data: {
                fileKey: payload
            }
        });
        const fileData = get(data, 'response', null);
        yield all([
            put(updateFileDataAction(fileData)),
            put(updateExpectAnswerFileDataAction(false))]);
    } catch (e) {
        console.error('loadFileDataSaga error: ', e.response, e);
        yield put(updateExpectAnswerFileDataAction(false));
    }
}

function* loadMessagesSaga({payload}: ReturnType<typeof loadMessagesAction>) {
    yield put(updateExpectAnswerMessagesAction(true));
    try {
        const {data} = yield call<any>(API, {
            url: '/ticket/loadMessages',
            method: 'POST',
            data: {
                fileKey: payload
            }
        });
        const messages = get(data, 'response', null);
        yield all([
            put(updateMessagesAction(messages)),
            put(updateExpectAnswerMessagesAction(false))]);
    } catch (e) {
        console.error('loadMessagesDataSaga error: ', e.response, e);
        yield put(updateExpectAnswerMessagesAction(false));
    }
}

function* sendMessageSaga({payload}: ReturnType<typeof sendMessageAction>) {
    const formData = new FormData();
    formData.append('file', payload.file);
    formData.append('text', payload.text);
    formData.append('dtc', payload.dtc);
    formData.append('fileKey', payload.fileKey);

    yield put(updateExpectAnswerSendMessageAction(true));
    try {
        yield call<any>(API_FORM_DATA, {
            url: '/ticket/sendMessage',
            method: 'POST',
            data: formData
        });
        yield all([
            put(updateExpectAnswerSendMessageAction(false)),
            put(loadMessagesAction(payload.fileKey))]);
    } catch (e) {
        console.error('sendMessageSaga error: ', e.response, e);
        yield put(updateExpectAnswerSendMessageAction(false));
    }
}


function* activityTasks() {
    yield takeLatest(TYPE.LOAD_FILE_DATA, loadFileDataSaga);
    yield takeLatest(TYPE.SEND_MESSAGE, sendMessageSaga);
    yield takeLatest(TYPE.LOAD_MESSAGES, loadMessagesSaga);
}

export function* sagas() {
    yield fork(activityTasks);
}

export default sagas;