

//types
export const TYPE = ((prefix) => ({
    PREFIX: new RegExp(prefix, 'i'),
    // complex actions
    CLEAR: `${prefix}CLEAR`,
    DATA: `${prefix}DATA`,
    LOAD_INITIAL_DATA: `${prefix}LOAD_INITIAL_DATA`,
    REMOVE_ED_FILE: `{prefix}REMOVE_ED_FILE`,
    LOAD_ED_FILE: `${prefix}LOAD_ED_FILE`
    // loading actions

}))('@ed-file-view/');


export const selector = (state: any) => ({ ...state.edFileView, user:state.app.user });


interface IUserState {
    expectAnswer: boolean;
    fileInfo: any;
    edFileInfo: any;
}

export function typedAction(type: string, payload?: any) {
    return { type, payload };
}

export const updateExpectAnswerAction = (expectAnswer: boolean) => typedAction(TYPE.DATA, { expectAnswer });
export const updateUploadedExpectAnswerAction = (uploadedExpectAnswer: boolean) => typedAction(TYPE.DATA, { uploadedExpectAnswer });
export const loadInitialDataAction = (key: string) => typedAction(TYPE.LOAD_INITIAL_DATA, key);
export const setInitialDataAction = (fileInfo: any) => typedAction(TYPE.DATA, fileInfo);
export const loadEdFileAction = (fileForm: any) => typedAction(TYPE.LOAD_ED_FILE, fileForm);
export const setEdFileInfoAction = (fileInfo: any) => typedAction(TYPE.DATA, fileInfo);
export const removeEdFileInfoAction = () => typedAction(TYPE.REMOVE_ED_FILE);


type UserAction = ReturnType<
    | typeof updateExpectAnswerAction
    | typeof updateUploadedExpectAnswerAction
    //
    | typeof loadInitialDataAction
    | typeof loadEdFileAction
    | typeof removeEdFileInfoAction
    >;

const initialState: IUserState = {
    expectAnswer: false,
    fileInfo: null,
    edFileInfo: null,
};

export function edFileView(state = initialState, action: UserAction): IUserState {
    switch (action.type) {
        default:
            return state;
        case TYPE.DATA:
            return { ...state, ...action.payload };
        case TYPE.CLEAR:
            return initialState;
        case TYPE.REMOVE_ED_FILE:
            return {...state, edFileInfo: null}
    }
}
