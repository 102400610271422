import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import {
    loadInitialDataAction, setInitialDataAction,
    TYPE,
    updateExpectAnswerAction
} from 'private-layout/encrypt-decrypt/file-list/reducer'
import API from "services/request.service";
import get from "lodash/get";


function* loadInitialDataSaga({payload}: ReturnType<typeof loadInitialDataAction>) {
    yield put(updateExpectAnswerAction(true));

    try {
        const {data} = yield call<any>(API, {
            url: '/client/encode-decode/file/file-list',
            method: 'get',
        });

        const initialData = get(data, 'response', null);

        yield all([
            put(setInitialDataAction(initialData)),
            put(updateExpectAnswerAction(false))
        ]);
    } catch ({message}) {
        console.error('loadInitialData error: ', message);
        yield put(updateExpectAnswerAction(false));
    }

}

function* activityTasks() {
    yield takeLatest(TYPE.LOAD_INITIAL_DATA, loadInitialDataSaga);
}

export function* sagas() {
    yield fork(activityTasks);
}

export default sagas;