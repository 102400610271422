// outsource dependencies
import { fork, takeLatest, put, call, delay, all } from 'redux-saga/effects';
import get from 'lodash/get';
// local dependencies
import { APP_TYPES, updateAppDataAction, updateAppExpectAnswerAction } from 'reducers';
import { historyPush } from 'store';
// sagas
import publicSagas from 'public-layout/sagas';
import privateSagas from 'private-layout/sagas';
// services
import API from 'services/request.service';
import cookieStorage from 'services/storage.service';
// constants
import { PUBLIC as PUBLIC_ROUTE } from 'constants/routes';
// utils
import { getLocalizedPath } from 'utils/routing.utils';

function* appInitializeSaga() {
  try {
    const { data } = yield call<any>(API, {
      url: '/init',
      method: 'GET',
    });
    const email = get(data, 'response.email', null);
    const username = get(data, 'response.username', null);
    const isMms = get(data, 'response.isMms', null);
    if (Boolean(email) && Boolean(username)) {
      yield put(updateAppDataAction({ initialized: true, user: { email, username, isMms } }));
    } else {
      yield put(updateAppDataAction({ initialized: true }));
    }
  } catch ({ message }) {
    console.error('appInitializeSaga error: ', message);
    yield put(updateAppDataAction({ initialized: true }));
  }
}

function* logOutSaga() {
  cookieStorage.removeItem('connect.sid');
  yield all([put(updateAppDataAction({ user: null })), put(updateAppExpectAnswerAction(true))]);
  yield call<any>(API, {
    url: '/client/logout',
    method: 'POST',
  });
  historyPush(getLocalizedPath(PUBLIC_ROUTE.HOME));
  window.location.reload();
  yield put(updateAppExpectAnswerAction(false));
}

function* navigationSaga() {
  yield put(updateAppExpectAnswerAction(true));
  yield delay(300); // ms
  yield put(updateAppExpectAnswerAction(false));
}

function* appSagas() {
  yield takeLatest(APP_TYPES.INITIALIZE, appInitializeSaga);
  yield takeLatest(APP_TYPES.LOG_OUT, logOutSaga);
  yield takeLatest(APP_TYPES.NAVIGATE, navigationSaga);
}

export function* sagas() {
  yield fork(appSagas);
  yield fork(publicSagas);
  yield fork(privateSagas);
}

export default sagas;
