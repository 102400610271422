import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles, Theme} from "@material-ui/core/styles";
import Layout from 'components/layout/Common';
import {Button, Typography} from "@material-ui/core";
import {connect} from "react-redux";

import {
    loadFileDataAction,
    selector as ticketSelector,
    sendMessageAction,
    loadMessagesAction,
} from 'private-layout/ticket/reducer';
import {useParams} from "react-router";
import Preloader from "../../components/Preloader";
import {APP_COLOR, CONSTRAINT} from "../../constants/style";
import CustomTextField from "../../components/input/TextField";

const useStyles = makeStyles((theme: Theme) => ({
    ticketContainer: {
        display: "flex",
        flexDirection: "column",
    },
    fileInfoContainer: {
        display: "grid",
        gridTemplateColumns: "min-content auto",
        gridColumnGap: "50px",
        marginLeft: "50px",
        marginTop: "20px",
        marginBottom: "20px",
        whiteSpace: "nowrap",
    },
    messageContainer: {
        marginLeft: "50px",
        marginBottom: "20px",
        paddingBottom: "20px"
    },
    messageHeader: {
        marginTop: "20px",
        display: "flex",
        justifyContent: "space-between",
    },
    messageHeaderComponent: {
        display: "grid",
        gridTemplateColumns: "min-content auto",
        gridColumnGap: "20px",
        marginBottom: "10px",
        color: '#aaaaaa'
    },
    messageHeaderComponentLink: {
        fontWeight: 'bold',
        fontSize: 12
    },
    messageHeaderComponentSpan: {
        fontSize: 12
    },
    message: {
        marginLeft: "20px",
    },
    dtcContainer: {
        display: "grid",
        gridTemplateColumns: "min-content auto",
        gridColumnGap: "20px",
        marginTop: "10px",
        marginLeft: "20px",
        color: "#5a5a5a",
        fontSize: '12px'
    },
    attachmentContainer: {
        display: "none",
        gridTemplateColumns: "min-content auto",
        gridColumnGap: "20px",
        color: "#5a5a5a",
        fontSize: '12px',
        marginLeft: '20px'
    },
    inputContainer: {
        marginLeft: "50px",
    },
    textInputField: {
        minWidth: "100%",
    },
    dtcInputField: {
        minWidth: "100%",
    },
    textLabel: {
        marginTop: "20px",
        marginBottom: "10px",
    },
    dtcLabel: {
        marginTop: "20px",
        marginBottom: "10px",
    },
    fileHeader: {
        marginTop: "20px",
        display: "none",
        gridTemplateColumns: "min-content auto",
        gridColumnGap: "20px",
    },
    fileContent: {
        marginTop: "20px",
        display: "none",
        gridTemplateColumns: "min-content auto",
        gridColumnGap: "20px",
    },
    selectedFileLabel: {
        whiteSpace: "nowrap",
    },
    openFileBtn: {
        maxWidth: "100px"
    },
    sendBtnContainer: {
        display: "flex",
        justifyContent: "end",
    },
    sendBtn: {
        width: "200px",
        color: '#000000',
        backgroundColor: "gray",
    },
    preloaderContainer: {
        marginLeft: "20px",
        marginRight: "20px",
    },
    uploadFile: {
        marginTop: 16,
        minWidth: 150,
    },
    hideUploader: {
        visibility: 'hidden',
        position: 'absolute',
    },
    uploaderBtn: {
        backgroundColor: theme.palette.primary.light,
        padding: 8,
        cursor: 'pointer',
        width: 'max-content',
        minWidth: CONSTRAINT.BUTTON_WIDTH,
        textAlign: 'center',
        color: APP_COLOR.WHITE,
        borderRadius: 4,
        marginRight: 8,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    uploader: {
        display: 'flex',
        alignItems: 'center',
    },
    alert: {
        marginTop: '20px',
        marginBottom: '20px',
        padding: '20px',
        border: '1px solid #FD9C00',
        width: 'auto',
        display: 'flex',
        backgroundColor: '#FDF4CD',
        borderRadius: '5px',
    }
}));

interface IProps {
    pageTitle: string;
    language: string;
    fileData?: any;
    messages?: any;

    loadFileData?: (fileKey: string | null) => void;
    sendMessage?: (fileData: any) => void;
    loadMessages?: (fileKey: string | null) => void;


    expectAnswerFileData?: boolean,
    expectAnswerMessages?: boolean,
    expectAnswerSendMessage?: boolean,
}


function Ticket(props: IProps) {
    const {key} = useParams();
    const {
        pageTitle,
        language,
        loadFileData,
        fileData,
        sendMessage,
        loadMessages,
        messages,
        expectAnswerFileData,
        expectAnswerMessages,
        expectAnswerSendMessage,
    } = props;
    const classes = useStyles();
    const {t} = useTranslation();

    const [text, setText] = useState<string>("");
    const [dtc, setDtc] = useState<string>("");
    const [file, setFile] = useState<any>(null);
    const [errorDtc, setErrorDtc] = useState<boolean>(false);

    useEffect(() => {
        loadFileData && loadFileData(key);
        loadMessages && loadMessages(key);
    }, [loadFileData, loadMessages, key]);

    const textOnChange = (event: any) => {
        setText(event.target.value);
    }

    const dtcOnChange = (event: any) => {
        const valid = validateDtc(event.target.value);
        if (!valid) {
            setErrorDtc(true);
        } else {
            setErrorDtc(false);
        }

        setDtc(event.target.value);
    }

    const fileOnChange = (event: any) => {
        setFile(event.target.files[0])
    }

    const sendMessageHandle = () => {
        sendMessage && sendMessage({
            text: text,
            dtc: dtc,
            file: file,
            fileKey: key,
        })

        clearInputs();
    }

    const clearInputs = () => {
        setText("");
        setDtc("");
        setFile(null);
    }

    const validateDtc = (dtc: string) => {
        if (dtc.length === 0) {
            return true;
        }
        const reg = new RegExp("^[0-9a-fA-F]{4}");

        const dtcArr = dtc.split(",");

        for (let i = 0; i < dtcArr.length; i++) {
            if (dtcArr[i].length !== 4) {
                return false;
            }

            const valid = reg.test(dtcArr[i]);
            if (!valid) {
                return false;
            }
        }
        return true;
    }

    return (
        <Layout pageTitle={pageTitle} language={language}>
            <Typography variant="h5">
                {t('ticket.ticket')}
                <span className={classes.preloaderContainer}>
                    {expectAnswerFileData ? <Preloader size={20}/> : null}
                </span>
            </Typography>
            {fileData &&
                <div className={classes.fileInfoContainer}>
                    <Typography>
                        <b>{t('ticket.file')}</b>
                    </Typography>
                    <Typography>
                        {fileData?.key}
                    </Typography>
                    <Typography>
                        <b>{t('ticket.date')}</b>
                    </Typography>
                    <Typography>
                        {new Date((fileData?.request_date + ' UTC').replace(/-/g, "/")).toLocaleString()}
                    </Typography>
                    <Typography>
                        <b>{t('ticket.name')}</b>
                    </Typography>
                    <Typography>
                        {fileData?.name}
                    </Typography>
                </div>}

            <span className={classes.preloaderContainer}>
                {expectAnswerMessages ? <Preloader size={20}/> : null}
            </span>

            {messages && Array.isArray(messages) && messages.length > 0 &&
              <>
                  <Typography variant="h5">
                      {t('ticket.messages')}
                  </Typography>
                  {messages.map((message, index) => {
                      return (
                        <div key={index} className={classes.messageContainer}>
                            <div className={classes.messageHeader}>
                                <div className={classes.messageHeaderComponent}>
                                    <label className={classes.messageHeaderComponentLink}>
                                        {t('ticket.date')}
                                    </label>
                                    <span className={classes.messageHeaderComponentSpan}>
                                {new Date((message.add_date + ' UTC').replace(/-/g, "/")).toLocaleString()}
                            </span>
                                </div>
                                <div className={classes.messageHeaderComponent}>
                                    <label className={classes.messageHeaderComponentLink}>
                                        {message.clientusername ? t('ticket.client') : t('ticket.admin')}
                                    </label>
                                    <label className={classes.messageHeaderComponentSpan}>
                                        {message.clientusername ? message.clientusername : ''}
                                    </label>
                                </div>
                            </div>
                            <div className={classes.message}>
                                {message.message}
                            </div>
                            {message.dtc && (
                              <div className={classes.dtcContainer}>
                                  <label>
                                      <b>DTC:</b>
                                  </label>
                                  <label>
                                      {message.dtc}
                                  </label>
                              </div>
                            )}
                            <div className={classes.attachmentContainer}>
                                <label>
                                    <b> {t('ticket.attachment')} </b>
                                </label>
                                <label>
                                    <a href="//google.com">
                                        test.bin
                                    </a>
                                </label>
                            </div>
                        </div>
                      )
                  })}
              </>
            }

            {fileData && fileData.status !== '99' &&
              <>
                  <Typography variant="h5">
                      {t('ticket.newMessage')}
                  </Typography>
                  <div className={classes.inputContainer}>
                      <Typography className={classes.textLabel}>
                          {t('ticket.text')}
                      </Typography>
                      <CustomTextField value={text}
                                       onChange={textOnChange}
                                       className={classes.textInputField}
                                       placeholder={t('input.placeholder.comment')}
                                       multiline={true}/>

                      <CustomTextField helperText={t("add_file.ticketDTCHint")}
                                       error={errorDtc}
                                       onChange={dtcOnChange}
                                       label={t('input.label.dtc')}
                                       placeholder={t('input.placeholder.dtc')}
                                       value={dtc}/>

                      <div className={classes.fileHeader}>
                          <Typography>
                              {t('ticket.file')}
                          </Typography>

                          <div className={classes.uploader}>
                              <label className={classes.uploaderBtn}>
                                  {t('button_title.choose_file')}
                                  <input type='file' className={classes.hideUploader} onChange={fileOnChange}/>
                              </label>
                          </div>
                      </div>
                      <div className={classes.fileContent}>
                          <label className={classes.selectedFileLabel}>
                              <b>{file != null ? t('ticket.selectedFile') : null}</b>
                          </label>
                          <label>
                              {file?.name}
                          </label>
                      </div>
                  </div>
                  <div className={classes.sendBtnContainer}>
                        <span className={classes.preloaderContainer}>
                            {expectAnswerSendMessage ? <Preloader size={40}/> : null}
                        </span>
                      <Button disabled={errorDtc || text.trim().length === 0}
                              onClick={sendMessageHandle}
                              className={classes.uploadFile} variant='contained' color='primary'>
                          {t('ticket.send')}
                      </Button>
                  </div>
              </>
            }
            {fileData && fileData.status === '99' &&
              <span className={classes.alert}>
                {t('ticket.task_closed_text')}
              </span>
            }


        </Layout>
    )
}

export default connect(
    (state) => ticketSelector(state),
    (dispatch) => ({
        loadFileData: (fileKey: string | null) => dispatch(loadFileDataAction(fileKey)),
        sendMessage: (message: any) => dispatch(sendMessageAction(message)),
        loadMessages: (fileKey: string | null) => dispatch(loadMessagesAction(fileKey)),
    })
)(Ticket);