// outsource dependencies
import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
// UI
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown'
// local dependencies
// UI
import Layout from 'components/layout/Common';
// constants
import { CONSTRAINT } from 'constants/style';
import currentConfig from "../../utils/config.utils";
interface IStyle {
  isMobile: boolean;
}
const useStyles = makeStyles({
  container: {
    padding: (props: IStyle) => (props.isMobile ? `0 ${CONSTRAINT.MOBILE_INDENT}px` : 0),
    overflow: (props: IStyle) => (props.isMobile ? 'hidden' : 'visible'),
  },
  textHtml: {
    overflow: (props: IStyle) => (props.isMobile ? 'scroll' : 'auto'),
  },
  preStyle: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif"
  }
});
interface IProps {
  pageTitle: string;
  language: string;
}

export default function Contacts(props: IProps) {
  const { pageTitle, language } = props;
  const classes = useStyles({ isMobile });
  const { t } = useTranslation();
  const [content, setContent] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {
    getContacts();
  }, [language]);


  const getContacts = () => {
    setLoading(true);
    fetch(currentConfig().apiUrl + 'page/contacts')
      .then(response => response.json())
      .then(({result, response}) => {
        if (result === 'success') {
          setContent(response.page.content);
          setLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  }

  return (
    <Layout pageTitle={pageTitle} language={language}>
      <div className={classes.container}>
        <Typography variant='h5'>{t('public_page.contacts.title')}</Typography>
        <br />

        {loading && <div>Loading...</div>}
        {!loading && <ReactMarkdown children={content} />}

        {/*{i18n.language === 'ua' &&*/}
        {/*  <>*/}
        {/*    Если вы еще не являетесь нашим клиентом и у Вас возникли вопросы  - пожалуйста напишите нам*/}
        {/*    <br/><a href="mailto:contacts@motorcar-tuning.shop">contacts@motorcar-tuning.shop</a><br/><br/>*/}
        {/*    Время работы файл сервиса:<br/>*/}
        {/*    <pre className={classes.preStyle}>ПН-ПТ с   9.00 до 18.00 (среднее время обработки файла - 30 мин) <br/>*/}
        {/*    ПН-ПТ с 18.00 до 23.00 (среднее время обработки файла - 1.5 ч)<br/>*/}
        {/*    СБ       c 10.00 до 23.00 (среднее время обработки файла до 2 ч)<br/>*/}
        {/*    ВС       выходной<br/><br/>*/}
        {/*    Файлы, обрабатываемые автоматически, доступны для скачивания круглосуточно, сразу после оплаты.<br/><br/>*/}
        {/*    Часовой пояс (киевское время)</pre>*/}
        {/*  </>*/}
        {/*}*/}
        {/*{i18n.language === 'en' &&*/}
        {/*  <>*/}
        {/*    If you want to become our client or partner please contact us:<br/>*/}
        {/*    <a href="mailto:contacts@motorcar-tuning.shop">contacts@motorcar-tuning.shop</a><br/><br/>*/}
        {/*    Open hours:<br/>*/}
        {/*    <pre className={classes.preStyle}>Mon-Fri    9.00 - 18.00 average file processing time 30 min<br/>*/}
        {/*    Mon-Fri  18.00 - 23.00 average file processing time 1.5 hours<br/>*/}
        {/*    Sat-Sun        10.00 - 23.00 average file processing time 2 hours<br/><br/>*/}
        {/*    TimeZone: EET</pre>*/}
        {/*  </>*/}
        {/*}*/}

      </div>
    </Layout>
  );
}
