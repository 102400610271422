// outsource dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';
// UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
// constants
import { APP_COLOR } from 'constants/style';

const useStyles = makeStyles({
  paperFullWidth: {
    width: 500,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 8px 8px 24px',
    backgroundColor: APP_COLOR.GREY,
    color: APP_COLOR.WHITE,
  },
  close: {
    padding: 6,
    color: APP_COLOR.WHITE,
    cursor: 'pointer',
  },
  titleContent: {
    lineHeight: 2,
  },
  content: {
    display: 'flex',
    padding: 12,
    flexDirection: 'column'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16,
  },
  continueAction: {
    backgroundColor: APP_COLOR.LIGHT_GREY,
    color: APP_COLOR.WHITE,
    '&:hover': {
      color: APP_COLOR.BLACK,
    },
    transition: '0.6s',
  },
});

interface IProps {
  open: boolean;
  handleClose: () => void;
  errorStatus?: number;
  errorKey?: string;
}

function ErrorDialog(props: IProps) {
  const { open, handleClose, errorStatus, errorKey } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const getLocalizedErrorMessage = () => {
      if (errorStatus && errorKey) {
        const errorMessage = t(`dialog.error_map.${errorKey}`);
        if (errorMessage === `dialog.error_map.${errorKey}`) {
          return t('dialog.content.server_error');
        } else {
          return errorMessage;
        }
      }
      return t('dialog.content.server_error');
  }

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paperFullWidth: classes.paperFullWidth }} fullWidth>
      <DialogTitle disableTypography classes={{ root: classes.title }}>
        <Typography variant='h6' className={classes.titleContent}>
          {t('dialog.title.server_error')}
        </Typography>
        <CloseIcon onClick={handleClose} className={classes.close} />
      </DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          <Typography variant='h6'>{getLocalizedErrorMessage()}</Typography>
          <div className={classes.actions}>
            <Button onClick={handleClose} className={classes.continueAction}>
              {t('button_title.continue')}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ErrorDialog;
