// outsource dependencies
import axios from 'axios';
// local dependencies
import localConf from '../environment/local';
import devConf from '../environment/development';
import conf from '../environment/production';

let currentConf: any = localConf;
if (process.env.REACT_APP_ENV === localConf.appEnv) {
  currentConf = localConf;
} else if (process.env.REACT_APP_ENV === devConf.appEnv) {
  currentConf = devConf;
} else if (process.env.REACT_APP_ENV === conf.appEnv) {
  currentConf = conf;
}
// console.log(process.env, currentConf);
const API = axios.create({
  baseURL: currentConf.apiUrl,
  withCredentials: true,
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
  },
});

export const API_FORM_DATA = axios.create({
  baseURL: currentConf.apiUrl,
  withCredentials: true,
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'multipart/form-data',
  },
});

export default API;
