// outsource dependencies
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
// UI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LanguageIcon from '@material-ui/icons/Language';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// local dependencies
// service
import CookieStorage from 'services/storage.service';
// utils
import { localizedPath } from 'utils/routing.utils';
// constants
import { APP_COLOR } from 'constants/style';
import LanguageList from 'constants/language';

const useStyles = makeStyles({
  menuOpen: {
    color: APP_COLOR.WHITE,
  },
});
interface IProps {
  updateAppLanguage?: (language: string | null) => void;
}

export default function Language(props: IProps) {
  const { updateAppLanguage } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [t, i18n] = useTranslation();
  const history = useHistory();
  const currentLocation = useLocation();
  // cookies in url represents language. We need to get clear pathname for correct navigating to page with chose locale
  const pathWithoutLanguage = currentLocation.pathname.replace(`${CookieStorage.getItem('locale')}/`, '');

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLanguage = (lng: string) => () => {
    i18n.changeLanguage(lng);
    updateAppLanguage && updateAppLanguage(lng);
    CookieStorage.setItem('locale', lng);
    history.push(localizedPath(lng, pathWithoutLanguage));
    // window.location.reload();
  };

  return (
    <>
      <Button onClick={handleMenuOpen} className={classes.menuOpen}>
        <LanguageIcon />
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
        {LanguageList.map((lang, index) => (
          <MenuItem key={index} onClick={handleLanguage(lang.language)}>
            {t(lang.textKey)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
