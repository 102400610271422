// outsource dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';
// UI
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
// local dependencies
// UI
import Layout from 'components/layout/Common';

const useStyles = makeStyles({
  title: {
    fontWeight: 600,
    fontSize: 20,
  },
  center: {
    textAlign: 'center',
  },
  text: {
    textIndent: 20,
  },
  italic: {
    fontStyle: 'italic',
  },
});

interface IProps {
  pageTitle: string;
  language: string;
}

export default function PrivacyPolicy(props: IProps) {
  const { pageTitle, language } = props;
  const { i18n } = useTranslation();
  const classes = useStyles();
  return (
    <Layout pageTitle={pageTitle} language={language}>
      {i18n.language === 'ua' && (
        <>
          <Typography className={classNames(classes.title, classes.center)}>Політика конфіденційності</Typography>
          <Typography className={classes.title}>Набирає чинності з 01 січня 2020 року</Typography>
          <Typography className={classes.title}>Преамбула</Typography>
          <Typography className={classes.text}>
            Ця Політика конфіденційності є невід'ємною частиною Угоди користувача, опублікованої на Сайті. Визначення, що використовуються у цій Політиці, відповідають визначенням, що використовуються у Угоді користувача.
          </Typography>
          <Typography className={classes.text}>
            Політика конфіденційності (далі «Політика») застосовується до: інформації в базі даних motorcar tuning; будь-які послуги, пропоновані motorcar tuning (далі іменовані як «послуги»); до доступу та використання Сайту; будь-які інші взаємодії, які Користувач може мати при використанні Сайту.
          </Typography>
          <Typography className={classes.text}>
            Використання Користувачем Веб-сайту та/або реєстрація для використання Послуг означає повну та беззастережну згоду Користувача з умовами цієї Політики; у випадку, якщо Користувач не погоджується з цими умовами, Користувач не може використовувати Сайт та Послуги motorcar tuning.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>1.Персональна інформація - Загальні положення</Typography>
          <Typography className={classes.text}>
            <strong>1.1.</strong> motorcar tuning збирає кілька типів персональної інформації через використання Сайту, Послуг та пов'язаних взаємодій з Користувачами:
          </Typography>
          <Typography className={classes.text}>
            <strong>1.1.1.</strong> Персональні дані – це інформація, яка може бути використана для ідентифікації користувачів, така як ім'я, поштова адреса, номер телефону, адреси електронної пошти та інша інформація, зібрана під час реєстрації Користувача та використання Послуг; при цьому всі особисті дані не перевіряються motorcar tuning і вважаються повними та достовірними. У разі виявлення помилкової та/або недостатньої інформації motorcar tuning залишає за собою право заблокувати/видалити обліковий запис Користувача відповідно до Угоди користувача.
          </Typography>
          <Typography className={classes.text}>
            <strong>1.1.2.</strong> Користувальницька інформація - це інформація, отримана від Користувача, який використовує Сайт / Послуги, але не може використовуватися для індивідуальної ідентифікації та включає в себе в тому числі, але не виключно, веб-сторінки, які відвідав Користувач, версію операційної системи та браузера, а також IP-адресу. У тій мірі, коли такі дані пов'язані з Персональними даними, об'єднані дані розглядаються як Персональні дані відповідно до цієї Політики конфіденційності. Користувальницька інформація використовується motorcar tuning для аналізу тенденцій використання та розуміння того, як використовуються Сайт та Послуги, а також може використовуватись для усунення проблем з обслуговуванням чи дослідження питань безпеки. Користувальницька інформація в короткій формі також може бути публічно надана інтернет-спільноті з освітньою або дослідною метою.
          </Typography>
          <Typography className={classes.text}>
            <strong>1.2.</strong> Деякі сторінки сайту можуть містити посилання на зовнішні веб-сайти, що належать або керовані третіми сторонами. motorcar tuning не несе відповідальності за контент, інформацію, роботу чи діяльність цих веб-сайтів. motorcar tuning рекомендує Користувачам уважно ознайомитися з Політикою конфіденційності будь-яких сторонніх веб-сайтів.
          </Typography>
          <Typography className={classes.text}>
            <strong>1.3.</strong> Особиста інформація збирається та зберігається відповідно до внутрішніх правил для певних Послуг Сайту.
          </Typography>
          <Typography className={classes.text}>
            <strong>1.4.</strong> Усі види особистої інформації вважаються суворо конфіденційними та можуть бути передані третім особам лише у випадках, передбачених пунктом 3 цієї Політики.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>2. Принципи збирання особистої інформації</Typography>
          <Typography className={classes.text}>
            <strong>2.1.</strong> Особиста інформація збирається motorcar tuning в обсязі, необхідному для надання Послуг, та/або в тих випадках, коли така інформація потрібна законодавством.
          </Typography>
          <Typography className={classes.text}>
            <strong>2.2.</strong> Принципи конфіденційності motorcar tuning щодо Персональних даних:
          </Typography>
          <ul>
            <li>отримувати персональні дані тільки для певних законних цілей та за згодою Користувача;</li>
            <li>зберігати особисті дані з належним захистом їхньої цілісності та конфіденційності;</li>
            <li>зберігати персональні дані стільки, скільки необхідно для цілей, для яких вони були отримані;</li>
            <li>докладати розумних зусиль для обробки запитів від користувачів на виправлення або видалення їх персональних даних, де це можливо.</li>
          </ul>
          <Typography className={classNames(classes.title, classes.center)}>3. Правила обробки персональних даних</Typography>
          <Typography className={classes.text}>
            <strong>3.1.</strong> Персональні дані можуть бути надані третім особам лише за дотримання таких положень:
          </Typography>
          <Typography className={classes.text}>
            <strong>3.1.1.</strong> Повна і явна згода Користувача на такі дії;
          </Typography>
          <Typography className={classes.text}>
            <strong>3.1.2.</strong> У разі повної чи часткової передачі корпоративних прав motorcar tuning покупцям у разі продажу чи іншої передачі бізнесу;
          </Typography>
          <Typography className={classes.text}>
            <strong>3.1.3.</strong>У разі, коли така передача даних необхідна для надання певної Послуги на Сайті Користувачеві.
          </Typography>
          <Typography className={classes.text}>
            <strong>3.2.</strong> Інформація про використання використовується лише як анонімні статистичні дані, створені в результаті обробки персональних даних Користувача, які надаються третім особам для проведення дослідницької діяльності та/або надання послуг відповідно до вимог motorcar tuning.
          </Typography>
          <Typography className={classes.text}>
            <strong>3.3.</strong> motorcar tuning дотримується розумних заходів безпеки для захисту даних, що передаються на Сайт через Інтернет, та запобігання несанкціонованому доступу, зміни або видалення всіх зібраних даних.
          </Typography>
          <Typography className={classes.text}>
            <strong>3.4.</strong> motorcar tuning зберігає Персональні дані та Користувальницькі дані протягом часу, необхідного ARIN для переслідування законних ділових інтересів, проведення аудитів, дотримання загальних методів зберігання ділової інформації, дотримання юридичних зобов'язань, вирішення спорів та забезпечення дотримання Угоди Користувача.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>4. Заходи захисту Персональних даних Користувачів</Typography>
          <Typography className={classes.text}>
            <strong>4.1.</strong> motorcar tuning захищає особисту інформацію, вживаючи необхідних та достатніх організаційних та технічних заходів:
          </Typography>
          <Typography className={classes.text}>
            <strong>4.1.1.</strong> Обмеження доступу третіх осіб до будь-якої особистої інформації;
          </Typography>
          <Typography className={classes.text}>
            <strong>4.1.2.</strong> Підтримка відповідного програмного забезпечення та технологій на Сайті для запобігання несанкціонованому доступу до серверів motorcar tuning;
          </Typography>
          <Typography className={classes.text}>
            <strong>4.1.3.</strong> Безпечне знищення особистої інформації Користувачів у разі коли потреба у її подальшому зберіганні технічно та/або юридично необґрунтована.
          </Typography>
          <Typography className={classes.text}>
            <strong>4.2</strong> motorcar tuning використовує технологію шифрування SSL (Secure Socket Layer) при обробці всієї особистої інформації Користувача, щоб забезпечити її безпеку.
          </Typography>
          <Typography className={classes.text}>
            <strong>4.3</strong> Якщо Користувач вважає, що його особиста інформація на Сайті була зламана або скомпрометована, йому слід негайно звернутися до motorcar tuning через електронну пошту за вказаними Контактами.
          </Typography>
          <Typography className={classes.text}>
            <strong>4.4</strong> Пароль Пользователя является ключом к Учетной записи. motorcar tuning рекомендует Пользователям использовать уникальные числа,
            буквы и специальные символы. Пользователь соглашается не распространять данные доступа к Сайты третьим лицам. Исключительно Пользователь несет
            ответственность за все действия, предпринятые от имени его Учетной записи.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>5. Виправлення та видалення особистої інформації</Typography>
          <Typography className={classes.text}>
            <strong>5.1.</strong> Користувач має право на доступ та перегляд будь-якої особистої інформації, зібраної та збереженої від його/його імені.
          </Typography>
          <Typography className={classes.text}>
            <strong>5.2.</strong> Користувач несе відповідальність за своєчасне інформування motorcar tuning про зміни в його особистій інформації. motorcar tuning має право запросити зміну неточної чи неповної особистої інформації шляхом надсилання запиту електронною поштою. При цьому motorcar tuning перевіряє всі запити перед виправленням особистої інформації. Після перевірки motorcar tuning вносить виправлення або оновлення, як тільки це стане можливим.
          </Typography>
          <Typography className={classes.text}>
            <strong>5.3.</strong> Для перевірки особистої інформації Користувача motorcar tuning може вимагати від Користувача документів, що підтверджують.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>6. Файли cookie </Typography>
          <Typography className={classes.text}>
            <strong>6.1.</strong> Файли cookie - це невеликі текстові файли, що створюються браузером користувача та зберігаються на комп'ютері користувача для відстеження доступу. Зокрема, motorcar tuning використовує файли cookie для відстеження користувачів після того, як вони увійшли на сайт. motorcar tuning не передає інформацію, зібрану за допомогою цих файлів cookie третім особам і використовує її виключно для надання Послуг Користувачам, для цільової реклами, що відображається для Користувача, для статистичних та дослідницьких цілей та для покращення Послуг.
          </Typography>
          <Typography className={classes.text}>
            <strong>6.2.</strong> motorcar tuning залишає за собою право визначати структуру, зміст та технічні параметри будь-якого файлу cookie без попереднього повідомлення. Користувача.
          </Typography>
          <Typography className={classes.text}>
            <strong>6.3.</strong> motorcar tuning розміщує лічильники в Послугах для файлів cookie Користувача, для збирання та обробки статистичної інформації про використання motorcar tuning та для забезпечення продуктивності Послуг загалом та їх певних функцій зокрема. Технічні параметри роботи лічильника визначаються motorcar tuning і можуть бути змінені без попередження Користувача.
          </Typography>
          <Typography className={classes.text}>
            <strong>6.4.</strong> Користувач може вимкнути файли cookie під час перегляду Сайту, але це може призвести до втрати функціональності, обмеження використання Сайту та/або затримки отримання Послуг.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>7. Заключні положення</Typography>
          <Typography className={classes.text}>
            <strong>7.1.</strong> motorcar tuning залишає за собою право вносити зміни до цієї Політики конфіденційності. При цьому motorcar tuning зобов'язується публікувати оновлену Політику конфіденційності на цій сторінці з оновленням дати набрання чинності вище. Якщо motorcar tuning вносить суттєві зміни, які можуть вплинути на використання Послуг, Користувачі можуть повідомити про зміни шляхом оголошення на Сайті. Продовжуючи використовувати Послуги після публікації змін, Користувач підтверджує свою згоду з Політикою конфіденційності. Виправлено.
          </Typography>
          <Typography className={classes.text}>
            <strong>7.2.</strong> Користувач може надсилати запити або запитання щодо цієї Політики конфіденційності, надіславши електронного листа на адресу contacts@motorcar-tuning.shop
          </Typography>
        </>
      )}
      {i18n.language === 'en' && (
        <>
          <Typography className={classNames(classes.title, classes.center)}>Privacy Policy for motorcar tuning service</Typography>
          <Typography className={classes.title}>Effective: 01 January, 2020</Typography>
          <Typography className={classes.title}>Preamble</Typography>
          <Typography className={classes.text}>
            This Privacy Policy is an initial part of Terms of Use published on the Website; the definitions used in this Policy are corresponded with
            definitions used in the Terms of Use.
          </Typography>
          <Typography className={classes.text}>
            Privacy Policy (hereinafter referred to as the “Policy”) applies to information in motorcar tuning’s registration database, any services offered by
            motorcar tuning (hereinafter referred to as the “Services”), applies to the access and usage of the Website and any other interactions the User may
            have with motorcar tuning.
          </Typography>
          <Typography className={classes.text}>
            The User’s use of the Website and/or registration for Services constitutes the User’s consent to the terms of this Policy; in case the User does not
            agree with these conditions, the User cannot use the Services.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>1. Personal Information – Generals Provisions</Typography>
          <Typography className={classes.text}>
            <strong>1.1.</strong> motorcar tuning collects several types of personal information through the use of Website, Services and related interactions
            with the Users:
          </Typography>
          <Typography className={classes.text}>
            <strong>1.1.1.</strong> Personal Data is information that can be used to identify Users, such as their given name, postal address, telephone number,
            email addresses and other information collected by motorcar tuning during the User’s registration and using Services; wherein all personal data is
            not verified by motorcar tuning and is considered to be full and true. In case of finding false and / or insufficient information motorcar tuning
            reserves the right to block / delete the User account according to the Terms of Use.
          </Typography>
          <Typography className={classes.text}>
            <strong>1.1.2.</strong> Usage Information is information obtained when the User access the Website or use the Services, but alone cannot typically
            be used to identify an individual. This information includes, for example, the web pages the User has visited, operating system and browser version,
            and the Internet Protocol (IP) address accessing our systems. To the extent that such data is associated with Personal Data, the combined data is
            treated as Personal Data per this Privacy Policy. Usage Information is used by motorcar tuning to analyze usage trends and to help us understand how
            Website and Services are used, and may also be used to troubleshoot service issues or investigate security matters. Usage Information in summary
            form may also be shared publicly with the Internet community for educational or research purposes.
          </Typography>
          <Typography className={classes.text}>
            <strong>1.2.</strong> Certain pages of the Website may feature links to external websites owned or operated by third parties. Qpay will not be
            responsible for the content, information, operation or activities of those websites. Qpay strongly recommends that you cautiously read and
            understand any third party websites’ privacy policy before you share your personal information with those websites.
          </Typography>
          <Typography className={classes.text}>
            <strong>1.3.</strong> Personal information is collected and stored according to internal regulations on particular Services on the Website.
          </Typography>
          <Typography className={classes.text}>
            <strong>1.4.</strong> All types of personal information are considered as strictly confidential and could be provided to third parties only in cases
            stipulated in the paragraph 3 of this Policy.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>2. The Principles of Collection of Personal Information</Typography>
          <Typography className={classes.text}>
            <strong>2.1.</strong> Personal information is collected by motorcar tuning required for the provision of the Services and / or in cases where such
            information is required to be collected by legislation for prescribed period.
          </Typography>
          <Typography className={classes.text}>
            <strong>2.2.</strong> motorcar tuning Personal Data Privacy Principles:
          </Typography>
          <ul>
            <li>to obtain personal data only for specific lawful purposes and by consent of the User;</li>
            <li>to store personal data with appropriate protections for its integrity and confidentiality;</li>
            <li>to store personal data for as long as necessary for the purposes for which it was obtained;</li>
            <li>to use reasonable efforts to process requests from Users for correction or deletion of their personal data where feasible.</li>
          </ul>
          <Typography className={classNames(classes.title, classes.center)}>3. Rules of Processing Personal Information</Typography>
          <Typography className={classes.text}>
            <strong>3.1.</strong> Personal data can be provided to third parties only in compliance with the following statements:
          </Typography>
          <Typography className={classes.text}>
            <strong>3.1.1.</strong> The consent of the User for such actions is full and explicit;
          </Typography>
          <Typography className={classes.text}>
            <strong>3.1.2.</strong> The provisions is made in order to transfer of corporate shares fully or partly from motorcar tuning to the buyers in case
            of selling tot other transfer the business which is announced;
          </Typography>
          <Typography className={classes.text}>
            <strong>3.1.3.</strong> The provisions is made for rendering specific Service on the Website or performance of a particular agreement or contract
            with the User.
          </Typography>
          <Typography className={classes.text}>
            <strong>3.2.</strong> Usage information is used only as anonymized statistical data generated as a result of processing of the User personal
            information are provided to a third party to carry out research activities and / or render services as requested by motorcar tuning.
          </Typography>
          <Typography className={classes.text}>
            <strong>3.3.</strong> motorcar tuning maintain reasonable security measures to protect the data transmitted to Website via the Internet and to
            prevent unauthorized access to, alteration or deletion of all collected data.
          </Typography>
          <Typography className={classes.text}>
            <strong>3.4.</strong> motorcar tuning retains Personal Data and Usage Data for the time needed for ARIN to pursue legitimate business interests,
            conduct audits, follow common business information retention practices, comply with (and demonstrate compliance with) legal obligations, resolve
            disputes and enforce our agreements.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>4. User Personal Information Protection Measures</Typography>
          <Typography className={classes.text}>
            <strong>4.1.</strong> motorcar tuning protects personal information by taking necessary and sufficient organizational and technical measures:
          </Typography>
          <Typography className={classes.text}>
            <strong>4.1.1.</strong> restricting third party access to any personal information;
          </Typography>
          <Typography className={classes.text}>
            <strong>4.1.2.</strong> maintaining relevant software and technology within the Website to prevent unauthorized computer access;
          </Typography>
          <Typography className={classes.text}>
            <strong>4.1.3.</strong> securely destroying your personal information when it is no longer needed for any legal or business purpose.
          </Typography>
          <Typography className={classes.text}>
            <strong>4.2</strong> motorcar tuning uses SSL (secure socket layer) encryption technology when processing all User’s personal information to keep it
            safe and secure.
          </Typography>
          <Typography className={classes.text}>
            <strong>4.3</strong> If the User believes that his/her personal information on the Website has been breached or compromised, he / she should contact
            motorcar tuning immediately.
          </Typography>
          <Typography className={classes.text}>
            <strong>4.4</strong> The User’s password is the key to the account. motorcar tuning encourages the Users using unique numbers, letters and special
            characters. The User agrees not share Qpay password to anyone. If the User shares his / her password with others, only the User will be responsible
            for all actions taken in the name of the User account and you could also be subject to legally binding actions taken on the User behalf.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>5. Personal Information Correction and Deletion</Typography>
          <Typography className={classes.text}>
            <strong>5.1.</strong> The User has the right to access and review any personal information collected and retained from him/her.
          </Typography>
          <Typography className={classes.text}>
            <strong>5.2.</strong> The User is responsible for informing motorcar tuning about changes to his/her personal information from time to time and has
            the right to request for the amendment of inaccurate or incomplete personal information by submitting a request via email and requests. motorcar
            tuning will screen and verify all requests before effecting the corrections. Once verified, motorcar tuning will affect the corrections or updates
            as soon as commercially feasible.
          </Typography>
          <Typography className={classes.text}>
            <strong>5.3.</strong> In order to verify the User’s personal information, motorcar tuning may request the User to provide supporting documentation
            to corroborate the personal information provided.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>6. Cookies and Counters </Typography>
          <Typography className={classes.text}>
            <strong>6.1.</strong> Cookies are small text files created by the User’s browser and stored on the User’s computer for tracking your access.
            Specifically, motorcar tuning uses cookies to track authenticated Users once they have logged in to Website. motorcar tuning does not share the
            information collected via these cookies with others and use to provide customized Services to the User, to target advertising displayed to the User,
            for statistical and research purposes and to improve the Services.
          </Typography>
          <Typography className={classes.text}>
            <strong>6.2.</strong> motorcar tuning has its solely discretion to determine structure, content and technical parameters of any cookie and may
            change them without prior notice to the User.
          </Typography>
          <Typography className={classes.text}>
            <strong>6.3.</strong> motorcar tuning places counters within the Services to cookies of the User, to collect and process statistical information on
            the Service use, and to ensure the performance capacity of the Services, in general, and their certain functions, in particular. Technical
            parameters of counter performance shall be determined by motorcar tuningService and may be changed without prior notice to the User.
          </Typography>
          <Typography className={classes.text}>
            <strong>6.4.</strong> The User may turn off cookies while browsing the Website but this may result in the loss of functionality, restrict use of the
            Website and/or delay or affect the way in which it operates.
          </Typography>
          <Typography className={classNames(classes.title, classes.center)}>7. Final Provisions</Typography>
          <Typography className={classes.text}>
            <strong>7.1.</strong> motorcar tuning reserve the right to amend this Privacy Policy and privacy practices. motorcar tuning will post a revised
            Privacy Policy to this page and update the Effective Date above. If motorcar tuning makes material changes that would impact use of the Services,
            motorcar tuning will endeavor to notify the Users of the changes by announcement on our Website or by any other reasonable method. By continuing to
            use the Services after the changes have been posted, the User indicates acceptance of the Privacy Policy as revised.
          </Typography>
          <Typography className={classes.text}>
            <strong>7.2.</strong> The User can submit requests or questions about this Privacy Policy by sending an email to contacts@motorcar-tuning.shop
          </Typography>
        </>
      )}
    </Layout>
  );
}
