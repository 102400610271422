export const TYPE = ((prefix) => ({
    PREFIX: new RegExp(prefix, 'i'),
    // complex actions
    CLEAR: `${prefix}CLEAR`,
    DATA: `${prefix}DATA`,
    // triggers
    LOAD_FILE_DATA: `${prefix}LOAD_FILE_DATA`,
    LOAD_MESSAGES: `${prefix}LOAD_MESSAGES`,
    SEND_MESSAGE: `${prefix}SEND_MESSAGE`,
}))('@ticket/');

// selectors
export const selector = (state: any) => state.ticket;

export function typedAction(type: string, payload?: any) {
    return {type, payload};
}

interface IUserState {
    expectAnswerFileData: boolean,
    expectAnswerMessages: boolean;
    expectAnswerSendMessage: boolean,
    fileData: any;
    messages: any[] | null;

    text: string,
    dtc: string,
    file: any;
}

export const updateExpectAnswerFileDataAction = (expectAnswerFileData: boolean) => typedAction(TYPE.DATA, {expectAnswerFileData});
export const updateExpectAnswerMessagesAction = (expectAnswerMessages: boolean) => typedAction(TYPE.DATA, {expectAnswerMessages});
export const updateExpectAnswerSendMessageAction = (expectAnswerSendMessage: boolean) => typedAction(TYPE.DATA, {expectAnswerSendMessage});
export const updateFileDataAction = (fileData: any) => typedAction(TYPE.DATA, fileData);
export const updateMessagesAction = (messages: any[] | null) => typedAction(TYPE.DATA, messages);
export const loadFileDataAction = (fileKey: string | null) => typedAction(TYPE.LOAD_FILE_DATA, fileKey);
export const sendMessageAction = (message: any) => typedAction(TYPE.SEND_MESSAGE, message);
export const loadMessagesAction = (fileKey: string | null) => typedAction(TYPE.LOAD_MESSAGES, fileKey);

type UserAction = ReturnType<| typeof updateExpectAnswerFileDataAction
    | typeof updateExpectAnswerMessagesAction
    | typeof updateExpectAnswerSendMessageAction
    | typeof updateFileDataAction
    | typeof updateMessagesAction
    | typeof sendMessageAction
    | typeof loadMessagesAction>;

const initialState: IUserState = {
    expectAnswerFileData: false,
    expectAnswerMessages: false,
    expectAnswerSendMessage: false,
    fileData: null,
    messages: null,

    text: "",
    dtc: "",
    file: null
};


export function ticket(state = initialState, action: UserAction): IUserState {
    switch (action.type) {
        default:
            return state;
        case TYPE.DATA:
            return {...state, ...action.payload};
        case TYPE.CLEAR:
            return initialState;
    }
}