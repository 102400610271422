// outsource dependencies
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Field, reduxForm, InjectedFormProps } from 'redux-form';
import { useTranslation } from 'react-i18next';
// UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
// local dependencies
// UI
import CustomTextField from 'components/input/TextField';
// constants
import { APP_COLOR } from 'constants/style';
// state selector and actions
import {
  selector as loginSelector,
  updateEmailAction,
  updatePasswordAction,
  loginSubmitAction,
  resetErrorLoginAction,
  ILoginError,
  updateErrorLogInAction,
} from 'public-layout/log-in/reducer';
// services
import ValidationService from 'services/validation.service';

// configurations
export const FORM_NAME = 'loginForm';

const LOGIN_FIELD_KEY = {
  email: 'email',
  password: 'password'
};

const useStyles = makeStyles({
  paperFullWidth: {
    width: 500,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 8px 8px 24px',
    backgroundColor: APP_COLOR.GREY,
    color: APP_COLOR.WHITE,
  },
  close: {
    padding: 6,
    color: APP_COLOR.WHITE,
    cursor: 'pointer',
  },
  titleContent: {
    lineHeight: 2,
  },
  content: {
    display: 'flex',
    padding: '12px 30px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 16,
  },
  loginAction: {
    backgroundColor: APP_COLOR.LIGHT_GREY,
    color: APP_COLOR.WHITE,
    '&:hover': {
      color: APP_COLOR.BLACK,
    },
    transition: '0.6s',
  },
});

interface IProps {
  open: boolean;
  handleClose: () => void;
  handleForgotPasswordOpen: () => void;
  updateEmail?: (email: string) => void;
  updatePassword?: (password: string) => void;
  email?: string | null;
  password?: string | null;
  errors?: ILoginError | null;
  loginSubmit?: () => any;
  expectAnswer?: boolean;
  validate?: (loginFormData: ILoginForm) => ILoginFormError | null;
  resetError?: (fieldKey: string) => void;
  updateErrorLogIn?: (errors: ILoginError) => void;
  handleSubmit?: any; // redux
}

const LoginDialog: React.FC<IProps & InjectedFormProps<{}, IProps>> = (props: IProps) => {
  const {
    open,
    email,
    password,
    expectAnswer,
    errors,
    //
    handleClose,
    handleForgotPasswordOpen,
    updateEmail,
    updatePassword,
    loginSubmit,
    resetError,
    updateErrorLogIn,
    //
    validate,
    handleSubmit,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const isEmailError = Boolean(errors?.email);
  const isPasswordError = Boolean(errors?.password);
  const emailError = typeof errors?.email === 'string' ? errors.email : '';
  const passwordError = typeof errors?.password === 'string' ? errors.password : '';

  const isSubmitNotAvailable = errors && Boolean(Object.values(errors).filter((error) => Boolean(error)).length);

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (updateEmail) updateEmail(e.target.value);
  };
  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (updatePassword) updatePassword(e.target.value);
  };

  const handleOnFocus = (fieldKey: string) => () => {
    const isErrorInState = errors && Object.keys(errors).some((errorKey: string, index) => Object.values(errors)[index] && fieldKey === errorKey);
    if (isErrorInState && resetError) {
      resetError(fieldKey);
    }
  };

  const handleFormLogin = (e: React.FormEvent<HTMLInputElement>) => {
    // prevent reload on 'ENTER' press
    e.preventDefault();
    e.persist();
    handleLogin();
  };

  const handleLogin = () => {
    const localErrors = validate && validate({
      email,
      password,
    });
    if (!localErrors && !isSubmitNotAvailable) {
      loginSubmit && loginSubmit();
    }
    if (localErrors && updateErrorLogIn) {
      updateErrorLogIn(localErrors);
    }
  };

  const handleForgotPassword = () => {
    handleForgotPasswordOpen && handleForgotPasswordOpen();
  };

  // useEffect(() => {
  //   // cleaning errors on exit
  //   return () => {
  //     if (errors) {
  //       Object.keys(errors).forEach((errKey: string) => {
  //         resetError && resetError(errKey);
  //       });
  //     }
  //   };
  // }, [resetError, errors, updatePassword]);
  useEffect(() => {
    // cleaning password on exit
    return () => {
      updatePassword && updatePassword('');
      resetError && resetError(LOGIN_FIELD_KEY.password);
    };
  }, [updatePassword, resetError]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm' classes={{ paperFullWidth: classes.paperFullWidth }}>
      <DialogTitle disableTypography classes={{ root: classes.title }}>
        <Typography variant='h6' className={classes.titleContent}>
          {t('dialog.title.login')}
        </Typography>
        <CloseIcon onClick={handleClose} className={classes.close} />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Form onSubmit={handleSubmit(handleFormLogin)} className={classes.form}>
          <Field
            type='text'
            component={CustomTextField}
            name='email'
            placeholder={t('input.placeholder.email')}
            label={t('input.label.emailOrUsername')}
            onChange={handleEmail}
            error={isEmailError}
            onFocus={handleOnFocus(LOGIN_FIELD_KEY.email)}
            helperText={t(emailError)}
          />
          <Field
            type='password'
            component={CustomTextField}
            name='password'
            placeholder={t('input.placeholder.password')}
            label={t('input.label.password')}
            style={{ marginTop: 16 }}
            onChange={handlePassword}
            onFocus={handleOnFocus(LOGIN_FIELD_KEY.password)}
            error={isPasswordError}
            helperText={t(passwordError)}
          />
          <div className={classes.actions}>
            <Link onClick={handleForgotPassword}>
              {t('button_title.forgot_password')}
            </Link>
            <div>
              {expectAnswer && <CircularProgress />}
              {!expectAnswer && (
                <Button type='submit' onClick={handleLogin} className={classes.loginAction} disabled={Boolean(isSubmitNotAvailable)}>
                  {t('button_title.continue')}
                </Button>
              )}
            </div>
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

interface ILoginForm {
  email?: string | null;
  password?: string | null;
}
interface ILoginFormError {
  email?: string | null;
  password?: string | null;
}

export default connect(
  (state) => ({
    ...loginSelector(state),
    initialValues: {
      email: loginSelector(state).email,
      password: loginSelector(state).password,
    },
  }),
  (dispatch) => ({
    updateEmail: (email: string) => dispatch(updateEmailAction(email)),
    updatePassword: (password: string) => dispatch(updatePasswordAction(password)),
    loginSubmit: () => dispatch(loginSubmitAction()),
    resetError: (fieldKey: string) => dispatch(resetErrorLoginAction(fieldKey)),
    updateErrorLogIn: (errors: ILoginError) => dispatch(updateErrorLogInAction(errors)),
  })
)(
  reduxForm<{}, IProps>({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: (values: ILoginForm): any => {
      const errors: ILoginFormError = {
        email: null,
        password: null,
      };
      const emailInvalid = typeof values.email === 'string' && ValidationService.getEmailInvalidReason(values.email, null);
      const passwordInvalid = typeof values.password === 'string' && ValidationService.getPasswordInvalidReason(values.password, null);
      if (emailInvalid) {
        errors.email = emailInvalid;
      }
      if (passwordInvalid) {
        errors.password = passwordInvalid;
      }
      // if at least one error were found
      if (Object.values(errors).filter((error) => Boolean(error)).length) return errors;
      return null;
    },
  })(LoginDialog)
);
