// outsource dependencies
import React from 'react';
// UI
// import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
});

export default function CustomTextField(props: any) {
  const classes = useStyles();
  const reduxProps = props.input || {}
  return <TextField className={classes.container} {...props} {...reduxProps} />;
}
