// outsource dependencies
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
// UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
// local dependencies
import {
  selector as userProfileSelector,
  submitChangePasswordAction,
  updatePasswordAction,
  updateNewPasswordAction,
  IChangePasswordError,
  resetErrorAction,
  updateErrorAction,
  updateNewPasswordDuplicateAction,
} from 'private-layout/user-profile/reducer';
// UI
import CustomTextField from 'components/input/TextField';
// constants
import { APP_COLOR } from 'constants/style';
// services
import ValidationService from 'services/validation.service';

// configurations
export const FORM_NAME = 'changePasswordForm';

const FIELD_KEY = {
  password: 'password',
  newPassword: 'newPassword',
  newPasswordDuplicate: 'newPasswordDuplicate',
};

const useStyles = makeStyles({
  paperFullWidth: {
    width: 500,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 8px 8px 24px',
    backgroundColor: APP_COLOR.GREY,
    color: APP_COLOR.WHITE,
  },
  close: {
    padding: 6,
    color: APP_COLOR.WHITE,
    cursor: 'pointer',
  },
  titleContent: {
    lineHeight: 2,
  },
  content: {
    display: 'flex',
    padding: 12,
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16,
  },
  submitAction: {
    backgroundColor: APP_COLOR.LIGHT_GREY,
    color: APP_COLOR.WHITE,
    '&:hover': {
      color: APP_COLOR.BLACK,
    },
    transition: '0.6s',
  },
});

interface IProps {
  open: boolean;
  handleClose: () => void;
  password?: string;
  newPassword?: string;
  newPasswordDuplicate?: string;
  expectChangePasswordAnswer?: boolean;
  updatePassword?: (password: string) => void;
  updateNewPassword?: (newPassword: string) => void;
  updateNewPasswordDuplicate?: (newPasswordDuplicate: string) => void;
  submitChangePassword?: () => void;
  handleSubmit?: any; // redux
  validate?: (formData: IChangePasswordError) => IChangePasswordError | null;
  resetError?: (fieldKey: string) => void;
  updateError?: (errors: IChangePasswordError) => void;
  errors?: IChangePasswordError | null;
  clearState?: () => void;
}

function ChangePasswordDialog(props: IProps) {
  const {
    open,
    password,
    newPassword,
    newPasswordDuplicate,
    errors,
    //
    handleClose,
    expectChangePasswordAnswer,
    updatePassword,
    updateNewPassword,
    updateNewPasswordDuplicate,
    submitChangePassword,
    resetError,
    updateError,
    //
    validate,
    handleSubmit,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const isPasswordError = Boolean(errors?.password);
  const isNewPasswordError = Boolean(errors?.newPassword);
  const isNewPasswordDuplicateError = Boolean(errors?.newPasswordDuplicate);

  const isSubmitNotAvailable = errors && Boolean(Object.values(errors).filter((error) => Boolean(error)).length);

  const passwordError = typeof errors?.password === 'string' ? errors.password : '';
  const newPasswordError = typeof errors?.newPassword === 'string' ? errors.newPassword : '';
  const newPasswordDuplicateError = typeof errors?.newPasswordDuplicate === 'string' ? errors.newPasswordDuplicate : '';

  const handleChangePasswordSubmit = () => {
     const localErrors = validate && validate({ password, newPassword, newPasswordDuplicate });
     if (!localErrors && !isSubmitNotAvailable) {
       submitChangePassword && submitChangePassword();
     }
     if (localErrors && updateError) {
       updateError(localErrors);
     }
  };
  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    updatePassword && updatePassword(e.target.value);
  };
  const handleNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateNewPassword && updateNewPassword(e.target.value);
  };
  const handleNewPasswordDuplicate = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateNewPasswordDuplicate && updateNewPasswordDuplicate(e.target.value);
  };

  const handleOnFocus = (fieldKey: string) => () => {
    const isErrorInState = errors && Object.keys(errors).some((errorKey: string, index) => Object.values(errors)[index] && fieldKey === errorKey);
    if (isErrorInState && resetError) {
      resetError(fieldKey);
    }
  };

  useEffect(() => {
    return () => {
      updatePassword && updatePassword('');
      updateNewPassword && updateNewPassword('');
      updateNewPasswordDuplicate && updateNewPasswordDuplicate('');
    };
  }, [updatePassword, updateNewPassword, updateNewPasswordDuplicate]);

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paperFullWidth: classes.paperFullWidth }} fullWidth>
      <DialogTitle disableTypography classes={{ root: classes.title }}>
        <Typography variant='h6' className={classes.titleContent}>
          {t('dialog.title.change_password')}
        </Typography>
        <CloseIcon onClick={handleClose} className={classes.close} />
      </DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          <Form onSubmit={handleSubmit(handleChangePasswordSubmit)} className={classes.form}>
            <Field
              type='password'
              component={CustomTextField}
              name='password'
              placeholder={t('input.placeholder.password')}
              label={t('input.label.password')}
              onChange={handlePassword}
              error={isPasswordError}
              onFocus={handleOnFocus(FIELD_KEY.password)}
              helperText={t(passwordError)}
            />
            <Field
              type='password'
              component={CustomTextField}
              name='newPassword'
              placeholder={t('input.placeholder.new_password')}
              label={t('input.label.new_password')}
              style={{ marginTop: 16 }}
              onChange={handleNewPassword}
              onFocus={handleOnFocus(FIELD_KEY.newPassword)}
              error={isNewPasswordError}
              helperText={t(newPasswordError)}
            />
            <Field
              type='password'
              component={CustomTextField}
              name='newPasswordDuplicate'
              placeholder={t('input.placeholder.new_password_duplicate')}
              label={t('input.label.new_password_duplicate')}
              style={{ marginTop: 16 }}
              onChange={handleNewPasswordDuplicate}
              onFocus={handleOnFocus(FIELD_KEY.newPasswordDuplicate)}
              error={isNewPasswordDuplicateError}
              helperText={t(newPasswordDuplicateError)}
            />
            <div className={classes.actions}>
              {expectChangePasswordAnswer && <CircularProgress />}
              {!expectChangePasswordAnswer && (
                <Button type='submit' onClick={handleChangePasswordSubmit} className={classes.submitAction} disabled={Boolean(isSubmitNotAvailable)}>
                  {t('button_title.continue')}
                </Button>
              )}
            </div>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
}

interface IChangePasswordForm {
  password?: string | null;
  newPassword?: string | null;
  newPasswordDuplicate?: string | null;
}
interface IChangePasswordFormError {
  password?: string | null;
  newPassword?: string | null;
  newPasswordDuplicate?: string | null;
}

export default connect(
  (state) => ({
    ...userProfileSelector(state),
    initialValues: {
      password: userProfileSelector(state).password,
      newPassword: userProfileSelector(state).newPassword,
      newPasswordDuplicate: userProfileSelector(state).newPasswordDuplicate,
    },
  }),
  (dispatch) => ({
    updatePassword: (password: string) => dispatch(updatePasswordAction(password)),
    updateNewPassword: (newPassword: string) => dispatch(updateNewPasswordAction(newPassword)),
    updateNewPasswordDuplicate: (newPasswordDuplicate: string) => dispatch(updateNewPasswordDuplicateAction(newPasswordDuplicate)),
    submitChangePassword: () => dispatch(submitChangePasswordAction()),
    resetError: (fieldKey: string) => dispatch(resetErrorAction(fieldKey)),
    updateError: (errors: IChangePasswordError) => dispatch(updateErrorAction(errors)),
  })
)(
  reduxForm<{}, IProps>({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: (values: IChangePasswordForm): any => {
      const errors: IChangePasswordFormError = {
        password: null,
        newPassword: null,
        newPasswordDuplicate: null,
      };
      const passwordInvalid = typeof values.password === 'string' && ValidationService.getPasswordInvalidReason(values.password, null);
      const newPasswordInvalid = typeof values.newPassword === 'string' && ValidationService.getPasswordInvalidReason(values.newPassword, null);
      const newPasswordDuplicateInvalid = typeof values.newPasswordDuplicate === 'string' && ValidationService.getPasswordInvalidReason(values.newPasswordDuplicate, null);
      if (passwordInvalid) {
        errors.password = passwordInvalid;
      }
      if (newPasswordInvalid) {
        errors.newPassword = newPasswordInvalid;
      }
      if (newPasswordDuplicateInvalid) {
        errors.newPasswordDuplicate = newPasswordDuplicateInvalid;
      }
      if (values.newPassword && values.newPasswordDuplicate && values.newPassword !== values.newPasswordDuplicate) {
        errors.newPasswordDuplicate = 'errors.validation_error.passwords_not_equal';
      }
      // if at least one error were found
      if (Object.values(errors).filter((error) => Boolean(error)).length) return errors;
      return null;
    },
  })(ChangePasswordDialog)
);
