// types
export const TYPE = ((prefix) => ({
  PREFIX: new RegExp(prefix, 'i'),
  // complex actions
  CLEAR: `${prefix}CLEAR`,
  DATA: `${prefix}DATA`,
  ERROR: `${prefix}ERROR`,
  RESET_ERROR: `${prefix}RESET_ERROR`,
  // loading actions
  LOAD_PROFILE: `${prefix}LOAD_PROFILE`,
  // triggers
  CHANGE_PASSWORD: `${prefix}CHANGE_PASSWORD`,
}))('@user-profile/');

// selectors
export const selector = (state: any) => state.userProfile;

export interface IChangePasswordError {
  password?: string | null;
  newPassword?: string | null;
  newPasswordDuplicate?: string | null;
}

export interface IUser {
  email: string | null;
  status: string | number | null;
  username: string | null;
  company: string | null;
  mobile_phone: string | null;
  first_name: string | null;
  last_name: string | null;
  create_date: string | null;
  email_confirmed: boolean | null;
  key: string;
  telegram_id: number | null;
  plan: number;
  bonus:  string;
  discount:  string;
  ed_main_price: string | null,
  ed_second_price: string | null,
  allow_ed: boolean,
  active_subscription:boolean | null,
  ed_subscribe_start:string | null,
  ed_subscribe_end:string | null,
  ed_subscribe_limit:number | null,
  limit_reached:boolean | null,
  uploaded_files:number | null,
}
interface IUserState {
  expectAnswer: boolean;
  expectChangePasswordAnswer: boolean;
  //
  user: IUser | null;
  password: string;
  newPassword: string;
  newPasswordDuplicate: string;
  errors: IChangePasswordError | null;
  newPasswordSuccess: boolean;
}

export function typedAction(type: string, payload?: any) {
  return { type, payload };
}

export const updateExpectAnswerAction = (expectAnswer: boolean) => typedAction(TYPE.DATA, { expectAnswer });
export const updateExpectChangePasswordAnswerAction = (expectChangePasswordAnswer: boolean) => typedAction(TYPE.DATA, { expectChangePasswordAnswer });

export const updateUserAction = (user: IUser | null) => typedAction(TYPE.DATA, { user });
export const updatePasswordAction = (password: string) => typedAction(TYPE.DATA, { password });
export const updateNewPasswordAction = (newPassword: string) => typedAction(TYPE.DATA, { newPassword });
export const updateNewPasswordDuplicateAction = (newPasswordDuplicate: string) => typedAction(TYPE.DATA, { newPasswordDuplicate });
export const updateNewPasswordSuccessAction = (newPasswordSuccess: boolean) => typedAction(TYPE.DATA, { newPasswordSuccess });

export const resetErrorAction = (fieldKey: string) => typedAction(TYPE.RESET_ERROR, { fieldKey });
export const updateErrorAction = (errors: IChangePasswordError) => typedAction(TYPE.ERROR, errors);
// trig loading
export const loadUserProfileAction = () => typedAction(TYPE.LOAD_PROFILE);
export const submitChangePasswordAction = () => typedAction(TYPE.CHANGE_PASSWORD);
export const clearStateAction = () => typedAction(TYPE.CLEAR);

type UserAction = ReturnType<
  | typeof updateExpectAnswerAction
  | typeof updateExpectChangePasswordAnswerAction
  | typeof updatePasswordAction
  | typeof updateNewPasswordAction
  | typeof updateUserAction
  | typeof loadUserProfileAction
  | typeof submitChangePasswordAction
  | typeof clearStateAction
  | typeof resetErrorAction
  | typeof updateErrorAction
  | typeof updateNewPasswordSuccessAction
  | typeof updateNewPasswordDuplicateAction
>;

const initialState: IUserState = {
  expectAnswer: false,
  expectChangePasswordAnswer: false,
  user: null,
  password: '',
  newPassword: '',
  newPasswordDuplicate: '',
  errors: null,
  newPasswordSuccess: false,
};

export function userProfile(state = initialState, action: UserAction): IUserState {
  switch (action.type) {
    default:
      return state;
    case TYPE.DATA:
      return { ...state, ...action.payload };
    case TYPE.ERROR:
      return _setError(state, action.payload);
    case TYPE.RESET_ERROR:
      return _resetError(state, action.payload);
    case TYPE.CLEAR:
      return initialState;
  }
}

const _resetError = (state: IUserState, error: { fieldKey: string }) => {
  const newErrors = state.errors ? { ...state.errors, [error.fieldKey]: null } : { [error.fieldKey]: null };
  return { ...state, errors: newErrors };
};

const _setError = (state: IUserState, errors: IChangePasswordError) => {
  const newErrors = state.errors ? { ...state.errors, ...errors } : errors;
  return { ...state, errors: newErrors };
};
