// outsource dependencies
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Field, reduxForm, InjectedFormProps } from 'redux-form';
import { useTranslation } from 'react-i18next';
// UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
// local dependencies
// UI
import CustomTextField from 'components/input/TextField';
// constants
import { APP_COLOR } from 'constants/style';
// state selector and actions
import {
  selector as loginSelector,
  updateEmailAction,
  requestNewPasswordAction,
  resetErrorLoginAction,
  ILoginError,
  updateErrorLogInAction,
} from 'public-layout/log-in/reducer';
// services
import ValidationService from 'services/validation.service';

// configurations
export const FORM_NAME = 'forgotPasswordForm';

const FIELD_KEY = {
  email: 'email',
};

const useStyles = makeStyles({
  paperFullWidth: {
    width: 500,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 8px 8px 24px',
    backgroundColor: APP_COLOR.GREY,
    color: APP_COLOR.WHITE,
  },
  close: {
    padding: 6,
    color: APP_COLOR.WHITE,
    cursor: 'pointer',
  },
  titleContent: {
    lineHeight: 2,
  },
  content: {
    display: 'flex',
    padding: '12px 30px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16,
  },
  loginAction: {
    backgroundColor: APP_COLOR.LIGHT_GREY,
    color: APP_COLOR.WHITE,
    '&:hover': {
      color: APP_COLOR.BLACK,
    },
    transition: '0.6s',
  },
});

interface IProps {
  open: boolean;
  handleClose: () => void;
  updateEmail?: (email: string) => void;
  email?: string | null;
  errors?: ILoginError | null;
  requestNewPassword?: () => void;
  expectRequestPasswordAnswer?: boolean;
  requestNewPasswordSuccess?: boolean;
  validate?: (loginFormData: IForgotPasswordForm) => IForgotPasswordFormError | null;
  resetError?: (fieldKey: string) => void;
  updateError?: (errors: ILoginError) => void;
  handleSubmit?: any; // redux
}

const ForgotPasswordDialog: React.FC<IProps & InjectedFormProps<{}, IProps>> = (props: IProps) => {
  const {
    open,
    handleClose,
    updateEmail,
    requestNewPassword,
    email,
    validate,
    expectRequestPasswordAnswer,
    requestNewPasswordSuccess,
    resetError,
    errors,
    updateError,
    handleSubmit,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const isEmailError = Boolean(errors?.email);
  const emailError = typeof errors?.email === 'string' ? errors.email : '';

  const isSubmitNotAvailable = errors && Boolean(Object.values(errors).filter((error) => Boolean(error)).length);

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (updateEmail) updateEmail(e.target.value);
  };

  const handleOnFocus = (fieldKey: string) => () => {
    const isErrorInState = errors && Object.keys(errors).some((errorKey: string, index) => Object.values(errors)[index] && fieldKey === errorKey);
    if (isErrorInState && resetError) {
      resetError(fieldKey);
    }
  };

  const handleFormSubmit = () => {
    handleRequestNewPassword();
  };

  const handleRequestNewPassword = () => {
    const localErrors = validate && validate({ email });
    if (!localErrors && !isSubmitNotAvailable) {
      requestNewPassword && requestNewPassword();
    }
    if (localErrors && updateError) {
      updateError(localErrors);
    }
  };

  useEffect(() => {
    // force close on success
    if (requestNewPasswordSuccess) handleClose();
    return () => {
      updateEmail && updateEmail('');
      resetError && resetError(FIELD_KEY.email);
    };
  }, [resetError, updateEmail, requestNewPasswordSuccess, handleClose]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm' classes={{ paperFullWidth: classes.paperFullWidth }}>
      <DialogTitle disableTypography classes={{ root: classes.title }}>
        <Typography variant='h6' className={classes.titleContent}>
          {t('dialog.title.forgot_password')}
        </Typography>
        <CloseIcon onClick={handleClose} className={classes.close} />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Form onSubmit={handleSubmit(handleFormSubmit)} className={classes.form}>
          <Field
            type='text'
            component={CustomTextField}
            name='email'
            placeholder={t('input.placeholder.email')}
            label={t('input.label.email')}
            onChange={handleEmail}
            error={isEmailError}
            onFocus={handleOnFocus(FIELD_KEY.email)}
            helperText={t(emailError)}
          />
          <div className={classes.actions}>
            {expectRequestPasswordAnswer && <CircularProgress />}
            {!expectRequestPasswordAnswer && (
              <Button onClick={handleRequestNewPassword} className={classes.loginAction} disabled={Boolean(isSubmitNotAvailable)}>
                {t('button_title.get_new_password')}
              </Button>
            )}
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

interface IForgotPasswordForm {
  email?: string | null;
}
interface IForgotPasswordFormError {
  email?: string | null;
}

export default connect(
  (state) => ({
    ...loginSelector(state),
    initialValues: {
      email: loginSelector(state).email,
      password: loginSelector(state).password,
    },
  }),
  (dispatch) => ({
    updateEmail: (email: string) => dispatch(updateEmailAction(email)),
    requestNewPassword: () => dispatch(requestNewPasswordAction()),
    resetError: (fieldKey: string) => dispatch(resetErrorLoginAction(fieldKey)),
    updateError: (errors: ILoginError) => dispatch(updateErrorLogInAction(errors)),
  })
)(
  reduxForm<{}, IProps>({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: (values: IForgotPasswordForm): any => {
      const errors: IForgotPasswordFormError = {
        email: null,
      };
      const emailInvalid = typeof values.email === 'string' && ValidationService.getEmailInvalidReason(values.email, null);
      if (emailInvalid) {
        errors.email = emailInvalid;
      }
      // if at least one error were found
      if (Object.values(errors).filter((error) => Boolean(error)).length) return errors;
      return null;
    },
  })(ForgotPasswordDialog)
);
