// outsource dependencies
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link as LinkRoute} from 'react-router-dom'
import {useTranslation} from 'react-i18next';
// UI
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import {makeStyles} from '@material-ui/core/styles';

// local dependencies
import {
    selector as processingTasksSelector,
    // update
    updateSelectedFileKeyAction,
    // triggers
    loadTasksAction,
    requestBillingOrderAction,
    requestPaymentAction,
    clearAction,
    // interfaces
    IFile,
    IFileData,
    IPrice,
    IRequestFileResponse,
    loadFileHistoryAction,
} from 'private-layout/processing-tasks/reducer';
// UI
import Layout from 'components/layout/Common';
import Preloader from 'components/Preloader';
import PaymentApproveDialog from 'components/dialog/PaymentApprove';
// constants
import {ProcessTaskStatus} from 'constants/status';
import currentConfig from "../../utils/config.utils";


const useStyles = makeStyles({
    tableContainer: {
        marginTop: 16,
    },
    table: {
        minWidth: 650,
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'none',
        whiteSpace: 'nowrap'
    },
    historySubRow: {
        paddingBottom: 0,
        paddingTop: 0
    },
    historyBox: {
        margin: 20
    },
    actionCell: {
        display: "flex",
        flexDirection: "column",
    },
    alert: {
        marginTop: '20px',
        marginBottom: '20px',
        padding: '20px',
        border: '1px solid #FD9C00',
        width: 'auto',
        display: 'flex',
        backgroundColor: '#FDF4CD',
        borderRadius: '5px',
    }
});

interface IProps {
    pageTitle: string;
    language: string;
    //
    expectAnswer?: boolean;
    expectOrderAnswer?: boolean;
    expectRequestFileAnswer?: boolean;
    requestFileResponse?: IRequestFileResponse;
    tasks?: IFile[] | null;
    selectedFileKey?: string | null;
    fileData?: IFileData | null;
    price?: IPrice | null;
    actions?: string[] | null;

    loadTasks?: () => void;
    requestBillingOrder?: () => void;
    updateSelectedFileKey?: (selectedFileKey: string | null) => void;
    clearState?: () => void;
    requestPayment?: () => void;
    loadFileHistory?: (key: string | null) => void;
    filesHistory?: any;
}

function ProcessingTasks(props: IProps) {
    const {
        pageTitle,
        language,
        //
        expectAnswer,
        tasks,
        selectedFileKey,
        fileData,
        price,
        actions,
        expectOrderAnswer,
        expectRequestFileAnswer,
        requestFileResponse,
        //
        loadTasks,
        requestBillingOrder,
        updateSelectedFileKey,
        clearState,
        requestPayment,
        loadFileHistory,
        filesHistory,
    } = props;

    const {t} = useTranslation();
    const classes = useStyles();

    const [openPayment, setOpenPayment] = useState<boolean>(false);

    const handleTaskStatus = (status: string) => (Boolean(status) || status ? t(`processing_tasks.status.${ProcessTaskStatus[Number(status)]}`) : '');
    const handleTaskColorStatus = (status: string) => {
        // console.log(status);
        let color: string = '#f6685e';
// eslint-disable-next-line
        if (status == '2')
            color = '#ffeb3b';
// eslint-disable-next-line
        if (status == '3')
            color = '#33c9dc';
// eslint-disable-next-line
        if (status == '4')
            color = '#a2cf6e';

        return color;
    };

    const handleBillingOrder = (fileKey: string | null) => () => {
        updateSelectedFileKey && updateSelectedFileKey(fileKey);
        requestBillingOrder && requestBillingOrder();
        handlePaymentOpen();
    };

    const handlePaymentOpen = () => {
        setOpenPayment(true);
    };
    const handlePaymentClose = () => {
        setOpenPayment(false);
    };
    const handlePayment = () => {
        requestPayment && requestPayment();
        //handlePaymentClose();
    };

    const handleOpenFileHistory = (key: string | null) => {
        loadFileHistory && loadFileHistory(key);
    }

    useEffect(() => {
        loadTasks && loadTasks();
        return () => {
            updateSelectedFileKey && updateSelectedFileKey(null);
        };
    }, [loadTasks, updateSelectedFileKey]);

    useEffect(() => {
        return () => {
            clearState && clearState();
        };
    }, [clearState]);
    return (
        <Layout pageTitle={pageTitle} language={language}>
            <Typography variant='h5'>{t('processing_tasks.title')}</Typography>
            {expectAnswer && <Preloader pageCentered/>}
            {!expectAnswer && (
                <>
                <span className={classes.alert}>
                    По вопросам или пожеланиям относительно модифицированных файлов просьба писать сообщения в "тикеты"
                </span>
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'
                                           style={{width: '5%'}}>{t('processing_tasks.tasks.title.key')}</TableCell>
                                <TableCell align='center'
                                           style={{width: '5%'}}>{t('processing_tasks.tasks.title.status')}</TableCell>
                                <TableCell align='center'
                                           style={{width: '10%'}}>{t('processing_tasks.tasks.title.request_date')}</TableCell>
                                <TableCell align='center'
                                           style={{width: '15%'}}>{t('processing_tasks.tasks.title.ecu')}</TableCell>
                                <TableCell align='center'
                                           style={{width: '30%'}}>{t('processing_tasks.tasks.title.comment')}</TableCell>
                                <TableCell align='center'
                                           style={{width: '28%'}}>{t('processing_tasks.tasks.title.note')}</TableCell>
                                <TableCell align='center'
                                           style={{width: '7%'}}>{t('processing_tasks.tasks.title.action')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(tasks) &&
                            tasks.map((task: IFile) => (
                                <React.Fragment key={`full-${task.key}`}>
                                    <TableRow key={`task-${task.key}`}>
                                        <TableCell align='center' component='th' scope='row'>
                                            {task.key}
                                        </TableCell>
                                        <TableCell align='center' style={{
                                            color: handleTaskColorStatus(task.status),
                                            fontWeight: task.status !== '1' ? 'bold' : 'normal'
                                        }}>
                                            {handleTaskStatus(task.status)}
                                        </TableCell>
                                        <TableCell align='left'>
                                            {new Date((task.request_date + ' UTC').replace(/-/g, "/")).toLocaleString()}
                                        </TableCell>
                                        <TableCell align='left'>{task.ecu}</TableCell>
                                        <TableCell align='left'>{task.comment}</TableCell>
                                        <TableCell align='left'>{task.note}</TableCell>
                                        <TableCell align='center'>
                                            <div className={classes.actionCell}>
                                                {task.status === '4' && 
                                                    ((task.history_count || 0) > 0 &&
                                                    <Link onClick={() => handleOpenFileHistory(task.key)}
                                                          className={classes.link}>
                                                        {t('processing_tasks.action.download') + " (" + task.history_count + ")"}
                                                    </Link>)}
                                                {task.status === '1' && task.ecu && (
                                                    <Link onClick={handleBillingOrder(task.key)}
                                                          className={classes.link}>
                                                        {t('processing_tasks.action.pay')}
                                                    </Link>
                                                )}
                                                <LinkRoute to={"ticket/" + task.key} className={classes.link}>
                                                    {t('processing_tasks.tasks.title.tickets') + ` (${task.ticket_count})`}
                                                </LinkRoute>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    {(filesHistory &&
                                        Array.isArray(filesHistory.files) &&
                                        filesHistory.key === task.key &&
                                        filesHistory.files.length > 0) &&
                                    filesHistory.files.map((fileHistory: any) => {
                                        const downloadLinkBin =
                                            currentConfig().apiUrl + "file/download/history/" + fileHistory.key + "/"

                                        return (
                                            <TableRow style={{backgroundColor: "#F9F9F9"}}
                                                      key={`history-${fileHistory.key}`}>
                                                <TableCell colSpan={2}> </TableCell>
                                                <TableCell>
                                                    {new Date((fileHistory.add_date + ' UTC').replace(/-/g, "/")).toLocaleString()}
                                                </TableCell>
                                                <TableCell>{fileHistory.file_name}</TableCell>
                                                <TableCell>{fileHistory.admin_comment}</TableCell>
                                                <TableCell>
                                                    <div>
                                                        <Link href={downloadLinkBin} target={"_blank"}  className={classes.link}
                                                           rel="noopener noreferrer">{t('processing_tasks.tasks.title.download')}</Link>
                                                    </div>
                                                </TableCell>
                                                <TableCell/>
                                            </TableRow>
                                        )
                                    })}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </>
            )}
            {openPayment && (
                <PaymentApproveDialog
                    open={openPayment}
                    handleClose={handlePaymentClose}
                    handlePayment={handlePayment}
                    fileData={fileData}
                    price={price}
                    actions={actions}
                    expectOrderAnswer={Boolean(expectOrderAnswer)}
                    expectRequestFileAnswer={Boolean(expectRequestFileAnswer)}
                    requestFileResponse={requestFileResponse}
                    selectedFileKey={selectedFileKey}
                />
            )}
        </Layout>
    );
}

export default connect(
    (state) => processingTasksSelector(state),
    (dispatch) => ({
        loadTasks: () => dispatch(loadTasksAction()),
        requestBillingOrder: () => dispatch(requestBillingOrderAction()),
        updateSelectedFileKey: (selectedFileKey: string | null) => dispatch(updateSelectedFileKeyAction(selectedFileKey)),
        clearState: () => dispatch(clearAction()),
        requestPayment: () => dispatch(requestPaymentAction()),
        loadFileHistory: (key: string | null) => dispatch(loadFileHistoryAction(key)),
    })
)(ProcessingTasks);
