

//types
export const TYPE = ((prefix) => ({
    PREFIX: new RegExp(prefix, 'i'),
    // complex actions
    CLEAR: `${prefix}CLEAR`,
    DATA: `${prefix}DATA`,
    LOAD_ED_FILE: `${prefix}LOAD_ED_FILE`
    // loading actions

}))('@ed-upload-file/');


export const selector = (state: any) => ({ ...state.edUploadFile, user:state.app.user });


interface IUserState {
    expectAnswer: boolean;
    fileInfo: any;
}

export function typedAction(type: string, payload?: any) {
    return { type, payload };
}

export const updateExpectAnswerAction = (expectAnswer: boolean) => typedAction(TYPE.DATA, { expectAnswer });
export const loadEdFileAction = (fileForm: any) => typedAction(TYPE.LOAD_ED_FILE, fileForm);
export const setEdFileInfoAction = (fileInfo: any) => typedAction(TYPE.DATA, fileInfo);
export const clearAction = () => typedAction(TYPE.CLEAR);

type UserAction = ReturnType<
    | typeof updateExpectAnswerAction
    //
    | typeof loadEdFileAction
    | typeof clearAction
    >;

const initialState: IUserState = {
    expectAnswer: false,
    fileInfo: null,
};

export function edUploadFile(state = initialState, action: UserAction): IUserState {
    switch (action.type) {
        default:
            return state;
        case TYPE.DATA:
            return { ...state, ...action.payload };
        case TYPE.CLEAR:
            return initialState;
    }
}
