// outsource dependencies
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
// local dependencies
import { selector as signUpSelector } from 'public-layout/sign-up/reducer';
import { selector as loginSelector, updateRequestNewPasswordSuccessAction } from 'public-layout/log-in/reducer';
import { selector as appSelector, appNavigateAction, updateAppDataAction } from 'reducers';
// helpers
import ScrollTop from 'components/layout/ScrollTop';
// UI
import Login from 'components/dialog/Login';
import SignUp from 'components/dialog/Signup';
import ForgotPassword from 'components/dialog/ForgotPassword';
import BaseDialog from 'components/dialog/Base';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Preloader from 'components/Preloader';
import ErrorDialog from 'components/dialog/Error';
// constants
import { CONSTRAINT } from 'constants/style';
import { ExpectedErrorStatus } from 'constants/error';
// services
import API, {API_FORM_DATA} from 'services/request.service';

const LAYOUT_INDENT = 16;
interface IStyle {
  isMobile: boolean;
}
const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '100%',
    justifyContent: 'space-between',
  },
  contentWrapper: {
    width: '100%',
    minHeight: `calc(100vh - ${CONSTRAINT.FOOTER}px - ${CONSTRAINT.HEADER}px - ${LAYOUT_INDENT}px*2)`,
    display: 'flex',
    justifyContent: 'center',
    padding: `${LAYOUT_INDENT}px 0`,
  },
  content: {
    maxWidth: CONSTRAINT.MAX_CONTENT_SIZE,
    width: '100%',
    padding: (props: IStyle) => (props.isMobile ? `0 ${CONSTRAINT.MOBILE_INDENT}px` : 0),
  },
});

interface IProps {
  pageTitle: string;
  children: any;
  language?: string;
  //
  confirmEmail?: boolean;
  requestNewPasswordSuccess?: boolean;
  updateRequestNewPasswordSuccess?: (requestNewPasswordSuccess: boolean) => void;
  // app state
  appExpectAnswer?: boolean;
  appNavigate?: () => void;
  updateAppLanguage?: (language: string | null) => void;
}

function Common(props: IProps) {
  const {
    children,
    //
    pageTitle,
    language,
    //
    confirmEmail,
    requestNewPasswordSuccess,
    updateRequestNewPasswordSuccess,
    //
    appExpectAnswer,
    appNavigate,
    updateAppLanguage,
  } = props;
  const classes = useStyles({ isMobile });
  const { t } = useTranslation();

  const [openLogin, setOpenLogin] = useState<boolean>(false);
  const [openSignUp, setOpenSignUp] = useState<boolean>(false);
  const [openForgotPassword, setOpenForgotPassword] = useState<boolean>(false);
  const [globalError, setGlobalError] = useState<boolean>(false);
  const [globalMessagedError, setGlobalMessagedError] = useState<any>(null);

  useEffect(() => {
    // Global error handler
    API.interceptors.response.use(
      (response) => {
        // Do something with response data
        return response;
      },
      (error) => {
        const errorStatus = get(error, 'response.status', null);
        const isValidationError = Boolean(ExpectedErrorStatus[errorStatus]);
        console.error('error', error.response);
        if (!isValidationError) setGlobalError(true);
        // Do something with response error
        return Promise.reject(error);
      }
    );

    API_FORM_DATA.interceptors.response.use(
        (response) => {
          // Do something with response data
          return response;
        },
        (error) => {
          console.error('error', error.response);
          const errorStatus = get(error, 'response.status', null);
          const errorKey = get(error, 'response.data.errorKey', null);
          const errorMessage = get(error, 'response.data.errorMessage', null);

          setGlobalMessagedError({
            errorStatus: errorStatus,
            errorKey: errorKey,
            errorMessage: errorMessage
          })
          // Do something with response error
          return Promise.reject(error);
        }
    );
  }, []);

  useEffect(() => {
    updateAppLanguage && typeof language === 'string' && updateAppLanguage(language);
  }, [updateAppLanguage, language]);

  const handleLoginOpen = () => {
    setOpenLogin(true);
  };
  const handleAppNavigate = () => {
    appNavigate && appNavigate();
  };
  const handleSignUpOpen = () => {
    setOpenSignUp(true);
  };
  const handleLoginClose = () => {
    setOpenLogin(false);
  };
  const handleSignUpClose = () => {
    setOpenSignUp(false);
  };
  const handleForgotPasswordOpen = () => {
    handleLoginClose();
    setOpenForgotPassword(true);
  };
  const handleForgotPasswordClose = () => {
    setOpenForgotPassword(false);
  };
  const handleRequestNewPasswordSuccessClose = () => {
    updateRequestNewPasswordSuccess && updateRequestNewPasswordSuccess(false);
  };
  const handleErrorClose = () => {
    setGlobalError(false);
  };

  const handleMessagedErrorClose = () => {
    setGlobalMessagedError(null);
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className={classes.container}>
        <Header handleLoginOpen={handleLoginOpen} handleSignUpOpen={handleSignUpOpen} handleAppNavigate={handleAppNavigate} />
        {appExpectAnswer && <Preloader pageCentered />}
        {!appExpectAnswer && (
          <div className={classes.contentWrapper}>
            <div className={classes.content}>{children}</div>
          </div>
        )}
        <Footer />
      </div>
      {openLogin && <Login open={openLogin} handleClose={handleLoginClose} handleForgotPasswordOpen={handleForgotPasswordOpen} />}
      {openSignUp && <SignUp open={openSignUp} handleClose={handleSignUpClose} confirmEmail={confirmEmail} />}
      {openForgotPassword && <ForgotPassword open={openForgotPassword} handleClose={handleForgotPasswordClose} />}
      {requestNewPasswordSuccess && (
        <BaseDialog
          open={requestNewPasswordSuccess}
          handleClose={handleRequestNewPasswordSuccessClose}
          title={t('dialog.title.request_new_password_success')}
          textContent={t('dialog.content.request_new_password_success')}
        />
      )}
      {globalError && <ErrorDialog open={globalError} handleClose={handleErrorClose} />}

      {globalMessagedError && <ErrorDialog open={true}
                                           errorKey={globalMessagedError.errorKey}
                                           errorStatus={globalMessagedError.errorStatus}
                                           handleClose={handleMessagedErrorClose} />}
      <ScrollTop />
    </>
  );
}

export default connect(
  (state) => ({
    confirmEmail: signUpSelector(state).confirmEmail,
    requestNewPasswordSuccess: loginSelector(state).requestNewPasswordSuccess,
    appExpectAnswer: appSelector(state).appExpectAnswer,
  }),
  (dispatch) => ({
    updateRequestNewPasswordSuccess: (requestNewPasswordSuccess: boolean) => dispatch(updateRequestNewPasswordSuccessAction(requestNewPasswordSuccess)),
    appNavigate: () => dispatch(appNavigateAction()),
    updateAppLanguage: (language: string | null) => dispatch(updateAppDataAction({ locale: language })),
  })
)(Common);
