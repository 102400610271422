// outsource dependencies
import { fork, takeLatest, call, put, all, select } from 'redux-saga/effects';
import get from 'lodash/get';
// local dependencies
import {
  selector as addFileSelector,
  //
  TYPE,
  //
  updateExpectAnswerAction,
  //
  updateSelectedVehicleKeyAction,
  updateFileInfoAction,
  updateSuggectECUsAction,
  selectSuggectECUsAction,
  updateVehiclesAction,
  updateVehicleBrandsAction,
  updateVehicleEcusAction,
  updateVehicleActionsAction,
  updateVehiclePriceAction,
  updateDtcAction,
  updateAllowDtcAction,
  updateAutoDtcAction,
  updateFileKeyAction,
  updateSelectedEcusKeyAction,
  updateUploadEcuSuccessAction,
  updateNoEcusInListAction,
  updatePaymentAllowedAction,
  updateShowInfoAction,
  updatePriceAction,
  updateActionsAction,
  updateSignatureAction,
  updateDataAction,
  updateFileDataAction,
  updateProcessedDTCsAction,
  updateProcessedActionsAction,
  updateManualActionsAction,
  updateExpectRequestFileAnswerAction,
  updateRequestFileResponseAction,
  //updateTaskAction,
  //
  updateVehicleBrandsLoadedAction,
  updateVehicleEcusLoadedAction,
  updateVehicleActionsLoadedAction,
  updateFileProcessedAction,
  //
  updateExpectBrandsAnswerAction,
  updateExpectEcusAnswerAction,
  updateExpectActionsAnswerAction,
  updateExpectUploadFileAnswerAction,
  updateExpectVehiclePriceAnswerAction,
  updateExpectBillingOrderAnswerAction,
  updateExpectProcessFileAnswerAction,
  // for type check in effects only
  loadVehicleBrandsAction,
  // interfaces
  IVehicleAction,
  ISuggectECUs,
  //
  uploadFileAction,
} from 'private-layout/add-file/reducer';
import API, { API_FORM_DATA } from 'services/request.service';
import { IRequestFileResponse } from 'private-layout/add-file/reducer';

function* loadVehiclesSaga() {
  yield put(updateExpectAnswerAction(true));
  try {
    const { data } = yield call<any>(API, {
      url: '/vehicle',
      method: 'GET',
    });
    const vehicles = get(data, 'response', null);
    // hide selects on reselect
    yield all([
      put(updateVehiclesAction(vehicles)),
      put(updateDtcAction('')),
      put(updateVehicleBrandsLoadedAction(false)),
      put(updateVehicleEcusLoadedAction(false)),
      put(updateProcessedDTCsAction(null)),
      put(updateProcessedActionsAction(null)),
      put(updateManualActionsAction(null)),
      put(updateFileProcessedAction(false)),
      put(updateShowInfoAction(false)),
      put(updateExpectAnswerAction(false)),
    ]);
  } catch ({ message }) {
    console.error('loadVehiclesSaga error: ', message);
    yield put(updateExpectAnswerAction(false));
  }
}

function* loadVehiclesBrandsSaga({ payload }: ReturnType<typeof loadVehicleBrandsAction>) {
  yield put(updateExpectBrandsAnswerAction(true));
  yield put(updateSelectedVehicleKeyAction(payload));
  try {
    const { data } = yield call<any>(API, {
      url: '/vehicle/brands',
      method: 'POST',
      data: {
        vehicle: payload,
      },
    });
    const vehicleBrands = get(data, 'response', null);
    // hide selects on reselect
    yield all([
      put(updateVehicleBrandsAction(vehicleBrands)),
      put(updateSelectedEcusKeyAction(null)), // if ecus previously were selected
      put(updateDtcAction('')),
      put(updateVehicleBrandsLoadedAction(true)),
      put(updateVehicleEcusLoadedAction(false)),
      put(updateVehiclePriceAction(null)),
      put(updateVehicleActionsAction(null)),
      put(updateAutoDtcAction(false)),
      put(updateVehicleActionsLoadedAction(false)),
      put(updateProcessedDTCsAction(null)),
      put(updateProcessedActionsAction(null)),
      put(updateManualActionsAction(null)),
      put(updateFileProcessedAction(false)),
      put(updateShowInfoAction(false)),
      put(updateExpectBrandsAnswerAction(false)),
    ]);
  } catch ({ message }) {
    console.error('loadVehiclesBrandsSaga error: ', message);
    yield put(updateExpectBrandsAnswerAction(false));
  }
}

function* loadVehiclesEcusSaga() {
  const { selectedBrandKey, selectedVehicleKey } = yield select(addFileSelector);
  yield put(updateExpectEcusAnswerAction(true));
  try {
    const { data } = yield call<any>(API, {
      url: '/vehicle/ecus',
      method: 'POST',
      data: {
        brand: selectedBrandKey,
        vehicle: selectedVehicleKey,
        search: '',
      },
    });
    const vehicleEcus = get(data, 'response', null);
    // hide selects on reselect
    yield all([
      put(updateVehicleEcusAction(vehicleEcus)),
      put(updateDtcAction('')),
      put(updateVehicleEcusLoadedAction(true)),
      put(updateVehiclePriceAction(null)),
      put(updateVehicleActionsAction(null)),
      put(updateAutoDtcAction(false)),
      put(updateVehicleActionsLoadedAction(false)),
      put(updateProcessedDTCsAction(null)),
      put(updateProcessedActionsAction(null)),
      put(updateManualActionsAction(null)),
      put(updateFileProcessedAction(false)),
      put(updateShowInfoAction(false)),
      put(updateExpectEcusAnswerAction(false)),
    ]);
  } catch ({ message }) {
    console.error('loadVehiclesEcusSaga error: ', message);
    yield put(updateExpectEcusAnswerAction(false));
  }
}

function* loadVehiclesActionsSaga() {
  const { selectedBrandKey, selectedEcusKey } = yield select(addFileSelector);
  yield put(updateExpectActionsAnswerAction(true));
  try {
    const { data } = yield call<any>(API, {
      url: '/vehicle/actions',
      method: 'POST',
      data: {
        key: `${selectedEcusKey}-${selectedBrandKey}`,
      },
    });
    let vehicleActions = get(data, 'response.actions', null);
    const allowDtc = get(data, 'response.allowDtc', false);
    const autoDtc = get(data, 'response.autoDtc', false);
    vehicleActions = Array.isArray(vehicleActions) ? vehicleActions.map((vehicleAction: IVehicleAction) => ({ ...vehicleAction, selected: false })) : vehicleActions;
    yield all([
      put(updateVehicleActionsAction(vehicleActions)),
      put(updateAllowDtcAction(allowDtc)),
      put(updateAutoDtcAction(autoDtc)),
      put(updateVehicleActionsLoadedAction(true)),
      put(updateVehiclePriceAction(null)),
      put(updateShowInfoAction(true)),
      put(updateProcessedDTCsAction(null)),
      put(updateProcessedActionsAction(null)),
      put(updateManualActionsAction(null)),
      put(updateFileProcessedAction(false)),
      put(updateExpectActionsAnswerAction(false)),
    ]);
  } catch ({ message }) {
    console.error('loadVehiclesActionsSaga error: ', message);
    yield put(updateExpectActionsAnswerAction(false));
  }
}

function* uploadEcuFileSaga({ payload }: ReturnType<typeof uploadFileAction>) {
  const { fileEncodingType } = yield select(addFileSelector);
  const formData = new FormData();
  formData.append('clientFile', payload.carFile);
  formData.append('comment', payload.comment);
  formData.append('fileEncodingType', fileEncodingType);
  yield put(updateExpectUploadFileAnswerAction(true));
  try {
    const { data } = yield call<any>(API_FORM_DATA, {
      url: '/client/file/upload/v3',
      method: 'POST',
      data: formData,
    });
    // const response = {
    //   key: '994a4', // 994a47
    //   fileName: 'motorcar-tuning-9ac3bb.bin',
    //   fileInfo: {
    //     ecu: 'EDC17_C46',
    //     soft: '',
    //   },
    //   suggectECUs: [
    //     {
    //       name: 'BOSCH EDC17C46',
    //       key: '52678',
    //     },
    //   ],
    // };
    // const key = get(response, 'key', null);
    // const fileName = get(response, 'fileName', null);
    // const ecu = get(response, 'fileInfo.ecu', null);
    // const soft = get(response, 'fileInfo.soft', null);
    // let suggectECUs = get(response, 'suggectECUs', null);
    const key = get(data, 'response.key', null);
    const fileName = get(data, 'response.fileName', null);
    const ecu = get(data, 'response.fileInfo.ecu', null);
    const soft = get(data, 'response.fileInfo.soft', null);
    let suggectECUs = get(data, 'response.suggectECUs', null);
    suggectECUs = Array.isArray(suggectECUs) ? suggectECUs.map((suggectECU: ISuggectECUs) => ({ ...suggectECU, selected: false })) : suggectECUs;
    const isNoEcusInList = !Array.isArray(suggectECUs) || !suggectECUs.length;
    yield all([
      put(updateUploadEcuSuccessAction(true)),
      put(updateNoEcusInListAction(isNoEcusInList)),
      put(updateFileInfoAction({ key, fileName, ecu, soft })),
      put(updateFileKeyAction(key)),
      put(updateSuggectECUsAction(suggectECUs)),
      put(updateDtcAction('')),
      put(updateProcessedDTCsAction(null)),
      put(updateProcessedActionsAction(null)),
      put(updateManualActionsAction(null)),
      put(updateFileProcessedAction(false)),
      put(updateExpectUploadFileAnswerAction(false)),
    ]);
  } catch ({ message }) {
    console.error('uploadEcuFileSaga error: ', message);
    yield put(updateExpectUploadFileAnswerAction(false));
  }
}

function* loadVehiclePriceSaga() {
  const { vehicleActions, dtcKey, selectedEcusKey, selectedBrandKey, selectedEcuAndBrandKey } = yield select(addFileSelector);
  const actions = Array.isArray(vehicleActions)
    ? vehicleActions
        .filter((vehicleAction: IVehicleAction) => vehicleAction.selected)
        .map((vehicleAction: IVehicleAction) => vehicleAction.key)
        .join()
    : '';
  const ecuBrand = selectedEcuAndBrandKey ? selectedEcuAndBrandKey : `${selectedEcusKey}-${selectedBrandKey}`;
  yield put(updateExpectVehiclePriceAnswerAction(true));
  try {
    const { data } = yield call<any>(API, {
      url: '/vehicle/price',
      method: 'POST',
      data: {
        ecu_brand: ecuBrand,
        actions: actions,
        dtc: dtcKey,
      },
    });
    const vehiclePrice = get(data, 'response', null);
    yield all([
      put(updateVehiclePriceAction(vehiclePrice)),
      put(updatePaymentAllowedAction(true)),
      put(updateProcessedDTCsAction(null)),
      put(updateProcessedActionsAction(null)),
      put(updateManualActionsAction(null)),
      put(updateExpectVehiclePriceAnswerAction(false)),
    ]);
  } catch ({ message }) {
    console.error('loadVehiclePriceSaga error: ', message);
    yield put(updateExpectVehiclePriceAnswerAction(false));
  }
}

function* billingOrderSaga() {
  const { fileKey } = yield select(addFileSelector);
  yield put(updateExpectBillingOrderAnswerAction(true));
  try {
    const { data: billingOrder } = yield call<any>(API, {
      url: '/billing/order',
      method: 'POST',
      data: {
        file: fileKey,
      },
    });
    const data = get(billingOrder, 'response.data', null);
    const actions = get(billingOrder, 'response.orderData.actions', null);
    const fileData = get(billingOrder, 'response.orderData.fileData', null);
    const price = get(billingOrder, 'response.orderData.price', null);
    const signature = get(billingOrder, 'response.signature', null);

    yield all([
      put(updateDataAction(data)),
      put(updateActionsAction(actions)),
      put(updateFileDataAction(fileData)),
      put(updatePriceAction(price)),
      put(updateSignatureAction(signature)),
      put(updateExpectBillingOrderAnswerAction(false)),
    ]);
  } catch (err) {
    console.error('billingOrderSaga error: ', err, err.message);
    yield put(updateExpectBillingOrderAnswerAction(false));
  }
}

function* paymentRequestSaga() {
  const { data, signature, price, fileKey } = yield select(addFileSelector);
  console.log(data, signature, price, fileKey)

  if  (parseFloat(price.toPay) === 0) {
    yield put(updateExpectRequestFileAnswerAction(true));
    try {
      const { data: billingOrder } = yield call<any>(API, {
        url: '/file/request/process',
        method: 'POST',
        data: {
          fileKey: fileKey,
        },
      });
      const response:IRequestFileResponse = get(billingOrder, 'response', null);

      yield all([
        //put(updateTaskAction(response)),
        put(updateRequestFileResponseAction(response)),
        put(updateExpectRequestFileAnswerAction(false))
      ])

    } catch (err) {
      yield put(updateExpectRequestFileAnswerAction(false));
      console.error('paymentRequestSaga error: ', err, err.message);
    }
  } else {
    let liqPayForm = document.createElement('form');
    liqPayForm.setAttribute('method', 'post');
    liqPayForm.setAttribute('action', 'https://www.liqpay.ua/api/3/checkout');
    //liqPayForm.setAttribute('target', '_blank');

    const d = document.createElement('input'); //input element, text
    d.setAttribute('type', 'text');
    d.setAttribute('name', 'data');
    d.setAttribute('value', data);

    const s = document.createElement('input'); //input element, text
    s.setAttribute('type', 'text');
    s.setAttribute('name', 'signature');
    s.setAttribute('value', signature);

    liqPayForm.appendChild(d);
    liqPayForm.appendChild(s);
    document.getElementsByTagName('body')[0].appendChild(liqPayForm);
    liqPayForm.submit();
    liqPayForm.remove();
  }
}
/*
function* paymentRequestSaga() {
  const { data, signature } = yield select(addFileSelector);
  let liqPayForm = document.createElement('form');
  liqPayForm.setAttribute('method', 'post');
  liqPayForm.setAttribute('action', 'https://www.liqpay.ua/api/3/checkout');
  //liqPayForm.setAttribute('target', '_blank');

  var d = document.createElement('input'); //input element, text
  d.setAttribute('type', 'text');
  d.setAttribute('name', 'data');
  d.setAttribute('value', data);

  var s = document.createElement('input'); //input element, text
  s.setAttribute('type', 'text');
  s.setAttribute('name', 'signature');
  s.setAttribute('value', signature);

  liqPayForm.appendChild(d);
  liqPayForm.appendChild(s);

  document.getElementsByTagName('body')[0].appendChild(liqPayForm);

  liqPayForm.submit();

  liqPayForm.remove();
}
*/
function* selectEcusSaga({ payload }: ReturnType<typeof selectSuggectECUsAction>) {
  const { suggectECUKey } = payload;
  const { suggectECUs } = yield select(addFileSelector);
  yield put(updateExpectBrandsAnswerAction(true));
  let keySelected: boolean = false;
  let updateSuggectECUs: ISuggectECUs[] | null = suggectECUs;
  if (Array.isArray(updateSuggectECUs)) {
    updateSuggectECUs = updateSuggectECUs.map((suggectECUItem: ISuggectECUs) => {
      if (suggectECUItem.key === suggectECUKey && !suggectECUItem.selected) {
        keySelected = true;
        return { ...suggectECUItem, selected: true };
      }
      return { ...suggectECUItem, selected: false };
    });
  }
  try {
    if (keySelected) {
      const { data } = yield call<any>(API, {
        url: '/vehicle/brands/byEcu',
        method: 'POST',
        data: {
          ecu: suggectECUKey,
        },
      });
      const vehicleBrands = get(data, 'response', null);
      yield all([
        put(updateVehicleBrandsAction(vehicleBrands)),
        put(updateSuggectECUsAction(updateSuggectECUs)),
        put(updateVehicleBrandsLoadedAction(true)),
        put(updateVehicleActionsLoadedAction(false)),
        put(updateShowInfoAction(false)),
        put(updateDtcAction('')),
        put(updateProcessedDTCsAction(null)),
        put(updateProcessedActionsAction(null)),
        put(updateManualActionsAction(null)),
        put(updateFileProcessedAction(false)),
        put(updateExpectBrandsAnswerAction(false)),
      ]);
    } else {
      yield all([
        put(updateVehicleBrandsAction(null)),
        put(updateSuggectECUsAction(updateSuggectECUs)),
        put(updateVehicleBrandsLoadedAction(false)),
        put(updateVehicleActionsLoadedAction(false)),
        put(updateShowInfoAction(false)),
        put(updateDtcAction('')),
        put(updateProcessedDTCsAction(null)),
        put(updateProcessedActionsAction(null)),
        put(updateManualActionsAction(null)),
        put(updateFileProcessedAction(false)),
        put(updateExpectBrandsAnswerAction(false)),
      ]);
    }
  } catch (err) {
    console.error('selectEcusSaga error: ', err, err.message);
    yield put(updateExpectBrandsAnswerAction(false));
  }
}

function* loadVehiclesActionsByBrandAndEcuSaga() {
  const { selectedEcuAndBrandKey } = yield select(addFileSelector);
  yield put(updateExpectActionsAnswerAction(true));
  try {
    const { data } = yield call<any>(API, {
      url: '/vehicle/actions',
      method: 'POST',
      data: {
        key: selectedEcuAndBrandKey,
      },
    });
    let vehicleActions = get(data, 'response.actions', null);
    const allowDtc = get(data, 'response.allowDtc', false);
    const autoDtc = get(data, 'response.autoDtc', false);
    vehicleActions = Array.isArray(vehicleActions) ? vehicleActions.map((vehicleAction: IVehicleAction) => ({ ...vehicleAction, selected: false })) : vehicleActions;
    yield all([
      put(updateVehicleActionsAction(vehicleActions)),
      put(updateAllowDtcAction(allowDtc)),
      put(updateAutoDtcAction(autoDtc)),
      put(updateVehicleActionsLoadedAction(true)),
      put(updateVehiclePriceAction(null)),
      put(updateShowInfoAction(true)),
      put(updateProcessedDTCsAction(null)),
      put(updateProcessedActionsAction(null)),
      put(updateManualActionsAction(null)),
      put(updateFileProcessedAction(false)),
      put(updateExpectActionsAnswerAction(false)),
    ]);
  } catch ({ message }) {
    console.error('loadVehiclesActionsByBrandAndEcuSaga error: ', message);
    yield put(updateExpectActionsAnswerAction(false));
  }
}

function* processFileSaga() {
  const { vehicleActions, dtcKey, selectedEcusKey, selectedBrandKey, selectedEcuAndBrandKey, fileKey } = yield select(addFileSelector);
  const actions = Array.isArray(vehicleActions)
    ? vehicleActions
        .filter((vehicleAction: IVehicleAction) => vehicleAction.selected)
        .map((vehicleAction: IVehicleAction) => vehicleAction.key)
        .join()
    : '';
  const key = selectedEcuAndBrandKey ? selectedEcuAndBrandKey : `${selectedEcusKey}-${selectedBrandKey}`;
  yield put(updateExpectProcessFileAnswerAction(true));
  try {
    const { data: processedData } = yield call<any>(API, {
      url: '/file/process',
      method: 'POST',
      data: {
        actions,
        dtc: dtcKey,
        key,
        fileKey,
      },
    });
    const processedDTCs = get(processedData, 'response.processedDTCs', null);
    const processedActions = get(processedData, 'response.processedActions', null);
    const manualActions = get(processedData, 'response.manualActions', null);
    yield all([
      put(updateProcessedDTCsAction(processedDTCs)),
      put(updateProcessedActionsAction(processedActions)),
      put(updateManualActionsAction(manualActions)),
      put(updateFileProcessedAction(true)),
      put(updateExpectProcessFileAnswerAction(false)),
    ]);
  } catch ({ message }) {
    console.error('processFileSaga error: ', message);
    yield put(updateExpectProcessFileAnswerAction(false));
  }
}

function* returnEcuSuggestionsSaga() {
  const { suggectECUs } = yield select(addFileSelector);
  let updateSuggectECUs: ISuggectECUs[] | null = Array.isArray(suggectECUs)
    ? suggectECUs.map((suggectECUItem: ISuggectECUs) => ({ ...suggectECUItem, selected: false }))
    : suggectECUs;
  yield all([
    put(updateSuggectECUsAction(updateSuggectECUs)),
    put(updateVehicleActionsAction(null)),
    put(updateAutoDtcAction(false)),
    put(updateNoEcusInListAction(false)),
    put(updateVehicleEcusLoadedAction(false)),
    put(updateVehicleBrandsLoadedAction(false)),
    put(updateVehicleActionsLoadedAction(false)),
    put(updateShowInfoAction(false)),
    put(updateDtcAction('')),
    put(updateProcessedDTCsAction(null)),
    put(updateProcessedActionsAction(null)),
    put(updateManualActionsAction(null)),
    put(updateFileProcessedAction(false)),
  ]);
}

function* activityTasks() {
  yield takeLatest(TYPE.LOAD_VEHICLES, loadVehiclesSaga);
  yield takeLatest(TYPE.LOAD_VEHICLE_BRANDS, loadVehiclesBrandsSaga);
  yield takeLatest(TYPE.LOAD_VEHICLE_ECUS, loadVehiclesEcusSaga);
  yield takeLatest(TYPE.LOAD_VEHICLE_ACTIONS, loadVehiclesActionsSaga);
  yield takeLatest(TYPE.LOAD_VEHICLE_ACTIONS_BY_BRAND_AND_ECU, loadVehiclesActionsByBrandAndEcuSaga);
  yield takeLatest(TYPE.LOAD_VEHICLE_PRICE, loadVehiclePriceSaga);
  yield takeLatest(TYPE.UPLOAD_FILE, uploadEcuFileSaga);
  yield takeLatest(TYPE.BILLING_ORDER, billingOrderSaga);
  yield takeLatest(TYPE.REQUEST_PAYMENT, paymentRequestSaga);
  yield takeLatest(TYPE.SELECT_ECUS, selectEcusSaga);
  yield takeLatest(TYPE.PROCESS_FILE, processFileSaga);
  yield takeLatest(TYPE.RETURN_ECU_SUGGESTIONS, returnEcuSuggestionsSaga);
}

export function* sagas() {
  yield fork(activityTasks);
}

export default sagas;
