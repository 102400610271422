import React, {useEffect, useState} from 'react';
import Layout from 'components/layout/Common';
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import Paper from "@material-ui/core/Paper";
import NewReleasesOutlinedIcon from "@material-ui/icons/NewReleasesOutlined";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {APP_COLOR, CONSTRAINT} from "../../../constants/style";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import UploadPayment from "../ed-payment";
import {connect} from "react-redux";
import {
    loadEdFileAction,
    selector as edUploadFileSelector,
    clearAction,
} from 'private-layout/encrypt-decrypt/upload/reducer'
import Preloader from "../../../components/Preloader";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        paddingBottom: 50,
    },
    oldPageLink: {
        fontSize: 14,
        marginLeft: 12,
    },
    actionsList: {
        margin: 0,
    },
    uploadWarningContainer: {
        width: "400px",
        padding: 15,
        fontSize: 'small',
        color: '#f44336',
        margin: '20px 0 10px 16px',
    },
    newReleaseIcon: {
        margin: '10px 0 0 -80px',
        position: 'absolute',
        width: 50,
        height: 50,
    },
    fileInfoContainer: {
        marginLeft: 20,
        lineHeight: '25px',
        overflow: 'hidden',
        display: "grid",
    },

    priceContainer: {
        marginTop: "5px",
        marginLeft: 20,
        lineHeight: '25px',
        overflow: 'hidden',
        display: "grid",
    },

    justifyEcuContainer: {
        marginTop: 20,
    },
    removeDtc: {
        marginTop: 16,
    },
    addFile: {
        padding: '20px 0',
    },
    payInfoContainer: {
        marginBottom: 10,
        marginTop: 20,
        padding: 15,
        fontSize: 'small',
        color: '#f44336',
    },
    processedDtcContainer: {
        padding: 15,
        maxWidth: 350,
        maxHeight: 100,
        margin: '8px 0 8px 1em',
        overflowY: 'auto',
    },
    noEcusMessage: {
        marginTop: 20,
    },
    optionsListContainer: {
        marginLeft: '20px',
    },
    rightBlockContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '40%',
    },
    optionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: theme.spacing(2),
    },
    uploaderContainer: {
        display: 'flex',
        width: '40%',
        flexDirection: 'column',
    },
    preloader: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        padding: '16px 0',
    },
    hideUploader: {
        visibility: 'hidden',
        position: 'absolute',
    },
    uploaderBtn: {
        display: "block",
        backgroundColor: (props: any) => (!props.uploadEcuSuccess ? theme.palette.primary.light : 'rgba(0, 0, 0, 0.26)'),
        padding: 8,
        cursor: 'pointer',
        width: 'max-content',
        minWidth: CONSTRAINT.BUTTON_WIDTH,
        textAlign: 'center',
        color: APP_COLOR.WHITE,
        borderRadius: 4,
        marginRight: 8,
        '&:hover': {
            backgroundColor: (props: any) => (!props.uploadEcuSuccess ? theme.palette.primary.dark : 'rgba(0, 0, 0, 0.26)'),
        },
        marginBottom: "20px",
        marginTop: "20px",
    },
    uploader: {
        display: 'flex',
        alignItems: 'center',
    },
    actions: {
        display: 'flex',
        paddingTop: 8,
    },
    denyTextTransform: {
        textTransform: 'none',
    },
    alert: {
        marginTop: 40,
    },
    cardInfo: {
        marginTop: 20,
    },
    cardInfoTextLine: {
        whiteSpace: 'nowrap',
    },
    cardInfoStrong: {
        display: 'inline-block',
        width: 150,
    },
    cardInfoActionsStrong: {
        display: 'inline-block',
        width: 200,
    },
    processedActionContainer: {
        padding: '20px 0 0 20px',
        lineHeight: '25px',
    },
    cogwheel: {
        cursor: 'pointer',
    },
    returnEcuSuggestions: {
        marginTop: 16,
        cursor: 'pointer',
    },
    radioBtnContainer: {
        marginLeft: '50px',
    },
    actionBtn: {
        marginTop: "20px",
        marginBottom: "20px",
    },
    hiddenBtn: {
        marginTop: "20px"
    },
    uploadPreloader: {
        paddingLeft: "20px",
        display: "inline-block"
    },
    payBtn: {
        width: "150px",
        marginTop: "20px",
        marginBottom: "20px",
    }
}));


interface IProps {
    pageTitle: string;
    language: string;

    fileInfo?: any;
    loadEdFile?: (formData: any) => void;
    expectAnswer?: boolean;
    clearState?: () => void;
}

function UploadFile(props: IProps) {
    const {pageTitle, language, fileInfo, loadEdFile, expectAnswer, clearState} = props;
    const {t} = useTranslation();
    const classes = useStyles({uploadEcuSuccess: false});

    const [file, setFile] = useState<null | File>(null);
    const [encodeDecodeRadioGroup, setEncodeDecodeRadioGroup] = useState<string>("decode");
    const [encodeRadioGroup, setEncodeRadioGroup] = useState<any>(null);
    const [openPayment, setOpenPayment] = useState<boolean>(false);

    useEffect(() => {
        //clean up data
        setFile(null);
        setEncodeDecodeRadioGroup("decode");
        setEncodeRadioGroup(null);
        setOpenPayment(false);

        //clear redux state
        clearState && clearState();
    }, [clearState])

    const changeEncodeDecodeRadioButton = (event: any) => {
        if (fileInfo || expectAnswer) {
            return;
        }
        setEncodeDecodeRadioGroup(event.target.value);
    }

    const changeDecodeRadioButton = (event: any) => {
        if (fileInfo || expectAnswer) {
            return;
        }
        setEncodeRadioGroup(event.target.value);
    }

    const handleOpenPayment = () => {
        setOpenPayment(true);
    }

    const handleClosePayment = () => {
        setOpenPayment(false);
    }

    const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const cFile = e.target.files ? e.target.files[0] : null;
        setFile(cFile);
    };

    const handleFileUploadSubmit = () => {
        uploadEncodeDecodeFile && uploadEncodeDecodeFile(file);
    };

    const isSelectedEncodeDecodeRadio = () => {
        if (encodeDecodeRadioGroup === null) {
            return false;
        }

        if (encodeDecodeRadioGroup === "decode") {
            return true;
        } else if (encodeDecodeRadioGroup === "encode" && encodeRadioGroup !== null) {
            return true;
        }

        return false;
    }

    const isRadioButtonDisabled = () => {
        return fileInfo || expectAnswer;
    }


    const uploadEncodeDecodeFile = (carFile: File | null) => {
        if (carFile === null || (encodeDecodeRadioGroup === "encode" && encodeRadioGroup === null)) {
            return;
        }

        loadEdFile && loadEdFile({
            carFile: carFile,
            encodeDecodeRadioGroup: encodeDecodeRadioGroup,
            encodeRadioGroup: encodeRadioGroup
        });
    };

    return (
        <Layout pageTitle={pageTitle} language={language}>
            <Typography variant='h5'>
                <span>{t('upload_file.title')}</span>
            </Typography>

            <Paper elevation={3} className={classes.uploadWarningContainer}>
                <NewReleasesOutlinedIcon className={classes.newReleaseIcon}/>
                {t('add_file.before_upload_warning_text_ed')}
            </Paper>

            <div className={classes.radioBtnContainer}>
                <RadioGroup name="fileEncodingType" value={encodeDecodeRadioGroup}
                            onChange={changeEncodeDecodeRadioButton}>
                    <FormControlLabel disabled={isRadioButtonDisabled()} value="decode" control={<Radio/>} label={t('upload_file.decrypt')}/>
                    <FormControlLabel disabled={isRadioButtonDisabled()} value="encode" control={<Radio/>} label={t('upload_file.encrypt')}/>
                </RadioGroup>
                {encodeDecodeRadioGroup === "encode" ?
                    <div className={classes.radioBtnContainer}>
                        <RadioGroup value={encodeRadioGroup} name="fileEncodingType" onChange={changeDecodeRadioButton}>
                            <FormControlLabel value="int_flash" control={<Radio/>} label="int_flash"
                                              disabled={isRadioButtonDisabled()} />
                            <FormControlLabel value="ext_flash" control={<Radio/>} label="ext_flash"
                                              disabled={isRadioButtonDisabled()} />
                            <FormControlLabel value="int_eeprom" control={<Radio/>} label="int_eeprom"
                                              disabled={isRadioButtonDisabled()} />
                            <FormControlLabel value="ext_eeprom" control={<Radio/>} label="ext_eeprom"
                                              disabled={isRadioButtonDisabled()} />
                            <FormControlLabel value="maps" control={<Radio/>} label="maps"
                                              disabled={isRadioButtonDisabled()} />
                        </RadioGroup>
                    </div> : null}
            </div>

            {!fileInfo && isSelectedEncodeDecodeRadio() && !expectAnswer ?
                <label className={classes.uploaderBtn}>
                    {t('button_title.choose_file')}
                     <input type='file' className={classes.hideUploader} onChange={handleFile}/>
                </label> : <div className={classes.hiddenBtn}/>}


            {file ?
                <div>
                    <div className={classes.fileInfoContainer}>
                <span className={classes.cardInfoTextLine}>
                    <strong className={classes.cardInfoStrong}>{t('upload_file.file_name')}</strong> {file.name}
                </span>
                        <span className={classes.cardInfoTextLine}>
                    <strong className={classes.cardInfoStrong}>{t('upload_file.size')}</strong> {file.size + " bytes"}
                </span>

                        {fileInfo ?
                            <span className={classes.cardInfoTextLine}>
                                <strong
                                    className={classes.cardInfoStrong}>{t('upload_file.key')}</strong> {fileInfo.key}
                            </span> : null}
                    </div>

                    <div>
                        {!fileInfo ?
                            <Button disabled={encodeDecodeRadioGroup === "encode" && !encodeRadioGroup}
                                    className={classes.actionBtn} variant='contained' color='primary'
                                    onClick={handleFileUploadSubmit}>
                                {t('upload_file.upload')}
                            </Button>
                            : <div className={classes.hiddenBtn}/>}

                        {expectAnswer ?
                            <div className={classes.uploadPreloader}>
                                <Preloader size={25}/>
                            </div>
                            : null}
                    </div>

                    {fileInfo ?
                        <div>
                            <Typography variant='h5'>
                                <span>{t('upload_file.price')}</span>
                            </Typography>

                            {fileInfo.orderInfo.to_pay !== '0.00' ? 
                                <>
                                    <div className={classes.priceContainer}>
                                        <span className={classes.cardInfoTextLine}>
                                                <strong
                                                    className={classes.cardInfoStrong}>{t('upload_file.to_pay')}</strong> {fileInfo.orderInfo.to_pay + " UAH"}
                                        </span>
                                    </div>
                                    <Button className={classes.payBtn} variant='contained' color='primary'
                                            onClick={handleOpenPayment}>
                                        {t('upload_file.pay')}
                                    </Button>
                                </> : 
                                <>
                                    <div className={classes.priceContainer}>
                                        <span className={classes.cardInfoTextLine}>
                                                <strong
                                                    className={classes.cardInfoStrong}>{t('upload_file.payed_by_subscription')}</strong>
                                        </span>
                                    </div>
                                    <Button className={classes.payBtn} variant='contained' color='primary'
                                        href={`/${language}/encrypt-decrypt-files/view-file/${fileInfo.key}`}>
                                        {t('upload_file.to_file')}
                                    </Button>
                                </>}
                        </div> : null}
                </div> : null
            }

            {fileInfo && openPayment ?
                <UploadPayment handleClose={handleClosePayment} open={openPayment}
                               fileKey={fileInfo.key}/>
                : null}

        </Layout>
    )
}

export default connect(
    (state) => edUploadFileSelector(state),
    (dispatch) => ({
        loadEdFile: (fileForm: any) => dispatch(loadEdFileAction(fileForm)),
        clearState: () => dispatch(clearAction()),
    })
)(UploadFile);