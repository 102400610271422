// local dependencies
import publicReducers from 'public-layout/reducers';
import privateReducers from 'private-layout/reducers';
// constants
import { DEFAULT_LANGUAGE } from 'constants/language';
// services
import CookieStorage from 'services/storage.service';

export const APP_TYPES = ((prefix) => ({
  PREFIX: new RegExp(prefix, 'i'),
  // complex action
  DATA: `${prefix}DATA`,
  // trigger
  NAVIGATE: `${prefix}NAVIGATE`,
  LOG_OUT: `${prefix}LOG_OUT`,
  INITIALIZE: `${prefix}INITIALIZE`,
}))('@app/');

// selectors
export const selector = (state: any) => state.app;

export function typedAction(type: string, payload?: any) {
  return { type, payload };
}

export interface IUser {
  username?: string | null;
  email?: string | null;
  isMms?: boolean | null;
}

interface IApp {
  user?: IUser | null;
  locale?: string | null;
  initialized?: boolean;
  appExpectAnswer?: boolean;
}

// app session flow
export const updateAppDataAction = (appData: IApp) => typedAction(APP_TYPES.DATA, appData);
export const updateAppExpectAnswerAction = (appExpectAnswer: boolean) => typedAction(APP_TYPES.DATA, { appExpectAnswer });

export const appNavigateAction = () => typedAction(APP_TYPES.NAVIGATE);

export const userLogOutAction = () => typedAction(APP_TYPES.LOG_OUT);
export const userInitializeAction = () => typedAction(APP_TYPES.INITIALIZE);

type UserAction = ReturnType<typeof updateAppDataAction | typeof userLogOutAction | typeof userInitializeAction>;

const appInitialState: IApp = {
  // logged user information
  user: null,
  initialized: false,
  locale: CookieStorage.getItem('locale') || DEFAULT_LANGUAGE,
  appExpectAnswer: false,
};

function app(state = appInitialState, action: UserAction) {
  switch (action.type) {
    default:
      return state;
    case APP_TYPES.DATA:
        console.log('appInitialState', action.payload)
      return { ...state, ...action.payload };
  }
}

export default { ...publicReducers, ...privateReducers, app };
