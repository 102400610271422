// outsource dependencies
import React from 'react';
import { connect } from 'react-redux';
import { Form, Field, reduxForm, InjectedFormProps } from 'redux-form';
import { useTranslation } from 'react-i18next';
// UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
// local dependencies
// UI
import CustomTextField from 'components/input/TextField';
import TermsConditionsCheckbox from 'components/checkbox/TermsConditionsCheckbox';
// constants
import { APP_COLOR } from 'constants/style';
// state selector and actions
import {
  selector as signUpSelector,
  updateEmailAction,
  updatePasswordAction,
  signUpSubmitAction,
  resetErrorSignUpAction,
  ISignUpError,
  updateErrorSignUpAction,
  SIGN_UP_FIELD_KEY,
  updateUsernameAction,
  updateCompanyAction,
  updateFirstNameAction,
  updateLastNameAction,
  updateMobilePhoneAction,
  updateTermsAgreeAction
} from 'public-layout/sign-up/reducer';
// services
import ValidationService from 'services/validation.service';
import { Paper } from '@material-ui/core';

// configurations
export const FORM_NAME = 'loginForm';
const useStyles = makeStyles({
  paperFullWidth: {
    width: 500,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 8px 8px 24px',
    backgroundColor: APP_COLOR.GREY,
    color: APP_COLOR.WHITE,
  },
  close: {
    padding: 6,
    color: APP_COLOR.WHITE,
    cursor: 'pointer',
  },
  titleContent: {
    lineHeight: 2,
  },
  content: {
    display: 'flex',
    padding: '12px 30px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 16,
  },
  signUpAction: {
    backgroundColor: APP_COLOR.LIGHT_GREY,
    color: APP_COLOR.WHITE,
    '&:hover': {
      color: APP_COLOR.BLACK,
    },
    transition: '0.6s',
  },
});

interface IProps {
  open: boolean;
  isTermsAgree?: boolean;
  username?: string | null;
  email?: string | null;
  password?: string | null;
  company?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  mobile_phone?: string | null;
  errors?: ISignUpError | null;
  expectAnswer?: boolean;
  confirmEmail?: boolean;
  //
  handleClose: () => void;
  updateEmail?: (email: string) => void;
  updatePassword?: (password: string) => void;
  updateTermsAgree?: (isTermsAgree: boolean) => void;
  signUpSubmit?: () => any;
  resetError?: (fieldKey: string) => void;
  updateErrorSignUp?: (errors: ISignUpError) => void;
  updateFirstName?: (first_name: string) => void;
  updateLastName?: (last_name: string) => void;
  updateMobilePhone?: (mobile_phone: string) => void;
  updateUsername?: (username: string) => void;
  updateCompany?: (company: string) => void;
  //
  validate?: (loginFormData: ILoginForm) => ILoginFormError | null;
}

const SignUpDialog: React.FC<IProps & InjectedFormProps<{}, IProps>> = (props: IProps) => {
  const {
    open,
    isTermsAgree,
    username,
    confirmEmail,
    email,
    password,
    expectAnswer,
    errors,
    company,
    first_name,
    last_name,
    mobile_phone,
    //
    handleClose,
    resetError,
    updateErrorSignUp,
    updateEmail,
    updatePassword,
    signUpSubmit,
    updateFirstName,
    updateLastName,
    updateMobilePhone,
    updateUsername,
    updateCompany,
    updateTermsAgree,
    //
    validate,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const isEmailError = Boolean(errors?.email);
  const isPasswordError = Boolean(errors?.password);
  const isCompanyError = Boolean(errors?.company);
  const isFirstNameError = Boolean(errors?.first_name);
  const isLastNameError = Boolean(errors?.last_name);
  const isMobilePhoneError = Boolean(errors?.mobile_phone);
  const isUsernameError = Boolean(errors?.username);

  const emailError = typeof errors?.email === 'string' ? errors.email : '';
  const passwordError = typeof errors?.password === 'string' ? errors.password : '';
  const companyError = typeof errors?.company === 'string' ? errors.company : '';
  const firstNameError = typeof errors?.first_name === 'string' ? errors.first_name : '';
  const lastNameError = typeof errors?.last_name === 'string' ? errors.last_name : '';
  const mobilePhoneError = typeof errors?.mobile_phone === 'string' ? errors.mobile_phone : '';
  const usernameError = typeof errors?.username === 'string' ? errors.username : '';

  const isSubmitNotAvailable = (errors && Boolean(Object.values(errors).filter((error) => Boolean(error)).length)) || !isTermsAgree;

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateEmail && updateEmail(e.target.value);
  };
  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    updatePassword && updatePassword(e.target.value);
  };
  const handleCompany = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateCompany && updateCompany(e.target.value);
  };
  const handleUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateUsername && updateUsername(e.target.value);
  };
  const handleMobilePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateMobilePhone && updateMobilePhone(e.target.value);
  };
  const handleFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFirstName && updateFirstName(e.target.value);
  };
  const handleLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateLastName && updateLastName(e.target.value);
  };
  const handleTermsAndConditionsAgree = () => {
    updateTermsAgree && updateTermsAgree(!isTermsAgree);
  };

  const handleOnFocus = (fieldKey: string) => () => {
    const isErrorInState = errors && Object.keys(errors).some((errorKey: string, index) => Object.values(errors)[index] && fieldKey === errorKey);
    if (isErrorInState && resetError) {
      resetError(fieldKey);
    }
  };

  const handleFormLogin = (e: React.FormEvent<HTMLInputElement>) => {
    // prevent reload on 'ENTER' press
    e.preventDefault();
    e.persist();
    handleLogin();
  };

  const handleLogin = () => {
    const localErrors =
      validate &&
      validate({
        email,
        password,
        company,
        first_name,
        last_name,
        mobile_phone,
        username,
      });
    if (!localErrors && !isSubmitNotAvailable) {
      signUpSubmit && signUpSubmit();
    }
    if (localErrors && updateErrorSignUp) {
      updateErrorSignUp(localErrors);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm' classes={{ paperFullWidth: classes.paperFullWidth }}>
      <DialogTitle disableTypography classes={{ root: classes.title }}>
        <Typography variant='h6' className={classes.titleContent}>
          {t('dialog.title.sign_up')}
        </Typography>
        <CloseIcon onClick={handleClose} className={classes.close} />
      </DialogTitle>
      <DialogContent className={classes.content}>
        {!confirmEmail && (
          <Form onSubmit={handleFormLogin} className={classes.form}>
            <Field
              type='text'
              component={CustomTextField}
              name='username'
              placeholder={t('input.placeholder.username')}
              label={`* ${t('input.label.username')}`}
              onChange={handleUsername}
              error={isUsernameError}
              onFocus={handleOnFocus(SIGN_UP_FIELD_KEY.username)}
              helperText={t(usernameError)}
            />
            <Field
              type='text'
              component={CustomTextField}
              name='email'
              placeholder={t('input.placeholder.email')}
              label={`* ${t('input.label.email')}`}
              onChange={handleEmail}
              error={isEmailError}
              onFocus={handleOnFocus(SIGN_UP_FIELD_KEY.email)}
              helperText={t(emailError)}
            />
            <Field
              type='text'
              component={CustomTextField}
              name='mobile_phone'
              placeholder={t('input.placeholder.mobile_phone')}
              label={`* ${t('input.label.mobile_phone')}`}
              onChange={handleMobilePhone}
              error={isMobilePhoneError}
              onFocus={handleOnFocus(SIGN_UP_FIELD_KEY.mobile_phone)}
              helperText={t(mobilePhoneError)}
            />
            <Field
              type='password'
              component={CustomTextField}
              name='password'
              placeholder={t('input.placeholder.password')}
              label={`* ${t('input.label.password')}`}
              onChange={handlePassword}
              onFocus={handleOnFocus(SIGN_UP_FIELD_KEY.password)}
              error={isPasswordError}
              helperText={t(passwordError)}
            />
            <Field
              type='text'
              component={CustomTextField}
              name='company'
              placeholder={t('input.placeholder.company')}
              label={t('input.label.company')}
              onChange={handleCompany}
              error={isCompanyError}
              onFocus={handleOnFocus(SIGN_UP_FIELD_KEY.company)}
              helperText={t(companyError)}
            />
            <Field
              type='text'
              component={CustomTextField}
              name='first_name'
              placeholder={t('input.placeholder.first_name')}
              label={t('input.label.first_name')}
              onChange={handleFirstName}
              error={isFirstNameError}
              onFocus={handleOnFocus(SIGN_UP_FIELD_KEY.first_name)}
              helperText={t(firstNameError)}
            />
            <Field
              type='text'
              component={CustomTextField}
              name='last_name'
              placeholder={t('input.placeholder.last_name')}
              label={t('input.label.last_name')}
              onChange={handleLastName}
              error={isLastNameError}
              onFocus={handleOnFocus(SIGN_UP_FIELD_KEY.last_name)}
              helperText={t(lastNameError)}
            />
            <div className={classes.actions}>
              <TermsConditionsCheckbox checked={Boolean(isTermsAgree)} onChange={handleTermsAndConditionsAgree} />
              <div>
                {expectAnswer && <CircularProgress />}
                {!expectAnswer && (
                  <div>
                    <Button type='submit' onClick={handleLogin} className={classes.signUpAction} disabled={Boolean(isSubmitNotAvailable)}>
                      {t('button_title.continue')}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Form>
        )}
        {confirmEmail && <Paper style={{ padding: 20, paddingTop: 70, height: 100 }}>{t('signup.confirm_email')}</Paper>}
      </DialogContent>
    </Dialog>
  );
};

interface ILoginForm {
  username?: string | null;
  company?: string | null;
  password?: string | null;
  email?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  mobile_phone?: string | null;
}
interface ILoginFormError {
  username?: string | null;
  company?: string | null;
  password?: string | null;
  email?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  mobile_phone?: string | null;
}

export default connect(
  (state) => ({
    ...signUpSelector(state),
    initialValues: {
      username: signUpSelector(state).username,
      company: signUpSelector(state).company,
      password: signUpSelector(state).password,
      email: signUpSelector(state).email,
      first_name: signUpSelector(state).first_name,
      last_name: signUpSelector(state).last_name,
      mobile_phone: signUpSelector(state).mobile_phone,
    },
  }),
  (dispatch) => ({
    updateUsername: (username: string) => dispatch(updateUsernameAction(username)),
    updateCompany: (company: string) => dispatch(updateCompanyAction(company)),
    updatePassword: (password: string) => dispatch(updatePasswordAction(password)),
    updateEmail: (email: string) => dispatch(updateEmailAction(email)),
    updateFirstName: (first_name: string) => dispatch(updateFirstNameAction(first_name)),
    updateLastName: (last_name: string) => dispatch(updateLastNameAction(last_name)),
    updateMobilePhone: (mobile_phone: string) => dispatch(updateMobilePhoneAction(mobile_phone)),
    updateTermsAgree: (isTermsAgree: boolean) => dispatch(updateTermsAgreeAction(isTermsAgree)),
    signUpSubmit: () => dispatch(signUpSubmitAction()),
    resetError: (fieldKey: string) => dispatch(resetErrorSignUpAction(fieldKey)),
    updateErrorSignUp: (errors: ISignUpError) => dispatch(updateErrorSignUpAction(errors)),
  })
)(
  reduxForm<{}, IProps>({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: (values: ILoginForm): any => {
      const errors: ILoginFormError = {
        username: null,
        company: null,
        password: null,
        email: null,
        first_name: null,
        last_name: null,
        mobile_phone: null,
      };
      // const emailInvalid = values.email && ValidationService.getEmailInvalidReason(values.email, null);
      // const passwordInvalid = values.password && ValidationService.getPasswordInvalidReason(values.password, null);
      const emailInvalid = typeof values.email === 'string' && ValidationService.getEmailInvalidReason(values.email, null);
      const passwordInvalid = typeof values.password === 'string' && ValidationService.getPasswordInvalidReason(values.password, null);
      const usernameInvalid = typeof values.username === 'string' && ValidationService.getUsernameInvalidReason(values.username, null);
      const mobilePhoneInvalid = typeof values.mobile_phone === 'string' && ValidationService.getMobilePhoneInvalidReason(values.mobile_phone, null);
      const companyInvalid = typeof values.company === 'string' && Boolean(values.company.trim().length) && ValidationService.getCompanyInvalidReason(values.company, null);
      const firstNameInvalid =
        typeof values.first_name === 'string' && Boolean(values.first_name.trim().length) && ValidationService.getNameInvalidReason(values.first_name, null, 'first_name');
      const lastNameInvalid =
        typeof values.last_name === 'string' && Boolean(values.last_name.trim().length) && ValidationService.getNameInvalidReason(values.last_name, null, 'last_name');
      

      if (passwordInvalid) {
        errors.password = passwordInvalid;
      }
      if (emailInvalid) {
        errors.email = emailInvalid;
      }
      if (usernameInvalid) {
        errors.username = usernameInvalid;
      }
      if (companyInvalid) {
        errors.company = companyInvalid;
      }
      if (firstNameInvalid) {
        errors.first_name = firstNameInvalid;
      }
      if (lastNameInvalid) {
        errors.last_name = lastNameInvalid;
      }
      if (mobilePhoneInvalid) {
        errors.mobile_phone = mobilePhoneInvalid;
      }
      // if at least one error were found
      if (Object.values(errors).filter((error) => Boolean(error)).length) return errors;
      return null;
    },
  })(SignUpDialog)
);
