import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import {
    loadInitialDataAction, setInitialDataAction,
    TYPE,
    updateExpectAnswerAction,
    loadEdFileAction,
    setEdFileInfoAction, updateUploadedExpectAnswerAction
} from 'private-layout/encrypt-decrypt/file-view/reducer'
import API, {API_FORM_DATA} from "services/request.service";
import get from "lodash/get";


function* loadInitialDataSaga({payload}: ReturnType<typeof loadInitialDataAction>) {
    yield put(updateExpectAnswerAction(true));

    try {
        const {data} = yield call<any>(API, {
            url: `/client/encode-decode/file/view-file/${payload}`,
            method: 'get',
        });

        const initialData = get(data, 'response', null);

        yield all([
            put(setInitialDataAction({fileInfo: initialData})),
            put(updateExpectAnswerAction(false))
        ]);
    } catch ({message}) {
        console.error('loadInitialData error: ', message);
        yield put(updateExpectAnswerAction(false));
    }

}

function* loadEdFileSaga({payload}: ReturnType<typeof loadEdFileAction>) {
    yield put(updateUploadedExpectAnswerAction(true));

    try {

        const formData = new FormData();
        formData.append('clientFile', payload.carFile);
        formData.append('parentFileKey', payload.parentFileKey);

        const {data} = yield call<any>(API_FORM_DATA, {
            url: '/client/encode-decode/file/upload/result/v1',
            method: 'POST',
            data: formData,
        });
        const fileInfo = get(data, 'response', null);


        //test data. remove this in dev/prod env
        /**
        const fileInfo = {
            key: "e8a8c7",
            status: "1",
            orderInfo: {
                to_pay: 332.00
            }
        };
        */


        yield all([
            put(setEdFileInfoAction({edFileInfo: fileInfo})),
            put(updateUploadedExpectAnswerAction(false))
        ]);
    } catch ({message}) {
        console.error('loadInitialData error: ', message);
        yield put(updateUploadedExpectAnswerAction(false));
    }

}

function* activityTasks() {
    yield takeLatest(TYPE.LOAD_INITIAL_DATA, loadInitialDataSaga);
    yield takeLatest(TYPE.LOAD_ED_FILE, loadEdFileSaga);
}

export function* sagas() {
    yield fork(activityTasks);
}

export default sagas;