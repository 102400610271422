// types
export const TYPE = ((prefix) => ({
  PREFIX: new RegExp(prefix, 'i'),
  // complex actions
  CLEAR: `${prefix}CLEAR`,
  DATA: `${prefix}DATA`,
  ERROR: `${prefix}ERROR`,
  RESET_ERROR: `${prefix}RESET_ERROR`,
  LOG_IN_SUBMIT: `${prefix}LOG_IN_SUBMIT`,
  REQUEST_PASSWORD: `${prefix}REQUEST_PASSWORD`,
}))('@log-in/');

// selectors
export const selector = (state: any) => state.login;

export const LOGIN_FIELD_KEY = {
  email: 'email',
  password: 'password',
};

export interface ILoginError {
  email?: string | null;
  password?: string | null;
}

interface IUserState {
  email: string;
  password: string;
  isMms: boolean;
  expectAnswer: boolean;
  expectRequestPasswordAnswer: boolean;
  errors: ILoginError | null;
  requestNewPasswordSuccess: boolean;
}

export function typedAction(type: string, payload?: any) {
  return { type, payload };
}

// login flow
export const updateEmailAction = (email: string) => typedAction(TYPE.DATA, { email });
export const updatePasswordAction = (password: string) => typedAction(TYPE.DATA, { password });

export const resetErrorLoginAction = (fieldKey: string) => typedAction(TYPE.RESET_ERROR, { fieldKey });
export const updateErrorLogInAction = (errors: ILoginError) => typedAction(TYPE.ERROR, errors);

export const updateExpectAnswerAction = (expectAnswer: boolean) => typedAction(TYPE.DATA, { expectAnswer });
export const updateExpectRequestPasswordAnswerAction = (expectRequestPasswordAnswer: boolean) => typedAction(TYPE.DATA, { expectRequestPasswordAnswer });
export const updateRequestNewPasswordSuccessAction = (requestNewPasswordSuccess: boolean) => typedAction(TYPE.DATA, { requestNewPasswordSuccess });

export const loginSubmitAction = () => typedAction(TYPE.LOG_IN_SUBMIT);
export const requestNewPasswordAction = () => typedAction(TYPE.REQUEST_PASSWORD);

type UserAction = ReturnType<
  | typeof updateEmailAction
  | typeof updatePasswordAction
  | typeof loginSubmitAction
  | typeof updateExpectAnswerAction
  | typeof updateExpectRequestPasswordAnswerAction
  | typeof resetErrorLoginAction
  | typeof updateErrorLogInAction
  | typeof requestNewPasswordAction
  | typeof updateRequestNewPasswordSuccessAction
>;

const initialState: IUserState = {
  email: '',
  password: '',
  isMms: false,
  expectAnswer: false,
  expectRequestPasswordAnswer: false,
  errors: null, 
  requestNewPasswordSuccess: false,
};

export function login(state = initialState, action: UserAction): IUserState {
  switch (action.type) {
    default:
      return state;
    case TYPE.DATA:
      return { ...state, ...action.payload };
    case TYPE.ERROR:
      return _setError(state, action.payload);
    case TYPE.RESET_ERROR:
      return _resetError(state, action.payload);
  }
}

const _resetError = (state: IUserState, error: { fieldKey: string }) => {
  const newErrors = state.errors ? { ...state.errors, [error.fieldKey]: null } : { [error.fieldKey]: null };
  return { ...state, errors: newErrors };
};

const _setError = (state: IUserState, errors: ILoginError) => {
  const newErrors = state.errors ? { ...state.errors, ...errors } : errors;
  return { ...state, errors: newErrors };
};
