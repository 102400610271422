

//types
export const TYPE = ((prefix) => ({
    PREFIX: new RegExp(prefix, 'i'),
    // complex actions
    CLEAR: `${prefix}CLEAR`,
    DATA: `${prefix}DATA`,
    LOAD_INITIAL_DATA: `${prefix}LOAD_INITIAL_DATA`
    // loading actions

}))('@ed-file-list/');


export const selector = (state: any) => ({ ...state.edFileList, user:state.app.user });


interface IUserState {
    expectAnswer: boolean;
    fileList: any[];
}

export function typedAction(type: string, payload?: any) {
    return { type, payload };
}

export const updateExpectAnswerAction = (expectAnswer: boolean) => typedAction(TYPE.DATA, { expectAnswer });
export const loadInitialDataAction = () => typedAction(TYPE.LOAD_INITIAL_DATA);
export const setInitialDataAction = (fileList: any[]) => typedAction(TYPE.DATA, fileList);

type UserAction = ReturnType<
    | typeof updateExpectAnswerAction
    //
    | typeof loadInitialDataAction
    >;

const initialState: IUserState = {
    expectAnswer: false,
    fileList: [],
};

export function edFileList(state = initialState, action: UserAction): IUserState {
    switch (action.type) {
        default:
            return state;
        case TYPE.DATA:
            return { ...state, ...action.payload };
        case TYPE.CLEAR:
            return initialState;
    }
}
