// outsource dependencies
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
// local dependencies
// injecting translations
import 'translation/i18next';
import theme from 'styles/theme';
import store from 'store';
import Routes from 'routes';
// import PrivateRoutes from 'private-layout';
import 'styles/index.css';

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);
