// outsource dependencies
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router';
// UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// local dependencies
import {
  selector as changePasswordSelector,
  updateNewPasswordAction,
  updateNewPasswordDuplicateAction,
  submitChangePasswordAction,
  updateTokenAction,
  IChangePasswordError,
  resetErrorAction,
  updateErrorAction,
  clearStateAction,
  updateNewPasswordSuccessAction,
} from 'public-layout/change-password/reducer';
// UI
import Layout from 'components/layout/Common';
import BaseDialog from 'components/dialog/Base';
import CustomTextField from 'components/input/TextField';
// services
import ValidationService from 'services/validation.service';
// constants
import { PUBLIC as PUBLIC_ROUTE } from 'constants/routes';

// configurations
export const FORM_NAME = 'changePasswordForm';

const FIELD_KEY = {
  newPassword: 'newPassword',
  newPasswordDuplicate: 'newPasswordDuplicate',
};
const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '50%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16,
  },
});
interface IProps {
  pageTitle: string;
  language: string;
  //
  expectAnswer?: boolean;
  newPassword?: string;
  newPasswordDuplicate?: string;
  newPasswordSuccess?: boolean;
  //
  submitChangePassword?: () => void;
  updateNewPassword?: (newPassword: string) => void;
  updateNewPasswordDuplicate?: (newPasswordDuplicate: string) => void;
  updateNewPasswordSuccess?: (newPasswordSuccess: boolean) => void;
  updateToken?: (token: string) => void;
  handleSubmit?: any; // redux
  validate?: (formData: IChangePasswordError) => IChangePasswordError | null;
  resetError?: (fieldKey: string) => void;
  updateError?: (errors: IChangePasswordError) => void;
  errors?: IChangePasswordError | null;
  clearState?: () => void;
}

function ChangePassword(props: IProps) {
  console.log("Change-pass");
  const {
    pageTitle,
    language,
    //
    expectAnswer,
    newPassword,
    newPasswordDuplicate,
    newPasswordSuccess,
    errors,
    //
    updateNewPassword,
    updateNewPasswordDuplicate,
    updateNewPasswordSuccess,
    updateToken,
    resetError,
    updateError,
    //
    submitChangePassword,
    clearState,
    //
    handleSubmit,
    validate,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  let { token: tokenId } = useParams<{ token: string }>();

  const isNewPasswordError = Boolean(errors?.newPassword);
  const isNewPasswordDuplicateError = Boolean(errors?.newPasswordDuplicate);

  const newPasswordError = typeof errors?.newPassword === 'string' ? errors.newPassword : '';
  const newPasswordDuplicateError = typeof errors?.newPasswordDuplicate === 'string' ? errors.newPasswordDuplicate : '';

  const isSubmitNotAvailable = errors && Boolean(Object.values(errors).filter((error) => Boolean(error)).length);

  const handleNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateNewPassword && updateNewPassword(e.target.value);
  };
  const handleNewPasswordDuplicate = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateNewPasswordDuplicate && updateNewPasswordDuplicate(e.target.value);
  };
  const handleChangePassword = () => {
    const localErrors = validate && validate({ newPassword, newPasswordDuplicate });
    if (!localErrors && !isSubmitNotAvailable) {
      submitChangePassword && submitChangePassword();
    }
    if (localErrors && updateError) {
      updateError(localErrors);
    }
  };
  const handleOnFocus = (fieldKey: string) => () => {
    const isErrorInState = errors && Object.keys(errors).some((errorKey: string, index) => Object.values(errors)[index] && fieldKey === errorKey);
    if (isErrorInState && resetError) {
      resetError(fieldKey);
    }
  };
  const handlePasswordSuccessClose = () => {
    updateNewPasswordSuccess && updateNewPasswordSuccess(false);
    history.push(PUBLIC_ROUTE.HOME);
  };

  useEffect(() => {
    if (tokenId) updateToken && updateToken(tokenId);
    return () => {
      clearState && clearState();
    };
  }, [updateToken, clearState, tokenId]);

  return (
    <>
      <Layout pageTitle={pageTitle} language={language}>
        <Typography variant='h5'>{t('public_page.change_password.title')}</Typography>
        <Form onSubmit={handleSubmit(handleChangePassword)} className={classes.form}>
          <Field
            type='password'
            component={CustomTextField}
            name='newPassword'
            placeholder={t('input.placeholder.new_password')}
            label={t('input.label.new_password')}
            onChange={handleNewPassword}
            onFocus={handleOnFocus(FIELD_KEY.newPassword)}
            error={isNewPasswordError}
            helperText={t(newPasswordError)}
          />
          <Field
            type='password'
            component={CustomTextField}
            name='newPasswordDuplicate'
            placeholder={t('input.placeholder.new_password_duplicate')}
            label={t('input.label.new_password_duplicate')}
            style={{ marginTop: 16 }}
            onChange={handleNewPasswordDuplicate}
            onFocus={handleOnFocus(FIELD_KEY.newPasswordDuplicate)}
            error={isNewPasswordDuplicateError}
            helperText={t(newPasswordDuplicateError)}
          />
          <div className={classes.actions}>
            {expectAnswer && <CircularProgress />}
            {!expectAnswer && (
              <Button variant='contained' color='primary' onClick={handleChangePassword} disabled={Boolean(isSubmitNotAvailable)}>
                {t('button_title.change_password')}
              </Button>
            )}
          </div>
        </Form>
      </Layout>
      {newPasswordSuccess && (
        <BaseDialog
          open={newPasswordSuccess}
          handleClose={handlePasswordSuccessClose}
          title={t('dialog.title.change_password_success')}
          textContent={t('dialog.content.change_password_success')}
        />
      )}
    </>
  );
}

interface IChangePasswordForm {
  newPassword?: string | null;
  newPasswordDuplicate?: string | null;
}
interface IChangePasswordFormError {
  newPassword?: string | null;
  newPasswordDuplicate?: string | null;
}

export default connect(
  (state) => changePasswordSelector(state),
  (dispatch) => ({
    submitChangePassword: () => dispatch(submitChangePasswordAction()),
    updateNewPassword: (newPassword: string) => dispatch(updateNewPasswordAction(newPassword)),
    updateNewPasswordDuplicate: (newPasswordDuplicate: string) => dispatch(updateNewPasswordDuplicateAction(newPasswordDuplicate)),
    updateNewPasswordSuccess: (newPasswordSuccess: boolean) => dispatch(updateNewPasswordSuccessAction(newPasswordSuccess)),
    updateToken: (token: string) => dispatch(updateTokenAction(token)),
    resetError: (fieldKey: string) => dispatch(resetErrorAction(fieldKey)),
    updateError: (errors: IChangePasswordError) => dispatch(updateErrorAction(errors)),
    clearState: () => dispatch(clearStateAction()),
  })
)(
  reduxForm<{}, IProps>({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: (values: IChangePasswordForm): any => {
      const errors: IChangePasswordFormError = {
        newPassword: null,
        newPasswordDuplicate: null,
      };
      const newPasswordInvalid = typeof values.newPassword === 'string' && ValidationService.getPasswordInvalidReason(values.newPassword, null);
      const newPasswordDuplicateInvalid = typeof values.newPasswordDuplicate === 'string' && ValidationService.getPasswordInvalidReason(values.newPasswordDuplicate, null);
      if (newPasswordInvalid) {
        errors.newPassword = newPasswordInvalid;
      }
      if (newPasswordDuplicateInvalid) {
        errors.newPasswordDuplicate = newPasswordDuplicateInvalid;
      }
      if (values.newPassword && values.newPasswordDuplicate && values.newPassword !== values.newPasswordDuplicate) {
        errors.newPasswordDuplicate = 'errors.validation_error.passwords_not_equal';
      }
      // if at least one error were found
      if (Object.values(errors).filter((error) => Boolean(error)).length) return errors;
      return null;
    },
  })(ChangePassword)
);
