// outsource dependencies
import { fork, takeLatest, call, put, all, select } from 'redux-saga/effects';
import get from 'lodash/get';
// local dependencies
import {
  selector as processingTasksSelector,
  //
  TYPE,
  //
  updateExpectAnswerAction,
  updateExpectOrderAnswerAction,
  updateExpectRequestFileAnswerAction,
  updateRequestFileResponseAction,
  updateTasksAction,
  updateTaskAction,
  updateFileDataAction,
  updatePriceAction,
  updateActionsAction,
  updateSignatureAction,
  updateDataAction,
  IRequestFileResponse, loadFileHistoryAction, updateFileHistory,
} from 'private-layout/processing-tasks/reducer';
import API from 'services/request.service';

function* loadTasksSaga() {
  yield put(updateExpectAnswerAction(true));
  try {
    const { data } = yield call<any>(API, {
      url: '/client/files',
      method: 'GET',
    });
    const tasks = get(data, 'response', null);
    yield all([put(updateTasksAction(tasks)), put(updateExpectAnswerAction(false))]);
  } catch (e) {
    console.error('loadVehiclesSaga error: ', e.response, e);
    yield put(updateExpectAnswerAction(false));
  }
}

function* loadFileHistorySaga({ payload }: ReturnType<typeof loadFileHistoryAction>) {
  //yield put(updateExpectAnswerAction(true));
  try {
    const { data } = yield call<any>(API, {
      url: '/client/files_history',
      method: 'POST',
      data: {
        key: payload
      }
    });
    const filesHistory = get(data, 'response', null);
    yield put(updateFileHistory(filesHistory));
  } catch (e) {
    console.error('loadVehiclesSaga error: ', e.response, e);
    //yield put(updateExpectAnswerAction(false));
  }
}

function* billingOrderSaga() {
  const { selectedFileKey } = yield select(processingTasksSelector);
  yield put(updateExpectOrderAnswerAction(true));
  try {
    const { data: billingOrder } = yield call<any>(API, {
      url: '/billing/order',
      method: 'POST',
      data: {
        file: selectedFileKey,
      },
    });
    const data = get(billingOrder, 'response.data', null);
    const actions = get(billingOrder, 'response.orderData.actions', null);
    const fileData = get(billingOrder, 'response.orderData.fileData', null);
    const price = get(billingOrder, 'response.orderData.price', null);
    const signature = get(billingOrder, 'response.signature', null);

    yield all([
      put(updateExpectOrderAnswerAction(false)),
      put(updateDataAction(data)),
      put(updateActionsAction(actions)),
      put(updateFileDataAction(fileData)),
      put(updatePriceAction(price)),
      put(updateSignatureAction(signature)),
      put(updateRequestFileResponseAction(null))
    ]);
  } catch (err) {
    yield put(updateExpectOrderAnswerAction(false));
    console.error('billingOrderSaga error: ', err, err.message);
  }
}

function* paymentRequestSaga() {
  const { data, signature, price, selectedFileKey } = yield select(processingTasksSelector);
  console.log(price)

  if  (parseFloat(price.toPay) === 0) {
    yield put(updateExpectRequestFileAnswerAction(true));
    try {
      const { data: billingOrder } = yield call<any>(API, {
        url: '/file/request/process',
        method: 'POST',
        data: {
          fileKey: selectedFileKey,
        },
      });
      const response:IRequestFileResponse = get(billingOrder, 'response', null);

      yield all([
        put(updateTaskAction(response)),
        put(updateRequestFileResponseAction(response)),
        put(updateExpectRequestFileAnswerAction(false))
      ])

    } catch (err) {
      yield put(updateExpectRequestFileAnswerAction(false));
      console.error('paymentRequestSaga error: ', err, err.message);
    }
  } else {
    let liqPayForm = document.createElement('form');
    liqPayForm.setAttribute('method', 'post');
    liqPayForm.setAttribute('action', 'https://www.liqpay.ua/api/3/checkout');
    //liqPayForm.setAttribute('target', '_blank');

    const d = document.createElement('input'); //input element, text
    d.setAttribute('type', 'text');
    d.setAttribute('name', 'data');
    d.setAttribute('value', data);

    const s = document.createElement('input'); //input element, text
    s.setAttribute('type', 'text');
    s.setAttribute('name', 'signature');
    s.setAttribute('value', signature);

    liqPayForm.appendChild(d);
    liqPayForm.appendChild(s);
    document.getElementsByTagName('body')[0].appendChild(liqPayForm);
    liqPayForm.submit();
    liqPayForm.remove();
  }
}

function* activityTasks() {
  yield takeLatest(TYPE.LOAD_FILES, loadTasksSaga);
  yield takeLatest(TYPE.BILLING_ORDER, billingOrderSaga);
  yield takeLatest(TYPE.REQUEST_PAYMENT, paymentRequestSaga);
  yield takeLatest(TYPE.LOAD_FILE_HISTORY, loadFileHistorySaga)
}

export function* sagas() {
  yield fork(activityTasks);
}

export default sagas;
