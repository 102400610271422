// outsource dependencies
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
// UI
import MaterialLink from '@material-ui/core/Link';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, Theme } from '@material-ui/core/styles';
import NewReleasesOutlinedIcon from '@material-ui/icons/NewReleasesOutlined';
import Paper from '@material-ui/core/Paper';
// local dependencies
import {
  selector as addFileSelector,
  // triggers
  loadVehiclesAction,
  loadVehicleBrandsAction,
  loadVehicleEcusAction,
  loadVehicleActionsAction,
  loadVehicleActionsByBrandAndEcuAction,
  loadVehiclePriceAction,
  // interfaces
  IVehicle,
  IVehicleBrand,
  IVehicleEcus,
  IVehicleAction,
  IVehiclePrice,
  // state update
  updateFileInfoAction,
  selectSuggectECUsAction,
  updateSelectedEcusKeyAction,
  updateSelectedBrandKeyAction,
  updateSelectedEcusAndBrandKeyAction,
  updateSelectActionStateAction,
  updateDtcAction,
  updateFileKeyAction,
  updateShowInfoAction,
  updateNoEcusInListAction,
  updateVehicleActionsLoadedAction,
  updateVehiclePriceAction,
  updateFileEncodingTypeAction,
  // request
  uploadFileAction,
  requestBillingOrderAction,
  requestPaymentAction,
  clearAction,
  processFileAction,
  returnEcuSuggestionsAction,
  // interfaces
  IFileInfo,
  IFileData,
  IPrice,
  ISuggectECUs,
  IRequestFileResponse,
} from 'private-layout/add-file/reducer';
// UI
import Layout from 'components/layout/Common';
import Preloader from 'components/Preloader';
import CustomSelect from 'components/select/Select';
import CustomCheckbox from 'components/checkbox/Checkbox';
import CustomTextField from 'components/input/TextField';
import PaymentApproveDialog from 'components/dialog/PaymentApprove';
import ECUsList from 'private-layout/add-file/components/ECUsList';
import FileSoft from 'private-layout/add-file/components/FileSoft';
// constants
import { APP_COLOR, CONSTRAINT } from 'constants/style';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { IUser } from 'reducers';

interface IStyleProps {
  uploadEcuSuccess?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingBottom: 50,
  },
  oldPageLink: {
    fontSize: 14,
    marginLeft: 12,
  },
  actionsList: {
    margin: 0,
  },
  uploadWarningContainer: {
    padding: 15,
    fontSize: 'small',
    color: '#f44336',
    margin: '20px 0 10px 16px',
  },
  newReleaseIcon: {
    margin: '10px 0 0 -80px',
    position: 'absolute',
    width: 50,
    height: 50,
  },
  fileInfoContainer: {
    marginTop: 20,
    marginLeft: 20,
    lineHeight: '25px',
    overflow: 'hidden',
  },
  justifyEcuContainer: {
    marginTop: 20,
  },
  removeDtc: {
    marginTop: 16,
  },
  addFile: {
    padding: '20px 0',
  },
  priceContainer: {
    lineHeight: '25px',
    paddingLeft: 20,
  },
  payInfoContainer: {
    marginBottom: 10,
    marginTop: 20,
    padding: 15,
    fontSize: 'small',
    color: '#f44336',
  },
  processedDtcContainer: {
    padding: 15,
    maxWidth: 350,
    maxHeight: 100,
    margin: '8px 0 8px 1em',
    overflowY: 'auto',
  },
  noEcusMessage: {
    marginTop: 20,
  },
  optionsListContainer: {
    marginLeft: '20px',
  },
  rightBlockContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.spacing(2),
  },
  uploaderContainer: {
    display: 'flex',
    width: '40%',
    flexDirection: 'column',
  },
  preloader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    padding: '16px 0',
  },
  hideUploader: {
    visibility: 'hidden',
    position: 'absolute',
  },
  uploaderBtn: {
    backgroundColor: (props: IStyleProps) => (!props.uploadEcuSuccess ? theme.palette.primary.light : 'rgba(0, 0, 0, 0.26)'),
    padding: 8,
    cursor: 'pointer',
    width: 'max-content',
    minWidth: CONSTRAINT.BUTTON_WIDTH,
    textAlign: 'center',
    color: APP_COLOR.WHITE,
    borderRadius: 4,
    marginRight: 8,
    '&:hover': {
      backgroundColor: (props: IStyleProps) => (!props.uploadEcuSuccess ? theme.palette.primary.dark : 'rgba(0, 0, 0, 0.26)'),
    },
  },
  uploader: {
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    paddingTop: 8,
  },
  actionBtn: {
    minWidth: `calc(${CONSTRAINT.BUTTON_WIDTH}px + 16px)`,
  },
  denyTextTransform: {
    textTransform: 'none',
  },
  alert: {
    marginTop: 40,
  },
  cardInfo: {
    marginTop: 20,
  },
  cardInfoTextLine: {
    whiteSpace: 'nowrap',
  },
  cardInfoStrong: {
    display: 'inline-block',
    width: 150,
  },
  cardInfoActionsStrong: {
    display: 'inline-block',
    width: 200,
  },
  processedActionContainer: {
    padding: '20px 0 0 20px',
    lineHeight: '25px',
  },
  cogwheel: {
    cursor: 'pointer',
  },
  returnEcuSuggestions: {
    marginTop: 16,
    cursor: 'pointer',
  },
}));
interface IProps {
  pageTitle: string;
  language: string;
  //
  suggectECUs?: ISuggectECUs[] | null;
  //
  vehicles?: IVehicle[] | null;
  vehicleBrands?: IVehicleBrand[] | null;
  vehicleEcus?: IVehicleEcus[] | null;
  vehicleActions?: IVehicleAction[] | null;
  processedDTCs?: string[];
  processedActions?: string[];
  manualActions?: string[];

  vehiclePrice?: IVehiclePrice | null;

  selectedEcusKey?: string | null;
  selectedBrandKey?: string | null;
  selectedEcuAndBrandKey?: string | null;
  allowDtc?: boolean;
  autoDtc?: boolean;
  dtcKey?: string;
  fileKey?: string;
  showInfo?: boolean;
  noEcusInList?: boolean;

  fileData?: IFileData | null;
  fileInfo?: IFileInfo;
  price?: IPrice | null;
  actions?: string[] | null;

  vehicleBrandsLoaded?: boolean;
  vehicleModelsLoaded?: boolean;
  vehicleEcusLoaded?: boolean;
  vehicleActionsLoaded?: boolean;
  fileProcessed?: boolean;

  expectAnswer?: boolean;
  expectBrandsAnswer?: boolean;
  expectEcusAnswer?: boolean;
  expectActionsAnswer?: boolean;
  expectUploadFileAnswer?: boolean;
  expectBillingOrderAnswer?: boolean;
  expectProcessFileAnswer?: boolean;
  expectRequestFileAnswer?: boolean;

  uploadEcuSuccess?: boolean;
  user?: IUser | null;
  fileEncodingType?: boolean;
  requestFileResponse?: IRequestFileResponse;

  // triggers
  loadVehicles?: () => void;
  loadVehicleBrands?: (vehicle: string) => void;
  loadVehicleEcus?: (engine: string | null) => void;
  loadVehicleActions?: (key: string | null) => void;
  loadVehicleActionsByBrandAndEcu?: () => void;
  loadVehiclePrice?: () => void;
  // update
  updateFileInfo?: (fileInfo: IFileInfo) => void;
  selectSuggectECUs?: (suggectECUKey: string) => void;
  //
  updateSelectedEcusKey?: (selectedEcusKey: string | null) => void;
  updateSelectedBrandKey?: (selectedBrandKey: string | null) => void;
  updateSelectedEcusAndBrandKey?: (selectedEcuAndBrandKey: string | null) => void;
  updateSelectActionState?: (actionKey: string) => void;
  updateDtc?: (dtcKey: string) => void;
  updateFileKey?: (fileKey: string) => void;
  updateShowInfo?: (showInfo: boolean) => void;
  updateNoEcusInList?: (noEcusInList: boolean) => void;
  updateVehicleActionsLoaded?: (vehicleActionsLoaded: boolean) => void;
  updateVehiclePrice?: (vehiclePrice: IVehiclePrice | null) => void;
  updateFileEncodingType?: (value: string) => void;
  // request
  uploadFile?: (data: {carFile: File | null, comment: string}) => void;
  requestBillingOrder?: () => void;
  clearState?: () => void;
  requestPayment?: () => void;
  processFile?: () => void;
  returnEcuSuggestions?: () => void;
}

function AddFile(props: IProps) {
  const {
    pageTitle,
    language,
    //
    suggectECUs,
    fileInfo,
    //
    vehicles,
    vehicleBrands,
    vehicleEcus,
    vehicleActions,
    processedDTCs,
    processedActions,
    manualActions,
    vehiclePrice,
    allowDtc,
    autoDtc,
    dtcKey,
    selectedEcusKey,
    selectedBrandKey,
    selectedEcuAndBrandKey,
    showInfo,
    noEcusInList,
    fileKey,
    fileData,
    price,
    actions,
    //
    vehicleBrandsLoaded,
    vehicleEcusLoaded,
    vehicleActionsLoaded,
    fileProcessed,
    //
    expectAnswer,
    expectBrandsAnswer,
    expectEcusAnswer,
    expectActionsAnswer,
    expectUploadFileAnswer,
    expectBillingOrderAnswer,
    expectProcessFileAnswer,
    expectRequestFileAnswer,
    requestFileResponse,
    //
    uploadEcuSuccess,
    //
    loadVehicles,
    loadVehicleBrands,
    loadVehicleEcus,
    loadVehicleActions,
    loadVehicleActionsByBrandAndEcu,
    loadVehiclePrice,
    //
    updateFileInfo,
    selectSuggectECUs,
    //
    updateSelectActionState,
    updateDtc,
    updateSelectedEcusKey,
    updateSelectedBrandKey,
    updateSelectedEcusAndBrandKey,
    // updateShowInfo,
    updateNoEcusInList,
    updateVehicleActionsLoaded,
    updateVehiclePrice,
    updateFileEncodingType,
    //
    uploadFile,
    requestBillingOrder,
    clearState,
    requestPayment,
    processFile,
    returnEcuSuggestions,
    user,
    fileEncodingType
  } = props;
  const classes = useStyles({ uploadEcuSuccess });
  const { t } = useTranslation();
  const [carFile, setCarFile] = useState<null | File>(null);
  const [openPayment, setOpenPayment] = useState<boolean>(false);
  const [rejectEcusList, setRejectEcusList] = useState<boolean>(false);
  const [errorDtc, setErrorDtc] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');

  const isDTC = allowDtc && dtcKey;
  const isActionSelected = Array.isArray(vehicleActions) && vehicleActions.some((vehicleAction: IVehicleAction) => vehicleAction.selected);
  const allowProcessFile = isDTC || isActionSelected;
  const isNoSuggestedEcus = !Array.isArray(suggectECUs) && !rejectEcusList;
  const isEmptySuggestedEcusList = Array.isArray(suggectECUs) && !suggectECUs.length && !rejectEcusList;
  const isNoActions = !expectActionsAnswer && vehicleActionsLoaded && ((Array.isArray(vehicleActions) && !vehicleActions.length) || !vehicleActions);

  useEffect(() => {
    loadVehicles && loadVehicles();
    return () => {
      clearState && clearState();
    };
  }, [clearState, loadVehicles]);
  const handleVehicle = (e: React.ChangeEvent<HTMLSelectElement>) => {
    loadVehicleBrands && loadVehicleBrands(e.target.value);
  };
  const handleBrand = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateSelectedBrandKey && updateSelectedBrandKey(e.target.value);
    loadVehicleEcus && loadVehicleEcus(e.target.value);
  };
  const handleProcessFile = () => {
    processFile && processFile();
  };
  const handleFileEncodingType = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFileEncodingType && updateFileEncodingType(e.target.value);
  };
  const handleNoEcusInList = () => {
    setRejectEcusList(true);
    updateNoEcusInList && updateNoEcusInList(true);
  };
  const handleBrandByEcu = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateSelectedEcusAndBrandKey && updateSelectedEcusAndBrandKey(e.target.value);
    loadVehicleActionsByBrandAndEcu && loadVehicleActionsByBrandAndEcu();
  };
  const handleEcusLabel = (ecusOption: IVehicleEcus) => ecusOption.name;
  const handleEcus = (e: any, value: IVehicleEcus | null) => {
    const flattenValue = value ? value.key : null;
    updateSelectedEcusKey && updateSelectedEcusKey(flattenValue);
    // force clean price on each ecus change
    updateVehiclePrice && updateVehiclePrice(null);
    if (flattenValue) {
      loadVehicleActions && loadVehicleActions(flattenValue);
    } else {
      // hide all actions data on ecus delete
      updateVehicleActionsLoaded && updateVehicleActionsLoaded(false);
    }
  };
  const handleAction = (actionKey: string) => () => {
    updateSelectActionState && updateSelectActionState(actionKey);
    handleCalculatePrice();
  };

  const validateDtc = (dtc: string) => {
      if (dtc.length === 0) {
          return true;
      }

      const reg = new RegExp("^[0-9a-fA-F]{4}");

      const dtcArr = dtc.split(",");
      for (let i = 0; i < dtcArr.length; i++) {
        const valid = reg.test(dtcArr[i]);
        if (!valid) {
          return false;
        }
      }

      return true;
  }

  const handleComment = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  }

  const handleDtc = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateDtc && updateDtc(e.target.value);
    const valid = validateDtc(e.target.value);
    if (!valid) {
      setErrorDtc(true);
    } else {
      setErrorDtc(false);
    }
    const isRecalculatePrice = (!dtcKey?.trim().length && Boolean(e.target.value.trim().length)) || (dtcKey?.trim().length && !e.target.value.trim().length);
    // recalculate only when dtc was empty or become empty
    if (isRecalculatePrice) handleCalculatePrice();
  };
  const handleCarFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cFile = e.target.files ? e.target.files[0] : null;
    updateFileInfo && updateFileInfo({ fileName: cFile?.name, size: cFile?.size });
    setCarFile(cFile);
  };
  const handleFileUploadSubmit = () => {
    uploadFile && uploadFile({carFile, comment});
  };
  const handleCalculatePrice = () => {
    if ((selectedEcusKey && selectedBrandKey) || selectedEcuAndBrandKey) loadVehiclePrice && loadVehiclePrice();
  };
  const handleBillingOrder = () => {
    requestBillingOrder && requestBillingOrder();
    handlePaymentOpen();
  };

  const handlePaymentOpen = () => {
    setOpenPayment(true);
  };
  const handlePaymentClose = () => {
    setOpenPayment(false);
  };
  const handlePayment = () => {
    requestPayment && requestPayment();
    //handlePaymentClose();
  };

  const handleReturnEcuSuggestions = () => {
    setRejectEcusList(false);
    returnEcuSuggestions && returnEcuSuggestions();
  };

  return (
    <Layout pageTitle={pageTitle} language={language}>
      <Typography variant='h5'>
        <span>{t('add_file.title')}</span>
      </Typography>
      {expectAnswer && <Preloader pageCentered />}
      {user?.isMms && (
        <RadioGroup name="fileEncodingType" onChange={handleFileEncodingType}>
          <FormControlLabel value="bin" control={<Radio />} label="Firmware (.bin)"  disabled={uploadEcuSuccess} />
          <FormControlLabel value="mms" control={<Radio />} label="Magic Motorsport Slave (.mmf)"  disabled={uploadEcuSuccess}  />
        </RadioGroup>
      )}
      {!expectAnswer && (!user?.isMms || (user?.isMms && fileEncodingType)) && (
        <div className={classes.root}>
          <div className={classes.uploaderContainer}>
            <Typography className={classes.alert} variant='h6'>
              {t('add_file.upload_file')}
            </Typography>
            <Paper elevation={3} className={classes.uploadWarningContainer}>
              <NewReleasesOutlinedIcon className={classes.newReleaseIcon} />
              <strong>{t('add_file.before_upload_warning_title')}</strong>
              <br />
              {t('add_file.before_upload_warning_text')}
            </Paper>
            <div className={classes.uploader}>
              <label className={classes.uploaderBtn}>
                {t('button_title.choose_file')}
                {!uploadEcuSuccess && <input type='file' className={classes.hideUploader} onChange={handleCarFile} />}
              </label>
            </div>
            {fileInfo && carFile && (
              <div className={classes.fileInfoContainer}>
                {Boolean(fileInfo.fileName) && (
                  <span className={classes.cardInfoTextLine}>
                    <strong className={classes.cardInfoStrong}>{t('add_file.file_name')}</strong> {fileInfo.fileName}
                  </span>
                )}
                <br />
                {Boolean(fileInfo.size) && (
                  <span className={classes.cardInfoTextLine}>
                    <strong className={classes.cardInfoStrong}>{t('add_file.file_size')}</strong> {fileInfo.size} bytes
                  </span>
                )}
                <br />
                {Boolean(fileInfo.key) && (
                  <>
                    <span className={classes.cardInfoTextLine}>
                      <strong className={classes.cardInfoStrong}>{t('add_file.file_key')}</strong> {fileInfo.key}
                    </span>
                    <br />
                  </>
                )}
                {Boolean(fileInfo.ecu) && (
                  <>
                    <span className={classes.cardInfoTextLine}>
                      <strong className={classes.cardInfoStrong}>{t('add_file.ecu')}</strong> {fileInfo.ecu}
                    </span>
                    <br />
                  </>
                )}
                {Boolean(fileInfo.soft) && (
                  <span className={classes.cardInfoTextLine}>
                    <strong className={classes.cardInfoStrong}>{t('add_file.soft')}</strong>
                    <FileSoft fileSoft={String(fileInfo.soft)} />
                  </span>
                )}
                <CustomTextField onChange={handleComment} label={t('input.label.comment')} placeholder={t('input.placeholder.comment')} value={comment} />
              </div>
            )}

            {fileInfo && carFile && !uploadEcuSuccess && (
              <div className={classes.actions}>
                <Button variant='contained' color='primary' onClick={handleFileUploadSubmit} className={classes.actionBtn}>
                  {t('button_title.upload')}
                </Button>
              </div>
            )}
            {expectUploadFileAnswer && (
              <div className={classes.preloader}>
                <Preloader />
              </div>
            )}
            {uploadEcuSuccess && !rejectEcusList && !noEcusInList && (
              <>
                <ECUsList selectSuggectECUs={selectSuggectECUs} suggectECUs={suggectECUs} />
                <div className={classes.actions}>
                  <Button variant='contained' color='primary' onClick={handleNoEcusInList} className={classNames(classes.actionBtn, classes.denyTextTransform)}>
                    {t('button_title.no_ecu_in_list')}
                  </Button>
                </div>
                <div className={classes.optionsContainer}>
                  {expectBrandsAnswer && <Preloader />}
                  {!expectBrandsAnswer && vehicleBrandsLoaded && (
                    <CustomSelect label={t('select_label.brand')} options={vehicleBrands} onChange={handleBrandByEcu} />
                  )}
                </div>
              </>
            )}
            {uploadEcuSuccess && noEcusInList && (
              <>
                {!isNoSuggestedEcus && !isEmptySuggestedEcusList && rejectEcusList && (
                  <MaterialLink className={classes.returnEcuSuggestions} onClick={handleReturnEcuSuggestions}>
                    {t('add_file.return_ecu_suggestions')}
                  </MaterialLink>
                )}
                {isNoSuggestedEcus && <Typography className={classes.noEcusMessage}>{t('add_file.no_ecus_text1')}</Typography>}
                {isEmptySuggestedEcusList && <Typography className={classes.noEcusMessage}>{t('add_file.no_ecus_text2')}</Typography>}
                {rejectEcusList && (
                  <Typography style={{ marginTop: 20 }} variant='h6'>
                    {t('add_file.no_ecus_text3')}
                  </Typography>
                )}
              </>
            )}
            {uploadEcuSuccess && noEcusInList && (
              <>
                <div className={classes.justifyEcuContainer}>
                  <CustomSelect label={t('select_label.vehicle')} options={vehicles} onChange={handleVehicle} />
                  <div className={classes.optionsContainer}>
                    {expectBrandsAnswer && <Preloader />}
                    {!expectBrandsAnswer && vehicleBrandsLoaded && (
                      <CustomSelect label={t('select_label.brand')} options={vehicleBrands} onChange={handleBrand} />
                    )}
                  </div>
                  <div className={classes.optionsContainer}>
                    {expectEcusAnswer && <Preloader />}
                    {!expectEcusAnswer && vehicleEcusLoaded && vehicleEcus && (
                      <Autocomplete
                        options={vehicleEcus}
                        onChange={handleEcus}
                        getOptionLabel={handleEcusLabel}
                        renderInput={(params) => <CustomTextField {...params} label={t('select_label.ecus')} />}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={classes.rightBlockContainer}>
            {!expectActionsAnswer && vehicleActionsLoaded && (
              <Typography className={classes.alert} variant='h6'>
                {t('add_file.available_actions')}
              </Typography>
            )}
            <div className={classes.optionsListContainer}>
              <div className={classes.optionsContainer}>
                {expectActionsAnswer && <Preloader />}

                {!expectActionsAnswer &&
                  vehicleActionsLoaded &&
                  Array.isArray(vehicleActions) &&
                  vehicleActions.map((vehicleAction: IVehicleAction) => (
                    <div key={vehicleAction.key}>
                      <CustomCheckbox
                        label={vehicleAction.name}
                        checked={vehicleAction.selected}
                        onChange={handleAction(vehicleAction.key)}
                        disabled={!vehicleAction.available}
                      />
                      {Boolean(vehicleAction.auto) && (
                        <Tooltip title={String(t('add_file.cogwheel_tooltip'))} arrow placement='right'>
                          <span role='img' aria-label='cogwheel' className={classes.cogwheel}>
                            ⚙️
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  ))}
                {isNoActions && <Typography>{t('add_file.no_actions')}</Typography>}
              </div>
            </div>
            {!expectActionsAnswer && vehicleActionsLoaded && (autoDtc || !autoDtc) && (
              <Typography className={classes.removeDtc} variant='h6'>
                <span>{`${t('add_file.remove_dtc')} `}</span>
                {Boolean(autoDtc) && (
                  <Tooltip title={String(t('add_file.remove_dtc_tooltip'))} arrow placement='right'>
                    <span role='img' aria-label='cogwheel' className={classes.cogwheel}>
                      ⚙️
                    </span>
                  </Tooltip>
                )}
              </Typography>
            )}
            <div className={classes.optionsListContainer}>
              {!expectActionsAnswer && vehicleActionsLoaded && allowDtc && (
                <CustomTextField helperText={t("add_file.errorDtc")} error={errorDtc} onChange={handleDtc} label={t('input.label.dtc')} placeholder={t('input.placeholder.dtc')} value={dtcKey} />
              )}
              {!expectActionsAnswer && vehicleActionsLoaded && !allowDtc && <Typography style={{ marginTop: 20 }}>{t('add_file.dtc_not_allowed')}</Typography>}
            </div>
            {showInfo && (
              <>
                <Typography className={classes.addFile} variant='h6'>
                  {t('add_file.price')}
                </Typography>
                <div className={classes.priceContainer}>
                  <span className={classes.cardInfoTextLine}>
                    <strong className={classes.cardInfoStrong}>{t('add_file.base_price')}</strong> {String(vehiclePrice?.basePrice || 0)} грн. (UAH)
                  </span>
                  <br />
                  {Boolean(vehiclePrice?.discount) && (
                    <>
                      <span className={classes.cardInfoTextLine}>
                        <strong className={classes.cardInfoStrong}>{t('add_file.discount')}</strong> {String(vehiclePrice?.discount || 0)}%
                      </span>
                      <br />
                    </>
                  )}
                  {Boolean(vehiclePrice?.bonus) && (
                    <>
                      <span className={classes.cardInfoTextLine}>
                        <strong className={classes.cardInfoStrong}>{t('add_file.bonus')}</strong> {String(vehiclePrice?.bonus || 0)} грн. (UAH)
                      </span>
                      <br />
                    </>
                  )}
                  <span className={classes.cardInfoTextLine}>
                    <strong className={classes.cardInfoStrong}>{t('add_file.to_pay')}</strong> {String(vehiclePrice?.toPay || 0)} грн. (UAH)
                  </span>
                </div>
                {allowProcessFile && (
                  <div className={classes.actions}>
                    <Button
                      disabled={errorDtc}
                      variant='contained'
                      color='primary'
                      onClick={handleProcessFile}
                      className={classNames(classes.actionBtn, classes.denyTextTransform)}
                    >
                      {t('button_title.process_file')}
                    </Button>
                  </div>
                )}
              </>
            )}
            {expectProcessFileAnswer && <Preloader />}
            {!expectProcessFileAnswer && fileProcessed && (
              <>
                <div className={classes.processedActionContainer}>
                  {Array.isArray(processedDTCs) && Boolean(processedDTCs.length) && (
                    <>
                      <span className={classes.cardInfoTextLine}>
                        <strong className={classes.cardInfoActionsStrong}>{t('add_file.processed_dtcs')}:</strong>
                      </span>
                      <br />
                      <Paper elevation={3} className={classes.processedDtcContainer}>
                        {processedDTCs.join(', ')}
                      </Paper>
                    </>
                  )}
                  {Array.isArray(processedActions) && Boolean(processedActions.length) && (
                    <>
                      <span className={classes.cardInfoTextLine}>
                        <strong className={classes.cardInfoActionsStrong}>{t('add_file.processed_actions')}:</strong>
                      </span>
                      <br />
                      <ul className={classes.actionsList}>
                        {processedActions.map((pAction: string) => (
                          <li key={pAction}>{pAction}</li>
                        ))}
                      </ul>
                    </>
                  )}
                  {Array.isArray(manualActions) && Boolean(manualActions.length) && (
                    <>
                      <span className={classes.cardInfoTextLine}>
                        <strong className={classes.cardInfoActionsStrong}>{t('add_file.manual_actions')}:</strong>
                      </span>
                      <br />
                      <ul className={classes.actionsList}>
                        {manualActions.map((mAction: string) => (
                          <li key={mAction}>{mAction}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
                <Paper elevation={3} className={classes.payInfoContainer}>
                  <strong>{t('add_file.pay_title')}</strong>
                  <br />
                  {Array.isArray(manualActions) && Boolean(manualActions.length) && <>{t('add_file.pay_info')}</>}
                  {(!Array.isArray(manualActions) || !manualActions.length) && (Boolean(processedActions?.length) || Boolean(processedDTCs?.length)) && (
                    <>{t('add_file.pay_info2')}</>
                  )}
                  {!processedActions?.length && !processedDTCs?.length && !manualActions?.length && <>{t('add_file.pay_info3')}</>}
                </Paper>
                <div className={classes.actions}>
                  {(Boolean(processedActions?.length) || Boolean(processedDTCs?.length) || Boolean(manualActions?.length)) && (
                    <Button variant='contained' color='primary' onClick={handleBillingOrder} className={classes.actionBtn}>
                      {t('button_title.pay')}
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {openPayment && (
        <PaymentApproveDialog
          open={openPayment}
          handleClose={handlePaymentClose}
          handlePayment={handlePayment}
          fileData={fileData}
          price={price}
          actions={actions}
          expectOrderAnswer={Boolean(expectBillingOrderAnswer)}
          expectRequestFileAnswer={Boolean(expectRequestFileAnswer)}
          requestFileResponse={requestFileResponse}
          selectedFileKey={fileKey}
        />
      )}
    </Layout>
  );
}

export default connect(
  (state) => addFileSelector(state),
  (dispatch) => ({
    loadVehicles: () => dispatch(loadVehiclesAction()),
    loadVehicleBrands: (vehicle: string) => dispatch(loadVehicleBrandsAction(vehicle)),
    loadVehicleEcus: (engine: string) => dispatch(loadVehicleEcusAction(engine)),
    loadVehicleActions: (key: string) => dispatch(loadVehicleActionsAction(key)),
    loadVehicleActionsByBrandAndEcu: () => dispatch(loadVehicleActionsByBrandAndEcuAction()),
    loadVehiclePrice: () => dispatch(loadVehiclePriceAction()),
    //
    updateFileInfo: (fileInfo: IFileInfo) => dispatch(updateFileInfoAction(fileInfo)),
    selectSuggectECUs: (suggectECUKey: string) => dispatch(selectSuggectECUsAction(suggectECUKey)),
    //
    updateSelectedEcusKey: (selectedEcusKey: string) => dispatch(updateSelectedEcusKeyAction(selectedEcusKey)),
    updateSelectedBrandKey: (selectedBrandKey: string) => dispatch(updateSelectedBrandKeyAction(selectedBrandKey)),
    updateSelectedEcusAndBrandKey: (selectedEcuAndBrandKey: string | null) => dispatch(updateSelectedEcusAndBrandKeyAction(selectedEcuAndBrandKey)),
    updateSelectActionState: (actionKey: string) => dispatch(updateSelectActionStateAction(actionKey)),
    updateDtc: (dtcKey: string) => dispatch(updateDtcAction(dtcKey)),
    updateFileKey: (fileKey: string) => dispatch(updateFileKeyAction(fileKey)),
    updateShowInfo: (showInfo: boolean) => dispatch(updateShowInfoAction(showInfo)),
    updateNoEcusInList: (noEcusInList: boolean) => dispatch(updateNoEcusInListAction(noEcusInList)),
    updateVehicleActionsLoaded: (vehicleActionsLoaded: boolean) => dispatch(updateVehicleActionsLoadedAction(vehicleActionsLoaded)),
    updateVehiclePrice: (vehiclePrice: IVehiclePrice | null) => dispatch(updateVehiclePriceAction(vehiclePrice)),
    updateFileEncodingType: (value: string) => dispatch(updateFileEncodingTypeAction(value)),
    //
    uploadFile: (data: {carFile: File | null, comment: string}) => dispatch(uploadFileAction(data)),
    requestBillingOrder: () => dispatch(requestBillingOrderAction()),
    clearState: () => dispatch(clearAction()),
    requestPayment: () => dispatch(requestPaymentAction()),
    processFile: () => dispatch(processFileAction()),
    returnEcuSuggestions: () => dispatch(returnEcuSuggestionsAction()),
  })
)(AddFile);
