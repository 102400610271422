// outsource dependencies
import { fork, takeLatest, call, put, select } from 'redux-saga/effects';
import get from 'lodash/get';
// local dependencies
import {
  TYPE,
  updateExpectAnswerAction,
  updateExpectChangePasswordAnswerAction,
  updateUserAction,
  selector as userProfileSelector,
  updateNewPasswordSuccessAction,
  updateErrorAction,
} from 'private-layout/user-profile/reducer';
import API from 'services/request.service';
// constants
import { API_VALIDATION } from 'constants/error';

function* loadUserProfileSaga() {
  yield put(updateExpectAnswerAction(true));
  try {
    const { data } = yield call<any>(API, {
      url: '/client/profile',
      method: 'GET',
    });
    const userProfile = get(data, 'response', null);
    yield put(updateUserAction(userProfile));
    yield put(updateExpectAnswerAction(false));
  } catch (e) {
    console.error('loadUserProfileSaga error: ', e.response, e);
    yield put(updateExpectAnswerAction(false));
  }
}

function* changePasswordSaga() {
  const { newPassword, password } = yield select(userProfileSelector);
  yield put(updateExpectChangePasswordAnswerAction(true));
  try {
    yield call<any>(API, {
      url: '/client/change-password',
      method: 'POST',
      data: {
        newPassword,
        password,
      },
    });
    yield put(updateNewPasswordSuccessAction(true));
    yield put(updateExpectChangePasswordAnswerAction(false));
  } catch (e) {
    console.error('changePasswordSaga error: ', e.response, e);
    const errorKey = get(e, 'response.data.errorKey', null);
    if (errorKey === API_VALIDATION.password_not_valid) {
      yield put(updateErrorAction({ password: 'errors.validation_error.password_not_valid' }));
    }
    yield put(updateExpectChangePasswordAnswerAction(false));
  }
}

function* activityTasks() {
  yield takeLatest(TYPE.LOAD_PROFILE, loadUserProfileSaga);
  yield takeLatest(TYPE.CHANGE_PASSWORD, changePasswordSaga);
}

export function* sagas() {
  yield fork(activityTasks);
}

export default sagas;
